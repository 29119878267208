import React from 'react';
import CustomBlueButton from '../../ReUsableComponent/CustomBlueButton';
import { useHistory } from 'react-router-dom';

const LinkExpired = () => {
    const history = useHistory();
    const onClickGotoLogin = () => {
        history.push('/auth/adminLogin');
    }
    return (
        <div className='d-flex flex-column align-items-center h-100'>
            <div className='paddingTop32'>
                <div className='montSerratFont font32 fontMedium'>Link has been expired!!!</div>
            </div>
            <div className='pt-3  notoSansFont'>
                <CustomBlueButton customCreatePropsBtnClass='fontMedium' onClick={onClickGotoLogin} disabled={false} title={'Go To Login'} />
            </div>
        </div>
    )
}

export default LinkExpired