import React from "react";
import { Doughnut } from "react-chartjs-2";

class CustomDoughnutChart extends React.Component {

    render() {
        const { doughnutChartData, width, height } = this.props;
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            cutout: '75%',
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            let label = context.label || '';
                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed !== null) {
                                label += Math.round((context.parsed / context.dataset.data.reduce((a, b) => a + b, 0)) * 100) + '%';
                            }
                            return label;
                        },
                        // labelColor: function (tooltipItem, chart) {
                        //     if (chart) {
                        //         return {
                        //             borderColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor, // Color of the indicator border
                        //             backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor, // Color of the indicator background
                        //             // borderRadius: 50,
                        //         };
                        //     } else {
                        //         return {
                        //             // borderColor: '#4169E1', // Default border color
                        //             // backgroundColor: '#4169E1', // Default background color
                        //             // borderRadius: 50,
                        //         };
                        //     }
                        // }
                    },
                    backgroundColor: '#D0D5EA',
                    titleColor: '#676767',
                    bodyColor: '#676767',
                    // displayColors: false, 
                },
                datalabels: {
                    display: false,
                }
            },
        }


        return (
            <div className="d-flex">
                <Doughnut width={width} data={doughnutChartData} height={height} options={options} />
            </div>
        );
    }
}

export default CustomDoughnutChart;