import React, { useEffect, useState } from 'react';
import CustomFilterDropDown from '../../ReUsableComponent/CustomFilterDropdown';
import CustomDateRangePicker from '../../ReUsableComponent/CustomDaterangePicker';
import { GetBrandNameList } from '../RuleManagement/RuleManagement.service';
import { userAuthActions } from '../../store/reducers/UserAuthReducers';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { targetVsAchievementActions } from '../../store/reducers/TargetVsAchievement.reducer';
import { GetSalesYear } from '../SalesOverview/SalesOverview.service';
import { ToastErrorService } from '../../ReUsableComponent/ToastNotification/ToastService';
import moment from 'moment';
import dropDownIcon from '../../assets/svg/dropDownIcon.svg';
import CalendarIcon from '../../assets/svg/calendar_2_fill.svg';
import PercentIcon from '../../assets/svg/discount-value.svg';
import './targetVsAchievement.css';
import RsmWiseChart from './DahboardCharts/RsmWiseChart';
import Top5Stores from './DahboardCharts/Top5Stores';
import OverAllChart from './DahboardCharts/OverAllTargetChart';
import Bottom5StoreChart from './DahboardCharts/Bottom5StoreChart';
import StaffWisePerformanceChart from './DahboardCharts/StaffWisePerformanceChart';
import RegionWiseChart from './DahboardCharts/RegionWiseChart';
import SellThruChart from './DahboardCharts/SellThruChart';
import { GetDashboardData } from './TargetVsAchievement.serive';
import StaffAvailabilityChart from './DahboardCharts/StaffAvailabilityChart';
import Loader from '../../ReUsableComponent/Loader';
import CustomFilterSearchDropdown from '../../ReUsableComponent/CustomFilterSearchDropdown';
import dayjs from 'dayjs';

const TargetVsAchievement = () => {

    const today = new Date();
    today.setDate(1)
    today.setHours(-1)
    const firstDayOfMonth = new Date(today.getFullYear(), (today.getMonth()));
    const history = useHistory();
    const dispatch = useDispatch();
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [brandList, setBrandList] = useState([]);
    const [salesYearList, setSalesYearList] = useState([]);
    const [anchorElForBrand, setAnchorElForBrand] = useState(null);
    const openBrandFilter = Boolean(anchorElForBrand);
    const [anchorElForSales, setAnchorElForSales] = useState(null);
    const openSalesYearFilter = Boolean(anchorElForSales);
    const [selectedBrand, setSelectedBrand] = useState({ label: '', value: '' });
    const [selectedSalesYear, setSelectedSalesYear] = useState({ label: '', value: '' });
    const [startDateDisplay, setStartDateDisplay] = useState(null);
    const [endDateDisplay, setEndDateDisplay] = useState(null);
    const [selectedRange, setSelectedRange] = useState([firstDayOfMonth, today]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const defaultYear = useSelector(state => state.target.defaultYear);
    const defaultBrand = useSelector(state => state.target.defaultBrand);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [dashboardData, setDashboardData] = useState([]);
    const [regionWiseChartData, setRegionWiseChartData] = useState([]);
    const [staffWisePerformanceChartData, setStaffWisePerformanceChartData] = useState([]);
    const [rsmWiseChartData, setRsmWiseChartData] = useState([]);
    const [topStoreChartData, setTopStoreChartData] = useState([]);
    const [botStoreChartData, setBotStoreChartData] = useState([]);
    const [disabledDateRange, setDisabledDateRange] = useState(true);
    const [dropSearchValue, setdropSearchValue] = React.useState('');

    const onChangeSlectedBrand = useSelector(state => state?.target?.defaultBrandForRecon);
    const USER_ID = useSelector((state) => state.userAuthentication.userId);

    useEffect(() => {
        dispatch(userAuthActions.setHeaderName({ type: 'Target vs Achievement' }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: false }));
        getAllBrand();
        getSalesYearList();
    }, [])

    useEffect(() => {
        if (defaultBrand && defaultYear) {
            getDashboardData(defaultYear, defaultBrand);
        }
    }, [defaultYear, defaultBrand])


    const openCalendar = () => {
        setCalendarOpen(prev => !prev)
    }

    const onClickGotoMaster = () => {
        history.push('/app/targetVsAchievementListView');
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
        dispatch(targetVsAchievementActions.setDefaultBrandForRecon({ type: onChangeSlectedBrand }));
        dispatch(targetVsAchievementActions.setBrandId({ type: selectedBrand?.value }));
    }

    const handleBrandClick = (event) => {
        setAnchorElForBrand(event.currentTarget);
    };
    const handleBrandClose = () => {
        setAnchorElForBrand(null);
    };

    const handleSalesClick = (event) => {
        setAnchorElForSales(event.currentTarget);
    };
    const handleSalesClose = () => {
        setAnchorElForSales(null);
    };

    const onSelectBrand = (event) => {
        brandList.forEach(brand => {
            if (event.target.value === brand?.value) {
                setSelectedBrand({ label: brand?.label, value: event.target.value })
                setAnchorElForBrand(null);
                dispatch(targetVsAchievementActions.setDefaultBrandForRecon({ type: brand }));
            }
        });
        dispatch(targetVsAchievementActions.setDefaultBrand({ type: event.target.value }));
    }

    const onSelectSalesYear = (event) => {
        if (event.target.value !== '') {
            dispatch(targetVsAchievementActions.setDefaultYear({ type: event.target.value }));
            setStartDateDisplay(null);
            setEndDateDisplay(null);
            setDisabledDateRange(true);
        } else {
            setDisabledDateRange(false);
        }
        if (event.target.value === '') {
            setSelectedSalesYear({ label: 'Custom', value: event.target.value });
        } else {
            setSelectedSalesYear({ label: event.target.value, value: event.target.value });
        }
        setAnchorElForSales(null);
    }

    const getAllBrand = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let brands = []
                response.data.data.forEach(element => {
                    let brandObject = {
                        label: element.brand_name,
                        value: element._id
                    }
                    brands.push(brandObject)
                });
                dispatch(targetVsAchievementActions.setDefaultBrand({ type: brands[0]?.value }));
                dispatch(targetVsAchievementActions.setDefaultBrandForRecon({ type: brands[0] }));
                setBrandList(brands);
                setSelectedBrand({ label: brands[0]?.label, value: brands[0]?.value });
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        })
    }
    const getSalesYearList = () => {
        GetSalesYear(USER_TOKEN).then(response => {
            let salesYear = []
            response.data.data.forEach(element => {
                let salesObj = {
                    label: element,
                    value: element
                }
                salesYear.push(salesObj)
            });
            salesYear.push({ label: 'Custom', value: '' });
            setSalesYearList(salesYear);
            dispatch(targetVsAchievementActions.setDefaultYear({ type: salesYear[0]?.value }));
            setSelectedSalesYear({ label: salesYear[0]?.label, value: salesYear[0]?.value });
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        })
    }

    const convertDate = (openingDate) => {
        let date = new Date(openingDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return moment(dateString).format("DD MMM YYYY");
    }

    // const handleDateRangeSelect = (date) => {
    //     if (!startDate || (startDate && endDate)) {
    //         setStartDate(date);
    //         setEndDate(null);
    //         setStartDateDisplay(date);
    //         setSelectedRange([]);
    //     } else if (startDate && !endDate && date > startDate) {
    //         setEndDate(date);
    //         setEndDateDisplay(date);
    //         setSelectedRange([startDate, date]);
    //         setCalendarOpen(false);
    //         getDashboardData('', selectedBrand.value, startDate, date);
    //     } else if (startDate && !endDate && date < startDate) {
    //         setStartDate(date);
    //         setStartDateDisplay(date);
    //     }
    // };

    const convertDateStringToObj = (dateString) => {
        const dateInIST = moment.tz(dateString, 'Asia/Kolkata');
        const finalFormattedDate = new Date(dateInIST.format());
        return finalFormattedDate
    }

    const handleDateRangeSelect = (date) => {
        if (!startDate || (startDate && endDate)) {
            setStartDate(date);
            setEndDate(null);
            setStartDateDisplay(date);
            setSelectedRange([]);
        } else if (startDate && !endDate && date > startDate) {
            let endDay = dayjs(date).endOf('day');
            setEndDate(convertDateStringToObj(endDay?.toISOString()));
            setEndDateDisplay(convertDateStringToObj(endDay?.toISOString()));
            setSelectedRange([startDate, convertDateStringToObj(endDay?.toISOString())]);
            setCalendarOpen(false)
            getDashboardData('', selectedBrand.value, startDate, convertDateStringToObj(endDay?.toISOString()));
        } else if (startDate && !endDate && date < startDate) {
            setStartDate(date);
            setStartDateDisplay(date);
        }
    };

    const getDashboardData = (year, brand, fromDate, toDate) => {
        setLoading(true);
        let param = '';
        if (fromDate && toDate) {
            param = `?brand_id=${brand}&from_date=${fromDate}&to_date=${toDate}`;
        } else {
            param = `?view_range=${year}&brand_id=${brand}`;
        }
        GetDashboardData(param).then(response => {
            setDashboardData(response.data.data);
            // Region Wise Chart Data
            let regionTarget = [];
            let regionAchieved = [];
            let regionLabel = [];
            response?.data?.data?.regionWiseValues?.forEach(item => {
                regionTarget.push(Math.round(item?.totalValueSum));
                regionAchieved.push(Math.round(item?.achievementValueSum));
                regionLabel.push(item?.region);
            })
            setRegionWiseChartData(prev => ({ ...prev, regionTarget, regionAchieved, regionLabel }));

            // Staff Wise Performance chart data
            let staffPerformanceTarget = [];
            let staffPerformanceAchieved = [];
            let staffPerformanceLabel = [];
            response?.data?.data?.getSumByBrandStaffName?.slice(0, 5)?.forEach(item => {
                staffPerformanceTarget.push(Math.round(item?.total_value_sum));
                staffPerformanceAchieved.push(Math.round(item?.achievementValue_sum));
                staffPerformanceLabel.push(item?.brand_staff_name !== 'null' ? item?.brand_staff_name : 'Others');
            })
            setStaffWisePerformanceChartData(prev => ({ ...prev, staffPerformanceTarget, staffPerformanceAchieved, staffPerformanceLabel }));

            // RSM Wise chart data
            let rsmWiseTarget = [];
            let rsmWiseAchieved = [];
            let rsmWiseLabel = [];
            response?.data?.data?.getValuesOnRM?.forEach(item => {
                rsmWiseTarget.push(Math.round(item?.total_value_sum));
                rsmWiseAchieved.push(Math.round(item?.achievementValue_sum));
                rsmWiseLabel.push(item?.brand_staff_name ? item?.brand_staff_name !== 'null' ? item?.brand_staff_name : 'Others' : 'Others');
            })
            setRsmWiseChartData(prev => ({
                ...prev, rsmWiseTarget, rsmWiseAchieved, rsmWiseLabel
            }));

            // Top Store chart data
            let topStoreTarget = [];
            let topStoreAchieved = [];
            let topStoreLabel = [];
            response?.data?.data?.topBottomstoreValues?.slice(0, 5)?.forEach(item => {
                topStoreTarget.push(Math.round(item?.totalValueSum));
                topStoreAchieved.push(Math.round(item?.achievementValueSum));
                topStoreLabel.push(item?.store_name);
            })
            setTopStoreChartData(prev => ({ ...prev, topStoreTarget, topStoreAchieved, topStoreLabel }));

            // Bot Store chart data
            let botStoreTarget = [];
            let botStoreAchieved = [];
            let botStoreLabel = [];
            response?.data?.data?.topBottomstoreValues.slice(-5)?.reverse()?.forEach(item => {
                botStoreTarget.push(Math.round(item?.totalValueSum));
                botStoreAchieved.push(Math.round(item?.achievementValueSum));
                botStoreLabel.push(item?.store_name);
            })
            setBotStoreChartData(prev => ({ ...prev, botStoreTarget, botStoreAchieved, botStoreLabel }));
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            setLoading(false);
        })

    }

    const handleSearchChange = (event) => {
        setdropSearchValue(event.target.value);
    };


    return (
        <div className='main-cont pb-4'>
            <div className='d-flex justify-content-between filter-cont'>
                <div className='d-flex align-items-center first-filter'>
                    <div className='goToMasterBtn me-3' onClick={onClickGotoMaster}>Go to Master</div>
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleBrandClick}>
                            <p className='text-truncate' title={selectedBrand.label}>{selectedBrand?.label}</p>
                            <img className={`${openBrandFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterSearchDropdown data={brandList} handleClose={handleBrandClose} open={openBrandFilter} anchorEl={anchorElForBrand}
                            onChange={onSelectBrand} selectedValue={selectedBrand?.value} category={'Brand'}
                            dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleSalesClick}>
                            <p className='text-truncate' title={selectedSalesYear.label}>{selectedSalesYear?.label}</p>
                            <img className={`${openSalesYearFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterDropDown data={salesYearList} handleClose={handleSalesClose} open={openSalesYearFilter} anchorEl={anchorElForSales}
                            onChange={onSelectSalesYear} selectedValue={selectedSalesYear?.value} category={'Category'} />
                    </div>
                    <div className='position-relative'>
                        <button className={`d-flex dateRangeFilter cursorPointer align-items-center ${!disabledDateRange ? 'justify-content-between' : ''}`} disabled={disabledDateRange}
                            onClick={openCalendar} >
                            <img src={CalendarIcon} alt="" />
                            {(startDateDisplay && endDateDisplay) ? <div>{`${convertDate(startDateDisplay)} - ${convertDate(endDateDisplay)}`}</div> : <div className='text-center w-100'>Select Date</div>}
                        </button>
                        <div className=''>
                            <CustomDateRangePicker startDate={startDate} endDate={endDate} calendarOpen={calendarOpen} selectedRange={selectedRange} handleSelect={handleDateRangeSelect}
                                closeOnOutSide={() => setCalendarOpen(false)} />
                        </div>
                    </div>
                </div>
            </div>
            <div id='card-parent' className='over-all'>
                <div className='card-bg'>
                    <div className='d-flex align-items-center'>
                        <div className='icon-bg'>
                            <img src={PercentIcon} width={20} height={20} alt="" />
                        </div>
                        <div className='montSerratFont fontSize18 textColorBlack'>Overall Target VS Achievement</div>
                    </div>
                    {dashboardData?.overallTargetAchievements && <div className='pt-3'>
                        <OverAllChart data={dashboardData?.overallTargetAchievements} />
                        <div className='paddingTop32'>
                            <div className='value-cont'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='notoSansFont fontSize16 textColorGray'>Target</div>
                                    <div className='notoSansFont fontSemiBold textColorBlack fontSize24'>{Math.round(dashboardData?.overallTargetAchievements?.totalValueSum)}</div>
                                </div>
                                <div className='gray-border'></div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='notoSansFont fontSize16 textColorGray'>Achievement</div>
                                    <div className='notoSansFont fontSemiBold textColorBlack fontSize24'>{Math.round(dashboardData?.overallTargetAchievements?.achievementValueSum)}</div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className='card-bg'>
                    <div className='header-cont'>
                        <div className='montSerratFont fontSize18 textColorBlack'>Region Wise Performace</div>
                        <div className='region-bar-color'>
                            <div className='color-cont'>
                                <div className='target'></div>
                                <div>Target</div>
                            </div>
                            <div className='color-cont'>
                                <div className='achievement'></div>
                                <div>Achievement</div>
                            </div>
                        </div>
                    </div>
                    {regionWiseChartData && <div className='pt-3'>
                        <RegionWiseChart chartData={regionWiseChartData} />
                    </div>}
                </div>
            </div>
            <div id='card-parent' className='top-bottom'>
                <div className='card-bg'>
                    <div className='header-cont'>
                        <div className='montSerratFont fontSize18 textColorBlack'>Target VS Achievement - Top 5 Store</div>
                        <div className='top-bar-color'>
                            <div className='color-cont'>
                                <div className='target'></div>
                                <div>Target</div>
                            </div>
                            <div className='color-cont'>
                                <div className='achievement'></div>
                                <div>Achievement</div>
                            </div>
                        </div>
                    </div>
                    {topStoreChartData && <div className='pt-3'>
                        <Top5Stores chartData={topStoreChartData} />
                    </div>}
                </div>
                <div className='card-bg'>
                    <div className='header-cont'>
                        <div className='montSerratFont fontSize18 textColorBlack'>Target VS Achievement - Bottom 5 Store</div>
                        <div className='bottom-bar-color'>
                            <div className='color-cont'>
                                <div className='target'></div>
                                <div>Target</div>
                            </div>
                            <div className='color-cont'>
                                <div className='achievement'></div>
                                <div>Achievement</div>
                            </div>
                        </div>
                    </div>
                    {botStoreChartData && <div className='pt-3'>
                        <Bottom5StoreChart chartData={botStoreChartData} />
                    </div>}
                </div>
            </div>
            <div id='card-parent' className='sell-thru'>
                <div className='card-bg'>
                    <div className='d-flex align-items-center'>
                        <div className='icon-bg'>
                            <img src={PercentIcon} width={20} height={20} alt="" />
                        </div>
                        <div className='montSerratFont fontSize18 textColorBlack'>Sell Thru</div>
                    </div>
                    {dashboardData?.calculateSellThrough && <div className='pt-3'>
                        <SellThruChart chartData={dashboardData?.calculateSellThrough} />
                        <div className='paddingTop32'>
                            <div className='value-cont'>
                                <div className='d-flex align-items-center justify-content-between gap-3 sell-thru-data'>
                                    <div className='d-flex w-50 align-items-center justify-content-between border-right pe-3'>
                                        <div className='notoSansFont fontSize16 textColorGray'>SOH</div>
                                        <div className='notoSansFont fontSemiBold textColorBlack fontSize24'>{Math.round(dashboardData?.calculateSellThrough?.total_stock_quantity)}</div>
                                    </div>
                                    <div className='d-flex w-50 align-items-center justify-content-between'>
                                        <div className='notoSansFont fontSize16 textColorGray'>Quantity</div>
                                        <div className='notoSansFont fontSemiBold textColorBlack fontSize24'>{Math.round(dashboardData?.calculateSellThrough?.total_sales_quantity)}</div>
                                    </div>
                                </div>
                                <div className='gray-border'></div>
                                <div className='d-flex justify-content-center align-items-center gap-3'>
                                    <div className='notoSansFont fontSize16 textColorGray'>Sell Thru</div>
                                    <div className='notoSansFont fontSemiBold textColorBlack fontSize24'>{Math.round(dashboardData?.calculateSellThrough?.sell_through)}%</div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className='card-bg'>
                    <div className='header-cont'>
                        <div className='montSerratFont fontSize18 textColorBlack'>Staff Wise Performance</div>
                        <div className='staff-bar-color'>
                            <div className='color-cont'>
                                <div className='target'></div>
                                <div>Target</div>
                            </div>
                            <div className='color-cont'>
                                <div className='achievement'></div>
                                <div>Achievement</div>
                            </div>
                        </div>
                    </div>
                    {staffWisePerformanceChartData && <div className='pt-3'>
                        <StaffWisePerformanceChart chartData={staffWisePerformanceChartData} />
                    </div>}
                </div>
            </div>
            <div id='card-parent' className='staff'>
                <div className='card-bg'>
                    <div className='header-cont'>
                        <div className='montSerratFont fontSize18 textColorBlack'>RSM Wise Performance</div>
                        <div className='rsm-bar-color'>
                            <div className='color-cont'>
                                <div className='target'></div>
                                <div>Target</div>
                            </div>
                            <div className='color-cont'>
                                <div className='achievement'></div>
                                <div>Achievement</div>
                            </div>
                        </div>
                    </div>
                    {rsmWiseChartData && <div className='pt-3'>
                        <RsmWiseChart chartData={rsmWiseChartData} />
                    </div>}
                </div>
                <div className='card-bg'>
                    <div className='d-flex align-items-center'>
                        <div className='icon-bg'>
                            <img src={PercentIcon} width={20} height={20} alt="" />
                        </div>
                        <div className='montSerratFont fontSize18 textColorBlack'>Staff Availability</div>
                    </div>
                    {dashboardData?.getBrandStaffPercentages && <div className='pt-3'>
                        <StaffAvailabilityChart chartData={dashboardData?.getBrandStaffPercentages} />
                        <div className='paddingTop32'>
                            <div className='value-cont'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='notoSansFont fontSize16 textColorGray'>Availability - Yes</div>
                                    <div className='notoSansFont fontSemiBold textColorBlack fontSize24'>{Math.round(dashboardData?.getBrandStaffPercentages?.trueCount)}</div>
                                </div>
                                <div className='gray-border'></div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='notoSansFont fontSize16 textColorGray'>Availability - No</div>
                                    <div className='notoSansFont fontSemiBold textColorBlack fontSize24'>{Math.round(dashboardData?.getBrandStaffPercentages?.falseCount)}</div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            {loading && <Loader />}
        </div>
    )
}

export default TargetVsAchievement
