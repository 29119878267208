import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isSidebarVisible: false,
};

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setIsSidebarVisible: (state, action) => {
            state.isSidebarVisible = action.payload.type
        },
    }
});

export const sidebarActions = sidebarSlice.actions;
export default sidebarSlice;