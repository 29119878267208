import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import '../targetVsAchievement.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const OverAllChart = ({ data }) => {
    const targetData = {
        labels: ['Achievement'], // Move labels to the root of the data object
        datasets: [
            {
                // data: [data?.totalValueSum, 0], // Full circle
                data: [data?.achievementValueSum || 0, 0],
                backgroundColor: ['#FFE7FF', 'transparent'],
                borderWidth: 0,
                cutout: '80%', // Inner cutout for the target chart
                maintainAspectRatio: false, // Disable the default aspect ratio
                // responsive: true,
            },
        ],
    };

    const achievementData = {
        labels: ['Total Target', 'Achievement'], // Move labels to the root of the data object
        datasets: [
            {
                data: [data?.totalValueSum || 0, data?.achievementValueSum || 0,], // 41% fill
                // data: [data?.achievementValueSum, data?.totalValueSum - data?.achievementValueSum], // Achievement fill
                backgroundColor: ['#4169E1', 'transparent'],
                borderWidth: 0,
                cutout: '80%', // Inner cutout for the achievement chart
                borderRadius: 30,
                maintainAspectRatio: false, // Disable the default aspect ratio
                // responsive: true,
            },
        ],
    };

    const options = {
        cutout: '70%', // Same cutout value for both charts to achieve overlap
        plugins: {
            tooltip: { 
                enabled: true ,
                callbacks: {
                    label: (tooltipItem) => {
                        const labels = tooltipItem.chart.data.labels; // Access labels from chart data
                        const dataIndex = tooltipItem.dataIndex;
                        
                        // Check if labels and data exist
                        const label = labels ? labels[dataIndex] : 'Unknown';
                        const value = tooltipItem.raw || 0;
                        
                        return `${label}: ${value}`;
                    },
                },
            },
            legend: { display: false },
            datalabels: {
                display: false, // Ensure no data labels are shown inside the bars
            },
        },
        // rotation: -90,
        // circumference: 360,
        // maintainAspectRatio: false, // Disable the default aspect ratio
        // responsive: true,

    };

    return (
        <div style={{ position: 'relative' }} className='d-flex justify-content-center'>
            <div style={{ position: 'relative', top: 0, left: 0 }}>
                <Doughnut style={{ margin: 'auto' }}
                    width={250}
                    data={targetData}
                    options={{
                        ...options,
                        circumference: 360, // Full circle for target
                    }}
                />
                <div className='ach-chart'>
                    <Doughnut
                        width={270}
                        data={achievementData}
                        options={{
                            ...options,
                            circumference: 360, // Partial circle for achievement
                        }}
                    />
                </div>
            </div>

            <div className='notoSansFont'
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '28px',
                    fontWeight: '600',
                }}
            >
                {Math.round(data?.percentage)}%
            </div>
        </div>
    );
};

export default OverAllChart;
