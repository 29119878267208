import React from 'react';

const FileRenderer = ({ file, fileType }) => {

    const checkFileType = () => {
        if (fileType === 'pdf') {
            return <object class="pdf"
                data={file}
                width="100%"
                height="500">
            </object>
        } else {
            return <div className='d-flex justify-content-center'>
                <img src={file} width={500} height={500} alt="" />
            </div>
        }
    }

    return (
        <div>
            {checkFileType()}
        </div>
    );
};

export default FileRenderer;
