import React, { Fragment } from "react";
import cx from "classnames";
import '../../global.css';

class SearchBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSearch: false,
        };
    }

    render() {
        const { activeSearch } = this.props
        return (
            <Fragment>
                <div className={cx("customSearch search-wrapper", {
                    active: this.state.activeSearch,
                })}>
                    <div className="input-holder">
                        <input type="text" className="search-input" placeholder="Type to search" />
                        <button onClick={() =>
                            this.setState({ activeSearch: !this.state.activeSearch })
                        }
                            className="search-icon">
                            <span />
                        </button>
                    </div>
                    {/* <button onClick={() =>
                        this.setState({ activeSearch: !this.state.activeSearch })
                    }
                        className="btn-close" /> */}
                </div>
            </Fragment>
        );
    }
}

export default SearchBox;
