import React from 'react';
import '../../global.css';

const PrimaryButton = (props) => {
    return (
        <div>
            <button className={`customPrimaryButton ${props.disabled ? 'opacityHalf cursorNotAllowed' : ''}`}
                onClick={props.onClick} disabled={props.disabled}>
                {props.title}
            </button>
        </div>
    )
}

export default PrimaryButton