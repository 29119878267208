import React, { useEffect, useRef, useState } from 'react'
import CustomFilterDropDown from '../../../ReUsableComponent/CustomFilterDropdown';
import CustomDateRangePicker from '../../../ReUsableComponent/CustomDaterangePicker';
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';
import { GetBrandNameList } from '../../RuleManagement/RuleManagement.service';
import { GetSalesYear } from '../../SalesOverview/SalesOverview.service';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import moment from 'moment';
import dropDownIcon from '../../../assets/svg/dropDownIcon.svg';
import CalendarIcon from '../../../assets/svg/calendar_2_fill.svg';
import { stockActions } from '../../../store/reducers/StockOverviewReducer';
import DownloadIcon from '../../../assets/svg/blueDownload.svg';
import CustomTableWithFilter from '../../../ReUsableComponent/CustomTableWithFilter';
import { stockDashboardTableHeader } from '../../TableHeaders';
import { DownlaodStockReport, GetStockDashboardData, getStockFilterValue } from '../StockOverview.service';
import Loader from '../../../ReUsableComponent/Loader';
import CustomModal from '../../../ReUsableComponent/CustomModal';
import CloseIcon from '../../../assets/svg/crossIcon.svg';
import ClothImg from '../../../assets/png/cloth.png';
import { saveAs } from 'file-saver';
import CustomFilterSearchDropdown from '../../../ReUsableComponent/CustomFilterSearchDropdown';
import dayjs from 'dayjs';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 624,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const StockDashboard = () => {

    const today = new Date();
    today.setDate(1)
    today.setHours(-1)
    const firstDayOfMonth = new Date(today.getFullYear(), (today.getMonth()));
    const gridRef = useRef(null);

    const history = useHistory();
    const dispatch = useDispatch();
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [openStockModal, setOpenStockModal] = useState(false);
    const [openSalesModal, setOpenSalesModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [brandList, setBrandList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [salesYearList, setSalesYearList] = useState([]);
    const [anchorElForBrand, setAnchorElForBrand] = useState(null);
    const openBrandFilter = Boolean(anchorElForBrand);
    const [anchorElForRegion, setAnchorElForRegion] = useState(null);
    const openRegionFilter = Boolean(anchorElForRegion);
    const [anchorElForState, setAnchorElForState] = useState(null);
    const openStateFilter = Boolean(anchorElForState);
    const [anchorElForStore, setAnchorElForStore] = useState(null);
    const openStoreFilter = Boolean(anchorElForStore);
    const [anchorElForSales, setAnchorElForSales] = useState(null);
    const openSalesYearFilter = Boolean(anchorElForSales);
    const [selectedBrand, setSelectedBrand] = useState({ label: '', value: '' });
    const [selectedRegion, setSelectedRegion] = useState({ label: '', value: '' });
    const [selectedState, setSelectedState] = useState({ label: '', value: '' });
    const [selectedStore, setSelectedStore] = useState({ label: '', value: '' });
    const [selectedSalesYear, setSelectedSalesYear] = useState({ label: '', value: '' });
    const startDateDisplay = useSelector(state => state.stock.startDateDisplay);
    const endDateDisplay = useSelector(state => state.stock.endDateDisplay);
    const [selectedRange, setSelectedRange] = useState([firstDayOfMonth, today]);
    const [startDate, setStartDate] = useState(useSelector(state => state.stock.startDate));
    const [endDate, setEndDate] = useState(useSelector(state => state.stock.endDate));
    const defaultYear = useSelector(state => state.stock.defaultYear);
    const defaultBrand = useSelector(state => state.stock.defaultBrand);
    const [stockActiveTabBtn, setStockActiveTabBtn] = useState(1);
    const [disabledDateRange, setDisabledDateRange] = useState(true);
    const [saleActiveTabBtn, setSaleActiveTabBtn] = useState(1);
    const [totalStockData, setTotalStockData] = useState([]);
    const [totalSoldOutData, setTotalSoldOutData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [dropSearchValue, setdropSearchValue] = React.useState({ brand: '', region: '', state: '', store: '' });
    const USER_ID = useSelector((state) => state.userAuthentication.userId);
    const handleSearchChange = (event, name) => {
        if (name === 'brand') setdropSearchValue(prev => ({ ...prev, brand: event.target.value }));
        else if (name === 'region') setdropSearchValue(prev => ({ ...prev, region: event.target.value }));
        else if (name === 'state') setdropSearchValue(prev => ({ ...prev, state: event.target.value }));
        else if (name === 'store') setdropSearchValue(prev => ({ ...prev, store: event.target.value }));
    };


    useEffect(() => {
        dispatch(userAuthActions.setHeaderName({ type: 'Stock Overview' }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: false }));
        getAllBrand();
        getSalesYearList();
    }, [])

    useEffect(() => {
        if (selectedBrand.value !== '') getRegionStateStore(selectedBrand.value, selectedRegion.value, selectedState.value);
    }, [selectedBrand, selectedRegion, selectedState])

    useEffect(() => {
        if (defaultBrand.value && defaultYear.value) {
            getDashboardData(defaultYear.value, defaultBrand.value, '', '', selectedRegion.value, selectedState.value, selectedStore.value);
        } else if (defaultYear.value === '' && startDate && endDate) {
            getDashboardData('', defaultBrand.value, startDate, endDate, selectedRegion.value, selectedState.value, selectedStore.value);
        }

    }, [defaultYear, defaultBrand, selectedRegion, selectedState, selectedStore])


    const openCalendar = () => {
        setCalendarOpen(prev => !prev)
    }

    const onClickGotoReports = () => {
        history.push('/app/stockOverview');
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
    }

    const handleBrandClick = (event) => {
        setAnchorElForBrand(event.currentTarget);
    };
    const handleBrandClose = () => {
        setAnchorElForBrand(null);
    };
    const handleRegionClick = (event) => {
        setAnchorElForRegion(event.currentTarget);
    };
    const handleRegionClose = () => {
        setAnchorElForRegion(null);
    };
    const handleStateClick = (event) => {
        setAnchorElForState(event.currentTarget);
    };
    const handleStateClose = () => {
        setAnchorElForState(null);
    };
    const handleStoreClick = (event) => {
        setAnchorElForStore(event.currentTarget);
    };
    const handleStoreClose = () => {
        setAnchorElForStore(null);
    };

    const handleSalesClick = (event) => {
        setAnchorElForSales(event.currentTarget);
    };
    const handleSalesClose = () => {
        setAnchorElForSales(null);
    };

    const onSelectBrand = (event) => {
        brandList.forEach(brand => {
            if (event.target.value === brand?.value) {
                // setSelectedBrand({ label: brand?.label, value: event.target.value })
                setAnchorElForBrand(null);
                dispatch(stockActions.setDefaultBrand({ type: { label: brand?.label, value: event.target.value } }));
            }
        });
        setSelectedState({ label: '', value: '' })
        setSelectedStore({ label: '', value: '' })
        setSelectedRegion({ label: '', value: '' })
    }
    const onSelectRegion = (event) => {
        regionList.forEach(region => {
            if (event.target.value === region?.value) {
                setSelectedRegion({ label: region?.label, value: event.target.value })
                setAnchorElForRegion(null);
            }
        });
        setSelectedState({ label: '', value: '' })
        setSelectedStore({ label: '', value: '' })
    }
    const onSelectState = (event) => {
        stateList.forEach(state => {
            if (event.target.value === state?.value) {
                setSelectedState({ label: state?.label, value: event.target.value })
                setAnchorElForState(null);
            }
        });
        setSelectedStore({ label: '', value: '' })
    }
    const onSelectStore = (event) => {
        storeList.forEach(store => {
            if (event.target.value === store?.value) {
                setSelectedStore({ label: store?.label, value: event.target.value })
                setAnchorElForStore(null);
            }
        });
    }

    const onSelectSalesYear = (event) => {
        if (event.target.value !== '') {
            dispatch(stockActions.setDefaultYear({ type: { label: event.target.value, value: event.target.value } }));
            dispatch(stockActions.setStartDateDisplay({ type: null }));
            dispatch(stockActions.setEndDateDisplay({ type: null }));
            dispatch(stockActions.setStartDate({ type: null }));
            dispatch(stockActions.setEndDate({ type: null }));
            setDisabledDateRange(true);
        } else {
            setDisabledDateRange(false);
        }
        if (event.target.value === '') {
            setSelectedSalesYear({ label: 'Custom', value: event.target.value });
            dispatch(stockActions.setDefaultYear({ type: { label: 'Custom', value: event.target.value } }));
        } else {
            dispatch(stockActions.setDefaultYear({ type: { label: event.target.value, value: event.target.value } }));
        }
        setAnchorElForSales(null);
    }

    const getAllBrand = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let brands = []
                response.data.data.forEach(element => {
                    let brandObject = {
                        label: element.brand_name,
                        value: element._id
                    }
                    brands.push(brandObject)
                });
                setBrandList(brands);
                if (!defaultBrand.value) dispatch(stockActions.setDefaultBrand({ type: { label: brands[0]?.label, value: brands[0]?.value } }));
                // setSelectedBrand({ label: brands[0]?.label, value: brands[0]?.value });
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
        })
    }

    const checkStoreName = (storeName, storeTradeName) => {
        if (storeTradeName && storeTradeName !== 'null') {
            return storeTradeName
        } else {
            return storeName
        }
    }

    const getRegionStateStore = (brand, region, state) => {
        setLoading(true);
        let param = `?store_region=${region}&store_state=${state}&brand_id=${brand}`
        getStockFilterValue(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let store = []
                response.data.data.store.forEach(element => {
                    let storeObject = {
                        label: checkStoreName(element.store_name, element.store_trade_name),
                        value: element.store_id
                    }
                    store.push(storeObject)
                });
                setStoreList(store);

                let regions = []
                response.data.data.region.forEach(element => {
                    let regionObj = {
                        label: element.store_region,
                        value: element.store_region
                    }
                    regions.push(regionObj)
                });
                setRegionList(regions);

                let states = []
                response.data.data.state.forEach(element => {
                    let stateObj = {
                        label: element.store_state,
                        value: element.store_state
                    }
                    states.push(stateObj)
                });
                setStateList(states)
            }
        }).catch(error => {
            console.log(error)
            if (error?.response?.status === 401) {
                history.push('/')
            }
            ToastErrorService(error?.response?.data?.message);
        }).finally(() => {
            // setLoading(false);
        })
    }

    const convertDates = (date) => {
        const startDate = new Date(date);
        const yyyy = startDate.getFullYear();
        const mm = String(startDate.getMonth() + 1).padStart(2, "0");
        const dd = String(startDate.getDate()).padStart(2, "0");
        const convertedDate = `${yyyy}-${mm}-${dd}`;
        return convertedDate
    }

    const convertDate = (openingDate) => {
        let date = new Date(openingDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return moment(dateString).format("DD MMM YYYY");
    }

    const convertDateStringToObj = (dateString) => {
        const dateInIST = moment.tz(dateString, 'Asia/Kolkata');
        const finalFormattedDate = new Date(dateInIST.format());
        return finalFormattedDate
    }

    const handleDateRangeSelect = (date) => {
        if (!startDate || (startDate && endDate)) {
            setStartDate(date);
            setEndDate(null);
            // setStartDateDisplay(date);
            setSelectedRange([]);
        } else if (startDate && !endDate && date >= startDate) {
            let endDay = dayjs(date).endOf('day');
            setEndDate(convertDateStringToObj(endDay?.toISOString()));
            // setEndDateDisplay(convertDateStringToObj(endDay?.toISOString()));
            setSelectedRange([startDate, convertDateStringToObj(endDay?.toISOString())]);
            setCalendarOpen(false);
            dispatch(stockActions.setStartDateDisplay({ type: startDate }));
            dispatch(stockActions.setEndDateDisplay({ type: convertDateStringToObj(endDay?.toISOString()) }));
            dispatch(stockActions.setStartDate({ type: startDate }));
            dispatch(stockActions.setEndDate({ type: convertDateStringToObj(endDay?.toISOString()) }));
            getDashboardData('', selectedBrand.value, startDate, convertDateStringToObj(endDay?.toISOString()), selectedRegion.value, selectedState.value, selectedStore.value);
        } else if (startDate && !endDate && date < startDate) {
            setStartDate(date);
            // setStartDateDisplay(date);
        }
    };

    const getSalesYearList = () => {
        GetSalesYear(USER_TOKEN).then(response => {
            let salesYear = []
            response.data.data.forEach(element => {
                let salesObj = {
                    label: element,
                    value: element
                }
                salesYear.push(salesObj)
            });
            salesYear.push({ label: 'Custom', value: '' });
            setSalesYearList(salesYear);
            setDisabledDateRange(true);
            if (!defaultYear.label) {
                dispatch(stockActions.setDefaultYear({ type: { label: salesYear.at(-2)?.label, value: salesYear.at(-2)?.value } }));
                setSelectedSalesYear({ label: salesYear.at(-2)?.label, value: salesYear.at(-2)?.value });
                dispatch(stockActions.setStartDateDisplay({ type: null }));
                dispatch(stockActions.setEndDateDisplay({ type: null }));
                dispatch(stockActions.setStartDate({ type: null }));
                dispatch(stockActions.setEndDate({ type: null }));
            }
            if (defaultYear.label === 'Custom') {
                setDisabledDateRange(false);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {

        })
    }

    const onClickSaleTabBtn = (value) => {
        setSaleActiveTabBtn(value);
    }
    const onClickStockTabBtn = (value) => {
        setStockActiveTabBtn(value);
    }

    const onClickSoldCountViewAll = () => {
        setOpenSalesModal(true)
    }
    const onClickCountViewAll = () => {
        setOpenStockModal(true)
    }

    const onClickDownload = () => {
        if (startDate && endDate) {
            downloadReport('', selectedBrand?.value, startDate, convertDateStringToObj(dayjs(endDate).endOf('day')?.toISOString()));
        } else {
            downloadReport(selectedSalesYear?.value, selectedBrand?.value)
        }
    }

    const valueCheck = (value) => {
        if (value && value !== 'null') {
            return value
        } else {
            return '-'
        }
    }

    const storeTradeNameCheck = (store, tradeName) => {
        if (tradeName && tradeName !== 'null') {
            return tradeName
        } else {
            return store
        }
    }

    const getDashboardData = (range, brand, startdate, enddate, region, state, store,) => {
        setLoading(true);
        let param = `?brand_id=${brand}&store_region=${region}&store_state=${state}&store_id=${store}`;
        if (startdate && enddate) {
            param = param + `&from_date=${startdate}&to_date=${enddate}`;
        } else {
            param = param + `&view_range=${range}`;
        }
        GetStockDashboardData(param).then(response => {
            setTotalStockData(response.data.data?.total_stock_data);
            setTotalSoldOutData(response.data.data?.total_soldout_data);
            let table = [];
            response?.data?.data?.fetchstockDataOnstyleCode.slice(0, 20)?.map(item => {
                let rowData = {
                    nameStore: storeTradeNameCheck(item?.store_name, item?.store_trade_name),
                    category: valueCheck(item?.category_presence),
                    styleCode: valueCheck(item?.style_code),
                    size: valueCheck(item?.size),
                    color: valueCheck(item?.color),
                    image: item?.image ? item?.image : ClothImg,
                    mrp: item?.mrp ? `₹${Number(item.mrp).toLocaleString('en-IN')}` : '-',
                    brokenSize: valueCheck(item?.broken_size),
                    pivotalSize: valueCheck(item?.pivotal_size),
                    currentStock: valueCheck(item?.current_stock),
                    soldOutCount: valueCheck(item?.sold_out_stock),
                }
                table.push(rowData);
            })
            setTableData(table)
        }).catch(error => {

        }).finally(() => {
            setLoading(false);
        })
    }

    const downloadReport = (range, brand, startdate, enddate) => {
        setLoading(true);
        let param = `?brand_id=${brand}`;
        if (startdate && enddate) {
            param = param + `&from_date=${startdate}&to_date=${enddate}`;
        } else {
            param = param + `&view_range=${range}`;
        }
        DownlaodStockReport(param, USER_TOKEN).then(response => {
            const responseInUnit = new Uint8Array(response.data.buffer.data);
            const blob = new Blob([responseInUnit], { type: 'application/octet-stream' });
            saveAs(blob, response.data.filename);
            if (response?.data?.buffer?.statusCode === 400) {
                ToastErrorService(response?.data?.buffer?.message);
            } else {
                ToastSuccessService('Report downloaded successfully.');
            }
        }).catch(error => {
            ToastErrorService(error?.response?.data?.message)
        }).finally(() => {
            setLoading(false);
        })
    }

    const onGridReady = (params) => {
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };


    return (
        <div className='dashboard-container'>
            <div className='d-flex justify-content-between filter-cont'>
                <div className='d-flex align-items-center first-filter'>
                    <div className='goToMasterBtn me-3' onClick={onClickGotoReports}>Go to Reports</div>
                    {/* brand filter */}
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleBrandClick}>
                            <p className='text-truncate' title={defaultBrand.label}>{defaultBrand?.label}</p>
                            <img className={`${openBrandFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterSearchDropdown data={brandList} handleClose={handleBrandClose} open={openBrandFilter} anchorEl={anchorElForBrand}
                            onChange={onSelectBrand} selectedValue={defaultBrand?.value} category={'Brand'}
                            dropSearchValue={dropSearchValue.brand} handleSearchChange={(e) => handleSearchChange(e, 'brand')} setdropSearchValue={setdropSearchValue} />
                    </div>
                    {/* region filter  */}
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleRegionClick}>
                            <p className='text-truncate' title={selectedRegion.label}>{selectedRegion?.label === '' ? 'Select Region' : selectedRegion?.label}</p>
                            <img className={`${openRegionFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterSearchDropdown data={regionList} handleClose={handleRegionClose} open={openRegionFilter} anchorEl={anchorElForRegion}
                            onChange={onSelectRegion} selectedValue={selectedRegion?.value} category={'Region'}
                            dropSearchValue={dropSearchValue.region} handleSearchChange={(e) => handleSearchChange(e, 'region')} setdropSearchValue={setdropSearchValue} />
                    </div>
                    {/* state filter  */}
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleStateClick}>
                            <p className='text-truncate' title={selectedState.label}>{selectedState?.label === '' ? 'Select State' : selectedState?.label}</p>
                            <img className={`${openStateFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterSearchDropdown data={stateList} handleClose={handleStateClose} open={openStateFilter} anchorEl={anchorElForState}
                            onChange={onSelectState} selectedValue={selectedState?.value} category={'Brand'}
                            dropSearchValue={dropSearchValue.state} handleSearchChange={(e) => handleSearchChange(e, 'state')} setdropSearchValue={setdropSearchValue} />
                    </div>
                    {/* store filter  */}
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleStoreClick}>
                            <p className='text-truncate' title={selectedStore.label}>{selectedStore?.label === '' ? 'Select Store' : selectedStore?.label}</p>
                            <img className={`${openStoreFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterSearchDropdown data={storeList} handleClose={handleStoreClose} open={openStoreFilter} anchorEl={anchorElForStore}
                            onChange={onSelectStore} selectedValue={selectedStore?.value} category={'Brand'}
                            dropSearchValue={dropSearchValue.store} handleSearchChange={(e) => handleSearchChange(e, 'store')} setdropSearchValue={setdropSearchValue} />
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleSalesClick}>
                            <p className='text-truncate' title={defaultYear.label}>{defaultYear?.label}</p>
                            <img className={`${openSalesYearFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterDropDown data={salesYearList} handleClose={handleSalesClose} open={openSalesYearFilter} anchorEl={anchorElForSales}
                            onChange={onSelectSalesYear} selectedValue={defaultYear?.value} category={'Category'} />
                    </div>
                    <div className='position-relative'>
                        <button className={`d-flex dateRangeFilter cursorPointer align-items-center ${!disabledDateRange ? 'justify-content-between' : ''}`} disabled={disabledDateRange}
                            onClick={openCalendar} >
                            <img src={CalendarIcon} alt="" />
                            {(startDateDisplay && endDateDisplay) ? <div>{`${convertDate(startDateDisplay)} - ${convertDate(endDateDisplay)}`}</div> : <div className='text-center w-100'>Select Date</div>}
                        </button>
                        <div className=''>
                            <CustomDateRangePicker restrictFutureDates={true} startDate={startDate} endDate={endDate} calendarOpen={calendarOpen} selectedRange={selectedRange} handleSelect={handleDateRangeSelect}
                                closeOnOutSide={() => setCalendarOpen(false)} />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='count-conatiner'>
                    <div className='count-card'>
                        <div className='fontSize18 montSerratFont textColorBlack'>Total Stock Count</div>
                        <div className='fontSize24 notoSansFont fontSemiBold text24 textColor333 pt-2'>{totalStockData?.totalStockCount}</div>
                        <div className='cat-season-btn-cont'>
                            <button onClick={() => onClickStockTabBtn(1)} className={`${stockActiveTabBtn === 1 ? 'active-tab-btn-bg' : 'tab-btn-bg'}`}>Category</button>
                            <button onClick={() => onClickStockTabBtn(2)} className={`${stockActiveTabBtn === 2 ? 'active-tab-btn-bg' : 'tab-btn-bg'}`}>Season</button>
                        </div>
                        <div>
                            {(stockActiveTabBtn === 1 ? totalStockData?.stockCategoryWisekCount?.slice(0, 5) : totalStockData?.stockSeasonWiseCount)?.map(item => (<div className='card-data'>
                                <div className='d-flex align-items-center'>
                                    <div className='dot-div me-2'></div>
                                    <div className='item-name'>{item?.category_group ? (item?.category_group !== 'null' ? item.category_group : 'Others') : (item?.season !== 'null' ? item?.season : 'Others')}</div>
                                </div>
                                <div className='counts'>{item?.totalQuantity}</div>
                            </div>))}
                        </div>
                        {stockActiveTabBtn === 1 && <div className='notoSansFont d-flex justify-content-end'>
                            <button onClick={onClickCountViewAll} className='view-all-btn'>view all</button>
                        </div>}
                    </div>
                    <div className='count-card'>
                        <div className='fontSize18 montSerratFont textColorBlack'>Total Sold Out Count</div>
                        <div className='fontSize24 notoSansFont fontSemiBold text24 textColor333 pt-2'>{totalSoldOutData?.totalSalesCount}</div>
                        <div className='cat-season-btn-cont'>
                            <button onClick={() => onClickSaleTabBtn(1)} className={`${saleActiveTabBtn === 1 ? 'active-tab-btn-bg' : 'tab-btn-bg'}`}>Category</button>
                            <button onClick={() => onClickSaleTabBtn(2)} className={`${saleActiveTabBtn === 2 ? 'active-tab-btn-bg' : 'tab-btn-bg'}`}>Season</button>
                        </div>
                        <div>
                            {(saleActiveTabBtn === 1 ? totalSoldOutData?.totalCategoryWiseSalesCount?.slice(0, 5) : totalSoldOutData?.salesSeasonWiseCount)?.map(item => (<div className='card-data'>
                                <div className='d-flex align-items-center'>
                                    <div className='dot-div me-2'></div>
                                    <div className='item-name'>{item?.category_group ? (item?.category_group !== 'null' ? item.category_group : 'Others') : (item?.season !== 'null' ? item?.season : 'Others')}</div>
                                </div>
                                <div className='counts'>{item?.totalQuantity}</div>
                            </div>))}
                        </div>
                        {saleActiveTabBtn === 1 && <div className='notoSansFont d-flex justify-content-end'>
                            <button onClick={onClickSoldCountViewAll} className='view-all-btn'>view all</button>
                        </div>}
                    </div>
                </div>
            </div>
            <div className='stock-details'>
                <div className='d-flex justify-content-between'>
                    <div className='fontSize18 montSerratFont textColorBlack'>{selectedBrand?.label} Stock Details</div>
                    <button className='download-btn' onClick={onClickDownload}>
                        <img src={DownloadIcon} width={24} height={24} alt="" />
                    </button>
                </div>
                <div className='paddingTop16'>
                    <CustomTableWithFilter isContentLeftRightFixed={false} column={stockDashboardTableHeader} tableRowData={tableData} onGridReady={onGridReady} />
                </div>
            </div>

            {/* Stock Modal */}
            <CustomModal open={openStockModal} style={modalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Total Stock Count</div>
                            <div className='modalCloseIconPlacement cursorPointer' onClick={() => setOpenStockModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='dataInsideModal customScroll'>
                        {(totalStockData?.stockCategoryWisekCount)?.map(item => (<div className='card-data'>
                            <div className='d-flex align-items-center'>
                                <div className='dot-div me-2'></div>
                                <div className='item-name'>{(item?.category_group !== 'null' ? item.category_group : 'Others')}</div>
                            </div>
                            <div className='counts'>{item?.totalQuantity}</div>
                        </div>))}
                    </div>
                </div>} />
            {/* Sales Modal */}
            <CustomModal open={openSalesModal} style={modalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Total Sold Out Count</div>
                            <div className='modalCloseIconPlacement cursorPointer' onClick={() => setOpenSalesModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='dataInsideModal customScroll'>
                        {(totalSoldOutData?.totalCategoryWiseSalesCount)?.map(item => (<div className='card-data'>
                            <div className='d-flex align-items-center'>
                                <div className='dot-div me-2'></div>
                                <div className='item-name'>{(item?.category_group !== 'null' ? item.category_group : 'Others')}</div>
                            </div>
                            <div className='counts'>{item?.totalQuantity}</div>
                        </div>))}
                    </div>
                </div>} />

            {/* Loader */}
            {loading && <Loader />}
        </div>
    )
}

export default StockDashboard