import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    viewFeedbackDetails: [],
    selectedPage: 1,
    tableFilters: {}
};

const feedbackSlice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
        setViewFeedbackDetails: (state, action) => {
            state.viewFeedbackDetails = action.payload.type
        },
        setSelectedPage: (state, action) => {
            state.selectedPage = action.payload.type
        },
        setTableFilters: (state, action) => {
            state.tableFilters = action.payload.type
        },
    }
});

export const feedbackActions = feedbackSlice.actions;
export default feedbackSlice;
