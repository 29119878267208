import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedBrandId: {},
    selectedCurrentDate: '',
    selectedPage: 1,
    selectedViewEmplStatusPage: 1,
    employeeStatuseRowId: '',
    // employeeStatuseRowIdBack: true,
};

const employeeStatusSlice = createSlice({
    name: 'employeeStatus',
    initialState,
    reducers: {
        setSelectedBrandId: (state, action) => {
            state.selectedBrandId = action.payload.type
        },
        setSelectedCurrentDate: (state, action) => {
            state.selectedCurrentDate = action.payload.type
        },
        setSelectedPage: (state, action) => {
            state.selectedPage = action.payload.type
        },
        setSelectedViewEmplStatusPage: (state, action) => {
            state.selectedViewEmplStatusPage = action.payload.type
        },
        setEmployeeStatuseRowId: (state, action) => {
            state.employeeStatuseRowId = action.payload.type
        },
        // setEmployeeStatuseRowIdBack: (state, action) => {
        //     state.employeeStatuseRowIdBack = action.payload.type
        // },
    }
});

export const employeeStatusActions = employeeStatusSlice.actions;
export default employeeStatusSlice;
