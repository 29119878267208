import axios from "axios";
import API_CONFIG from "../../Configurations/ApiDeclaration";

export function GetStockDashboardData(param, token) {
    const URL = API_CONFIG.GET_STOCK_DASHBOARD_DATA + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function DownlaodStockReport(param, token) {
    const URL = API_CONFIG.DOWNLOAD_DASHBOARD_REPORT + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function getStockFilterValue(param, token) {
    const URL = API_CONFIG.GET_FILTER_DATA_FOR_STOCK + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}