// Any Things take 
export const validateTextInputAny = (value) => {
    const regex = /^[^\s]/;
    return regex.test(value);
};


// Any Things take 
export const validateTextInputAnyMin = (value) => {
    const regex = /^[^\s].{2,}$/;
    return regex.test(value);
};

// Free text Alfha Betical
export const validateTextInputFree = (value) => {
    const regex = /^[a-zA-Z.]*$/;
    return regex.test(value);
}

// Only take Text and ( . ) This
export const validateOnlyTextInput = (value) => {
    const regex = /^[a-zA-Z.]$/;
    return regex.test(value);
};

// Only take Text ( . ) and & This
export const validateTextDotAndAmpersand = (value) => {
    const regex = /^[a-zA-Z.&]+$/;
    return regex.test(value);
};

// Only take Text ( . ) and Comma This
export const validateTextDotCommaSpace = (value) => {
    const regex = /^[a-zA-Z.,][a-zA-Z., ]{2,}$/;  // Regex pattern
    return regex.test(value);
};

// Only take Text Comma - and & This
export const validateTextDotAndAmpersandCommaWithNum = (value) => {
    // const regex = /^[a-zA-Z0-9&,-]+$/;
    const regex = /^[a-zA-Z0-9&,-]{3,}$/;
    return regex.test(value);
};

// Only take Text Comma - and & This
export const validateTextAndAmpersandCommaWithHy = (value) => {
    // const regex = /^[a-zA-Z0-9&,-]+$/;
    const regex = /^[a-zA-Z0-9&-]{3,}$/;
    return regex.test(value);
};

// Regular expression for alphanumeric, underscore, and slash
export const validateTextUnderscoreSlash = (value) => {
    const regex = /^(?! )[a-zA-Z0-9_\/]+$/;
    return regex.test(value);
}

// export const validateTextDotAndSpecialCharacter = (value) => {
//     const regex = /^[a-zA-Z0-9&~`'".!,-_ ]+$/;
//     return regex.test(value);
// };

export const validateTextDotAndSpecialCharacter = (value) => {
    const regex = /^[^\s][a-zA-Z0-9&~`'".!,-_ ]*$/;
    return regex.test(value);
};

// free Text min 2 value max 100 value
export const validateFreeTextInput = (value) => {
    const regex = /^.{2,100}$/;
    return regex.test(value);
};

// export const validateFreeTextInputAmpresent = (value) => {
//     const regex = /^[^\s&]{2,100}$/;
//     return regex.test(value);
// };

export const validateFreeTextInputAmpresent = (value) => {
    const regex = /^(?! )[A-Za-z& ]{1,100}$/;
    return regex.test(value);
};

// free Text min 2 value max 100 value
export const validateTextInput = (value) => {
    const regex = /^(?! )[a-zA-Z.\s]{2,100}$/;
    return regex.test(value);
};

// free Text min 10 value max 100 value
export const validateTextAddressInput = (value) => {
    // const regex = /^.{10,100}$/;
    const regex = /^[^\s].{2,99}$/;
    return regex.test(value);
}

// free Text min 2 value max 200 value
export const validateText2To200Input = (value) => {
    const regex = /^[a-zA-Z.\s]{2,200}$/;
    return regex.test(value);
};

// free Text min 3 value max 200 value
export const validateText3To80Input = (value) => {
    // const regex = /^[a-zA-Z.\s]{3,800}$/;
    const regex = /^(?! )[a-zA-Z.\s]{3,80}$/;
    return regex.test(value);
};

// free Text Number With Special Character value
export const validateInputNumberSC = (value) => {
    const regex = /^\d{1,3}(?:[.,]\d{3})*(?:[.,]\d+)?$/;
    return regex.test(value);
};

// Email Validation
export const validateEmailInput = (value) => {
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(value);
};

export const validateEmailList = (emailList) =>{
    const emails = emailList.split(',').map(email => email.trim());
    const emailRegex = /^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    for (const email of emails) {
        if (email && !emailRegex.test(email)) {
            return false; // Invalid email found
        }
    }
    return true;
}

// Only take Number Number 
export const validateFreeNumberInput = (value) => {
    const regex = /^[0-9]+$/;
    return regex.test(value);
};

// Only take 10 Digit Number 
export const validateNumberInput = (value) => {
    const regex = /^[6-9][0-9]{9}$/;
    return regex.test(value);
};

export const validatePhoneNumberInput = (value) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(value);
};

// Min 1 Digit to 25 Digit Number 
export const validateCustomerNumberInput = (value) => {
    const regex = /^[0-9]{1,25}$/; // Allows up to 10 digits
    return regex.test(value);
};

// Only take 6 Digit Number 
export const validatePinCodeInput = (value) => {
    const regex = /^[1-9][0-9]{5}$/;
    return regex.test(value);
}

// Take 2 Digit to 6 Digit Number 
export const validateNumber2To6Input = (value) => {
    const regex = /^[0-9]{2,6}$/; // Allows up to 10 digits
    return regex.test(value);
};

// Take 1 Digit to 2 Digit Number 
export const validateNumber1To2Input = (value) => {
    const regex = /^[0-9]{1,2}$/; // Allows up to 10 digits
    return regex.test(value);
};

// Take 1 Digit to 3 Digit Number 
export const validateNumber1To3Input = (value) => {
    const regex = /^[0-9]{1,3}$/; // Allows up to 10 digits
    return regex.test(value);
};

// Take 2 Digit to 20 Digit Number 
export const validateNumber2To20Input = (value) => {
    const regex = /^[0-9]{2,20}$/; // Allows up to 10 digits
    return regex.test(value);
};

// Take 1 Digit to 4 Digit Number 
export const validateNumber1To4Input = (value) => {
    const regex = /^[0-9]{1,4}$/; 
    return regex.test(value);
};

// Allows at least 1 digit with no upper limit
export const validateNumberAtLeast1Input = (value) => {
    const regex = /^[0-9]+$/; 
    return regex.test(value);
};

// PAN Validation
export const validatePANInput = (value)=> {
    const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return regex.test(value);
}

// GSTIN Validation
export const validateGSTINInput = (value)=> {
    const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    return regex.test(value);
}

// Array Validation
export const validateArray = (value) => {
    if (value.length === 0) {
      return false;
    }
    return value.every(validateEmailInput);
};

// Only take 6 Digit 40 digit Number 
export const validateAccountNumberInput = (value) => {
    const regex = /^[0-9]{6,40}$/; // Allows up to 10 digits
    return regex.test(value);
};

// IFSC CODE
export const validateIFSCCode = (value) => {
    const regex = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;
    return regex.test(value);
};

export const validateNumberWithSpecialCharater = (value) => {
    const regex = /\d{1,3}(?:,\d{3})*(?:\.\d+)?|\d+\.\d+|\d+/;
    return regex.test(value);
};

// Date Validation
export const validateDateFormat = (value) => {
    // Regular expression to match DD/MM/YYYY format
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    return regex.test(convertISOToDDMMYYYY(value));
};

// Validate Latitude function
export const validateLatitude = (latitude) => {
    // const regex = /^(\+|-)?(90(\.0+)?|([0-8]?\d(\.\d+)?))$/;
    const regex = /^(\+|-)?(180(\.0+)?|((\d+)(\.\d+)?))$/;
    return regex.test(latitude);
};

// Validate Longitude function
export const validateLongitude = (longitude) => {
    // const regex = /^(\+|-)?(180(\.0+)?|((1[0-7]\d|[1-9]?\d)(\.\d+)?))$/;
    // const regex = /^(\+|-)?(180(\.0+)?|((1[0-7]\d|\d{1,3})(\.\d+)?))$/;
    const regex = /^(\+|-)?(180(\.0+)?|((\d+)(\.\d+)?))$/;
    return regex.test(longitude);
};


export const validNumericInRange = (value) => {
    const regex = /^[1-9]\d{0,4}$/;
    return regex.test(value);
}

export const isNumberOrFloat = (value) => {
    const regex = /^\d{1,3}(\.\d{1,2})?$/;
    return regex.test(value);
}





function convertISOToDDMMYYYY(isoTimestamp) {
    // Create a Date object from ISO string
    const date = new Date(isoTimestamp);

    // Extract day, month, and year
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Month is zero-based, so add 1
    const year = date.getUTCFullYear();

    // Format the date as DD/MM/YYYY
    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

    return formattedDate;
}

// Date formating
export const formatDateToDDMMYYYY = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
        return null; // Return null if the input is not a valid Date object
    }

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

export const formatDateToTextMonthDDYYYY = (dateStr) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Parse the ISO 8601 date string
    const date = new Date(dateStr);

    // Check if the Date object is valid
    if (isNaN(date)) {
        return null; // Return null if the input is not a valid date string
    }

    // Format the date to "Mon DD, YYYY"
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
}


// only take number
export  const handleNumericInput = (input) => {
    // Replace non-numeric characters with an empty string
    const numericInput = input.replace(/\D/g, '');
    return numericInput;
};

// Check Object
export const isFileDetailsEmpty = (obj) => {
    return (
        obj?.file_id === '' &&
        obj?.doc_name === '' &&
        obj?.doc_url === '' &&
        obj?.size === '' &&
        // obj?.created_at === '' &&
        obj?.doc_type === ''
    );
}

export const isNotEmpty = (obj) => {
    for (let key in obj) {
      if (typeof obj[key] === 'object') {
        if (!isNotEmpty(obj[key])) return false;
      } else {
        if (obj[key] === '') return false;
      }
    }
    return true;
};





export const proxyUrl = 'https://cors-anywhere.herokuapp.com/';

export const getFileType = (fileName) => {
    if (!fileName) {
        return '';
    }
    const parts = fileName.split('.');
    return parts.length > 1 ? parts[parts.length - 1] : '';
}





















// check For mandatry filed
const mandatoryFieldsBrand = {
    brandOwnerDetails: ['person_name', 'mobile', 'email'],
    brandDetails: [
        'brand_name', 'status', 'email_id', 'brand_mobile', 
        'product_type', 'business_model', 'pan', 'gstin',
        'account_name', 'bank_name', 'account_number', 'ifsc_code', 'branch', 
        'branch_address'
    ],
    contactDetails: ['person_dept', 'person_name', 'person_mobile', 'email'],
    channelDetails: ['channel_served', 'channel_offline', 'channel_online'],
    address: {
        registered_office_address: ['address', 'region', 'state', 'city', 'area', 'pincode', 'email'],
        warehouse_address: ['address', 'region', 'state', 'city', 'area', 'pincode', 'email', 'mobile', 'person_mobile'],
        return_warehouse_address: ['address', 'region', 'state', 'city', 'area', 'pincode', 'email', 'mobile', 'person_mobile'],
        billing_address: ['address', 'region', 'state', 'city', 'area', 'pincode', 'email', 'mobile', 'person_mobile'],
    },
    companyContactDetails: [
        'contacts_name', 'contacts_mobile', 'contacts_email_id', 'contacts_person_dept', 
        'account_name', 'bank_name', 'account_number', 'ifsc_code', 'branch', 
        'branch_address'
    ],
    panDocumentFile: ['file_id', 'doc_name', 'doc_url', 'size', 'doc_type'],
    gstDocumentFile: ['file_id', 'doc_name', 'doc_url', 'size', 'doc_type'],
    documentUrls: ['doc_url'], 
};


const mandatoryFieldsStore = {
    ownerDetails: [
      'constitution_of_firm',
      'authorise_signitory_name',
      'business_incharge_name',
      'business_incharge_mobile_number',
      'contact_detail.email',
      'contact_detail.mobile',
      'contact_detail.person_name',
      'address.address',
      'address.pincode',
      'address.region',
      'address.state',
      'address.city',
      'address.area',
    ],
    storeDemographic: [
      'customer_name',
      'store_legal_name',
      'store_trade_name',
      'latitude',
      'longitude',
      'store_status',
      'store_opening',
      'store_opening_status',
      'store_type',
      'store_retail_billing',
      'cust_no',
      'gstin',
      'gst_slab',
      'regional_manager',
      'pan_number',
      'address.address',
      'address.pincode',
      'address.region',
      'address.state',
      'address.city',
      'address.area',
      'address.email',
      'address.mobile', 
    ],
    parentStore: [
      'parent_store_name',
      'parent_store_gstin',
    ],
    contactDetails: [
      'email',
      'phone_number',
      'person_name',
      'person_dept',
    ],
    storeContractDetails: [
      'distribution_type',
      'channel',
      'fresh_margin_type',
      'fresh_margin',
      'eoss_margin_type',
      'eoss_margin',
      'markdown_percentage_type',
      'markdown_percentage',
      'discount',
      'discount_percentage',
      'target_linked_insentives',
      'target_linked_insentives_percentagre',
      'cash_discount',
      'cash_discount_type',
      'cash_discount_percentage',
      'payable_duration',
      'stock_correction',
      'promo_contribution',
      'is_security',
      'comments',
      'credit_limit',
      'business_model',
    ],
    storeWallBrandsDetails: [
      'brand_name',
      'wall_space',
      'category_group',
      'category_division',
      'category_presence',
    ],
    storeAddressesDetails: [
      'billing_place_address.address',
      'billing_place_address.pincode',
      'billing_place_address.area',
      'billing_place_address.city',
      'billing_place_address.region',
      'billing_place_address.state',
      'billing_place_address.email',
      'billing_place_address.phone_number',
      'billing_place_address.mobile',
      'shipping_place_address.address',
      'shipping_place_address.pincode',
      'shipping_place_address.area',
      'shipping_place_address.city',
      'shipping_place_address.region',
      'shipping_place_address.state',
      'shipping_place_address.email',
      'shipping_place_address.phone_number',
      'shipping_place_address.mobile',
      'warehouse_address.address',
      'warehouse_address.pincode',
      'warehouse_address.area',
      'warehouse_address.city',
      'warehouse_address.region',
      'warehouse_address.state',
      'warehouse_address.email',
      'warehouse_address.phone_number',
      'warehouse_address.mobile',
      'return_warehouse_address.address',
      'return_warehouse_address.pincode',
      'return_warehouse_address.area',
      'return_warehouse_address.city',
      'return_warehouse_address.region',
      'return_warehouse_address.state',
      'return_warehouse_address.email',
      'return_warehouse_address.phone_number',
      'return_warehouse_address.mobile',
    ],
    mouAggrementFile: [
        'store_id',
        'brand_id',
        'brand_name',
        'file_id',
        'doc_name',
        'doc_url',
        'size',
        'created_at',
        'doc_type',
    ],
    gstDocumentParentStoreFile: [
        'store_id',
        'brand_id',
        'brand_name',
        'file_id',
        'doc_name',
        'doc_url',
        'size',
        'created_at',
        'doc_type',
    ],
    gstDocumentStoreFile: [
        'store_id',
        'brand_id',
        'brand_name',
        'file_id',
        'doc_name',
        'doc_url',
        'size',
        'created_at',
        'doc_type',
    ],
    panDocumentFile: [
        'store_id',
        'brand_id',
        'brand_name',
        'file_id',
        'doc_name',
        'doc_url',
        'size',
        'created_at',
        'doc_type',
    ],
};
  
const getNestedProperty = (obj, path) => {
    return path.split('.').reduce((o, key) => (o && o[key] !== undefined ? o[key] : undefined), obj);
};
  
// const areMandatoryFieldsFilled = (obj, mandatoryFields) => {
//     return mandatoryFields.every((field) => getNestedProperty(obj, field) !== '');
// };

const areMandatoryFieldsFilled = (obj, mandatoryFields) => {
    if (Array.isArray(obj)) {
        return obj.every(item => areMandatoryFieldsFilled(item, mandatoryFields));
    }
    if (Array.isArray(mandatoryFields)) {
        return mandatoryFields.every(field => getNestedProperty(obj, field) !== '');
    }
    return Object.keys(mandatoryFields).every(key => 
        areMandatoryFieldsFilled(obj[key], mandatoryFields[key])
    );
};

export const allMandatoryFieldsFilledBrand = (brandOwnerDetails, brandDetails, contactDetails, channelDetails, address, companyContactDetails, panDocumentFile, gstDocumentFile) => {
    return (
        areMandatoryFieldsFilled(brandOwnerDetails, mandatoryFieldsBrand?.brandOwnerDetails) 
        && areMandatoryFieldsFilled(brandDetails, mandatoryFieldsBrand?.brandDetails) 
        && contactDetails.every(contact => areMandatoryFieldsFilled(contact, mandatoryFieldsBrand?.contactDetails)) 
        && areMandatoryFieldsFilled(channelDetails, mandatoryFieldsBrand?.channelDetails) 
        && areMandatoryFieldsFilled(address, mandatoryFieldsBrand?.address) 
        && areMandatoryFieldsFilled(companyContactDetails, mandatoryFieldsBrand?.companyContactDetails) 
        && areMandatoryFieldsFilled(panDocumentFile, mandatoryFieldsBrand?.panDocumentFile) 
        && areMandatoryFieldsFilled(gstDocumentFile, mandatoryFieldsBrand?.gstDocumentFile)
        // documentUrls.every(doc => areMandatoryFieldsFilled(doc, mandatoryFieldsBrand?.documentUrls))
    );
};
  
export const allMandatoryFieldsFilledStore = (ownerDetails, storeDemographic, parentStore, contactDetails, storeContractDetails, storeWallBrandsDetails, storeAddressesDetails, mouAggrementFile, gstDocumentParentStoreFile, gstDocumentStoreFile, panDocumentFile) => {
    return (
        areMandatoryFieldsFilled(ownerDetails, mandatoryFieldsStore?.ownerDetails) 
        && areMandatoryFieldsFilled(storeDemographic, mandatoryFieldsStore?.storeDemographic) 
        && areMandatoryFieldsFilled(parentStore, mandatoryFieldsStore?.parentStore) 
        && contactDetails.every(contact => areMandatoryFieldsFilled(contact, mandatoryFieldsStore?.contactDetails)) 
        && areMandatoryFieldsFilled(storeContractDetails, mandatoryFieldsStore?.storeContractDetails)
        && storeWallBrandsDetails.every(brand => areMandatoryFieldsFilled(brand, mandatoryFieldsStore?.storeWallBrandsDetails)) 
        && areMandatoryFieldsFilled(storeAddressesDetails, mandatoryFieldsStore?.storeAddressesDetails) 
        && areMandatoryFieldsFilled(mouAggrementFile, mandatoryFieldsStore?.mouAggrementFile) 
        && areMandatoryFieldsFilled(gstDocumentParentStoreFile, mandatoryFieldsStore?.gstDocumentParentStoreFile) 
        && areMandatoryFieldsFilled(gstDocumentStoreFile, mandatoryFieldsStore?.gstDocumentStoreFile) 
        && areMandatoryFieldsFilled(panDocumentFile, mandatoryFieldsStore?.panDocumentFile)
    );
};
