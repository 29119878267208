// DropzoneComponent.js
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { ToastErrorService } from '../ToastNotification/ToastService';

const CustomDropzone = (props) => {
    const { displayContent, onFileDrop } = props
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        onFileDrop(acceptedFiles);
    }, [onFileDrop]);

    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        onDrop, accept: props.filesAccepted
    });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        errors.map(e => {
            ToastErrorService(e.message, 'dropzone');
            fileRejections.pop(0);
        })
    ));

    return (
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
            <input {...getInputProps()} />
            <div className='h100'>{displayContent}</div>
        </div>
    );
};

export default CustomDropzone;
