import React from "react";

function StatusTag({ status }) {
    var bgColor = "bg-[#FF4D4D]";

    if (status.toUpperCase() === "Inactive".toUpperCase()) {
        bgColor = "statusInActive"
    } else if (status.toUpperCase() === "Pass".toUpperCase() || status.toUpperCase() === "Action Taken".toUpperCase() || status.toUpperCase() === "Active".toUpperCase()) {
        bgColor = "statusActive";
    } else if (
        status.toUpperCase() === "Fail".toUpperCase()) {
        bgColor = "statusInActive";
    } else if (status.toUpperCase() === "Partially Fail".toUpperCase() || status.toUpperCase() === "No Action Required".toUpperCase()) {
        bgColor = "statusInActiveDanger";
    } else if (status.toUpperCase() === "Completed".toUpperCase()) {
        bgColor = "statusActive";
    } else if (status.toUpperCase() === "Incomplete".toUpperCase()) {
        bgColor = "statusInActive";
    }

    return (
        <>
            <div
                className={bgColor}
            >
                <div className="text-capitalize">{status}</div>
            </div>
        </>
    );
}

export default StatusTag;
