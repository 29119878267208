import React, { Fragment } from "react";
import { useHistory } from 'react-router-dom';
import { CreateUserPassowrd } from "../UserAuth.service";
import InputTextField from "../../../ReUsableComponent/InputTextField";
import PrimaryButton from "../../../ReUsableComponent/PrimaryButton";
import EyeSlashIcon from '../../../assets/svg/eyeSlash.svg';
import EyeOpenIcon from '../../../assets/svg/eyeOpen.svg';
import '../../../global.css';
import '../Login/login.css';
import Loader from "../../../ReUsableComponent/Loader";
import { ToastErrorService, ToastSuccessService } from "../../../ReUsableComponent/ToastNotification/ToastService";
import { ToastContainer } from "react-toastify";


const ChangePassword = () => {

    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [password, setPassword] = React.useState({ value: '', valid: true, requiredName: true });
    const [confirmPassword, setConfirmPassword] = React.useState({ value: '', valid: true, requiredName: true });
    const [showPwd, setShowPwd] = React.useState(false);
    const [showCnfPwd, setShowCnfPwd] = React.useState(false);
    const [pwdNotMatch, setPwdNotMatch] = React.useState(true);
    const [SubmitBtnDisabled, setSubmitBtnDisabled] = React.useState(false);
    const [showToastMsg, setShowToastMsg] = React.useState(false);
    const [showErrToastMsg, setShowErrToastMsg] = React.useState(false);
    const [changePwdResponse, setChangePwdResponse] = React.useState([]);

    const onClickSubmit = () => {
        if (password?.value === confirmPassword?.value) {

            setLoading(true);
            let requestBody = {
                "emailEmpId": localStorage.getItem('userEmail'),
                "password": password?.value,
                "confirmPassword": confirmPassword?.value,
                "passwordType": "reset",
                "deviceType": "web"
            }
            CreateUserPassowrd(requestBody).then(response => {
                if (response?.data?.statusCode === 204) {
                    ToastSuccessService(response?.data?.message);
                    setChangePwdResponse(response?.data?.data);
                    setTimeout(() => {
                        history.push('/auth/adminLogin');
                    }, 1000);
                } else if (response.data.statusCode === 400) {
                    ToastErrorService(response.data.message);
                }
            }).catch(error => {
                ToastErrorService(error?.response?.data.message);
                setChangePwdResponse(error?.response?.data);
            }).finally(() => {
                setLoading(false);
            })
        } else {
            setPwdNotMatch(false);
        }

    }

    const onInputPassword = (event, type) => {
        setPwdNotMatch(true);
        const REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        if (event.target.value !== "" && REGEX.test(event.target.value)) {
            if (type === 'new') {
                if (event.target.value.length <= 64) {
                    setPassword({
                        ...password,
                        value: event.target.value,
                        valid: true,
                        requiredName: true
                    })
                }
            } else {
                if (event.target.value.length <= 64) {
                    setConfirmPassword({
                        ...confirmPassword,
                        value: event.target.value,
                        valid: true,
                        requiredName: true

                    })
                }
            }
        } else if (event.target.value === "") {
            if (type === 'new') {
                if (event.target.value.length <= 64) {
                    setPassword({
                        ...password,
                        value: event.target.value,
                        valid: true,
                        requiredName: false
                    })
                }
            } else {
                if (event.target.value.length <= 64) {
                    setConfirmPassword({
                        ...confirmPassword,
                        value: event.target.value,
                        valid: true,
                        requiredName: true
                    })
                }
            }
        }
        else {
            if (type === 'new') {
                if (event.target.value.length <= 64) {
                    setPassword({
                        ...password,
                        value: event.target.value,
                        valid: false,
                        requiredName: true
                    })
                }
            } else {
                if (event.target.value.length <= 64) {
                    setConfirmPassword({
                        ...confirmPassword,
                        value: event.target.value,
                        valid: false,
                        requiredName: true

                    })
                }
            }
        }
    }

    const handleShowPwd = () => {
        setShowPwd(prev => !prev)
    }
    const handleShowCnfPwd = () => {
        setShowCnfPwd(prev => !prev)
    }

    React.useEffect(() => {
        if ((password?.value !== '' && password?.valid) && (confirmPassword?.value && confirmPassword?.valid)) {
            setSubmitBtnDisabled(false)
        } else {
            setSubmitBtnDisabled(true)
        }

    }, [password, confirmPassword])

    return (
        <Fragment>
            <div className="loginForm customShadow notoSansFont">
                <div className="loginCard">
                    <div className="montSerratFont fontSize24">
                        Create a new password
                    </div>
                    <div className="notoSansFont fontSize12 textColorGray pt-1">Create a new password that is at least 8 characters.</div>
                    <div className="paddingTop16"></div>
                    <div className="paddingTop16 position-relative">
                        <InputTextField label='New Password' maxLength={64} type={showPwd ? 'text' : 'password'} helperText='' error={false} required={true} onChange={(event) => onInputPassword(event, 'new')} value={password?.value} disabled={false} />
                        <div className='position-absolute pwdViewBtn' onClick={handleShowPwd}>
                            <img src={showPwd ? EyeOpenIcon : EyeSlashIcon} alt='' />
                        </div>
                        {!password?.valid && <div className="text-danger errorMsg">Please enter a valid Password.</div>}
                    </div>
                    <div className="pt-3 position-relative">
                        <InputTextField label='Confirmed Password' maxLength={64} type={showCnfPwd ? 'text' : 'password'} helperText='' error={false} required={true} onChange={(event) => onInputPassword(event, 'cnf')} value={confirmPassword?.value} disabled={false} />
                        <div className='position-absolute pwdViewBtn' onClick={handleShowCnfPwd}>
                            <img src={showCnfPwd ? EyeOpenIcon : EyeSlashIcon} alt='' />
                        </div>
                        {!confirmPassword?.valid && <div className="text-danger errorMsg">Please enter a valid Password.</div>}
                        {!pwdNotMatch && <div className="text-danger errorMsg">Password does not match.</div>}
                    </div>

                    <div className="paddingTop24">
                        <PrimaryButton disabled={SubmitBtnDisabled} title='Submit' onClick={onClickSubmit} />
                    </div>
                </div>
            </div>
            <ToastContainer />
            {loading && <Loader />}
        </Fragment>)
};

export default ChangePassword;
