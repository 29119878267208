import React, { useState, useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import ActiveIcon from '../../assets/svg/active.svg';
import InActiveIcon from '../../assets/svg/inActive.svg';
import ViewIcon from '../../assets/svg/viewicon.svg';
import EditIcon from '../../assets/svg/pencil.svg';
import ExportIcon from '../../assets/svg/exportIconWhite.svg';
import DeleteIcon from '../../assets/svg/deleteWhiteIcon.svg';
import StatusTag from '../../ReUsableComponent/StatusTag';
import DownloadIcon from '../../assets/svg/reportsDownload.svg';
import { Tooltip } from "@mui/material";
import './CustomTableWithFilter.css';
import RedirectIcon from '../../assets/svg/redirectIcon.svg';
import { useHistory } from "react-router-dom";

const CustomTableWithFilter = (props) => {

    const { onClickView, onClickEdit, onClickDelete, refGrid, handleActiveToggle, filterModel, onClickDownloadReport, getRowStyle, gotoReconReport, isContentLeftRightFixed = true } = props;
    const [isContentExceeds, setIsContentExceeds] = useState(false);
    const gridRef = useRef(null);
    const history = useHistory();

    useEffect(() => {
        if (window.innerWidth >= 768) {
            if (gridRef.current) {
                const totalColumnWidth = props.column.reduce((sum, column) => sum + (column.width || 100), 0);
                const gridContainerWidth = gridRef.current.clientWidth;
                setIsContentExceeds(totalColumnWidth < gridContainerWidth);
            }
        }

    }, [props.column]);

    const customColumn = props.column.map((column, index) => {
        let pinned = null;
        if (window.innerWidth >= 768) {
            if (isContentLeftRightFixed) {
                if (index === 0) {
                    pinned = 'left';
                } else if (column.field === 'action' || column.field === 'feedbackAction' || column.field === 'targetAction' || column.field === 'employeeStatusaction') {
                    pinned = 'right';
                }
            }
        }

        return {
            ...column,
            cellRenderer: (params) => {
                if (column.field === 'action' && column.actionFor === 'salesOverview') {
                    return (<div className="h100 actionBtnContainer">
                        <button title="View" className="tableActionButtons d-flex align-items-center justify-content-center viewAndEditBtn"
                            onClick={() => onClickView(params?.data?.id)}>
                            <img src={ViewIcon} alt="" />
                        </button>
                        <button title="Edit" className="tableActionButtons d-flex align-items-center justify-content-center viewAndEditBtn"
                            onClick={() => onClickEdit(params?.data?.id)}>
                            <img id="edit" src={EditIcon} alt="" />
                        </button>
                        <button style={{ padding: '4px 2px', }} className="tableActionButtons d-flex align-items-center justify-content-center viewAndEditBtn"
                            onClick={() => onClickEdit(params?.data?.id)}>
                            <img id="edit" src={ExportIcon} alt="" />
                        </button>
                    </div>);
                } else if (column.field === 'action' && column.actionFor === 'rulesMangment') {
                    return (<div className="h100 actionBtnContainer">
                        <button title="Edit" className="tableActionButtons d-flex align-items-center justify-content-center viewAndEditBtn"
                            onClick={() => onClickEdit(params?.data?.id, params?.data?.index)}>
                            <img id="edit" src={EditIcon} alt="" />
                        </button>
                    </div>);
                } else if (column.field === 'action') {
                    return (<div className="h100 actionBtnContainer">
                        <button title="View" className="tableActionButtons d-flex align-items-center justify-content-center viewAndEditBtn"
                            onClick={() => onClickView(params?.data?.id)}>
                            <img src={ViewIcon} alt="" />
                        </button>
                        <button title="Edit" className="tableActionButtons d-flex align-items-center justify-content-center viewAndEditBtn"
                            onClick={() => onClickEdit(params?.data?.id)}>
                            <img id="edit" src={EditIcon} alt="" />
                        </button>
                        <button title="Enable/Disable" className="tableActionButtons d-flex align-items-center justify-content-center"
                            onClick={() => handleActiveToggle(params?.data)}>
                            <img src={params?.data?.status ? ActiveIcon : InActiveIcon} alt="" />
                        </button>
                    </div>);
                } else if (column.field === 'promotionAction') {
                    return (<div className="h100 actionBtnContainer">
                        <button title="View" className="tableActionButtons d-flex align-items-center justify-content-center viewAndEditBtn"
                            onClick={() => onClickView(params?.data?.id)}>
                            <img src={ViewIcon} alt="" />
                        </button>
                        <button title="Edit" disabled={!params.data.status} className={`tableActionButtons d-flex align-items-center justify-content-center viewAndEditBtn ${!params.data.status ? 'opacity-50' : 'opacity-100'}`}
                            onClick={() => onClickEdit(params?.data?.id)}>
                            <img id="edit" src={EditIcon} alt="" />
                        </button>
                        {/* <button className="tableActionButtons d-flex align-items-center justify-content-center"
                            onClick={() => handleActiveToggle(params?.data)}>
                            <img src={params?.data?.status ? ActiveIcon : InActiveIcon} alt="" />
                        </button> */}
                    </div>);
                } else if (column.field === 'brandStatus' || column.field === 'reportStatus' || column.field === 'feedbackStatus' || column.field === 'profileStatus' || column.field === 'employeeSalesStatus') {
                    return <div className="tableStatusContainer">
                        {column.field === 'profileStatus' ? <StatusTag status={params?.data?.is_profile_completed ? 'Completed' : 'Incomplete'} /> : <StatusTag status={params.value} />}
                    </div>
                } else if (column.field === 'brandDeck') {
                    return <div>
                        <a href={params.value} target="_blank" rel="noreferrer">{params.value}</a>
                    </div>
                } else if (column.field === 'storeName' || column.field === 'brandName' || column.field === 'userName') {
                    return <button className="text-truncate cursor-pointer text-decoration-underline" title={params.value} onClick={() => onClickView(params?.data?.id)} >
                        {params.value}
                    </button>
                } else if ((column.field === 'passRecord') || column.field === 'errorRecord' || column.field === 'failedRecord') {
                    return <div className="h100 actionBtnContainer">
                        {params.value ? (<button title="Download" className={`tableActionButtons d-flex align-items-center justify-content-center ${column.field === 'passRecord' ? 'passReport' : column.field === 'errorRecord' ? 'errorReport' : 'failedReport'}`}
                            onClick={() => onClickDownloadReport(column.field, params)}>
                            <img src={DownloadIcon} alt="" />
                        </button>) : 'NA'}
                    </div>
                } else if (column.field === 'feedbackAction') {
                    return (<div className="h100 actionBtnContainer">
                        <button title="View" className="tableActionButtons d-flex align-items-center justify-content-center viewAndEditBtn"
                            onClick={() => onClickView(params?.data?.id)}>
                            <img src={ViewIcon} alt="" />
                        </button>
                    </div>);
                } else if (column.field === 'targetAction') {
                    return (<div className="h100 actionBtnContainer">
                        <button title="View" className="tableActionButtons d-flex align-items-center justify-content-center viewAndEditBtn"
                            onClick={() => onClickView(params?.data?.id)}>
                            <img src={ViewIcon} alt="" />
                        </button>
                        <button className="tableActionButtons d-flex align-items-center justify-content-center viewAndEditBtn"
                            onClick={() => onClickDelete(params?.data?.id)}>
                            <img id="delete" src={DeleteIcon} alt="" style={{ width: '39px' }} />
                        </button>
                    </div>);
                } else if (column.field === 'image') {
                    return (<div className="zoom-icon-container">
                        <Tooltip arrow slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -14],
                                        },
                                    },
                                ],
                            },
                        }} title={<img width={120} src={params.value} />}>
                            <img
                                src={params.value} width={24}
                                alt="cross icon"
                                className="zoom-icon"
                            />
                        </Tooltip>
                    </div>);
                } else if (column.field === 'barcode' || column.field === 'styleCode' || column.field === 'nameStore') {
                    return <span title={params.value} style={{ lineHeight: 1.3 }} >
                        {params.value}
                    </span>
                } else if (column.field === 'reconReport') {
                    return <div className="h100 actionBtnContainer">
                        <button title="Goto Recon Reports" className={`tableActionButtons d-flex align-items-center justify-content-center`} style={{
                            backgroundColor: '#f2f2f2', width: 'fit-content',
                            border: '1px solid #f2f2f2', borderRadius: '30px', padding: '8px 8px 10px 10px'
                        }}
                            onClick={() => gotoReconReport(params)}>
                            <img src={RedirectIcon} alt="" />
                        </button>
                    </div>
                } else if ((column.field === 'reconFileDownload')) {
                    return <div className="h100 actionBtnContainer">
                        <button title="Download" className={`tableActionButtons d-flex align-items-center justify-content-center passReport`}
                            onClick={() => onClickDownloadReport(params)}>
                            <img src={DownloadIcon} alt="" />
                        </button>
                    </div>
                } else if ((column.field === 'employeeStatusaction')) {
                    return (<div className="h100 actionBtnContainer">
                        <button title="View" className="tableActionButtons d-flex align-items-center justify-content-center viewAndEditBtn"
                            onClick={() => onClickView(params?.data)}>
                            <img src={ViewIcon} alt="" />
                        </button>
                    </div>);
                } else if ((column.field === 'notiAction')) {
                    return (<div className="h100 actionBtnContainer">
                        <button title="Edit" className="tableActionButtons d-flex align-items-center justify-content-center viewAndEditBtn"
                            onClick={() => onClickEdit(params?.data)}>
                            <img src={EditIcon} alt="" />
                        </button>
                    </div>);
                }
                return <div title={params.value} className="text-truncate">
                    {params.value}
                </div>
            },
            pinned,
        }
    })

    return (
        <div className={`customTable ag-theme-alpine ${props?.customeHeightClass}`} style={{ height: props.height ? props.height : 400, width: '100%' }}>
            <AgGridReact ref={refGrid ? refGrid : gridRef}
                columnDefs={customColumn}
                rowData={props.tableRowData}
                defaultColDef={props.filter}
                sortable
                onGridReady={props.onGridReady}
                onFilterChanged={props.onFilterChanged}
                onFirstDataRendered={props.onFirstDataRendered}
                initialFilterModel={props.initialFilterModel}
                filterModel={filterModel}
                onRowClicked={props.onRowClicked}
                tooltipShowDelay={0}
                getRowStyle={getRowStyle}
                suppressColumnMoveAnimation={true}
                suppressMovableColumns={true}
            />
        </div>
    );
};

export default CustomTableWithFilter;
