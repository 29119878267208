import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 312,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '24px',
    padding: '32px 55px'
};


const SuccessModal = (props) => {

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                className='notoSansFont'
                closable={props.closable}
            >
                <Box className='' sx={props.style ? props.style : style}>
                    {props.content}
                </Box>
            </Modal>
        </div>
    );
}

export default SuccessModal