import React, { useEffect, useMemo, useState, useRef } from 'react';
import './storeManagement.css';
import CustomBlueButton from '../../ReUsableComponent/CustomBlueButton';
import '../../global.css';
import CustomPagination from '../../ReUsableComponent/Pagination';
import { ChangeStoreStatus, DownloadStoreList, GetStoreDetails, GetStoreList, UploadStoreList } from './StoreManagent.service';
import Loader from '../../ReUsableComponent/Loader';
import ExportIcon from '../../assets/svg/downloadIcon.svg';
import ImportIcon from '../../assets/svg/uploadIcon.svg';
import CustomTableWithFilter from '../../ReUsableComponent/CustomTableWithFilter'
import { storeTableHeader } from '../TableHeaders';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CloseIcon from '../../assets/svg/closeIconofmodal.svg';
import CustomModal from '../../ReUsableComponent/CustomModal';
import CustomDropzone from '../../ReUsableComponent/CustomDropzone';
import { storeManagementActions } from '../../store/reducers/StoreManagementReducer';
import SecondaryButton from '../../ReUsableComponent/SecondaryButton';
import WellDoneIcon from "../../assets/lottie/welldone.json";
import SuccessModal from "../../ReUsableComponent/SuccesModal";
import Lottie from 'lottie-react';
import CustomFilterDropDown from '../../ReUsableComponent/CustomFilterDropdown';
import { saveAs } from 'file-saver';
import { ToastErrorService, ToastSuccessService } from '../../ReUsableComponent/ToastNotification/ToastService';
import { userAuthActions } from '../../store/reducers/UserAuthReducers';
import { DownloadTemplate } from '../../Configurations/Core.service';
import ProgressBarForFileUpload from '../../ReUsableComponent/ProgressBarForFileUpload';
import { RefreshOutlined } from '@mui/icons-material';

const uploadFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};
const SuccessUploadModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 390,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 835,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};
const downloadType = [{ label: 'Template Download', value: 'Template' }, { label: 'Report Download', value: 'Report' }]

const StoreManagement = () => {

    const gridRef = useRef(null);

    const history = useHistory();
    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(false);
    // const [selectedPage, setSelectedPage] = useState(1);
    const selectedPage = useSelector(state => state.storeManagement.selectedPage);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [storeTableData, setStoreTableData] = useState([]);
    const [totalStoreCount, setTotalStoreCount] = useState(0);
    const [isStoreActive, setIsStoreActive] = useState(true);
    const [openInitialUploadFileModal, setOpenInitialUploadFileModal] = useState(false);
    const [openUploadFileModal, setOpenUploadFileModal] = useState(false);
    const [openFileUploadedModal, setOpenFileUploadedModal] = useState(false);
    const [anchorElForExport, setAnchorElForExport] = useState(null);
    const [selectedDownloadType, setSelectedDownloadType] = useState(null);
    const [progress, setProgress] = useState(10);
    const [initialFilterModel, setInitialFilterModel] = useState(null);
    const [uploadRateBytesPerSecond, setUploadRateBytesPerSecond] = useState(0);
    const [uploadSpeed, setUploadSpeed] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [openFileSelectedModal, setOpenFileSelectedModal] = useState(false);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);

    const openDownloadType = Boolean(anchorElForExport);
    const userId = useSelector((state) => state.userAuthentication.userId);
    const storeStatus = useSelector((state) => state.storeManagement.storeStatus);
    const storeClass = useSelector((state) => state.storeManagement.storeClass);
    const storeRegion = useSelector((state) => state.storeManagement.region);
    const storeId = useSelector((state) => state.storeManagement.storeId);
    const storeChannel = useSelector((state) => state.storeManagement.channel);
    const storeBrandName = useSelector((state) => state.storeManagement.brandName);
    const storeOpeningStatus = useSelector((state) => state.storeManagement.storeOpeningStatus);
    const storeClassification = useSelector((state) => state.storeManagement.storeClassification);
    const storeBusinessModel = useSelector((state) => state.storeManagement.storeBusinessModel);
    const selectedFyYear = useSelector((state) => state.storeManagement.selectedFyYear);
    const cityTierValue = useSelector((state) => state.storeManagement.cityTierValue);
    const tableFilterValue = useSelector((state) => state.storeManagement.tableFilterValue);
    const displayMasterData = useSelector((state) => state.storeManagement.displayMasterData);
    const isProfileCompleted = useSelector((state) => state.storeManagement.isProfileCompleted);
    const isClikedProfileCompleted = useSelector((state) => state.storeManagement.isClikedProfileCompleted);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);


    const defaultColDef = useMemo(() => {
        return {
            // editable: true,
            filter: true,
        };
    }, []);

    const checkFilterValue = (value) => {
        if (value) {
            return value;
        } else {
            return '';
        }
    }

    useEffect(() => {
        let reqBody =
            isClikedProfileCompleted ? {
                'store_opening_status': tableFilterValue?.openingStatus ? tableFilterValue?.openingStatus?.filter : '',
                'customer_name': tableFilterValue?.storeName ? tableFilterValue?.storeName?.filter : '',
                'store_classification': tableFilterValue?.classification ? tableFilterValue?.classification?.filter : '',
                'business_model': tableFilterValue?.businessModel ? tableFilterValue?.businessModel?.filter : '',
                'channel': tableFilterValue?.channel ? tableFilterValue?.channel?.filter : '',
                'store_class': tableFilterValue?.storeClass ? tableFilterValue?.storeClass?.filter : '',
                'city': tableFilterValue?.city ? tableFilterValue?.city?.filter : '',
                'city_tier': tableFilterValue?.cityTier ? tableFilterValue?.cityTier?.filter : '',
                'area': tableFilterValue?.area ? tableFilterValue?.area?.filter : '',
                'region': tableFilterValue?.region ? tableFilterValue?.region?.filter : '',
                'store_status': displayMasterData ? null : storeStatus,
                'brand_name': tableFilterValue?.nameBrand ? tableFilterValue?.nameBrand?.filter : '',
                'is_profile_completed': isProfileCompleted,
            }
                :
                {
                    'store_opening_status': tableFilterValue?.openingStatus ? tableFilterValue?.openingStatus?.filter : '',
                    'customer_name': tableFilterValue?.storeName ? tableFilterValue?.storeName?.filter : '',
                    'store_classification': tableFilterValue?.classification ? tableFilterValue?.classification?.filter : '',
                    'business_model': tableFilterValue?.businessModel ? tableFilterValue?.businessModel?.filter : '',
                    'channel': tableFilterValue?.channel ? tableFilterValue?.channel?.filter : '',
                    'store_class': tableFilterValue?.storeClass ? tableFilterValue?.storeClass?.filter : '',
                    'city': tableFilterValue?.city ? tableFilterValue?.city?.filter : '',
                    'city_tier': tableFilterValue?.cityTier ? tableFilterValue?.cityTier?.filter : '',
                    'area': tableFilterValue?.area ? tableFilterValue?.area?.filter : '',
                    'region': tableFilterValue?.region ? tableFilterValue?.region?.filter : '',
                    'store_status': displayMasterData ? null : storeStatus,
                    'brand_name': tableFilterValue?.nameBrand ? tableFilterValue?.nameBrand?.filter : '',
                }
        getAllStoreList(reqBody);
    }, [selectedPage, tableFilterValue])

    const handlePageChange = (pageValue) => {
        // setSelectedPage(pageValue)
        dispatch(storeManagementActions.setSelectedPage({ type: pageValue }));
    }

    const getAllStoreList = (reqBody) => {
        setLoading(true);
        let queryParams = `?page=${selectedPage}&limit=${20}`
        GetStoreList(reqBody, queryParams, USER_TOKEN).then(response => {
            let tableData = []
            setTotalStoreCount(response?.data?.data?.total);
            response?.data?.data?.stores?.map((item, index) => {
                let row = {
                    storeName: item?.store_trade_name ? item?.store_trade_name : '-',
                    nameBrand: item?.brands?.length !== 0 ? item?.brands[0]?.brand_name : item?.brand_name,
                    openingDate: convertDate(item?.store_opening),
                    openingStatus: item?.store_opening_status,
                    storeClass: item?.store_class,
                    classification: item?.store_classification,

                    city: item?.store_address !== 'null' ? item?.store_address?.city : 'NA',
                    area: item?.store_address !== 'null' ? item?.store_address?.area : 'NA',
                    businessModel: item?.business_model?.length !== 0 ? item?.business_model?.join(",") : '',
                    displayOptions: item?.brands?.length !== 0 ? item?.brands?.map(brand => brand.display_options).join(",") : 'NA',
                    displayQty: item?.brands?.length !== 0 ? item?.brands?.map(brand => brand.display_qty).join(",") : 'NA',
                    channel: item?.channel,
                    freshMarginType: item?.fresh_margin_type,
                    freshMargin: item?.fresh_margin,
                    eossMarginType: item?.eoss_margin_type,
                    eossMargin: item?.eoss_margin,
                    status: item?.store_status,
                    id: item?._id,
                    is_profile_completed: item?.is_profile_completed,
                }
                tableData.push(row)
            })
            setStoreTableData(tableData)
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const getEndDate = (startDate) => {
        const originalDate = new Date(startDate);
        // Change the year to 2025 and the month to March (which is 2 in JavaScript Date objects)
        let pastYear = originalDate.getFullYear();
        originalDate.setFullYear(pastYear + 1);
        originalDate.setMonth(2); // 0-indexed, so March is 2
        originalDate.setDate(31);
        const formattedEndDate = originalDate.toISOString().substring(0, 10);
        return formattedEndDate
    }

    const onClickImport = () => {
        setOpenUploadFileModal(true);
    }

    const onClickCreateStore = () => {
        // alert('Create store functionality coming soon!');
        dispatch(storeManagementActions.setStepActiveState({ type: 0 }));
        dispatch(storeManagementActions.setStepProgress({ type: 0 }));
        dispatch(storeManagementActions.setCreateStoreOwnerDetails({ type: {} }));
        history.push('/app/createStoreDetails');
    }

    const convertDate = (openingDate) => {
        // let fullDateTimeString = '2024-03-26T13:30:00';
        let date = new Date(openingDate);
        // Extract the date part
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // Month is zero-based, so we add 1
        let day = date.getDate();

        // Create a new date string in the format YYYY-MM-DD
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return moment(dateString).format("DD-MMM-YYYY");
    }


    const downloadStoreList = (reqBody) => {
        setLoading(true);
        DownloadStoreList(reqBody, USER_TOKEN).then(response => {
            const responseInUnit = new Uint8Array(response.data.buffer.data);
            const blob = new Blob([responseInUnit], { type: 'application/octet-stream' });
            saveAs(blob, response.data.filename);
            if (response?.data?.buffer?.statusCode === 400) {
                ToastErrorService(response?.data?.buffer?.message);
            } else {
                ToastSuccessService('Report downloaded successfully.');
            }
        }).catch(error => {
            ToastErrorService('Something went wrong, Please try again after sometime.');
        }).finally(() => {
            setLoading(false);
        })
    }

    const downloadStoreTemplate = () => {
        setLoading(true);
        let param = `?template_type=store_template`
        DownloadTemplate(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                window.location.href = response.data.data
                ToastSuccessService('Template Downloaded Successfully.')
            } else {
                ToastErrorService(response.data.message);
            }
            setOpenUploadFileModal(false);
        }).catch(error => {
            ToastErrorService('Something went wrong, Please try again after sometime.');
        }).finally(() => {
            setLoading(false);
        })
    }

    const uploadBulkStoreList = () => {
        setLoading(true)
        let param = `?user_id=${userId}`
        const formData = new FormData();
        formData.append('file', uploadedFile);
        UploadStoreList(formData, param, USER_TOKEN).then(response => {
            if ((response?.status === 200 || response?.status === 201) && response?.data?.statusCode !== 400) {
                setOpenFileUploadedModal(true);
                setOpenUploadFileModal(false);
                setOpenFileSelectedModal(false);
            } else if (response?.data?.statusCode === 400) {
                ToastErrorService(response?.data?.message)
            }

        }).catch(error => {
        }).finally(() => {
            setLoading(false);
        })
    };

    const toggleStoreActive = (event) => {
        setLoading(true);
        let updatedStatus = !event.status
        let reqBody = {
            'store_status': updatedStatus,
        };
        let queryParam = `?id=${event?.id}`
        ChangeStoreStatus(reqBody, queryParam, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                ToastSuccessService(response?.data.message);
                getAllStoreList();
            }
        }).catch(error => {
            ToastErrorService(error?.response?.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }
    const onClickStoreEdit = (store_id) => {
        dispatch(storeManagementActions.setSelectedTab({ type: 0 }));
        dispatch(storeManagementActions.setViewStoreSelectedTabLabel({ type: 'Owner Details' }));
        dispatch(storeManagementActions.setStoreId({ type: store_id }));
        getStoreDetailById(store_id, 'edit');
        dispatch(userAuthActions.setIsSearchBtnActive({ type: 'editStoreDetails' }));
        // alert('Edit store functionality coming soon!');
    }

    const getStoreDetailById = (store_id, action) => {
        setLoading(true);
        let param = `?id=${store_id}`
        GetStoreDetails(param, USER_TOKEN).then(response => {
            if (response?.data?.statusCode === 200) {
                dispatch(storeManagementActions.setStoreDetailData({ type: response?.data?.data }));
                dispatch(storeManagementActions.setCreateStoreOwnerDetails({ type: response?.data?.data }));
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            if (action === 'view') {
                history.push('/app/viewStoreDetails');
            } else {
                history.push('/app/editStoreDetails');
            }
        })
    }

    const onClickView = (store_id) => {
        dispatch(storeManagementActions.setSelectedTab({ type: 0 }));
        dispatch(storeManagementActions.setViewStoreSelectedTabLabel({ type: 'Owner Details' }));
        getStoreDetailById(store_id, 'view');
        dispatch(userAuthActions.setIsSearchBtnActive({ type: 'viewStoreDetails' }));

    }

    // const handleInitialFileDrop = (files) => {
    //     setProgress(100);
    //     setUploadedFile(files[0]);
    //     setOpenInitialUploadFileModal(false);
    //     setOpenUploadFileModal(true);
    // }

    // const handleFileDrop = (files) => {
    //     setUploadedFile(files[0]);
    //     simulateUpload()
    // };

    const upLoadFileHandle = () => {
        if (uploadedFile) {
            uploadBulkStoreList();
        }
    }

    const simulateUpload = () => {
        setUploadButtonDisabled(true);
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const nextProgress = prevProgress + 10;
                if (nextProgress >= 100) {
                    clearInterval(interval);
                    setUploadSpeed(null);
                    setUploadButtonDisabled(false);
                    return 100;
                }
                if (!startTime) {
                    setStartTime(Date.now());
                }
                const elapsedTime = (Date.now() - startTime) / 1000; // in seconds
                const speed = (nextProgress / elapsedTime).toFixed(2); // in % per second
                setUploadSpeed(speed);
                return nextProgress;
            });
        }, 500);
    };

    const handleFileDrop = (files) => {
        const fileUploaded = files[0];
        if (fileUploaded) {
            setUploadedFile(fileUploaded);
            setProgress(0);
            simulateUpload();
            setOpenFileSelectedModal(true);
            setOpenUploadFileModal(false);
        }
    };

    const removeFile = () => {
        setUploadedFile([]);
    }

    const handleRemoveFile = () => {
        setProgress(0);
        setUploadedFile([]);
        setOpenInitialUploadFileModal(true);
        setOpenUploadFileModal(false);
    }

    const onClickDownloadTemplate = () => {
        downloadStoreTemplate();
    }
    const onClickDownloadStore = (event) => {
        setAnchorElForExport(event.currentTarget);
    }
    const onSelectDownloadCategory = (event) => {
        if (event?.target.value.toUpperCase() === 'Template'.toUpperCase()) {
            downloadStoreTemplate();
        } else {
            let requestBody = {
                'city_tier': cityTierValue,
                'customer_name': tableFilterValue?.storeName ? tableFilterValue?.storeName?.filter : '',
                'store_opening_status': tableFilterValue?.openingStatus ? tableFilterValue?.openingStatus?.filter : storeOpeningStatus,
                'store_classification': tableFilterValue?.classification ? tableFilterValue?.classification?.filter : storeClassification,
                'business_model': tableFilterValue?.businessModel ? tableFilterValue?.businessModel?.filter : storeBusinessModel,
                'channel': tableFilterValue?.channel ? tableFilterValue?.channel?.filter : storeChannel,
                'store_class': tableFilterValue?.storeClass ? tableFilterValue?.storeClass?.filter : storeClass,
                'city': tableFilterValue?.city ? tableFilterValue?.city?.filter : '',
                'area': tableFilterValue?.area ? tableFilterValue?.area?.filter : '',
                'region': storeRegion,
                'brand_name': tableFilterValue?.nameBrand ? tableFilterValue?.nameBrand?.filter : storeBrandName,
                'store_status': displayMasterData ? null : storeStatus,
                'start_date': '',
                'end_date': '',
                'fresh_margin_type': tableFilterValue?.freshMarginType ? tableFilterValue?.freshMarginType?.filter : '',
                'eoss_margin_type': tableFilterValue?.eossMarginType ? tableFilterValue?.eossMarginType?.filter : ''
            }
            downloadStoreList(requestBody);
        }
        setAnchorElForExport(null);
    }
    const handleExportClose = () => {
        setAnchorElForExport(null);
    }

    const onGridReady = (params) => {
        params.api.setFilterModel(tableFilterValue);
        // gridRef.current = params.api;
        // gridRef.current.sizeColumnsToFit();
    };

    const onFilterChanged = (event) => {
        dispatch(storeManagementActions.setTableFilterValue({ type: event.api.getFilterModel() }));
    };

    const onClickReset = () => {
        const gridApi = gridRef.current.api;
        gridApi.setFilterModel(null); // Clears all the filters
        gridApi.onFilterChanged();    // Apply the changes
        dispatch(storeManagementActions.setStoreClass({ type: '' }));
        dispatch(storeManagementActions.setRegion({ type: '' }));
        dispatch(storeManagementActions.setChannel({ type: '' }));
        dispatch(storeManagementActions.setBrandName({ type: '' }));
        dispatch(storeManagementActions.setStoreOpeningStatus({ type: '' }));
        dispatch(storeManagementActions.setStoreClassification({ type: '' }));
        dispatch(storeManagementActions.setStoreBusinessModel({ type: '' }));
        dispatch(storeManagementActions.setCityTierValue({ type: '' }));
        let reqBody = {
            'store_opening_status': '',
            'customer_name': '',
            'store_classification': '',
            'business_model': '',
            'channel': '',
            'store_class': '',
            'city': '',
            'city_tier': '',
            'area': '',
            'region': '',
            'store_status': displayMasterData ? null : storeStatus,
            'brand_name': ''
        }
        getAllStoreList(reqBody);
    }

    return (
        <div className='mainContainer'>
            <div className='notoSansFont containerDiv'>
                <div className='filterMainDiv'>
                </div>
                <div className='d-flex justify-content-end align-items-center'>
                    <button onClick={onClickReset} className='me-2 reset_filters_button'>
                        <span className='me-1 reset_filters_text'>Reset Filters</span>
                        <RefreshOutlined className='RefreshOutlined' />
                    </button>
                    <button className='me-3 importExportFile cursorPointer' title='Upload' onClick={onClickImport} >
                        <div className="cursorPointer">
                            <img src={ImportIcon} alt="" />
                        </div>
                    </button>
                    <button className='me-3 importExportFile' title='Download' onClick={onClickDownloadStore}>
                        <div>
                            <img src={ExportIcon} alt="" />
                        </div>
                    </button>
                    <CustomFilterDropDown data={downloadType} handleClose={handleExportClose} open={openDownloadType} anchorEl={anchorElForExport}
                        onChange={onSelectDownloadCategory} category={'Category'} />
                    <div>
                        <CustomBlueButton title={<div className='d-flex align-items-center'>
                            <span className='fontSize18 fontBold pr-13px'>+</span>
                            <div>Create Store</div>
                        </div>} onClick={onClickCreateStore} />
                    </div>
                </div>
            </div>
            <div className='paddingTop24'>
                <div className='tableContainer'>
                    <CustomTableWithFilter refGrid={gridRef} column={storeTableHeader} tableRowData={storeTableData} filter={defaultColDef} handleActiveToggle={toggleStoreActive} onClickEdit={onClickStoreEdit} onClickView={onClickView} onFilterChanged={onFilterChanged} onGridReady={onGridReady} filterModel={tableFilterValue} customeHeightClass={'tableCustomeHeightClass'} />

                </div>
                <div className='paddingTop16 d-flex justify-content-end customPagination'>
                    <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={totalStoreCount} />
                </div>
            </div>

            {/* Upload File Modal */}
            <CustomModal open={openUploadFileModal} style={uploadFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenUploadFileModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className=''>
                            <div className='channelDisplayData'>
                                <CustomDropzone onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                        <div className='textColorGray fontSize12 pt-3'>Or</div>
                                    </div>
                                    <div className='pt-3'>
                                        <CustomBlueButton onClick={null} title={'Choose File'} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='pt-3 d-flex justify-content-between align-items-center'>
                                <div className='fontSize12 textColorGray'>Download excel file template</div>
                                <div>
                                    <SecondaryButton onClick={onClickDownloadTemplate} title={<div className='d-flex align-items-center'>
                                        <div>
                                            <img src={ExportIcon} alt="" />
                                        </div>
                                        <div className='fontBold'>
                                            Download Template
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* Upload file progress Modal */}
            <CustomModal classes="customeProgressModal" open={openFileSelectedModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileSelectedModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className='dropZoneContentContainer'>
                            <div className='channelDisplayData'>
                                <CustomDropzone onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent uploadedFileDropZoneContent'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <SecondaryButton onClick={handleFileDrop} title={<span className='fontSemiBold textColorBlue'>Choose File</span>} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='dropZoneContentRight'>
                                {uploadedFile?.path && <div>
                                    <p className='uploadingP'>Uploading</p>
                                    <div className='paddingTop24'>
                                        <ProgressBarForFileUpload onClickImageCloseIcon={removeFile} progress={progress} File={uploadedFile} uploadSpeed={uploadSpeed} />
                                    </div>
                                    <div className='pt-3 CustomBlueButtonConatiner' >
                                        <CustomBlueButton customCreatePropsBtnClass="customCreatePropsBtnClass" disabled={uploadButtonDisabled} onClick={uploadBulkStoreList} title={'Upload'} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* File Uploaded Successfully Modal */}
            <SuccessModal style={SuccessUploadModalStyle} open={openFileUploadedModal} handleClose={null} content={<div className='text-center'>
                <div className='d-flex justify-content-center align-items-center'>
                    <Lottie className="welldoneLottie" animationData={WellDoneIcon} loop={false} />
                </div>
                <div className='fontBold textBlack fontSize18 paddingTop24'>File Uploaded Successfully!!!</div>
                <div className='fontSize12 pt-2'>We will be processing the data in background, you can continue with your work.</div>
                <div className='pt-4 d-flex justify-content-center align-items-center'>
                    <button className='modalButton'
                        onClick={() => { setOpenFileUploadedModal(false); setOpenUploadFileModal(false); }}>OK</button>
                </div>
            </div>} />
            {loading && <Loader />}
        </div>
    )
}

export default StoreManagement
