import axios from "axios";
import API_CONFIG from "../../Configurations/ApiDeclaration";

export function SubmitNotificationFormDetails(requestBody, token) {
    const URL = API_CONFIG.SUBMIT_NOTIFICATION_DATA;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}
export function GetNotificationsList(param, token) {
    const URL = API_CONFIG.GET_NOTIFICATION_LIST + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function UpdateNotificationStatus(param, token) {
    const URL = API_CONFIG.GET_NOTIFICATION_LIST + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, '', headers);
}