import { toast, Bounce } from 'react-toastify';

export const ToastSuccessService = (message, toastId) => (toast.success(`${message}`,
    {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        theme: "light",
        transition: Bounce,
        toastId: toastId
    })
)
export const ToastErrorService = (message, toastId) => (toast.error(`${message}`,
    {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        theme: "light",
        transition: Bounce,
        toastId: toastId
    })
)