import React from 'react';
import './Circle.css';

const CustomUserCircle = ({ key, onCircleClick, number, initials }) => {
    const getInitials = (name) => {
        const words = name?.split(' ');
        if (words?.length > 1) {
            return (words[0][0] + words[1][0]).toUpperCase();
        }
        return name?.substring(0, 2).toUpperCase();
    };

    return (
        <div className="circle" key={key} onClick={onCircleClick}>
            <div className="number">{number}</div>
            <div className="initials">{getInitials(initials)}</div>
            <div className="circleTooltip">{initials}</div>
        </div>
    );
};

export default CustomUserCircle;

