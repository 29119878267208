import React, { useState } from 'react';
import './storeInfo.css'; // Assume you have a CSS file for styling

const StoreInfo = ({ content }) => {

    return (
        <div>{content}</div>
    );
};

export default StoreInfo;
