import React, { useState } from 'react';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import { useDispatch, useSelector } from "react-redux";
import { ruleManagementActions } from '../../store/reducers/RuleManagementReducer';

const ExcelToJsonTable = ({ handleFor }) => {
    const dispatch = useDispatch();
    const [cols, setCols] = useState([]);
    const [rows, setRows] = useState([]);
    const [indexs, setIndexs] = useState('');

    const fileHandler = (event) => {
        let fileObj = event.target.files[0];
        // Pass the fileObj to ExcelRenderer
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.error(err);
            } else {
                setCols(resp.cols);
                const matchingRow3 = resp?.rows?.find(row => row[3] != null && row[3] != row[4]);
                const matchingRow4 = resp?.rows?.find(row => row[4] != null && row[4] != row[5]);

                if (matchingRow3) {
                    setRows(matchingRow3);
                    if (handleFor === 'updateRule') {
                        dispatch(ruleManagementActions.setExcelFileUpdatedRowData({ type: matchingRow3 }));
                    } else {
                        dispatch(ruleManagementActions.setExcelFileRowData({ type: matchingRow3 }));
                    }
                } else if (matchingRow4) {
                    setRows(matchingRow4);
                    if (handleFor === 'updateRule') {
                        dispatch(ruleManagementActions.setExcelFileUpdatedRowData({ type: matchingRow4 }));
                    } else {
                        dispatch(ruleManagementActions.setExcelFileRowData({ type: matchingRow4 }));
                    }
                }
            }
        });
    };

    return (
        <div>
            <label htmlFor="fileUpload" style={{ display: 'inline-block', padding: '10px', backgroundColor: '#4169e1', color: '#fff', borderRadius: '19px', cursor: 'pointer' }}>
                Upload Excel File
            </label>
            <input 
                id="fileUpload" 
                type="file" 
                onChange={fileHandler} 
                style={{ display: 'none' }} 
            />
        </div>
    );
};

export default ExcelToJsonTable;

