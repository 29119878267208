import React, { Fragment } from "react";
import ResizeDetector from "react-resize-detector";
import AppMain from "../../AppLayout/AppMain";

const Main = () => {
    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <Fragment>
                    <div>
                        <AppMain />
                    </div>
                </Fragment>
            )}
        />
    );
}

export default Main
