import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedPage: 1,
    defaultYear: '',
    defaultBrand: '',
    filterModel: {},
    viewDetailsData: {},
    viewDetailsAllDataByID: {},
    defaultBrandForRecon: {},
    brandId: '',
};

const targetVsAchievementSlice = createSlice({
    name: 'target',
    initialState,
    reducers: {
        setSelectedPage: (state, action) => {
            state.selectedPage = action.payload.type
        },
        setDefaultYear: (state, action) => {
            state.defaultYear = action.payload.type
        },
        setDefaultBrand: (state, action) => {
            state.defaultBrand = action.payload.type
        },
        setFilterModel: (state, action) => {
            state.filterModel = action.payload.type
        },
        setViewDetailsData: (state, action) => {
            state.viewDetailsData = action.payload.type
        },
        setViewDetailsAllDataByID: (state, action) => {
            state.viewDetailsAllDataByID = action.payload.type
        },
        setDefaultBrandForRecon: (state, action) => {
            state.defaultBrandForRecon = action.payload.type
        },
        setBrandId: (state, action) => {
            state.brandId = action.payload.type
        },
    }
});

export const targetVsAchievementActions = targetVsAchievementSlice.actions;
export default targetVsAchievementSlice;
