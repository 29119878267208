import React from 'react';
import { Bar } from "react-chartjs-2";

const CategoryBarChart = ({ chartData, width, height }) => {

    const barChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false, // Ensure no data labels are shown inside the bars
            },
        },
        datalabels: {
            display: false,
            color: '#fff',
        },
    }

    return (
        <div className='chart-wrapper' >
            <Bar id='barrr' height={400} data={chartData}
                options={barChartOptions} />
        </div>
    )
}

export default CategoryBarChart