import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    excelFileRowData: [],
    excelFileUpdatedRowData: [],
    excelFileColData: [],
    updateDisabledItems: [],
};

const ruleManagementSlice = createSlice({
    name: 'ruleManagement',
    initialState,
    reducers: {
        setExcelFileRowData: (state, action) => {
            state.excelFileRowData = action.payload.type
        },
        setExcelFileUpdatedRowData: (state, action) => {
            state.excelFileUpdatedRowData = action.payload.type
        },
        setExcelFileColData: (state, action) => {
            state.excelFileColData = action.payload.type
        },
        setUpdateDisabledItems: (state, action) => {
            state.updateDisabledItems = action.payload.type
        },
    }
});

export const ruleManagementActions = ruleManagementSlice.actions;
export default ruleManagementSlice;
