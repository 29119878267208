import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './loader.css';

const Loader = () => {
    return (
        <div>
            <div className="parentDisable"></div>
            <div id="popup">
                <CircularProgress style={{ color: '#7363D6' }} />
            </div>
        </div>
    )
}

export default Loader