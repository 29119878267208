import axios from "axios";
import API_CONFIG from "../../Configurations/ApiDeclaration";

export function AdminLogin(requestBody) {
    const URL = API_CONFIG.ADMIN_LOGIN;
    return axios.post(URL, requestBody);
}

export function CreateUserPassowrd(requestBody) {
    const URL = API_CONFIG.CREATE_PASSWORD;
    return axios.post(URL, requestBody);
}

export function SendOtpToChangePassword(requestBody) {
    const URL = API_CONFIG.SEND_OTP;
    return axios.post(URL, requestBody);
}

export function VerifyOtp(requestBody) {
    const URL = API_CONFIG.VERIFY_OTP;
    return axios.post(URL, requestBody);
}
