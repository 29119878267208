

import React, { useState, useRef, Component, Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import Loader from '../../../ReUsableComponent/Loader';
import SuccessPopup from '../../../ReUsableComponent/SuccessPopupMsg';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import { CreateNewPromotion } from './../promotion.service';

import PromotionForm from '../PromotionForm';

const CreatePromotion = () => {
    const timeoutRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const styleCode = useSelector((state) => state.promotion.styleCodeType);
    const [loading, setLoading] = useState(false);
    const [isXValueDisabled, setIsXValueDisabled] = useState(true);
    const [isYValueDisabled, setIsYValueDisabled] = useState(true);

    const [formData, setFormData] = useState(
        {
            description: "",
            brand_data: {
                brand_id: "",
                brand_name: ""
            },
            from_date: "",
            to_date: "",
            region: [],
            state: [],
            store_detail: [],
            promotion_type: "",
            x_value: "",
            promo_code: "",
            y_value: "",
            promotion_type_value: "",
            season: [],
            category: [],
            style_code: [],
            style_code_value: '',
            barcode: ""
        }
    );

    const [formDataError, setFormDataError] = useState({
        description: '',
        // x_value: '',
        // y_value: '',
    });

    const onClickCancel = () => {
        history.push('/app/promotion');
    }

    const handleSubmit = () => {
        setLoading(true);
        // delete formData.style_code_value;
        CreateNewPromotion(formData, USER_TOKEN)
            .then(response => {
                if (response.status === 200) {
                    setOpenSuccessPopup(true);
                    // setTimeout(() => {
                    //     history.push('/app/promotion');
                    //     setOpenSuccessPopup(false);
                    // }, 5000);

                    // Clear any previous timeout if it exists
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    // Set a new timeout to automatically close the popup
                    timeoutRef.current = setTimeout(() => {
                        setOpenSuccessPopup(false);
                        history.push('/app/promotion');
                    }, 5000);
                }
            })
            .catch(error => {
                ToastErrorService(error.response?.data?.message || 'An error occurred.');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div className='notoSansFont'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='breadCrumbs'>
                    <span className='fontLight'>
                        <Link className="breadCrumbs" to={'/app/promotion'}>Home</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>Create Promotion</span>
                </div>
            </div>

            <div className='pt-3'>
                <div className='bg-white createPromotionFormCard'>
                    <div className='createPromotionHeader montSerratFont fontMedium fontSize18 textColorBlack'>
                        Create Promotion
                    </div>
                    <PromotionForm formData={formData} setFormData={setFormData} formDataError={formDataError} setFormDataError={setFormDataError} isXValueDisabled={isXValueDisabled} setIsXValueDisabled={setIsXValueDisabled} isYValueDisabled={isYValueDisabled} setIsYValueDisabled={setIsYValueDisabled} />
                </div>
            </div>

            <div className='pt-3'>
                <div className='bg-white continueBtn d-flex'>
                    <div className='pe-3'>
                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                    </div>
                    <div>
                        <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleSubmit} title={`Submit`} disabled={
                            formDataError &&
                            (
                                formDataError?.description !== null ||
                                formData?.brand_data?.brand_name === '' ||
                                formData?.from_date === '' ||
                                formData?.to_date === '' ||
                                formData?.promotion_type === '' ||
                                formData?.promotion_type_value === '' ||
                                formData?.promo_code === '' ||
                                ((styleCode?.value && styleCode?.value === 'Custom') && formData.style_code?.length === 0)
                                // ||(!isXValueDisabled && formDataError?.x_value !== null)
                                // || (!isYValueDisabled && formDataError?.y_value !== null)
                            )
                        }
                        />
                    </div>
                </div>
            </div>

            <SuccessPopup open={openSuccessPopup} closable={false} onClose={() => { if (timeoutRef.current) { clearTimeout(timeoutRef.current); } setOpenSuccessPopup(false); history.push('/app/promotion'); }} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>Promotion Created Successfully!</div>
                    <div className='fontSize16 textColor333'>You have successfully created your promotion </div>
                </div>
            </div>} />
            {loading && <Loader />}
        </div>
    )
}

export default CreatePromotion