import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dropDownIcon from '../../assets/svg/dropDownIcon.svg';
import CustomFilterSearchDropdown from '../CustomFilterSearchDropdown';
import { GetBrandNameList } from '../../Pages/Promotion/promotion.service';
import { ToastErrorService, ToastSuccessService } from '../ToastNotification/ToastService';
import { useDispatch, useSelector } from 'react-redux';

const BrandListDropdown = ({ extraClass, selectedBrandListData, setSelectedBrandListData }) => {
    const history = useHistory();
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const USER_ID = useSelector((state) => state.userAuthentication.userId);
    const [brandList, setBrandList] = useState([]);
    const [anchorElForBrandList, setAnchorElForBrandList] = useState(null);
    const openBrandListFilter = Boolean(anchorElForBrandList);
    const [dropSearchValue, setdropSearchValue] = useState('');

    useEffect(() => {
        getAllBrand();
    }, []);

    const handleSearchChange = (event) => {
        setdropSearchValue(event.target.value);
    };

    const handleBrandListClick = (event) => {
        setAnchorElForBrandList(event.currentTarget);
    };

    const handleBrandListClose = () => {
        setAnchorElForBrandList(null);
    };

    const onSelectDownloadBrandList = (event) => {
        const selectedBrand = brandList.find(brand => brand.value === event.target.value);
        if (selectedBrand) {
            setSelectedBrandListData({
                label: selectedBrand?.label,
                value: selectedBrand?.value,
                email: selectedBrand?.email,
                id: selectedBrand?.id,
            });
            handleBrandListClose();
        }
    };

    const getAllBrand = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let brands = []
                response.data.data.forEach(element => {
                    let brandObject = {
                        label: element?.brand_name,
                        value: element?.brand_name,
                        email: element?.email_id,
                        id: element?._id
                    }
                    brands.push(brandObject)
                });
                setBrandList(brands);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
        })
    }

    return (
        <div className='position-relative max-w-200'>
            <div className={`d-flex statusFilter cursorPointer align-items-center justify-content-between me-3 ${extraClass}`}
                onClick={handleBrandListClick}>
                <p className='text-truncate' title={selectedBrandListData?.label}>{selectedBrandListData?.label ? selectedBrandListData?.label : 'Sleact Brand'}</p>
                <img className={`${openBrandListFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
            </div>
            <CustomFilterSearchDropdown customClass='upload-file' data={brandList} handleClose={handleBrandListClose} open={openBrandListFilter} anchorEl={anchorElForBrandList}
                onChange={onSelectDownloadBrandList} selectedValue={selectedBrandListData?.value} category={'Brand'} dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
        </div>
    );
};

export default BrandListDropdown;

