import React from 'react';
import closeIcon from '../../assets/png/closeIcon.png';
import deleteIcon from '../../assets/svg/deleteIcon.svg';
import ExcellIcon from '../../assets/png/excellIcon.png';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import PdfIconForFile from '../../assets/svg/pdf.svg';
import JPEG from '../../assets/svg/jpeg.svg';
import PNG from '../../assets/svg/png.svg';


const ProgressBarForFileUpload = (props) => {

    const { progress, File, uploadSpeed, onClickImageCloseIcon, notShowBrand, isCLass } = props;

    const renderImage = (file_type) => {
        if (file_type === 'application/pdf') {
            return PdfIconForFile
        } else if (file_type === 'image/jpeg' || file_type === 'jpg') {
            return JPEG
        } else if (file_type === 'image/png') {
            return PNG
        } else {
            return ExcellIcon
        }
    }


    function LinearProgressWithLabel(props) {
        return (
            <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Box style={{ width: '100%', marginRight: 1 }}>
                    <LinearProgress variant="determinate" color="success" {...props} />
                </Box>
            </Box>
        );
    }

    return (
        <div style={{ width: '100%' }}>
            {File && <div className='progressBarSec'>
                <div className={`dropZoneImport ${notShowBrand ? 'dropZoneImportInactive' : ''}`} >
                    <img src={renderImage(File?.type)} alt="" width={29} height={29} />
                </div>
                <div className='linearProgressContainer'>
                    <div className='progressHeading'><span>{File?.doc_name ? File?.doc_name : (File.name ? File.name : File.fileName)} - {((File.size ? File.size : File.fileSize) / (1024 * 1024)).toFixed(2)} mb</span> <span className='cursorPointer'><span className='cursorPointer' onClick={onClickImageCloseIcon}><img src={deleteIcon} alt="" /></span></span></div>
                    <Box style={{ width: '100%' }} className={`${isCLass}`}>
                        <LinearProgressWithLabel value={progress} />
                    </Box>
                    <div className='progressHeading bottom'>
                        <span>{progress}% Done</span>
                        {uploadSpeed && <span>{uploadSpeed}KB/sec</span>}
                    </div>
                </div>
            </div>}

        </div>
    )
}

export default ProgressBarForFileUpload