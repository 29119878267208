import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tableFilters: {},
    selectedPage: 1,
    notiFormData: {},
    editNotiForm: false
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setTableFilters: (state, action) => {
            state.tableFilters = action.payload.type
        },
        setSelectedPage: (state, action) => {
            state.selectedPage = action.payload.type
        },
        setNotiFormData: (state, action) => {
            state.notiFormData = action.payload.type
        },
        setEditNotiFormData: (state, action) => {
            state.editNotiForm = action.payload.type
        },
    }
});

export const notificationActions = notificationSlice.actions;
export default notificationSlice;
