import axios from "axios";
import API_CONFIG from "../../Configurations/ApiDeclaration";

export function GetFeedbackList(param, token) {
    const URL = API_CONFIG.GET_FEEDBACK_LIST + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function updateFeedbackDetails(requestBody, param, token) {
    const URL = API_CONFIG.UPDATE_FEEDBACK_DEAILS + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.patch(URL, requestBody, headers);
}
export function createFeedback(requestBody, token) {
    const URL = API_CONFIG.CREATE_FEEDBACK;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}