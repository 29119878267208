import axios from "axios";
import API_CONFIG from "../../Configurations/ApiDeclaration";

export function GetUserList(queryParam, token) {
    const URL = API_CONFIG.GET_USER_LIST + queryParam;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function CreateBulkUsers(requestBody, param, token) {
    const URL = API_CONFIG.BULK_CREATE_USERS + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}
export function CreateAndUpdateUser(requestBody, userId, token) {
    const URL = API_CONFIG.CREATE_USER + userId;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}
export function UpdateUser(requestBody, userId, token) {
    const URL = API_CONFIG.UPDATE_USER + userId;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.put(URL, requestBody, headers);
}
export function UploadUserDocs(requestBody, token) {
    const URL = API_CONFIG.FILE_UPLOAD;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}
export function GetUserRoleList(param, token) {
    const URL = API_CONFIG.GET_DROPDOWN_MASTER + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetDesignationList(param, token) {
    const URL = API_CONFIG.GET_DROPDOWN_MASTER + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetReportingManagerList(param, token) {
    const URL = API_CONFIG.GET_REPORTING_MANAGER + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function GetTemeHierarchyManagerList(param, token) {
    const URL = API_CONFIG.GET_TEME_HIERARCHY_MANAGER_LIST + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function GetBrandStateList(param, token) {
    const URL = API_CONFIG.GET_USER_BRAND_STATE + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function DownloadUserMangmentReports(param, token) {
    const URL = API_CONFIG.DOWNLOAD_USER_MANGMENT_REPORTS + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, '', headers);
}