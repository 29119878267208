import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const RsmWiseChart = ({ chartData }) => {
    const data = {
        labels: chartData?.rsmWiseLabel,
        datasets: [
            {
                label: 'Target',
                data: chartData?.rsmWiseTarget,
                borderWidth: 1,
                backgroundColor: '#ffceff',
                hoverBackgroundColor: '#ffceff',
                hoverBorderColor: '#ffceff',
                borderColor: '#ffceff',
                maxBarThickness: 40,
            },
            {
                label: 'Achievement',
                data: chartData?.rsmWiseAchieved,
                borderWidth: 1,
                backgroundColor: '#91a5f9',
                hoverBackgroundColor: '#91a5f9',
                hoverBorderColor: '#91a5f9',
                borderColor: '#91a5f9',
                maxBarThickness: 40,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        datalabels: {
            display: false,
            color: '#fff',
        },
        categoryPercentage: 0.8,
        barPercentage: 0.9,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false, // Ensure no data labels are shown inside the bars
            },
            // tooltip: {
            //     callbacks: {
            //         label: function (context) {
            //             let label = context.dataset.label || '';
            //             if (label) {
            //                 label += ': ';
            //             }
            //             label += new Intl.NumberFormat('en-IN', {
            //                 style: 'currency',
            //                 currency: 'INR',
            //                 minimumFractionDigits: 0,
            //             }).format(context.parsed.y);
            //             return label;
            //         },
            //     },
            // },
        },
        // scales: {
        //     y: {
        //         ticks: {
        //             callback: function (value) {
        //                 return value / 100000 + 'Lac';
        //             },
        //         },
        //     },
        // },
    };

    return (
        <div className='overflow-auto'>
            <Bar data={data} height={412} options={options} />
        </div>
    );
};

export default RsmWiseChart;