import React from "react";
import { Pie } from "react-chartjs-2";


const SellThruChart = ({ chartData }) => {
    const data = {
        labels: ['SOH', 'SLS qty'],
        datasets: [
            {
                data: [chartData?.total_stock_quantity, chartData?.total_sales_quantity],
                backgroundColor: ["#7263D6", "#7D96FF"],
                hoverBackgroundColor: ["#7263D6", "#7D96FF"],
            },
        ],
    };
    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                color: 'white',
                font: {
                    size: 18,
                    family: 'Noto Sans, sans-serif',
                },
            },
        },

    };
    return (
        <div>
            <Pie data={data} width={667} height={210} options={options} />
        </div>
    );
}

export default SellThruChart