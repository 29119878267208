import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import InputTextField from "../../../ReUsableComponent/InputTextField";
import EyeSlashIcon from '../../../assets/svg/eyeSlash.svg';
import EyeOpenIcon from '../../../assets/svg/eyeOpen.svg';
import PrimaryButton from "../../../ReUsableComponent/PrimaryButton";
import { CreateUserPassowrd } from "../UserAuth.service";
import '../../../global.css';
import '../Login/login.css';
import Loader from "../../../ReUsableComponent/Loader";
import SuccessModal from "../../../ReUsableComponent/SuccesModal";
import Lottie from 'lottie-react';
import WellDoneIcon from "../../../assets/lottie/welldone.json";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { ToastErrorService, ToastSuccessService } from "../../../ReUsableComponent/ToastNotification/ToastService";
import { useSelector } from "react-redux";
import CustomModal from '../../../ReUsableComponent/CustomModal';
import warningRedIcon from './../../../assets/svg/warning_red_icon.svg';
import CloseIcon from '../../../assets/svg/crossIcon.svg';

const CreatePassword = ({ match }) => {
    const history = useHistory();

    const [emailDetail, setEmailDetail] = React.useState({ value: '', valid: true, requiredName: true });
    const [successModalOpen, setSuccessModalOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [password, setPassword] = React.useState({ value: '', valid: true, requiredName: true });
    const [confirmPassword, setConfirmPassword] = React.useState({ value: '', valid: true, requiredName: true });
    const [showPwd, setShowPwd] = React.useState(false);
    const [showCnfPwd, setShowCnfPwd] = React.useState(false);
    const [pwdNotMatch, setPwdNotMatch] = React.useState(true);
    const [submitBtnDisabled, setSubmitBtnDisabled] = React.useState(false);
    const [showToastMsg, setShowToastMsg] = React.useState(false);
    const [showErrToastMsg, setShowErrToastMsg] = React.useState(false);
    const [errorResponse, setErrorResponse] = React.useState([]);
    const [createPasswordResponse, setcreatePasswordResponse] = React.useState([]);
    const [mobileWarningModalOpen, setMobileWarningModalOpen] = React.useState(false);
    const [deviceType, setDeviceType] = React.useState('');

    const parts = window.location.href.split('?');
    const emailQueryString = parts;
    const triggeredAtQueryString = parts[2];
    const searchParams = new URLSearchParams(emailQueryString[1]);
    const searchParamsTriggeredAt = new URLSearchParams(emailQueryString[2]);

    const emailId = useSelector((state) => state.userAuthentication.userEmail);
    const userName = useSelector((state) => state.userAuthentication.userName);
    const triggeredAt = searchParamsTriggeredAt.get('triggered_at');

    const forceLogoutStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 380,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: '40px 32px',
        borderRadius: 6,
        border: '1px solid #3333330D'
    };

    React.useEffect(() => {
        setEmailDetail({ value: emailId, valid: true, requiredName: true })
    }, [])

    const onInputEmail = (event) => {
        const REGEX = /^\S+@\S+\.\S+$/;
        if (event.target.value !== "" && REGEX.test(event.target.value)) {
            setEmailDetail({
                ...emailDetail,
                value: event.target.value,
                valid: true,
                requiredName: true

            })
        } else if (event.target.value === "") {
            setEmailDetail({
                ...emailDetail,
                value: event.target.value,
                valid: true,
                requiredName: false
            })
        }
        else {
            setEmailDetail({
                ...emailDetail,
                value: event.target.value,
                valid: false,
                requiredName: true
            })
        }
    }

    const onInputPassword = (event, type) => {
        setPwdNotMatch(true)
        const REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        if (event.target.value !== "" && REGEX.test(event.target.value)) {
            if (type === 'new') {
                setPassword({
                    ...password,
                    value: event.target.value,
                    valid: true,
                    requiredName: true
                })
            } else {
                setConfirmPassword({
                    ...confirmPassword,
                    value: event.target.value,
                    valid: true,
                    requiredName: true
                })
            }
        } else if (event.target.value === "") {
            if (type === 'new') {
                setPassword({
                    ...password,
                    value: event.target.value,
                    valid: true,
                    requiredName: false
                })
            } else {
                setConfirmPassword({
                    ...confirmPassword,
                    value: event.target.value,
                    valid: true,
                    requiredName: true
                })
            }
        }
        else {
            if (type === 'new') {
                setPassword({
                    ...password,
                    value: event.target.value,
                    valid: false,
                    requiredName: true
                })
            } else {
                setConfirmPassword({
                    ...confirmPassword,
                    value: event.target.value,
                    valid: false,
                    requiredName: true
                })
            }
        }
    }

    const handleShowPwd = () => {
        setShowPwd(prev => !prev)
    }
    const handleShowCnfPwd = () => {
        setShowCnfPwd(prev => !prev)
    }

    const onClickSubmit = () => {
        if (password?.value === confirmPassword?.value) {
            setLoading(true);
            let requestBody = {
                "emailEmpId": emailDetail?.value,
                "password": password?.value,
                "confirmPassword": confirmPassword?.value,
                "passwordType": "create",
                "deviceType": "web"
            }
            CreateUserPassowrd(requestBody).then(response => {
                if (response?.data?.statusCode === 204) {
                    setSuccessModalOpen(true);
                    setcreatePasswordResponse(response?.data?.data);
                    setDeviceType(response?.data?.deviceType);
                    // setShowToastMsg(true);
                    // ToastSuccessService(response?.data?.message);
                } else {
                    ToastErrorService(response?.data.message);
                }
            }).catch(error => {
                setErrorResponse(error?.response?.data)
                // setShowErrToastMsg(true);
                ToastErrorService(error?.response?.data.message);
            }).finally(() => {
                setLoading(false);
            })
        } else {
            setPwdNotMatch(false);
        }
    }

    const handleClose = () => {
        setSuccessModalOpen(false);
    };

    const handleCreatePassword = () => {
        if (deviceType === "mobile") {
            setMobileWarningModalOpen(true);
            setSuccessModalOpen(false);
        } else {
            history.push('/auth/adminLogin');
        }
    }

    const handleDeviceType = () => {
        setMobileWarningModalOpen(false);
        history.push('/auth/adminLogin');
    }

    React.useEffect(() => {
        if ((emailId !== '' && emailDetail?.valid) && (password?.value !== '' && password?.valid) && (confirmPassword?.value && confirmPassword?.valid)) {
            setSubmitBtnDisabled(false)
        } else {
            setSubmitBtnDisabled(true)
        }

    }, [emailId, password, confirmPassword])

    return (
        <Fragment>
            <div className="loginForm customShadow">
                <div className="montSerratFont fontSize24">
                    Create Password
                </div>
                {/* <div className="notoSansFont paddingTop32 textColorGray">Create Password</div> */}
                <div className="paddingTop24 position-relative">
                    <InputTextField label='E-Mail' type='email' helperText='' error={false} required={true} value={emailDetail?.value} disabled={true} />
                    {!emailDetail?.valid && <div className="text-danger errorMsg">Please enter a valid Email Id.</div>}
                </div>
                <div className="pt-3 position-relative">
                    <InputTextField label='New Password' maxLength={64} type={showPwd ? 'text' : 'password'} helperText='' error={false} required={true} onChange={(event) => onInputPassword(event, 'new')} value={password?.value} disabled={false} />
                    <div className='position-absolute pwdViewBtn' onClick={handleShowPwd}>
                        <img src={showPwd ? EyeOpenIcon : EyeSlashIcon} alt='' />
                    </div>
                    {!password?.valid && <div className="text-danger errorMsg">Please enter a valid Password.</div>}
                </div>
                <div className="pt-3 position-relative">
                    <InputTextField label='Confirmed Password' maxLength={64} type={showCnfPwd ? 'text' : 'password'} helperText='' error={false} required={true} onChange={(event) => onInputPassword(event, 'cnf')} value={confirmPassword?.value} disabled={false} />
                    <div className='position-absolute pwdViewBtn' onClick={handleShowCnfPwd}>
                        <img src={showCnfPwd ? EyeOpenIcon : EyeSlashIcon} alt='' />
                    </div>
                    {!confirmPassword?.valid && <div className="text-danger errorMsg">Please enter a valid Password.</div>}
                    {!pwdNotMatch && <div className="text-danger errorMsg">Password does not match.</div>}
                </div>
                <div className="paddingTop24">
                    <PrimaryButton disabled={submitBtnDisabled} title='Submit' onClick={onClickSubmit} />
                </div>
            </div>
            <SuccessModal open={successModalOpen} handleClose={handleCreatePassword} content={<div className='text-center'>
                <div className='d-flex justify-content-center align-items-center'>
                    <Lottie className="welldoneLottie" animationData={WellDoneIcon} loop={true} />
                </div>
                <div className='fontBold textBlack fontSize18 paddingTop24'>Successfully Created</div>
                <div className='fontSize12 pt-2'>Your password has been successfully created</div>
                <div className='pt-4 d-flex justify-content-center align-items-center'>
                    <button className='modalButton'
                        onClick={handleCreatePassword}>OK</button>
                </div>
            </div>} />
            {loading && <Loader />}

            <CustomModal open={mobileWarningModalOpen} handleClose={handleDeviceType} style={forceLogoutStyle} content={
                <div className="text-center">
                    <div className='forceLogoutCloseIconPlacement cursorPointer' onClick={handleDeviceType}>
                        <img src={CloseIcon} alt="" />
                    </div>
                    <div className="mb-3">
                        <img width="100px" src={warningRedIcon} alt="" />
                    </div>
                    {/* {userName && */}
                    <div className="fontSize14 pt-2"> Remember, login is only available through our mobile app.</div>
                    {/* // } */}
                    {/* <div className="pt-3">
                        <PrimaryButton disabled={false} title='Force Logout' onClick={onClickForceLogout} />
                    </div> */}
                </div>
            } />
        </Fragment>)
};

export default CreatePassword;
