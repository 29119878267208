import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CustomModal = (props) => {
    return (
        <div>
            <Modal className={`notoSansFont customModal ${props.classes}`}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade className={`${props?.subClassess}`} in={props.open}>
                    <Box sx={{
                        ...props.style,
                        position: 'relative',
                    }}>
                        {props?.isCloseIcon &&
                            <IconButton
                                onClick={props.handleClose}
                                sx={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                    color: '#000',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        }
                        {props.content}
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default CustomModal