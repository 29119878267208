import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './BackIcon.css';

const CustomIcon = ({ onClick, isBackIcon }) => {
  return (
    <div className="back-icon" onClick={onClick}>
      <FontAwesomeIcon icon={isBackIcon ? faChevronLeft : faChevronRight} />
    </div>
  );
};

export default CustomIcon;