import axios from "axios";
import API_CONFIG from "../../Configurations/ApiDeclaration";

export function GetPromotionsList(param, token) {
    const URL = API_CONFIG.GET_PROMOTIONS_LIST + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function UpdatePromotionsList(requestBody, param, token) {
    const URL = API_CONFIG.UPDATE_PROMOTIONS_LIST + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.put(URL, requestBody, headers);
}

export function DownaloadPromotionList(requestBody, token) {
    const URL = API_CONFIG.DOWNLOAD_PROMOTION_LIST;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}

export function GetBrandNameList(param, token) {
    const URL = API_CONFIG.GET_BRAND_NAME + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function GetBrandStateList(param, token) {
    const URL = API_CONFIG.GET_USER_BRAND_STATE + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function GetStoreCustomerNameList(param, token) {
    const URL = API_CONFIG.GET_STORE_CUSTOMER_NAME + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function CreateNewPromotion(requestBody, token) {
    const URL = API_CONFIG.CREATE_NEW_PROMOTION;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}

export function GetStyleCode(param, token) {
    const URL = API_CONFIG.GET_STYLE_CODE + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetSeasons(param, token) {
    const URL = API_CONFIG.GET_SEASONS + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetRegions(param, token) {
    const URL = API_CONFIG.GET_REGIONS + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetCategoryByBrand(param, token) {
    const URL = API_CONFIG.GET_CATEGORY_BY_BRAND + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

