import React from 'react'
import PrimaryButton from '../../ReUsableComponent/PrimaryButton'
import { useHistory } from 'react-router-dom';

const PageNotFound = () => {

    const history = useHistory();

    const onClickGotoLogin = () => {
        history.push('/auth/adminLogin')
    }

    return (
        <div className='notoSansFont d-flex justify-content-center'>
            <div>
                <div className='fontMedium fontSize24 mt-3'>Page Not Found!</div>
                <div className='mt-3'>
                    <PrimaryButton title='Go To Login' onClick={onClickGotoLogin} />
                </div>
            </div>
        </div>
    )
}

export default PageNotFound