import React, { useState } from 'react'
import { GetIncentiveHistory } from '../Insentive.service';
import Loader from '../../../ReUsableComponent/Loader';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import timeMoment from 'moment-timezone';
import ViewIcon from '../../../assets/svg/viewicon.svg';
import moment from 'moment-timezone';
import CustomModal from '../../../ReUsableComponent/CustomModal';
import CloseIcon from '../../../assets/svg/closeIconofmodal.svg';

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1121,
    bgcolor: 'white',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const IncentiveHistory = ({ incentiveHistory, onClickTabBtn, incentiveType, activeTabBtn, onClickView, openViewModal, setModalVisible, viewIncentiveHistoryData }) => {

    const renderTypeOfIncentive = (incentive_type) => {
        if (incentive_type === 'wow_bill') {
            return 'WOW Bill Incentive'
        } else if (incentive_type === 'monthly_bill') {
            return 'Monthly Incentive'
        } else {
            return 'Special Incentive'
        }
    }

    const convertDate = (datestring) => {
        let date = new Date(datestring);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return moment(dateString).format("DD MMM YYYY");
    }

    const renderBillDetails = (incentive_type, data) => {
        if (incentive_type === 'wow_bill') {
            return <>
                {data?.wow_bill?.map(item => (<div className={`${data?.wow_bill?.length > 1 ? 'bill-details' : 'bill-details '}`}>
                    <div className='storeLabel'>Total Bill Amount</div>
                    <div className='storeValue p-0'>₹{Number(item?.bill_amount).toLocaleString('en-IN')}</div>
                    <div className='storeLabel'>Condition</div>
                    <div className='storeValue p-0'>{item?.condition}</div>
                    <div className='storeLabel'>Incentive Amount</div>
                    <div className='storeValue p-0'>₹{Number(item?.set_incentive_amount).toLocaleString('en-IN')}</div>
                </div>))}
            </>
        } else if (incentive_type === 'monthly_bill') {
            return <>
                {data?.monthly_bill?.map(item => (<div className={`${data?.monthly_bill?.length > 1 ? 'bill-details' : 'bill-details '}`}>
                    <div className='storeLabel'>Achievement %</div>
                    <div className='storeValue p-0'>{item?.achievement_percent}</div>
                    <div className='storeLabel'>Condition</div>
                    <div className='storeValue p-0'>{item?.condition}</div>
                    <div className='storeLabel'>Set Incentive %</div>
                    <div className='storeValue p-0'>{item?.set_incentive_percent}</div>
                </div>))}
            </>
        } else if (incentive_type === 'special_bill') {
            return <>
                {Array.isArray(data?.special_bill) ?
                    data?.special_bill?.map(item => (<div className='bill-details'>
                        <div className='storeLabel'>Type</div>
                        <div className='storeValue p-0 text-capitalize'>{item?.type !== 'per_peice' ? item?.type : 'Per Piece'}</div>
                        {item?.type === 'category' && <>
                            <div className='storeLabel'>Category</div>
                            <div className='storeValue p-0 store-name customScroll'>{item?.category?.map(cat => cat).toString()}</div>
                        </>}
                        {item?.type === 'barcode' && <>
                            <div className='storeLabel'>Barcode</div>
                            <div className='storeValue p-0 store-name customScroll'>{item?.barcode?.map(bar => bar).toString()}</div>
                        </>}
                        <div className='storeLabel'>Set Incentive Amount</div>
                        <div className='storeValue p-0'>₹{Number(item?.set_incentive_amount).toLocaleString('en-IN')}</div>
                    </div>))
                    :
                    <div className='bill-details'>
                        <div className='storeLabel'>Type</div>
                        <div className='storeValue p-0 text-capitalize'>{data?.special_bill?.type !== 'per_peice' ? data?.special_bill?.type : 'Per Piece'}</div>
                        {data?.special_bill?.type === 'category' && <>
                            <div className='storeLabel'>Category</div>
                            <div className='storeValue p-0 store-name customScroll'>{data?.special_bill?.category?.map(cat => cat).toString()}</div>
                        </>}
                        {data?.special_bill?.type === 'barcode' && <>
                            <div className='storeLabel'>Barcode</div>
                            <div className='storeValue p-0 store-name customScroll'>{data?.special_bill?.barcode?.map(bar => bar).toString()}</div>
                        </>}
                        <div className='storeLabel'>Set Incentive Amount</div>
                        <div className='storeValue p-0'>₹{Number(data?.special_bill?.set_incentive_amount).toLocaleString('en-IN')}</div>
                    </div>
                }
            </>
        }
    }

    const valueCheckForName = (storeName, storeTradeName) => {
        if (storeTradeName && storeTradeName !== 'null') {
            return storeTradeName
        } else if (storeName !== 'null') {
            return storeName
        } else {
            return '-'
        }
    }

    return (
        <div className='mt-3'>
            <div className='tableContainer history-container'>
                <div>
                    <button className={`incentive-tab-btn ${incentiveType === 'wow_bill' && 'active-incentive-btn'}`} onClick={() => onClickTabBtn(1)}>WOW Bill</button>
                    <button className={`incentive-tab-btn ${incentiveType === 'monthly_bill' && 'active-incentive-btn'}`} onClick={() => onClickTabBtn(2)}>Monthly Incentive</button>
                    <button className={`incentive-tab-btn ${incentiveType === 'special_bill' && 'active-incentive-btn'}`} onClick={() => onClickTabBtn(3)}>Special Incentive</button>
                </div>
                {incentiveHistory?.length > 0 ? <div className='pt-3'>
                    <div className='wow-bill-history-data'>
                        {incentiveHistory?.map((item, index) => (<div className='wow-bill' key={index}>
                            <div className='storeLabel'>Type Of Incentive</div>
                            <div className='storeValue p-0'>{renderTypeOfIncentive(item?.incentive_type)}</div>
                            <div className='storeLabel'>Brand Name</div>
                            <div className='storeValue p-0' title={item?.brand_details?.map(brand => brand?.brand_name).toString()}>{item?.brand_details?.map(brand => brand?.brand_name).toString()}</div>
                            <div className='storeLabel'>Store Name</div>
                            <div className='storeValue p-0 text-truncate' title={item?.store_details?.map(store => valueCheckForName(store.customer_name, store.store_trade_name))}>{item?.store_details?.map(store => valueCheckForName(store.customer_name, store.store_trade_name))}</div>
                            <div className='storeLabel'>Date</div>
                            <div className='storeValue p-0'>{convertDate(item?.from_date)} - {convertDate(item?.to_date)}</div>
                            <div className='storeLabel'>Action</div>
                            <div className='storeValue p-0'>
                                <button className="tableActionButtons d-flex align-items-center justify-content-center viewAndEditBtn"
                                    onClick={() => onClickView(item?._id)}>
                                    <img src={ViewIcon} alt="" />
                                </button>
                            </div>
                        </div>))}
                    </div>
                </div> : <div className='fontSize24 textColorBlack d-flex align-items-center justify-content-center mt-5'>No Data</div>}
            </div>

            <CustomModal classes="customeProgressModal" open={openViewModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            {viewIncentiveHistoryData && <div className='fontMedium text000'>{renderTypeOfIncentive(viewIncentiveHistoryData?.incentive_type)} Details</div>}
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setModalVisible(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        {viewIncentiveHistoryData && <div className='incentive-details'>
                            <div className='incentive-view'>
                                <div className='storeLabel'>Type Of Incentive</div>
                                <div className='storeValue p-0'>{renderTypeOfIncentive(viewIncentiveHistoryData?.incentive_type)}</div>
                                <div className='storeLabel'>Brand Name</div>
                                <div className='storeValue p-0'>{viewIncentiveHistoryData?.brand_details?.map(brand => brand?.brand_name).toString()}</div>
                                <div className='storeLabel'>Store Name</div>
                                <div className='storeValue p-0 store-name customScroll'>{viewIncentiveHistoryData?.store_details?.map(store => valueCheckForName(store.customer_name, store.store_trade_name))}</div>
                                <div className='storeLabel'>Date</div>
                                <div className='storeValue p-0'>{convertDate(viewIncentiveHistoryData?.from_date)} - {convertDate(viewIncentiveHistoryData?.to_date)}</div>
                            </div>
                            <div className={`${viewIncentiveHistoryData?.incentive_type !== 'special_bill' ? 'bills' : 'w-50'}`}>
                                {renderBillDetails(viewIncentiveHistoryData?.incentive_type, viewIncentiveHistoryData)}
                            </div>
                        </div>}
                    </div>
                </div>} />
            {/* {loading && <Loader />} */}
        </div>
    )
}

export default IncentiveHistory