import axios from "axios";

export const environment = {
    API_URL: process.env.REACT_APP_TEKEXCELRATOR_API,
};

const AxiosInstance = axios.create({
    // baseURL: "http://4.224.82.84/Insurance/api/v1/",
    // headers: {
    //   "Content-Type": 'application/json',
    //   "Accept": 'application/json'
    // },
    // timeout: 60000, // 60 Seconds
});

export { AxiosInstance };
