import React, { useEffect, useRef, useState } from 'react';
import Loader from '../../ReUsableComponent/Loader';
import dropDownIcon from '../../assets/svg/dropDownIcon.svg';
import CustomFilterDropDown from '../../ReUsableComponent/CustomFilterDropdown';
import CustomFilterSearchDropdown from '../../ReUsableComponent/CustomFilterSearchDropdown';
import CustomBlueButton from '../../ReUsableComponent/CustomBlueButton';
import './ruleManagement.css';
import CustomTableWithFilter from './../../ReUsableComponent/CustomTableWithFilter';
import { rulesMangmentViewTableHeader } from './../../Pages/TableHeaders';
import { GetReportHeaderList, GetBrandNameList, GetStoreCustomerNameList, GetHeaderValueList, CreateRuleMangment, UpdateRuleMangment } from './RuleManagement.service';
import CustomPagination from '../../ReUsableComponent/Pagination';
import { ToastErrorService, ToastSuccessService } from '../../ReUsableComponent/ToastNotification/ToastService';
import { ButtonDropdown } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SearchBox from './../../ReUsableComponent/CustomSearch/indedx';
import ExcelToJsonTable from './ExcelToJsonTable';
import { ruleManagementActions } from '../../store/reducers/RuleManagementReducer';
import close_circle_bg_gray from './../../assets/svg/close_circle_bg_gray.svg';
import CustomModal from '../../ReUsableComponent/CustomModal';
import CloseIcon from '../../assets/svg/closeIconofmodal.svg';
import { Padding } from '@mui/icons-material';

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
}

const RuleManagement = () => {
    const gridRef = useRef(null);

    const history = useHistory();

    const dispatch = useDispatch();

    const getExcelData = useSelector((state) => state.ruleManagement.excelFileRowData);

    const getExcelUpdatedData = useSelector((state) => state.ruleManagement.excelFileUpdatedRowData);
    const [activeTab, setActiveTab] = useState(1);
    const [allTableListData, setAllTableListData] = useState([]);
    const [tableListData, setTableListData] = useState([]);
    const [tableListFilteredData, setTableListFilteredData] = useState([]);
    const [tableListFilteredDataStoreType, setTableListFilteredDataStoreType] = useState([]);
    const [tableListSearchDataStoreType, setTableListSearchDataStoreType] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [excelHeaderValue, setExcelHeaderValue] = useState('');
    const [excelUpdateHeaderValue, setExcelUpdateHeaderValue] = useState([]);
    const [excelHeaderData, setExcelHeaderData] = useState([]);

    const [excelHeaderMappingValue, setExcelHeaderMappingValue] = useState('');
    const [excelHeaderMappingData, setExcelHeaderMappingData] = useState([]);
    const [discountPercentageMappingValue, setDiscountPercentageMappingValue] = useState('');
    const [discountPercentageMappingData, setDiscountPercentageMappingData] = useState([]);

    const [correctHeaderMappingValue, setCorrectHeaderMappingValue] = useState('');
    const [correctHeaderMappingValueNew, setCorrectHeaderMappingValueNew] = useState([]);
    const [correctHeaderMappingData, setCorrectHeaderMappingData] = useState([]);

    const [correctPercentageMappingValue, setcorrectPercentageMappingValue] = useState('');
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [updatedRuleMangmentID, setUpdatedRuleMangmentID] = useState('');
    const [updetedTableListData, setUpdatedTableListData] = useState('');
    const [openUpdetedTableListData, setOpenUpdatedTableListData] = useState(true);

    // reportType
    const [selectedReportType, setSelectedReportType] = useState('');
    const [initialSelectedReportType, setInitialSelectedReportType] = useState({ label: 'Header', value: 'Header' });
    const [anchorElForReportType, setAnchorElForReportType] = useState(null);
    const openReportTypeFilter = Boolean(anchorElForReportType);
    const [reportTypeList, setReportTypeList] = useState([{ label: 'Sales', value: 'Sales' }, { label: 'Stock', value: 'Stock' }]);
    // BrandType
    const [selectedBrandType, setSelectedBrandType] = useState('');
    const [initialSelectedBrandType, setInitialSelectedBrandType] = useState({ label: 'Brand', value: 'Brand' });
    const [anchorElForBrandType, setAnchorElForBrandType] = useState(null);
    const openBrandTypeFilter = Boolean(anchorElForBrandType);
    const [brandTypeList, setBrandTypeList] = useState([]);
    // StoreType
    const [selectedStoreType, setselectedStoreType] = useState('');
    const [initialSelectedStoreType, setInitialSelectedStoreType] = useState({ label: 'Store', value: 'Store' });
    const [anchorElForStoreType, setAnchorElForStoreType] = useState(null);
    const openStoreTypeFilter = Boolean(anchorElForStoreType);
    const [storeTypeList, setStoreTypeList] = useState([]);

    // Table Filter StoreType
    const [initialSelectedBrandTableFilterType, setInitialSelectedBrandTableFilterType] = useState({ label: 'Brand', value: 'Brand' });
    const [anchorElForBrandTableFilterType, setAnchorElForBrandTableFilterType] = useState(null);
    const openBrandTableFilterTypeFilter = Boolean(anchorElForBrandTableFilterType);
    const [BrandTableFilterTypeList, setBrandTableFilterTypeList] = useState([]);

    // Table Filter reportType TableFilter
    const [selectedReportTableFilterType, setSelectedReportTableFilterType] = useState('');
    const [initialSelectedReportTableFilterType, setInitialSelectedReportTableFilterType] = useState({ label: 'Header', value: 'Header' });
    const [anchorElForReportTableFilterType, setAnchorElForReportTableFilterType] = useState(null);
    const openReportTableFilterTypeFilter = Boolean(anchorElForReportTableFilterType);
    const [reportTableFilterTypeList, setReportTableFilterTypeList] = useState([{ label: 'Sales', value: 'Sales' }, { label: 'Stock', value: 'Stock' }]);

    const [loading, setLoading] = useState(false);
    const [viewRowReportTypeData, setViewRowReportTypeData] = useState("");
    const [selectedPage, setSelectedPage] = useState(1);
    const [totalReportHeaderCount, setTotalReportHeaderCount] = useState(0);
    const [isbtnActive, setIsBtnActive] = useState(false);
    const [dropSearchValue, setdropSearchValue] = React.useState('');

    const [slectedFilterBrandData, setSlectedFilterBrandData] = useState('');
    const [slectedFilterBrandDataForStore, setSlectedFilterBrandDataForStore] = useState('');

    const USER_ID = useSelector((state) => state.userAuthentication.userId);

    const [openPopupForSlecte, setOpenPopupForSlecte] = useState(false);
    const [currentFiled, setCurrentFiled] = useState('');
    const [selectedData, setSelectedData] = useState([]);
    const [disabledItems, setDisabledItems] = useState([]);
    const [openUpdatePopupForSlecte, setOpenUpdatePopupForSlecte] = useState(false);
    const [processedHeaderMapping, setProcessedHeaderMapping]= useState([]);
    const [selectedUpdateData, setSelectedUpdateData] = useState([]);

    const [selectedRowIndex, setSelectedRowIndex] = useState("");
    const [selectedUpdateRowIndex, setSelectedUpdateRowIndex] = useState("");

    const [getUpdateDisabledItems, setGetUpdateDisabledItems] = useState([]);
    
    const [ dummyArray, setDummyArray] = useState([]);


    useEffect(() => {
        getAllHeaderList();
        getAllBrandList();
        dispatch(ruleManagementActions.setExcelFileRowData({ type: [] }));
    }, []);

    useEffect(() => {
        getAllHeaderList();
    }, [selectedPage, slectedFilterBrandData]);

    useEffect(() => {
        getAllStoreCustomerNameList();
    }, [slectedFilterBrandDataForStore]);

    const getAllHeaderList = () => {
        let queryParams = `?brand=${slectedFilterBrandData && slectedFilterBrandData?.id}&page=${''}&limit=${''}`;
        GetReportHeaderList(queryParams, USER_TOKEN).then((response) => {
            setAllTableListData(response?.data?.data?.data);
            setViewRowReportTypeData(response?.data?.data?.data[0]);
            let tableData = [];
            response?.data?.data?.data?.map((item, index) => {
                let row = {
                    id: item?._id,
                    index: index,
                    reportTypeName: item?.report_type ? item?.report_type : "-",
                    reportTypeBrandName: item?.brand_details?.brand_name ? item?.brand_details?.brand_name : "-",
                    reportTypeStoreName: item?.store_details?.store_name ? item?.store_details?.store_name : "-",
                    reportTypeStoreID: item?.store ? item?.store : "-",
                }
                tableData.push(row)
            })
            setTableListData(tableData);
            setTableListFilteredData(tableData);
            setTableListSearchDataStoreType(tableData);
            setTotalReportHeaderCount(response?.data?.data?.totalCount);
        }).catch(error => {
            if (error?.response?.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const getAllBrandList = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then((response) => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item?.brand_name,
                    value: item?.brand_name,
                    email: item?.email_id,
                    id: item?._id,
                }
                dropdownData.push(row)
            });
            setBrandTypeList(dropdownData);

        }).catch(error => {
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
        if (tabNumber === 1) {
            setInitialSelectedBrandType({ label: 'Brand', value: 'Brand' });
            setInitialSelectedReportType({ label: 'Header', value: 'Header' });
            setInitialSelectedStoreType({ label: 'Store', value: 'Store' });
            setdropSearchValue('');
            setTableListFilteredData(tableListData);
            setTableListSearchDataStoreType(tableListData);
            dispatch(ruleManagementActions.setExcelFileRowData({ type: [] }));
        } else if (tabNumber === 2) {
            setTableListFilteredData(tableListData);
            setTableListSearchDataStoreType(tableListData);
            setInitialSelectedBrandTableFilterType({ label: 'Brand', value: 'Brand' });
            setInitialSelectedReportTableFilterType({ label: 'Header', value: 'Header' });
            setdropSearchValue('');
            setSlectedFilterBrandData('');
            setSlectedFilterBrandDataForStore('');
        }
    };

    const handleDropDownClick = (event, TypeOfData) => {
        if (TypeOfData === 'ReportType') {
            setAnchorElForReportType(event.currentTarget);
            setdropSearchValue('');
        } else if (TypeOfData === 'BrandType') {
            setAnchorElForBrandType(event.currentTarget);
            setdropSearchValue('');
        } else if (TypeOfData === 'StoreType') {
            setAnchorElForStoreType(event.currentTarget);
            setdropSearchValue('');
        } else if (TypeOfData === 'tableFilterBrandType') {
            setAnchorElForBrandTableFilterType(event.currentTarget);
            setdropSearchValue('');
        } else if (TypeOfData === 'tableFilterReportType') {
            setAnchorElForReportTableFilterType(event.currentTarget);
            setdropSearchValue('');
        }

    };
    const handleTypeClose = () => {
        setAnchorElForReportType(null);
        setAnchorElForBrandType(null);
        setAnchorElForStoreType(null);
        setAnchorElForBrandTableFilterType(null);
        setAnchorElForReportTableFilterType(null);
    }

    const handleSearchChange = (event) => {
        setdropSearchValue(event.target.value);
    };

    const handleInputChange = (event, TypeOfParm) => {
        if (TypeOfParm === "ExcelHeader") {

            const inputValue = event.target.value;
            setExcelHeaderValue(inputValue);
            const words = inputValue.split(',');
            setExcelHeaderData(words);

        } else if (TypeOfParm === "ExcelHeaderMapping") {

            const inputValue = event.target.value;
            setExcelHeaderMappingValue(inputValue);
            const words = inputValue.split(',');
            setExcelHeaderMappingData(words);

        } else if (TypeOfParm === "DiscountPercentage") {
            const inputValue = event.target.value;
            setDiscountPercentageMappingValue(inputValue);
            const words = inputValue.split(',');
            setDiscountPercentageMappingData(words);
        }
    }
    const handleKeyDown = (event, TypeOfParm) => {
        if (TypeOfParm === "ExcelHeader") {

            if (event.key === ',') {
                event.preventDefault();
                const cursorPosition = event.target.selectionStart;
                const newValue = excelHeaderValue.substring(0, cursorPosition) + ',\n' + excelHeaderValue.substring(cursorPosition);
                setExcelHeaderValue(newValue);
                setExcelHeaderData(newValue.split(','));
            }
        } else if (TypeOfParm === "ExcelHeaderMapping") {
            if (event.key === ',') {
                event.preventDefault();
                const cursorPosition = event.target.selectionStart;
                const newValue = excelHeaderMappingValue.substring(0, cursorPosition) + ',\n' + excelHeaderMappingValue.substring(cursorPosition);
                setExcelHeaderMappingValue(newValue);
                setExcelHeaderMappingData(newValue.split(','));
            }
        } else if (TypeOfParm === "DiscountPercentage") {
            if (event.key === ',') {
                event.preventDefault();
                const cursorPosition = event.target.selectionStart;
                const newValue = discountPercentageMappingValue.substring(0, cursorPosition) + ',\n' + discountPercentageMappingValue.substring(cursorPosition);
                setDiscountPercentageMappingValue(newValue);
                setDiscountPercentageMappingData(newValue.split(','));
            }
        }
    };

    const filterData = (data, searchValue, searchType) => {
        if (!searchValue) {
            return data;
        }

        if (searchType === 'tableFilterBrandType') {
            return data?.filter(item => item?.reportTypeBrandName?.toLowerCase()?.includes(searchValue?.toLowerCase()))
        } else if (searchType === 'tableFilterReportType') {
            return data?.filter(item => item?.reportTypeName?.toLowerCase()?.includes(searchValue?.toLowerCase()))
        }
    }

    const onSelectChangeType = (event, TypeOfData) => {
        if (TypeOfData === 'ReportType') {
            reportTypeList.forEach(TypeValue => {
                if (event.target.value === TypeValue?.value) {
                    setInitialSelectedReportType({ label: TypeValue?.label, value: event.target.value });
                    setSelectedReportType(TypeValue);
                    setAnchorElForReportType(null);
                    getAllHeaderValueOnlyList(event.target.value);
                }
            })
        } else if (TypeOfData === 'BrandType') {
            brandTypeList.forEach(TypeValue => {
                if (event.target.value === TypeValue?.value) {
                    setInitialSelectedBrandType({ label: TypeValue?.label, value: event.target.value });
                    setSelectedBrandType(TypeValue);
                    setAnchorElForBrandType(null);
                    setSlectedFilterBrandDataForStore(TypeValue);
                }
            })
        } else if (TypeOfData === 'StoreType') {
            storeTypeList.forEach(TypeValue => {
                if (event.target.value === TypeValue?.value) {
                    setInitialSelectedStoreType({ label: TypeValue?.label, value: event.target.value });
                    setselectedStoreType(TypeValue);
                    setAnchorElForStoreType(null);
                }
            })
        } else if (TypeOfData === 'tableFilterBrandType') {
            brandTypeList?.forEach(TableFilterTypeValue => {
                if (event.target.value === TableFilterTypeValue?.value) {
                    setSlectedFilterBrandData(TableFilterTypeValue)

                    setInitialSelectedBrandTableFilterType({ label: TableFilterTypeValue?.label, value: event.target.value });
                    // setselectedStoreTableFilterType(TableFilterTypeValue);
                    setAnchorElForBrandTableFilterType(null);
                    // setTableListFilteredDataStoreType(filterData(tableListData, TableFilterTypeValue?.value, 'tableFilterBrandType'));
                    // setTableListSearchDataStoreType(filterData(tableListData, TableFilterTypeValue?.value, 'tableFilterBrandType'));
                    // setTableListFilteredData(filterData(tableListData, TableFilterTypeValue?.value, 'tableFilterBrandType'));
                    // setInitialSelectedReportTableFilterType({ label: 'Header', value: 'Header' });
                }
            });
        } else if (TypeOfData === 'tableFilterReportType') {
            reportTableFilterTypeList.forEach(TableFilterTypeValue => {
                if (event.target.value === TableFilterTypeValue?.value) {
                    setInitialSelectedReportTableFilterType({ label: TableFilterTypeValue?.label, value: event.target.value });
                    setSelectedReportTableFilterType(TableFilterTypeValue);
                    setAnchorElForReportTableFilterType(null);
                    if (tableListFilteredDataStoreType?.length !== 0) {
                        setTableListFilteredData(filterData(tableListFilteredDataStoreType, TableFilterTypeValue?.value, 'tableFilterReportType'));
                        setTableListSearchDataStoreType(filterData(tableListFilteredDataStoreType, TableFilterTypeValue?.value, 'tableFilterReportType'));
                    } else {
                        setTableListFilteredData(filterData(tableListData, TableFilterTypeValue?.value, 'tableFilterReportType'));
                        setTableListSearchDataStoreType(filterData(tableListData, TableFilterTypeValue?.value, 'tableFilterReportType'));
                    }
                }
            });
        }

    }

    const onRowClicked = (event) => {
        setLoading(true);
        if (event?.data?.reportTypeName !== '' && event?.data?.reportTypeStoreID !== '') {
            let queryParams = `?report_type=${event?.data?.reportTypeName}&store=${event?.data?.reportTypeStoreID?._id}`;
            GetReportHeaderList(queryParams, USER_TOKEN).then(response => {
                setViewRowReportTypeData(response?.data?.data?.data[0]);
            }).catch(error => {
                if (error?.response?.status === 401) {
                    history.push('/')
                }
                ToastErrorService(error.response.data.message);
            }).finally(() => {
                setLoading(false);
            })
        }
    };

    const handlePageChange = (pageValue) => {
        setSelectedPage(pageValue);
    }

    const storeTradeNameCheck = (store, tradeName) => {
        if (tradeName && tradeName !== 'null') {
            return tradeName
        } else {
            return store
        }
    }

    const getAllStoreCustomerNameList = () => {
        let queryParams = `?brand_id=${slectedFilterBrandDataForStore && slectedFilterBrandDataForStore?.id}`;
        GetStoreCustomerNameList(queryParams, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: storeTradeNameCheck(item?.customer_name, item?.store_trade_name),
                    value: storeTradeNameCheck(item?.customer_name, item?.store_trade_name),
                    email: item?.store_email_id,
                    id: item?._id,
                }
                dropdownData.push(row)
            })
            setStoreTypeList(dropdownData);
            // setBrandTableFilterTypeList(dropdownData);
        }).catch(error => {
            ToastErrorService(error?.response?.data?.message)
        });
    }

    const getAllHeaderValueOnlyList = (reportType) => {
        let queryParams = `?report_type=${reportType}`;
        GetHeaderValueList(queryParams, USER_TOKEN).then(response => {
            let arrayData = [];
            response?.data?.header_values?.map(item => {
                arrayData.push(item)
            });
            setCorrectHeaderMappingValue(arrayData?.join(',\n'));
            setCorrectHeaderMappingValueNew(arrayData);
            setSelectedData(new Array(arrayData.length).fill(""));
            setDummyArray(new Array(arrayData.length).fill(""));
            setProcessedHeaderMapping([]);
            setSelectedUpdateData([]);
        });
    }

    const onClickSubmitRuleMangment = () => {
        setLoading(true);
        let requestBody = {
            brand: selectedBrandType?.id,
            brand_details: {
                brand_name: selectedBrandType?.value,
                brand_id: selectedBrandType?.id,
                email: selectedBrandType?.email
            },
            report_type: selectedReportType?.value,
            store_details: {
                store_name: selectedStoreType?.value,
                email: selectedStoreType?.email,
            },
            store: selectedStoreType?.id,
            excel_headers : getExcelData,
            headers: selectedData,
            is_percentage: discountPercentageMappingData
        }
        

        if (!requestBody?.excel_headers || !requestBody?.headers) {
            ToastErrorService('Please fill all the fields');
            setLoading(false);
        } else if (!requestBody?.brand || !requestBody?.store || !requestBody?.report_type) {
            ToastErrorService('Please select Brand, Header and Store.');
            setLoading(false);
        } else {
            CreateRuleMangment(requestBody, USER_TOKEN).then(response => {
                ToastSuccessService("Headers created successfully!")
                getAllHeaderList();
                setActiveTab(1);
                setInitialSelectedReportType({ label: 'Header', value: 'Header' });
                setSelectedReportType('');
                setInitialSelectedBrandType({ label: 'Brand', value: 'Brand' });
                setSelectedBrandType('');
                setInitialSelectedStoreType({ label: 'Store', value: 'Store' });
                setselectedStoreType('');
                setExcelHeaderValue('');
                setExcelHeaderData([]);
                setExcelHeaderMappingValue('');
                setExcelHeaderMappingData([]);
                setOpenUpdatedTableListData(true);
                setCorrectHeaderMappingData([]);

                dispatch(ruleManagementActions.setExcelFileRowData({ type: [] }));
                dispatch(ruleManagementActions.setExcelFileUpdatedRowData({ type: [] }));
                setSelectedData([]);
                setProcessedHeaderMapping([]);
                setSelectedUpdateData([]);
                setCorrectHeaderMappingValueNew([]);
                
            }).catch(error => {
                ToastErrorService('Something went wrong, Please try again after sometime.');
                if (error?.response?.status === 401) {
                    history.push('/')
                }
            }).finally(() => {
                setLoading(false);
            });
        }

    }

    const swapKeysAndValues = (obj) => {
        const swappedObj = {};
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            swappedObj[obj[key]] = key;
          }
        }
        return swappedObj;
    };

    const updateRuleMangmentRow = (id, index) => {
        setIsBtnActive(true);
        setOpenUpdatedTableListData(false);
        setUpdatedRuleMangmentID(allTableListData[index]?._id);

        setUpdatedTableListData(allTableListData[index]);
        setInitialSelectedReportType({ label: allTableListData[index]?.report_type, value: allTableListData[index]?.report_type });
        setSelectedReportType({ label: allTableListData[index]?.report_type, value: allTableListData[index]?.report_type });
        setInitialSelectedStoreType({ label: allTableListData[index]?.store_details?.store_name, value: allTableListData[index]?.store_details?.store_name });
        setselectedStoreType(
            {
                label: allTableListData[index]?.store_details?.store_name,
                value: allTableListData[index]?.store_details?.store_name,
                email: allTableListData[index]?.store_details?.email,
                id: allTableListData[index]?.store,
            }
        );
        setInitialSelectedBrandType(
            {
                label: allTableListData[index]?.brand_details?.brand_name,
                value: allTableListData[index]?.brand_details?.brand_name,
                email: allTableListData[index]?.brand_details?.email,
                id: allTableListData[index]?.brand_details?.brand_id,
            }
        );
        setSelectedBrandType(
            {
                label: allTableListData[index]?.brand_details?.brand_name,
                value: allTableListData[index]?.brand_details?.brand_name,
                email: allTableListData[index]?.brand_details?.email,
                id: allTableListData[index]?.brand_details?.brand_id,
            }
        );
        setExcelHeaderValue(allTableListData[index]?.excel_headers?.join(',\n'));
        setExcelUpdateHeaderValue(allTableListData[index]?.excel_headers);
        const cleanedArray = allTableListData[index]?.excel_headers.map(item => item.trim());
        dispatch(ruleManagementActions.setExcelFileUpdatedRowData({ type: cleanedArray }));
        setExcelHeaderData(allTableListData[index]?.excel_headers);
        setExcelHeaderMappingValue((allTableListData[index]?.processed_headers.length > 0 ? allTableListData[index]?.processed_headers : allTableListData[index]?.process_excel_headers)?.join(',\n'));
        const transformedArray = allTableListData[index]?.processed_headers?.map((item, index) => ({
            id: (index + 1).toString(),
            content: item
          }));
        setExcelHeaderMappingData(allTableListData[index]?.processed_headers);
        setGetUpdateDisabledItems(allTableListData[index]?.processed_headers);
        if (allTableListData[index].header_values.length > 0) {
            setCorrectHeaderMappingValue(allTableListData[index]?.header_values?.join(',\n'));
            setCorrectHeaderMappingValueNew(allTableListData[index]?.header_values);
            setSelectedUpdateData(allTableListData[index]?.processed_headers);
            setDummyArray(new Array(allTableListData[index]?.header_values.length).fill(""));
        } else {
            setSelectedUpdateData(allTableListData[index]?.processed_headers);
            setDummyArray(new Array(allTableListData[index]?.header_values.length).fill(""));
        }
        if (allTableListData[index].is_percentage) {
            setcorrectPercentageMappingValue(allTableListData[index]?.is_percentage?.join(',\n'))
        }
        
    }

    const onGridReady = (params) => {
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };

    const onclickHandleUpdateRuleMangment = () => {
        setLoading(true);
        let requestBody = {
            // id: updatedRuleMangmentID,
            brand: selectedBrandType?.id,
            brand_details: {
                brand_name: selectedBrandType?.value,
                brand_id: selectedBrandType?.id,
                email: selectedBrandType?.email
            },
            report_type: selectedReportType?.value,
            store_details: {
                store_name: selectedStoreType?.value,
                email: selectedStoreType?.email,
            },
            store: selectedStoreType?.id?._id,
            excel_headers: getExcelUpdatedData,
            headers: selectedUpdateData,
            is_percentage: discountPercentageMappingData
        }

        if ((requestBody?.excel_headers && requestBody?.excel_headers[0] === '') || (requestBody?.headers && requestBody?.headers[0] === '')) {
            ToastErrorService('Please fill all the fields');
            setLoading(false);
        } else {
            CreateRuleMangment(requestBody, USER_TOKEN).then(response => {
                ToastSuccessService("Headers Updated successfully!")
                getAllHeaderList();
                setActiveTab(1);
                setSelectedPage(1);
                setInitialSelectedReportType({ label: 'Header', value: 'Header' });
                setSelectedReportType('');
                setInitialSelectedStoreType({ label: 'Store', value: 'Store' });
                setselectedStoreType('');
                setExcelHeaderValue('');
                setExcelHeaderData([]);
                setExcelHeaderMappingValue('');
                setExcelHeaderMappingData([]);
                setOpenUpdatedTableListData(true);
                setCorrectHeaderMappingData([]);
                setCorrectHeaderMappingValue('');

                dispatch(ruleManagementActions.setExcelFileRowData({ type: [] }));
                dispatch(ruleManagementActions.setExcelFileUpdatedRowData({ type: [] }));
                setSelectedData([]);
                setProcessedHeaderMapping([]);
                setSelectedUpdateData([]);
                setCorrectHeaderMappingValueNew([]);
                
            }).catch(error => {
                ToastErrorService('Something went wrong, Please try again after sometime.');
                if (error?.response?.status === 401) {
                    history.push('/')
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const onClickBackButton = () => {
        setIsBtnActive(false);
        setActiveTab(1);
        setInitialSelectedReportType({ label: 'Header', value: 'Header' });
        setSelectedReportType('');
        setInitialSelectedBrandType({ label: 'Brand', value: 'Brand' });
        setSelectedBrandType('');
        setInitialSelectedStoreType({ label: 'Store', value: 'Store' });
        setselectedStoreType('');
        setExcelHeaderValue('');
        setExcelHeaderData([]);
        setExcelHeaderMappingValue('');
        setExcelHeaderMappingData([]);
        setDiscountPercentageMappingValue('');
        setDiscountPercentageMappingData([]);
        setOpenUpdatedTableListData(true);
        setCorrectHeaderMappingData([]);
        setCorrectHeaderMappingValue('');
        setCorrectHeaderMappingValueNew([]);
        setProcessedHeaderMapping([]);
        setSelectedUpdateData([]);
        // setInitialSelectedBrandType({ label: 'Brand', value: 'Brand' });
    }

    const renderBackButton = (btnActive) => {
        if (btnActive) {
            return <button className='backButton cursorPointer' onClick={onClickBackButton}>
                <svg xmlns="http://www.w3.org/2000/svg" width="8.402" height="14.805" viewBox="0 0 8.402 14.805">
                    <path id="Path_63354" data-name="Path 63354" d="M0,0,5.988,5.988,0,11.977" transform="translate(6.988 13.391) rotate(180)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                </svg>
            </button>
        }
    }

    useEffect(() => {
        if (searchTerm === '') {
            setTableListFilteredData(tableListFilteredData);
        }
    }, [searchTerm, tableListFilteredData]);

    const handleSearchTableData = (event) => {
        const value = event?.target?.value?.toLowerCase();
        setSearchTerm(value);

        if (value) {
            const filtered = tableListSearchDataStoreType?.filter(report =>
                report?.reportTypeStoreName?.toLowerCase()?.includes(value)
            );
            setTableListFilteredData(filtered);
        } else {
            setTableListFilteredData(tableListFilteredData);
        }
    };

    // const handleSetHeaderData = (item, index) => {
    //     const newItemContent = item.target.innerText;
    //     setSelectedData((prevData) => ({
    //         ...prevData,
    //         [currentFiled]: newItemContent,
    //     }));
    //     setDisabledItems(prevData => [...prevData, newItemContent]);
    //     setOpenPopupForSlecte(false);
    // };

    const handleSetHeaderData = (item, index) => {
        setSelectedData((prevData) => {
            const updatedArray = [...prevData];
            if (selectedRowIndex >= 0 && selectedRowIndex < dummyArray.length) {
                updatedArray[selectedRowIndex] = item;
            }
            return updatedArray;
        });
        setDisabledItems((prevData) => {
            if (!prevData.includes(item)) {
                return [...prevData, item];
            }
            return prevData;
        });
        setOpenPopupForSlecte(false);
    };

    const handleUpdateSetHeaderData = (item) => {
        setSelectedUpdateData((prevData) => {
            const updatedData = [...prevData];
            if (selectedUpdateRowIndex >= 0 && selectedUpdateRowIndex < dummyArray.length) {
                updatedData[selectedUpdateRowIndex] = item;
            }
            return updatedData;
        });

        setGetUpdateDisabledItems((prevData) => [...prevData, item]);
        setOpenUpdatePopupForSlecte(false);
    };
    

    const removeAddedMappedExcelHeadersDragableItems = (item, indexToRemove) => {
        setSelectedData((prevData) => 
            prevData.filter((_, index) => index !== indexToRemove)
        );
        setDisabledItems((prevData) => 
            prevData.filter((disabledItem) => disabledItem !== item)
        );
    };

    const removeUpdatedAddedMappedExcelHeadersDragableItems = (item, indexToRemove) => {
        setSelectedUpdateData((prevData) => {
            const updatedData = [...prevData];
            updatedData[indexToRemove] = ""; 
            return updatedData;
        });
    
        setGetUpdateDisabledItems((prevData) =>
            prevData.filter((disabledItem) => disabledItem !== item)
        );
    };

    const handleButtonClick = (item, index) => {
        if(getExcelData.length === 0){
            alert('Please Upload Excel File');
        }else{
            setOpenPopupForSlecte(true);
            setSelectedRowIndex(index);
        }
    };

    const handleUpdateButtonClick = (item, index) => {
        if(getExcelUpdatedData?.length === 0){
            alert('Please Upload Excel File');
        }else{
            setOpenUpdatePopupForSlecte(true);
            setSelectedUpdateRowIndex(index);
        }
    };


    return (
        <>
            {openUpdetedTableListData ?

                <>
                    <div className='d-flex ruleMangTabHeader'>
                        <div className='d-flex mb-3'>
                            <div className='storeAndRevenue'>
                                <button value={1} className={`tab ${activeTab === 1 ? 'tabBackground fontBold textColorBlack' : 'bg-white textColorGary'}`} onClick={() => handleTabClick(1)}>
                                    View Rule
                                </button>
                                <button value={2} className={`tab ${activeTab === 2 ? 'tabBackground fontBold textColorBlack' : 'bg-white textColorGary'}`} onClick={() => handleTabClick(2)}>
                                    Create Rule
                                </button>
                            </div>
                        </div>
                        {
                            activeTab === 1 &&
                            <div className='d-flex mx-4 rulemangFilterCont'>
                                <div className='position-relative'>
                                    <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                        onClick={(e) => handleDropDownClick(e, 'tableFilterBrandType')}>
                                        <p className='text-truncate' title={initialSelectedBrandTableFilterType.label}>{initialSelectedBrandTableFilterType?.label}</p>
                                        <img className={`${openBrandTableFilterTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                    </button>
                                    <CustomFilterSearchDropdown data={brandTypeList} handleClose={handleTypeClose} open={openBrandTableFilterTypeFilter} anchorEl={anchorElForBrandTableFilterType}
                                        onChange={(e) => onSelectChangeType(e, 'tableFilterBrandType')} selectedValue={initialSelectedBrandTableFilterType?.value} category={'tableFilterBrandType'} dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                                </div>
                                <div className='position-relative'>
                                    <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                        onClick={(e) => handleDropDownClick(e, 'tableFilterReportType')}>
                                        <p className='text-truncate' title={initialSelectedReportTableFilterType.label}>{initialSelectedReportTableFilterType?.label}</p>
                                        <img className={`${openReportTableFilterTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                    </button>
                                    <CustomFilterDropDown data={reportTableFilterTypeList} handleClose={handleTypeClose} open={openReportTableFilterTypeFilter} anchorEl={anchorElForReportTableFilterType}
                                        onChange={(e) => onSelectChangeType(e, 'tableFilterReportType')} selectedValue={initialSelectedReportTableFilterType?.value} category={'tableFilterReportType'} />
                                </div>
                                {/* <div className='position-relative'>
                                    <SearchBox extraClass="rulemangSearch" />
                                </div> */}
                            </div>
                        }
                    </div>
                    {activeTab === 1 &&
                        <div className='d-flex'>
                            <div className={`rulemangementSearch customSearch search-wrapper active`}>
                                <div className="input-holder">
                                    <input type="text" className="search-input" placeholder="Type to search" onChange={handleSearchTableData} />
                                    <button
                                        className={`search-icon`} >
                                        <span />
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className=''>
                        {activeTab === 1
                            ?
                            <>
                                <div className='ruleMangmentViewCont d-flex'>
                                    <div className='ruleMangmentViewTableCont reports'>
                                        <CustomTableWithFilter isContentLeftRightFixed={false} onGridReady={onGridReady} column={rulesMangmentViewTableHeader} tableRowData={tableListFilteredData} onRowClicked={onRowClicked} filter={null} handleActiveToggle={null} onClickEdit={updateRuleMangmentRow} onFilterChanged={null} filterModel={null} customeHeightClassName={'tableCustomeHeightClass'} />
                                    </div>
                                    {viewRowReportTypeData &&
                                        <div className='ruleMangmentViewListCont'>
                                            <div className='excelCorrectHeaderMapingCont CH50 w-100'>
                                                <div className='w-100 pe-3'>
                                                    <div className='d-flex align-items-center justify-content-between' style={{width: '100%'}}>
                                                        <div style={{width: '50%'}}>
                                                            <div className='w-100 d-flex align-items-center minHeight58'>
                                                                <label className='m-2 labelHeader' htmlFor={'storeTyped'}>
                                                                    Mapped excel Headers:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div style={{width: '50%'}}>
                                                            <div className='w-100 d-flex align-items-center minHeight58'>
                                                                <label className='m-2 labelHeader' htmlFor={'storeTyped'}>
                                                                    Predefined Header List:
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex textOverlowAuto calcH58' style={{ overflowY: "auto", padding: '10px'}}>
                                                        <div className='w-50' >
                                                                {(viewRowReportTypeData?.processed_headers.length > 0 ? viewRowReportTypeData?.processed_headers : viewRowReportTypeData?.process_excel_headers)?.map((item, index) => (
                                                                    <>
                                                                        <span className='ItemsContainer' key={item}>{item},</span><br />
                                                                    </>
                                                                ))}
                                                        </div>
                                                        <div className='w-50' style={{paddingLeft: "15px"}}>
                                                                { viewRowReportTypeData?.processed_header_mapping && (viewRowReportTypeData?.header_values.length > 0 ? viewRowReportTypeData?.header_values : Object?.values(viewRowReportTypeData?.processed_header_mapping))?.map((item, index) => (
                                                                    <>
                                                                        <span className='ItemsContainer' key={item}>{item},</span><br />
                                                                    </>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='excelCorrectHeaderMapingCont d-flex CH50'>
                                                <div className='w-50'>
                                                    <div className='w-100 d-flex align-items-center minHeight58'>
                                                        <label className='m-2 labelHeader' htmlFor={'storeTyped'}>
                                                            Input excel Headers:
                                                        </label>
                                                    </div>
                                                    <div className='excelHeaderCont calcH58'>
                                                        <div className='overflowAuto'>
                                                            {viewRowReportTypeData?.excel_headers?.map((item, index) => (
                                                                <div key={item}>
                                                                    <span key={item}>{item},</span><br />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-50'>
                                                    <div className='w-100 d-flex align-items-center minHeight58'>
                                                        <label className='m-2 labelHeader' htmlFor={'storeTyped'}>
                                                            Discount in Percentage:
                                                        </label>
                                                    </div>
                                                    <div className='excelHeaderCont calcH58'>
                                                        <div className='overflowAuto'>
                                                            {viewRowReportTypeData?.is_percentage?.map((item, index) => (
                                                                <div key={item}>
                                                                    <span key={item}>{item},</span><br />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {/* {(totalReportHeaderCount !== 0 || totalReportHeaderCount?.length !== 0) &&
                                    <div className='paddingTop16 d-flex justify-content-end customPagination'>
                                        <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={totalReportHeaderCount} />
                                    </div>
                                } */}
                            </>
                            :
                            <>
                            
                            <div >
                                <div className='mb-2'>
                                    <div className='d-flex align-items-end rulemangCreateUpdate'>
                                        <div className='position-relative'>
                                            <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                                onClick={(e) => handleDropDownClick(e, 'BrandType')}>
                                                <p className='text-truncate' title={initialSelectedBrandType.label}>{initialSelectedBrandType?.label}</p>
                                                <img className={`${openBrandTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                            </button>
                                            <CustomFilterSearchDropdown data={brandTypeList} handleClose={handleTypeClose} open={openBrandTypeFilter} anchorEl={anchorElForBrandType}
                                                onChange={(e) => onSelectChangeType(e, 'BrandType')} selectedValue={initialSelectedBrandType?.value} category={'BrandType'} dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                                        </div>
                                        <div className='position-relative'>
                                            <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                                onClick={(e) => handleDropDownClick(e, 'ReportType')}>
                                                <p className='text-truncate' title={initialSelectedReportType.label}>{initialSelectedReportType?.label}</p>
                                                <img className={`${openReportTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                            </button>
                                            <CustomFilterDropDown data={reportTypeList} handleClose={handleTypeClose} open={openReportTypeFilter} anchorEl={anchorElForReportType}
                                                onChange={(e) => onSelectChangeType(e, 'ReportType')} selectedValue={initialSelectedReportType?.value} category={'ReportType'} />
                                        </div>
                                        <div className='position-relative'>
                                            <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                                onClick={(e) => handleDropDownClick(e, 'StoreType')}>
                                                <p className='text-truncate' title={initialSelectedStoreType.label}>{initialSelectedStoreType?.label}</p>
                                                <img className={`${openStoreTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                            </button>
                                            <CustomFilterSearchDropdown data={storeTypeList} handleClose={handleTypeClose} open={openStoreTypeFilter} anchorEl={anchorElForStoreType}
                                                onChange={(e) => onSelectChangeType(e, 'StoreType')} selectedValue={initialSelectedStoreType?.value} category={'StoreType'} dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                                        </div>
                                        <div className='position-relative'>
                                            <ExcelToJsonTable handleFor={'createRule'} />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className='d-flex ruleMangmentForm createForm'>
                                    <div className='d-flex flex-column leftTypeBox'>
                                        <div className='d-flex align-items-center justify-content-between minHeight58'>
                                            <label className='m-2 labelHeader' htmlFor={'reportTypeId'}>
                                                Input excel Headers:
                                            </label>
                                            <div className="info-icon-container">
                                                <span className="info-icon">i</span>
                                                <div className="tooltip">Please enter the column names. Each column name should be end of comma(",") separated</div>
                                            </div>
                                        </div>
                                        <div className='textOverlowAuto height100'>
                                            {/* <textarea className='textarea height100' id={'reportTypeId'} name="postContent" value={excelHeaderValue} onChange={(e) => handleInputChange(e, 'ExcelHeader')} onKeyDown={(e) => handleKeyDown(e, 'ExcelHeader')} /> */}
                                            {/* <textarea className='textarea height100' id={'reportTypeId'} name="postContent" value={getExcelData?.join("\n")}  style={{ whiteSpace: 'pre-wrap' }}/> */}
                                            <div className='textarea height100 overFlowYScroll'>
                                                <ul className='getExcelDataUl'>
                                                    {getExcelData?.map(item => <li>{item}</li>)}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column rightTypeBox height100 '>
                                        <div className='d-flex h-100 h75_dflex'>
                                            <div className='w-100 pe-3'>
                                                <div className='d-flex align-items-center justify-content-between' style={{width: '100%'}}>
                                                    <div style={{width: '50%'}}> 
                                                        <div className='d-flex align-items-center justify-content-between minHeight58'>
                                                            <label className='m-2 labelHeader' htmlFor={'brandTyped'}>
                                                                Mapped excel Headers:
                                                            </label>
                                                            <div className="info-icon-container">
                                                                <span className="info-icon">i</span>
                                                                <div className="tooltip">Please enter the column names. Each column name should be end of comma(",") separated</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{width: '50%'}}>
                                                        <div className='d-flex align-items-center minHeight58'>
                                                            <label className='m-2 labelHeader' htmlFor={'storeTyped'}>
                                                                Predefined Header List:
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{position: 'relative'}}>
                                                    <div className='d-flex textOverlowAuto calcH58 mappedExcelHeadersDragableCont'>
                                                        {/* <textarea className='textarea height100' id={'brandTyped'} name="postContent" value={excelHeaderMappingValue} onChange={(e) => handleInputChange(e, 'ExcelHeaderMapping')} onKeyDown={(e) => handleKeyDown(e, 'ExcelHeaderMapping')} /> */}
                                                        {/* <div className='textarea height100'>
                                                            <ul className='getExcelDataUl'>
                                                                {mappedExcelHeaders?.map(item => <li >{item}</li>)}
                                                            </ul>
                                                        </div> */}
                                                        <div style={{width: '50%', position: 'relative'}}>
                                                            <div className='textarea height100'>
                                                                {selectedData && selectedData?.map((item, index) => (
                                                                    <div key={index} className='DragableItemsContainer' >
                                                                        <p>{item}</p>
                                                                        {item !== '' ?
                                                                            <span>
                                                                                <img style={{width: '19px'}} src={close_circle_bg_gray } alt="" onClick={() => removeAddedMappedExcelHeadersDragableItems(item, index)} />
                                                                            </span>
                                                                            :
                                                                            <div style={{position: 'relative'}}>
                                                                                <button style={{backgroundColor: '#4169e1', borderRadius: '10px', color: '#fff'}} onClick={() => handleButtonClick(item, index)}>
                                                                                    Add 
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        {/* <textarea className='textarea height100' id={'storeTyped'} name="postContent" value={correctHeaderMappingValue} disabled /> */}
                                                        <div style={{width: '50%'}}>
                                                            <div className='textarea height100'>
                                                                {correctHeaderMappingValueNew && correctHeaderMappingValueNew?.map(item => <div className='DragableItemsContainer'>{item}</div>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {openPopupForSlecte && (
                                                        <div className="openListCont" >
                                                            <div className='closeCustomePopup' onClick={() => setOpenPopupForSlecte(false)}>
                                                                    <img src={CloseIcon} alt="" />
                                                            </div>
                                                            <div className='pt-3 w-100' >
                                                                <div >
                                                                    <ul className='getExcelDataUl popupCont'>
                                                                        {getExcelData?.map((item, index) => (
                                                                            <li
                                                                                key={item}
                                                                                onClick={(e) => {
                                                                                    if (!disabledItems.includes(item)) handleSetHeaderData(item, index);
                                                                                }}
                                                                                className={disabledItems.includes(item) ? "disabled-item" : ""}
                                                                            >
                                                                                {item}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex mt-3 mb-3 width-100 '>
                                    <div className='d-flex flex-column justify-content-center leftTypeBox'>
                                        <CustomBlueButton customCreatePropsBtnClassName={(selectedReportType !== '' && selectedBrandType !== '' && selectedStoreType !== '' && excelHeaderData?.length !== 0 && excelHeaderMappingData?.length !== 0) ? '' : 'disabled'} title={<div className='d-flex align-items-center'>
                                            <div>Submit</div>
                                        </div>} onClick={onClickSubmitRuleMangment} />
                                    </div>

                                    <div className='d-flex flex-column rightTypeBox  width-100'>
                                        <div className='pe-3 w-100'>
                                            <div className='d-flex justify-content-between align-items-center minHeight58'>
                                                <label className='labelHeader' htmlFor={'discount'}>
                                                    Discount in Percentage:
                                                </label>
                                                <div className="info-icon-container">
                                                    <span className="info-icon">i</span>
                                                    <div className="tooltip">Please enter the discount column names. Values should be comma(,) separated</div>
                                                </div>
                                            </div>
                                            <div className='textOverlowAuto calcH36_58'>
                                                {/* <CustomeChip disabled={true} value={correctHeaderMappingData} /> */}
                                                <textarea className='textarea height100' id={'discount'} name="postContent" value={discountPercentageMappingValue} onChange={(e) => handleInputChange(e, 'DiscountPercentage')} onKeyDown={(e) => handleKeyDown(e, 'DiscountPercentage')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </>
                        }
                        {loading && <Loader />}
                    </div>

                </>
                :
                <>
                    <div >
                        <div className='mb-4'>
                            <h5 className='d-flex align-items-center'>{renderBackButton(isbtnActive)} Update Rule Management</h5>
                        </div>
                        
                        <div className='mb-2'>
                            <div className='d-flex align-items-end rulemangCreateUpdate'>
                                <div className='position-relative'>
                                    <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                        onClick={(e) => handleDropDownClick(e, 'BrandType')}>
                                        <p className='text-truncate' title={initialSelectedBrandType.label}>{initialSelectedBrandType?.label}</p>
                                        <img className={`${openBrandTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                    </button>
                                    {/* <CustomFilterDropDown data={brandTypeList} handleClose={handleTypeClose} open={openBrandTypeFilter} anchorEl={anchorElForBrandType}
                                        onChange={(e) => onSelectChangeType(e, 'BrandType')} selectedValue={initialSelectedBrandType?.value} category={'BrandType'} /> */}
                                    <CustomFilterSearchDropdown data={brandTypeList} handleClose={handleTypeClose} open={openBrandTypeFilter} anchorEl={anchorElForBrandType}
                                        onChange={(e) => onSelectChangeType(e, 'BrandType')} selectedValue={initialSelectedBrandType?.value} category={'BrandType'} dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                                </div>
                                <div className='position-relative'>
                                    <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                        onClick={(e) => handleDropDownClick(e, 'ReportType')}>
                                        <p className='text-truncate' title={initialSelectedReportType.label}>{initialSelectedReportType?.label}</p>
                                        <img className={`${openReportTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                    </button>
                                    <CustomFilterDropDown data={reportTypeList} handleClose={handleTypeClose} open={openReportTypeFilter} anchorEl={anchorElForReportType}
                                        onChange={(e) => onSelectChangeType(e, 'ReportType')} selectedValue={initialSelectedReportType?.value} category={'ReportType'} />
                                </div>

                                <div className='position-relative'>
                                    <ButtonDropdown className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                        onClick={(e) => handleDropDownClick(e, 'StoreType')}>
                                        <p className='text-truncate' title={initialSelectedStoreType.label}>{initialSelectedStoreType?.label}</p>
                                        <img className={`${openStoreTypeFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                    </ButtonDropdown>
                                    <CustomFilterDropDown data={storeTypeList} handleClose={handleTypeClose} open={openStoreTypeFilter} anchorEl={anchorElForStoreType}
                                        onChange={(e) => onSelectChangeType(e, 'StoreType')} selectedValue={initialSelectedStoreType?.value} category={'StoreType'} />
                                </div>
                                <div className='position-relative'>
                                    <ExcelToJsonTable handleFor={'updateRule'}  />
                                </div>
                            </div>
                        </div>
                        
                        <div className='d-flex ruleMangmentForm updateCont'>
                            <div className='d-flex flex-column leftTypeBox'>
                                <div className='d-flex align-items-center justify-content-between minHeight58'>
                                    <label className='m-2 labelHeader' htmlFor={'reportTypeId'}>
                                        Input excel Headers:
                                    </label>
                                    <div className="info-icon-container">
                                        <span className="info-icon">i</span>
                                        <div className="tooltip">Please enter the column names. Each column name should be end of comma(",") separated</div>
                                    </div>
                                </div>
                                <div className='textOverlowAuto height100'>
                                    <div className='textarea height100 overFlowYScroll'>
                                        <ul className='getExcelDataUl'>
                                            {getExcelUpdatedData?.map(item => <li>{item}</li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-column rightTypeBox height100'>
                                <div className='d-flex h-100 h75_dflex'>
                                    <div className='w-100 pe-3'>
                                        <div className='d-flex align-items-center justify-content-between' style={{width: '100%'}}>
                                            <div style={{width: '50%'}}> 
                                                <div className='d-flex align-items-center justify-content-between minHeight58'>
                                                    <label className='m-2 labelHeader' htmlFor={'brandTyped'}>
                                                        Mapped excel Headers:
                                                    </label>
                                                    <div className="info-icon-container">
                                                        <span className="info-icon">i</span>
                                                        <div className="tooltip">Please enter the column names. Each column name should be end of comma(",") separated</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{width: '50%'}}>
                                                <div className='d-flex align-items-center minHeight58'>
                                                    <label className='m-2 labelHeader' htmlFor={'storeTyped'}>
                                                        Predefined Header List:
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{position: 'relative'}}>
                                            <div className='d-flex textOverlowAuto calcH58 mappedExcelHeadersDragableCont'>
                                                <div style={{width: '50%', position: 'relative'}}>
                                                    <div className='textarea height100'>
                                                        {selectedUpdateData && selectedUpdateData?.map((item, index) =>(
                                                            <div key={item} className='DragableItemsContainer' >
                                                                <p>{item}</p>
                                                                {item !== '' ?
                                                                    <span>
                                                                        <img style={{width: '19px'}} src={close_circle_bg_gray } alt="" onClick={() => removeUpdatedAddedMappedExcelHeadersDragableItems(item, index)} />
                                                                    </span>
                                                                    :
                                                                    <button style={{backgroundColor: '#4169e1', borderRadius: '10px', color: '#fff'}} onClick={() => handleUpdateButtonClick(item, index)}>
                                                                        Add 
                                                                    </button>
                                                                }
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div style={{width: '50%'}}>
                                                    <div className='textarea height100'>
                                                        {correctHeaderMappingValueNew && correctHeaderMappingValueNew?.map(item => <div className='DragableItemsContainer'>{item}</div>)}
                                                    </div>
                                                </div>
                                            </div>
                                            {openUpdatePopupForSlecte && (
                                                <div className="openListCont" >
                                                    <div className='closeCustomePopup' onClick={() => setOpenUpdatePopupForSlecte(false)}>
                                                        <img src={CloseIcon} alt="" />
                                                    </div>
                                                    <div className='pt-3 w-100' >
                                                        <div >
                                                            <ul className='getExcelDataUl popupCont'>
                                                                {getExcelUpdatedData?.map((item, index) => (
                                                                    <li
                                                                        key={item}
                                                                        onClick={(e) => {
                                                                            if (!getUpdateDisabledItems.includes(item)) handleUpdateSetHeaderData(item, index); 
                                                                        }}
                                                                        className={getUpdateDisabledItems.includes(item) ? "disabled-item" : ""}
                                                                    >
                                                                        {item}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex mt-3 mb-3 width-100 '>
                            <div className='d-flex flex-column justify-content-center leftTypeBox'>
                            <CustomBlueButton customCreatePropsBtnClassName={(selectedReportType !== '' && selectedBrandType !== '' && selectedStoreType !== '' && (excelHeaderData?.length !== 0 || excelHeaderValue !== '') && (excelHeaderMappingData?.length !== 0 || excelHeaderMappingValue !== '')) ? '' : 'disabled'} title={<div className='d-flex align-items-center'>
                                    <div>Submit</div>
                                </div>} onClick={onclickHandleUpdateRuleMangment} />
                            </div>

                            <div className='d-flex flex-column rightTypeBox  width-100'>
                                <div className='pe-3 w-100'>
                                    <div className='d-flex justify-content-between align-items-center minHeight58'>
                                        <label className='labelHeader' htmlFor={'discount'}>
                                            Discount in Percentage:
                                        </label>
                                        <div className="info-icon-container">
                                            <span className="info-icon">i</span>
                                            <div className="tooltip">Please enter the discount column names. Values should be comma(,) separated</div>
                                        </div>
                                    </div>
                                    <div className='textOverlowAuto calcH36_58'>
                                        <textarea className='textarea height100' id={'discount'} name="postContent" value={discountPercentageMappingValue} onChange={(e) => handleInputChange(e, 'DiscountPercentage')} onKeyDown={(e) => handleKeyDown(e, 'DiscountPercentage')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {loading && <Loader />}
        </>
    )
};
export default RuleManagement;