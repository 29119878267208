import React, { Fragment, useState } from "react";
import { useHistory } from 'react-router-dom';
import '../Login/login.css';
import InputTextField from "../../../ReUsableComponent/InputTextField";
import '../../../global.css';
import PrimaryButton from "../../../ReUsableComponent/PrimaryButton";
import { SendOtpToChangePassword, VerifyOtp } from "../UserAuth.service";
import '../Login/login.css';
import OtpInput from "react-otp-input";
import Loader from "../../../ReUsableComponent/Loader";
import { ToastContainer } from 'react-toastify';
import { ToastErrorService, ToastSuccessService } from "../../../ReUsableComponent/ToastNotification/ToastService";


const ForgotPassword = () => {

    const history = useHistory();

    const [emailDetail, setEmailDetail] = React.useState({ value: '', valid: true, requiredName: true });
    const [loading, setLoading] = React.useState(false);
    const [otp, setOtp] = useState('');
    const [otpCount, setOtpCount] = React.useState(0);
    const [otpInputVisible, setOtpInputVisible] = React.useState(false);
    const [isVerifyBtnDisabled, setIsVerifyBtnDisabled] = React.useState(true);
    const [isResendDisable, setIsResendDisable] = React.useState(true);
    const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = React.useState(true);

    const [showToastMsg, setShowToastMsg] = React.useState(false);
    const [showErrToastMsg, setShowErrToastMsg] = React.useState(false);
    const [errorResponse, setErrorResponse] = React.useState([]);
    const [sendOtpResponse, setSendOtpResponse] = React.useState([]);

    React.useEffect(() => {
        if (otpCount > 0) {
            if (otp.length < 4) {
                setIsVerifyBtnDisabled(true);
            } else {
                setIsVerifyBtnDisabled(false);
            }
        } else {
            setIsVerifyBtnDisabled(true);
        }
    }, [otpCount, otp]);

    React.useEffect(() => {
        otpCount > 0 && setTimeout(() => setOtpCount(otpCount - 1), 1000);
        if (otpCount > 0) {
            setIsResendDisable(true)
        } else {
            setIsResendDisable(false)
        }
    }, [otpCount]);

    const onInputEmail = (event) => {
        const regex = /^\S+@\S+\.\S+$/;
        if (event.target.value !== "" && regex.test(event.target.value)) {
            setEmailDetail({
                ...emailDetail,
                value: event.target.value,
                valid: true,
                requiredName: true

            });
            setIsConfirmBtnDisabled(false);
        } else if (event.target.value === "") {
            setEmailDetail({
                ...emailDetail,
                value: event.target.value,
                valid: true,
                requiredName: false
            })
            setIsConfirmBtnDisabled(true);
        }
        else {
            setEmailDetail({
                ...emailDetail,
                value: event.target.value,
                valid: false,
                requiredName: true
            })
            setIsConfirmBtnDisabled(true);
        }
    }

    const onClickConfirm = () => {
        setLoading(true);
        let requestBody = {
            "emailEmpId": emailDetail?.value,
            "login_type": 'web'
        }
        setOtp('');
        SendOtpToChangePassword(requestBody).then(response => {
            if (response?.data?.statusCode === 200) {
                setOtpCount(30);
                setOtpInputVisible(true);
                ToastSuccessService(response?.data.message);
                setSendOtpResponse(response?.data);
            } else if (response.data.statusCode === 400) {
                ToastErrorService(response.data.message);
            } else if (response.data.statusCode === 403) {
                ToastErrorService(response.data.message);
            }
        }).catch(error => {
            ToastErrorService(error?.response?.data.message);
            setErrorResponse(error?.response?.data);
        }).finally(() => {
            setLoading(false);
        })

    }

    const onClickGoBack = () => {
        history.push('/auth/adminLogin')
    }

    const onClickVerify = () => {
        setLoading(true);
        localStorage.setItem('userEmail', emailDetail?.value)
        let reqBody = {
            "emailEmpId": emailDetail?.value,
            "otp": otp
        }
        VerifyOtp(reqBody).then(response => {
            if (response?.data?.statusCode === 200) {
                // setShowToastMsg(true);
                ToastSuccessService(response?.data?.message);
                setSendOtpResponse(response?.data?.data);
                history.push('/auth/changePassword');
            }
        }).catch(error => {
            // setShowErrToastMsg(true);
            ToastErrorService(error?.response?.data.message);
            setErrorResponse(error?.response?.data);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onOtpInput = (value) => {
        setOtp(value);
    }

    const onClickEdit = () => {
        setOtpInputVisible(false)
    }

    return (
        <Fragment>
            <div className="loginForm customShadow notoSansFont">
                {!otpInputVisible && <div className="loginCard">
                    <div className="montSerratFont fontSize24">
                        Forgot your password ?
                    </div>
                    <div className="paddingTop24 position-relative">
                        <InputTextField label='E-Mail' type='email' helperText='' error={false} required={true} onChange={(event) => onInputEmail(event)} value={emailDetail?.value} disabled={false} />
                        {!emailDetail?.valid && <div className="text-danger errorMsg">Please enter a valid Email Id.</div>}
                    </div>

                    <div className="paddingTop24 position-relative">
                        <PrimaryButton disabled={isConfirmBtnDisabled} title='Confirm' onClick={onClickConfirm} />
                    </div>
                    <div className="textColorBlue fontMedium paddingTop18 cursorPointer widthFit" onClick={onClickGoBack}>
                        Go Back
                    </div>
                </div>}
                {otpInputVisible && <div>
                    <div className="fontLight fontSize16 textColorGray">We sent a 4 digit code to your email</div>
                    <div className="fontSize16">{emailDetail?.value}
                        <button className="fontMedium textColorRed text-decoration-underline resendOtpBtn" onClick={onClickEdit}>Edit</button>
                    </div>
                    <div className="paddingTop32 custom-Otp-Input">
                        <OtpInput
                            value={otp}
                            onChange={onOtpInput}
                            numInputs={4}
                            inputType="tel"
                            renderInput={(props) => <input {...props} />}
                        />
                    </div>
                    <div className="paddingTop24">
                        <PrimaryButton disabled={isVerifyBtnDisabled} title='Verify' onClick={onClickVerify} />
                    </div>
                    <div className="fontLight fontColorGray fontSize14 paddingTop16">00:{otpCount < 10 ? 0 : ''}{otpCount} sec left to verify OTP</div>
                    <div className="fontLight fontColorGray fontSize14">Didn’t receive OTP?
                        <button className={`resendOtpBtn ${isResendDisable ? 'opacityHalf cursorNotAllowed' : ''}`} onClick={onClickConfirm} disabled={isResendDisable}>
                            <span className="textColorBlue fontMedium text-decoration-underline">Resend</span>
                        </button>
                    </div>
                    <div className="paddingTop16 text-break fontLight fontColorGray fontSize12 maxWidthForOtpInfo">
                        If you don’t see the email in your inbox, check your spam folder.
                    </div>
                </div>}
            </div>
            {/* <ToastContainer /> */}
            {loading && <Loader />}
        </Fragment>)
};

export default ForgotPassword;
