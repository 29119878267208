export const ToLacs = (value) => {
    if (value > 0) {
        return (value / 100000).toFixed(2)
    } else return value
}

export const ToThousands = (value) => {
    if (value > 0) {
        return (value / 1000).toFixed(2)
    } else return value
}

export const ToCrores = (value) => {
    if (value > 0) {
        return (value / 10000000).toFixed(2)
    } else return value
}