import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const Top5Stores = ({ chartData }) => {
    const data = {
        labels: chartData?.topStoreLabel,
        datasets: [
            {
                label: 'Target',
                data: chartData?.topStoreTarget,
                backgroundColor: '#a6b5f4',
                hoverBackgroundColor: '#a6b5f4',
                hoverBorderColor: '#a6b5f4',
                borderColor: '#a6b5f4',
                borderWidth: 1,
                maxBarThickness: 30,
            },
            {
                label: 'Achievement',
                data: chartData?.topStoreAchieved,
                backgroundColor: '#d6d6d6',
                hoverBackgroundColor: '#d6d6d6',
                hoverBorderColor: '#d6d6d6',
                borderColor: '#d6d6d6',
                borderWidth: 1,
                maxBarThickness: 30,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        datalabels: {
            display: false,
            color: '#fff',
        },
        categoryPercentage: 0.8,
        barPercentage: 0.9,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false, // Ensure no data labels are shown inside the bars
            },
            // tooltip: {
            //     callbacks: {
            //         label: function (context) {
            //             let label = context.dataset.label || '';
            //             if (label) {
            //                 label += ': ';
            //             }
            //             label += new Intl.NumberFormat('en-IN', {
            //                 style: 'currency',
            //                 currency: 'INR',
            //                 minimumFractionDigits: 0,
            //             }).format(context.parsed.y);
            //             return label;
            //         },
            //     },
            // },
        },
        scales: {
            x: {
                ticks: {
                    callback: function (value) {
                        const label = this.getLabelForValue(value);
                        return label.length > 10 ? label.slice(0, 10) + '...' : label;
                    }
                }
            }
        },
    };

    return (
        <div className='overflow-auto'>
            <Bar data={data} height={300} options={options} />
        </div>
    );
};

export default Top5Stores;