import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './customAutoComplete.css';

const CustomIcon = () => {
    return <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="10.828" height="6.414" viewBox="0 0 10.828 6.414">
            <path id="Path_17966" data-name="Path 17966" d="M12,16l4-4L12,8" transform="translate(17.414 -10.586) rotate(90)" fill="none" stroke="#4169e1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </svg>
    </div>
}

const CustomAutoComplete = ({ options, onChange, value, label, placeholder, name, disabled, getOptionLabel, errored, errorMsg, userTeam }) => {

    const renderOptions = (props, options) => {
        return <li {...props} key={options.id}>{options.label}</li>
    }

    return (
        <div className={`customAutoComplete ${disabled ? 'disabled' : ''}`}>
            {userTeam === "userTeam" ?
                <div className='inputBottomSpace'>
                    <Autocomplete
                        // id="size-small-filled"
                        id={name}
                        size="small"
                        options={options}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        disableClearable={true}
                        getOptionLabel={getOptionLabel}
                        popupIcon={<CustomIcon />}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="filled"
                                label={label}
                                placeholder={placeholder}
                            />
                        )}
                        renderOption={renderOptions}
                    />
                    {!errored && <div className="error">{errorMsg}</div>}
                </div>
                :
                <Autocomplete
                    id="size-small-filled"
                    size="small"
                    options={options}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    disableClearable={true}
                    getOptionLabel={getOptionLabel}
                    popupIcon={<CustomIcon />}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="filled"
                            label={label}
                            placeholder={placeholder}
                        />
                    )}
                    renderOption={renderOptions}
                />
            }
        </div>
    );
}

export default CustomAutoComplete