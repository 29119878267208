import dayjs from 'dayjs';

export function convertToIST(dateString) {
    // Split the input date string
    const [day, month, year] = dateString.split('-').map(Number);

    // Create a Date object in the local timezone
    const date = new Date(year, month - 1, day);

    // Get the UTC time in milliseconds
    const utcTime = date.getTime() + (date.getTimezoneOffset() * 60000);

    // Create a new Date object for IST by adding the IST offset (5 hours 30 minutes)
    const istOffset = 5.5 * 60 * 60 * 1000;
    const istTime = new Date(utcTime + istOffset);

    return istTime;
}

export function parseDateString(dateString) {
    return dayjs(dateString, 'DD-MM-YYYY');
}

export const dateIntoDayJs = (date) => {
    var newDate;
    if (date?.split('/').length > 1) {
        newDate = date?.split("/").reverse().join("-");
    } else {
        newDate = date?.split("-").reverse().join("-");
    }
    return newDate
}