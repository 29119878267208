import './pagination.css';

const CustomPagination = (props) => {

    function paginate(totalItems, currentPage, pageSize) {
        const totalPages = Math.ceil(totalItems / pageSize);
        let startPage;
        let endPage;

        if (totalPages <= 6) {
            // Less than or equal to 6 total pages, so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // More than 6 total pages, so calculate start and end pages
            if (currentPage <= 4) {
                startPage = 1;
                endPage = 6;
            } else if (currentPage + 2 >= totalPages) {
                startPage = totalPages - 5;
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage + 3;
            }
        }

        // Calculate start and end item indexes
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // Create an array of pages to display in the pagination control
        const pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
        return (
            <div className='notoSansFont fontSize12 fontMedium p-b-15'>
                <div className='d-flex'>
                    <button className='prevBtn' disabled={props.activePage === 1}
                        onClick={() => props.onClick(props.activePage - 1)}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8.402" height="14.805" viewBox="0 0 8.402 14.805">
                                <path id="Path_63354" data-name="Path 63354" d="M0,0,5.988,5.988,0,11.977" transform="translate(6.988 13.391) rotate(180)" fill="none" stroke="#4169E1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            </svg>
                        </div>
                    </button>
                    {pages.map((page, index) => (
                        <div key={index} className={`cursorPointer paginationBorder ${props.activePage === page ? 'activePage' : ''}`}
                            onClick={() => props.onClick(page)}>
                            <div>{page}</div>
                        </div>))}
                    <button className='nxtBtn' onClick={() => props.onClick(props.activePage + 1)} disabled={props.activePage >= totalPages}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8.402" height="14.805" viewBox="0 0 8.402 14.805">
                                <path id="Path_63355" data-name="Path 63355" d="M0,11.977,5.988,5.988,0,0" transform="translate(1.414 1.414)" fill="none" stroke="#4169E1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        )
    }
    return paginate(props.totalItems, props.currentPage, props.pageSize)

}

export default CustomPagination