import React from 'react';
import TextField from '@mui/material/TextField';
import './inputField.css';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarIcon from '../../assets/svg/calendar_2_fill.svg';

const InputTextField = ({ label, maxLength, minLength, type, errored, disabled, name, value, required, onChange, InputLabelProps, errorMsg, disabledClass, calendarIcon, userTeam }) => {
    return (
        <div className={`customTextField ${disabledClass}`}>
            {userTeam === "userTeam" ?
                <div className='inputBottomSpace'>
                    <TextField 
                        // id={`filled-basic-${label}`} 
                        id={name} 
                        label={label} 
                        variant="filled" 
                        type={type}
                        helperText={errored ? errorMsg : ''} 
                        error={errored} 
                        autoComplete='off'
                        required={required} onChange={onChange} value={value} disabled={disabled} name={name}
                        InputLabelProps={InputLabelProps}
                        InputProps={{
                            // maxLength: maxLength,
                            // minLength: minLength ,
                            endAdornment: (
                                <>
                                    {calendarIcon &&
                                    <InputAdornment position="end" className='calendar_icon_img'>
                                        <img  src={CalendarIcon} alt="Calendar Icon" />
                                    </InputAdornment>
                                    }
                                </>
                            ),
                            inputProps: {
                                maxLength: maxLength,
                                minLength: minLength
                            }
                        }}
                    />
                    {!errored && <div className="error">{errorMsg}</div>}
                </div>
                :
                <TextField id={`filled-basic-${label}`} label={label} variant="filled" type={type}
                    helperText={errored ? errorMsg : ''} 
                    error={errored} 
                    autoComplete='off'
                    required={required} onChange={onChange} value={value} disabled={disabled} name={name}
                    InputLabelProps={InputLabelProps}
                    InputProps={{
                        // maxLength: maxLength,
                        // minLength: minLength ,
                        endAdornment: (
                            <>
                                {calendarIcon &&
                                <InputAdornment position="end" className='calendar_icon_img'>
                                    <img  src={CalendarIcon} alt="Calendar Icon" />
                                </InputAdornment>
                                }
                            </>
                        ),
                        inputProps: {
                            maxLength: maxLength,
                            minLength: minLength
                        }
                    }}
                />
            }
        </div>
    )
}

export default InputTextField