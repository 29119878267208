import CryptoJS from "crypto-js";

const key = "YTVHB2Q3W4E5R6T7WMKAIO"

export const encryptRequest = (data) => {
    return { request: (CryptoJS.AES.encrypt(JSON.stringify(data), key)).toString() };
}

export const decryptResponse = (data) => {
    let bytes = CryptoJS.AES.decrypt(data, key);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
}