import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import '../targetVsAchievement.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const StaffAvailabilityChart = ({ chartData }) => {
    const targetData = {
        datasets: [
            {
                data: [100, 0], // Full circle
                backgroundColor: ['#D9E1FF', 'transparent'],
                borderWidth: 0,
                cutout: '80%', // Inner cutout for the target chart
                maintainAspectRatio: false, // Disable the default aspect ratio
                // responsive: true,
            },
        ],
    };

    const achievementData = {
        datasets: [
            {
                data: [chartData?.truePercentage, chartData?.falsePercentage], // 41% fill
                backgroundColor: ['#7263D6', 'transparent'],
                borderWidth: 0,
                cutout: '80%', // Inner cutout for the achievement chart
                borderRadius: 30,
                maintainAspectRatio: false, // Disable the default aspect ratio
                // responsive: true,
            },
        ],
    };

    const options = {
        cutout: '70%', // Same cutout value for both charts to achieve overlap
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
            datalabels: {
                display: false, // Ensure no data labels are shown inside the bars
            },
        },
        // rotation: -90,
        // circumference: 360,
        // maintainAspectRatio: false, // Disable the default aspect ratio
        // responsive: true,

    };

    return (
        <div style={{ position: 'relative' }} className='d-flex justify-content-center'>
            <div style={{ position: 'relative', top: 0, left: 0 }}>
                <Doughnut style={{ margin: 'auto' }}
                    width={250}
                    data={targetData}
                    options={{
                        ...options,
                        circumference: 360, // Full circle for target
                    }}
                />
                <div className='ach-chart'>
                    <Doughnut
                        width={270}
                        data={achievementData}
                        options={{
                            ...options,
                            circumference: 360, // Partial circle for achievement
                        }}
                    />
                </div>
            </div>

            <div className='notoSansFont'
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '28px',
                    fontWeight: '600',
                }}
            >
                {Math.round(chartData?.truePercentage)}%
            </div>
        </div>
    );
};

export default StaffAvailabilityChart;
