import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel" className='customTabPanels'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomTabs = (props) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Box>
                <Tabs className='customTab' value={props.value} onChange={props.handleChange} aria-label="basic tabs example">
                    {props.tabList.map((item, index) => (<Tab key={item?.label} label={item?.label} {...a11yProps(index)} />))}
                </Tabs>
            </Box>
            <CustomTabPanel value={props.value} index={props.value}>
                {props.children}
            </CustomTabPanel>
        </Box>
    );
}

export default CustomTabs