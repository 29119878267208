import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    teamId: '',
    viewTeamDetails: [],
    selectedTab: 0,
    viewTeamSelectedTabLabel: 'Brand Owner Details',
    tableFilters: {},
    masterData: [],
};

const teamManagementSlice = createSlice({
    name: 'teamManagement',
    initialState,
    reducers: {
        setTeamIs: (state, action) => {
            state.teamId = action.payload.type
        },
        setViewTeamDetails: (state, action) => {
            state.viewTeamDetails = action.payload.type
        },
        setViewTeamSelectedTabLabel: (state, action) => {
            state.viewTeamSelectedTabLabel = action.payload.type
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload.type
        },
        setTableFilters: (state, action) => {
            state.tableFilters = action.payload.type
        },
        setMasterData: (state, action) => {
            state.masterData = action.payload.type
        },
    }
});

export const teamManagementActions = teamManagementSlice.actions;
export default teamManagementSlice;
