import React, { useState } from 'react'
import { Loader } from 'react-bootstrap-typeahead';
import { useHistory } from 'react-router-dom';
import { userAuthActions } from '../../store/reducers/UserAuthReducers';
import { useDispatch } from "react-redux";


const RedirectionPage = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const parts = window.location.href.split('?');
    const emailQueryString = parts;
    const triggeredAtQueryString = parts[2];
    const searchParams = new URLSearchParams(emailQueryString[1]);
    const searchParamsTriggeredAt = new URLSearchParams(emailQueryString[2]);

    const emailId = searchParams.get('email_id');
    const triggeredAt = searchParamsTriggeredAt.get('triggered_at');
    React.useEffect(() => {

    }, [])
    React.useEffect(() => {
        checkLinkExpiryTime();
    }, [])

    const checkLinkExpiryTime = () => {
        const timestamp = new Date(triggeredAt);
        // Current time
        const now = new Date();
        // Calculate the difference in milliseconds
        const differenceMs = now - timestamp;
        // Convert milliseconds to hours
        const hours = differenceMs / (1000 * 60 * 60);
        // if (hours >= 24) {
        //     history.push('/linkExpired');
        // } else {
        const emailString = emailId.split(" ");
        if (emailString.length > 1) {
            let correctEmail = `${emailString[0]}+${emailString[1]}`
            dispatch(userAuthActions.setUserEmail({ type: correctEmail }));
        } else {
            dispatch(userAuthActions.setUserEmail({ type: emailId }));
        }
        history.push('/auth/createPassword');
        // }
    }
    return (
        <div>
            {loading && <Loader />}
        </div>
    )
}

export default RedirectionPage