import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link, useHistory } from 'react-router-dom';
import "./ViewEditPromotion.css";
import Loader from '../../../ReUsableComponent/Loader';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import SuccessPopup from './../../../ReUsableComponent/SuccessPopupMsg';
import EditIcon from '../../../assets/svg/edit-icon.svg';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import { GetPromotionsList, UpdatePromotionsList } from './../promotion.service';
import { ToastErrorService } from '../../../ReUsableComponent/ToastNotification/ToastService';

import { promotionActions } from '../../../store/reducers/PromotionReducer';
import PromotionForm from '../PromotionForm';
import CustomModal from '../../../ReUsableComponent/CustomModal';


const uploadFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const ViewEditPromotion = () => {
    const timeoutRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const editFormVisible = useSelector((state) => state.promotion.isEditFormVisible);
    const getPromotionId = useSelector((state) => state.promotion.promotionId);
    const getPromotionDataListById = useSelector((state) => state.promotion.promotionDataListById);
    const [loading, setLoading] = useState(false);
    const [isEditFormVisible, setIsEditFormVisible] = useState(editFormVisible);
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [showViewMoreDataPopup, setShowViewMoreDataPopup] = useState(false);
    const [showViewMoreData, setShowViewMoreData] = useState(false);
    const [formData, setFormData] = useState(getPromotionDataListById);
    const styleCode = useSelector((state) => state.promotion.styleCodeType);
    const [isXValueDisabled, setIsXValueDisabled] = useState(getPromotionDataListById?.x_value ? false : true);
    const [isYValueDisabled, setIsYValueDisabled] = useState(getPromotionDataListById?.y_value ? false : true);

    const [formDataError, setFormDataError] = useState({
        description: getPromotionDataListById?.description && null,
        x_value: getPromotionDataListById?.x_value && null,
        y_value: getPromotionDataListById?.y_value && null,
    });

    useEffect(() => {
        getPromotionAllList();
    }, [getPromotionId]);

    const convertDate = (openingDate) => {
        let date = new Date(openingDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return moment(dateString).format("DD MMM YYYY");
    }

    const onClickEditDetails = () => {
        dispatch(promotionActions.setIsEditFormVisible({ type: false }));
    }

    const onClickCancel = () => {
        dispatch(promotionActions.setIsEditFormVisible({ type: true }));
    }

    const getPromotionAllList = () => {
        setLoading(true);
        let param = `?id=${getPromotionId}`
        GetPromotionsList(param, USER_TOKEN).then(response => {
            const foundObject = response?.data?.data;
            if (foundObject) {
                const dataById = {
                    description: foundObject?.description,
                    brand_data: {
                        brand_id: foundObject?.brand_data?.brand_id,
                        brand_name: foundObject?.brand_data?.brand_name
                    },
                    from_date: foundObject?.from_date || null,
                    to_date: foundObject?.to_date || null,
                    region: foundObject?.region,
                    state: foundObject?.state,
                    store_detail: foundObject?.store_detail,
                    promotion_type: foundObject?.promotion_type,
                    x_value: foundObject?.x_value,
                    y_value: foundObject?.y_value,
                    promotion_type_value: foundObject?.promotion_type_value,
                    season: foundObject?.season,
                    category: foundObject?.category,
                    style_code: foundObject?.style_code,
                    barcode: foundObject?.barcode,
                }
                setFormData(dataById);
                dispatch(promotionActions.setPromotionDataListById({ type: dataById }));
                let typeOfStyle = {}
                if (foundObject.style_code?.length > 0) {
                    typeOfStyle.label = 'Custom';
                    typeOfStyle.value = 'Custom';
                } else {
                    typeOfStyle.label = 'All';
                    typeOfStyle.value = 'All';
                }
                dispatch(promotionActions.setStyleCodeType({ type: { ...typeOfStyle } }));
            }

        })
            .catch(error => {
                if (error.response.status === 401) {
                    history.push('/')
                }
                ToastErrorService(error.response?.data?.message || 'An error occurred.');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onClickSaveUpdateDetails = () => {
        setLoading(true);
        let updatedStatus = '';
        let param = `?id=${getPromotionId}&status=${updatedStatus}`;
        UpdatePromotionsList(formData, param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                setOpenSuccessPopup(true);
                // setTimeout(() => {
                //     history.push('/app/promotion');
                //     setOpenSuccessPopup(false);
                // }, 5000);

                // Clear any previous timeout if it exists
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                // Set a new timeout to automatically close the popup
                timeoutRef.current = setTimeout(() => {
                    setOpenSuccessPopup(false);
                    history.push('/app/promotion');
                }, 5000);
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleViewMore = (Name) => {
        setShowViewMoreDataPopup(true);
        setShowViewMoreData(Name);
    };

    const renderTabData = () => {
        return (
            <>
                {editFormVisible ?
                    <div className='bg-white storeDetailsContainer scrollChange'>
                        <div className='montSerratFont fontSize18 tabheader'>Promotion Details</div>
                        <div className='position-relative'>
                            <div className='editStoreBtn'>
                                <SecondaryButton onClick={onClickEditDetails} title={<div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <img src={EditIcon} alt="" />
                                        <div className='textColorBlue fontMedium'>Edit Details</div>
                                    </div>
                                </div>} />
                            </div>
                        </div>
                        <div className='paddingTop24 notoSansFont'>
                            <div className='storeOwnerDetails'>
                                <div>
                                    <div className='storeLabel'>Promotion Description*</div>
                                    <div className='storeValue'>{formData?.description ? formData?.description : '-'}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Promotion Code</div>
                                    <div className='storeValue'>{formData?.promo_code ? formData?.promo_code : '-'}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Brand Name</div>
                                    <div className='storeValue'>{formData?.brand_data?.brand_name ? formData?.brand_data?.brand_name : '-'}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Date</div>
                                    <div className='storeValue'>{(formData?.to_date && formData?.from_date) ? `${convertDate(formData?.from_date)} - ${convertDate(formData?.to_date)}` : '-'}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Region</div>
                                    <div className='storeValue'>{formData?.region ? formData?.region?.join(',') : '-'}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>State</div>
                                    <div className='storeValue'>
                                        {/* {formData?.state ? formData?.state?.join(',') : '-'} */}
                                        {formData?.state?.length > 0
                                            ? formData?.state?.slice(0, 2).join(', ')
                                            : '-'}
                                        {formData?.state?.length > 2 && (
                                            <button className='viewMoreBtn' onClick={() => handleViewMore('state')}>View More</button>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Store Name</div>
                                    <div className='storeValue'>
                                        {/* {formData?.store_detail ? formData?.store_detail?.map(item => item?.store_name)?.join(',') : '-'} */}
                                        {formData?.store_detail?.length > 0
                                            ? formData?.store_detail?.slice(0, 2).map(item => (item.store_trade_name && item.store_trade_name !== 'null') ? item.store_trade_name : item.store_name).join(', ')
                                            : '-'}
                                        {formData?.store_detail?.length > 2 && (
                                            <button className='viewMoreBtn' onClick={() => handleViewMore('StoreName')}>View More</button>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Promotion Type*</div>
                                    <div className='storeValue'>{formData?.promotion_type ? formData?.promotion_type : '-'}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>X Value*</div>
                                    <div className='storeValue'>{formData?.x_value ? formData?.x_value : '-'}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Y Value*</div>
                                    <div className='storeValue'>{formData?.y_value ? formData?.y_value : '-'}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Promotion Type With X & Y Values</div>
                                    <div className='storeValue'>{formData?.promotion_type_value ? formData?.promotion_type_value : '-'}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Season</div>
                                    <div className='storeValue'>{formData?.season ? formData?.season : '-'}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Category</div>
                                    <div className='storeValue'>{formData?.category?.length > 0
                                        ? formData?.category?.slice(0, 2).toString()
                                        : '-'}
                                        {formData?.category?.length > 2 && (
                                            <button className='viewMoreBtn' onClick={() => handleViewMore('category')}>View More</button>
                                        )}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Style Code</div>
                                    <div className='storeValue'>{formData?.style_code?.length > 0
                                        ? formData?.style_code?.slice(0, 2).toString()
                                        : '-'}
                                        {formData?.style_code?.length > 2 && (
                                            <button className='viewMoreBtn' onClick={() => handleViewMore('StyleCode')}>View More</button>
                                        )}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className='editPromotionContainer bg-white storeDetailsContainer'>
                            <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                                Promotion Details
                            </div>
                            {formData &&
                                <PromotionForm formData={formData} setFormData={setFormData} formDataError={formDataError} setFormDataError={setFormDataError} isXValueDisabled={isXValueDisabled} setIsXValueDisabled={setIsXValueDisabled} isYValueDisabled={isYValueDisabled} setIsYValueDisabled={setIsYValueDisabled} />
                            }
                        </div>
                        <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                            <div className='pe-3'>
                                <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                            </div>
                            <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                <div>Save Changes</div>
                            </div>} onClick={onClickSaveUpdateDetails} disabled={
                                formDataError &&
                                (
                                    formDataError?.description !== null ||
                                    formData?.brand_data?.brand_name === '' ||
                                    formData?.from_date === '' ||
                                    formData?.to_date === '' ||
                                    formData?.promotion_type === '' ||
                                    formData?.promotion_type_value === '' ||
                                    ((styleCode?.value && styleCode?.value === 'Custom') && formData.style_code?.length === 0)
                                    // (!isXValueDisabled && formDataError?.x_value !== null) ||
                                    // (!isYValueDisabled && formDataError?.y_value !== null)
                                )
                            }
                            />
                        </div>
                    </>
                }
            </>
        )
    }


    return (
        <div>
            <div className='d-flex justify-content-between align-items-baseline'>
                <div className='breadCrumbs'>
                    <span className='fontLight'>
                        <Link className="breadCrumbs" to={'/app/promotion'}>Home</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>{editFormVisible ? "View Promotion Details" : "Edit Promotion Details"}</span>
                </div>
            </div>
            <div className='paddingTop12'>
                {renderTabData()}
            </div>

            <SuccessPopup open={openSuccessPopup} closable={false} onClose={() => { if (timeoutRef.current) { clearTimeout(timeoutRef.current); } setOpenSuccessPopup(false); history.push('/app/promotion'); }} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>Promotion Created Successfully!</div>
                    <div className='fontSize16 textColor333'>You have successfully created your promotion </div>
                </div>
            </div>} />

            <CustomModal open={showViewMoreDataPopup} isCloseIcon={true} style={uploadFileModalStyle} handleClose={() => { setShowViewMoreDataPopup(false) }} content={
                <div>
                    <div className='text-center'>
                        <div className='fontSize16 textColor333 customScroll' style={{ textAlign: 'left', maxHeight: '400px', overflow: 'auto' }}>
                            {showViewMoreData &&
                                showViewMoreData === 'StoreName' &&
                                formData?.store_detail?.map(item => (
                                    <li>{(item.store_trade_name && item.store_trade_name !== 'null') ? item.store_trade_name : item.store_name}</li>
                                ))
                            }
                            {showViewMoreData === 'state' && formData?.state?.map(item => (
                                <li>{item}</li>))
                            }
                            {showViewMoreData === 'StyleCode' && formData?.style_code?.map(item => (
                                <li>{item}</li>))
                            }
                            {showViewMoreData === 'category' && formData?.category?.map(item => (
                                <li>{item}</li>))
                            }
                        </div>
                    </div>
                </div>}
            />

            {loading && <Loader />}
        </div>
    )
}

export default ViewEditPromotion