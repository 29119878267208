import axios from "axios";
import API_CONFIG from "../../Configurations/ApiDeclaration";

export function GetReportHeaderList(param, token) {
    const URL = API_CONFIG.GET_REPORT_HEADER_VALUES + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetBrandNameList(param, token) {
    const URL = API_CONFIG.GET_BRAND_NAME + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetStoreCustomerNameList(param, token) {
    const URL = API_CONFIG.GET_STORE_CUSTOMER_NAME + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetHeaderValueList(param, token) {
    const URL = API_CONFIG.GET_HEADER_VALUE_ONLY + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function CreateRuleMangment(requestBody, token) {
    const URL = API_CONFIG.CREATE_RULE_MANGMENT;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}
export function UpdateRuleMangment(requestBody, token) {
    const URL = API_CONFIG.UPDATE_RULE_MANGMENT;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.put(URL, requestBody, headers);
}