import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const HorizontalLinearStepper = (props) => {

    const { steps, activeStep, handleBackStep } = props;

    return (
        <div className='customStepper'>
            <Box className="customStepperBox customeScroll" sx={{ width: '100%' }}>
                <Stepper className='customStepperEachCont' activeStep={activeStep}>
                    {steps.map((label, index) => {
                        return (
                            <Step key={label} onClick={() => handleBackStep(label, index)} >
                                <StepLabel >{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
        </div>
    );
}

export default HorizontalLinearStepper
