import React, { Component, Fragment } from 'react';
import IncentiveDashboard from './IncentiveDashboard/index';
import CreateIncentive from './CreateIncentive/index';

const Incentive = () => {
    return (
        <IncentiveDashboard />
        // <CreateIncentive />
    )
}

export default Incentive
