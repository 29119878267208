import axios from "axios";
import API_CONFIG from "../../Configurations/ApiDeclaration";

export function GetStoreList(requestBody, queryParam, token) {
    const URL = API_CONFIG.GET_STORE_LIST + queryParam;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}
export function GetStoreDashboardData(requestBody, queryParam, token) {
    const URL = API_CONFIG.GET_STORE_DASHBOARD_DATA + queryParam;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}
export function DownloadStoreList(requestBody, token) {
    const URL = API_CONFIG.DOWNLOAD_STORE_LIST;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}
export function UploadStoreList(formdata, param, token) {
    const URL = API_CONFIG.UPLOAD_STORE_LIST + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, formdata, headers);
}
export function ChangeStoreStatus(requestBody, param, token) {
    const URL = API_CONFIG.UPDATE_STORE_STATUS + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.put(URL, requestBody, headers);
}
export function GetFinanicalYearList(token) {
    const URL = API_CONFIG.GET_FINANCIAL_YEAR_LIST;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetStoreDetails(param, token) {
    const URL = API_CONFIG.GET_STORE_DETAILS_BY_ID + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function getListStateCityByPincode(param, token) {
    const URL = API_CONFIG.GET_LIST_STATE_CITY_BY_PINCODE + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function fileUpload(requestBody, token) {
    const URL = API_CONFIG.FILE_UPLOAD;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}

export function createStore(requestBody, token) {
    const URL = API_CONFIG.CREATE_STORE;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}

export function updateStoreDetailsBasedOnStoreId(requestBody, param, token) {
    const URL = API_CONFIG.CREATE_STORE + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.put(URL, requestBody, headers);
}

export function getDropdownList(param, token) {
    const URL = API_CONFIG.GET_CREATE_BRAND_DROPDOWN_LIST + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function getAllBrandList(param, token) {
    const URL = API_CONFIG.GET_ALL_BRANDS_LIST + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
