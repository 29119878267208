import React, { Fragment } from "react";
import { Route } from "react-router-dom";

// Layout
import Login from "../../Pages/UserAuth/Login";
import picBg from '../../assets/svg/loginPagePhoto.svg'
import CreatePassword from "../../Pages/UserAuth/CreatePassword";
import ForgotPassword from "../../Pages/UserAuth/ForgotPassword";
import ChangePassword from "../../Pages/UserAuth/ChangePasssword";


const PreAuthLayout = ({ match }) => (
    <Fragment>
        <div className="authLayout">
            <div className="backGround d-flex justify-content-center" id="leftContainer">
                <div>
                    <div>
                        <div className="fontSize40 montSerratFont text-white fontMedium">Welcome to Our Company</div>
                        <div className="fontSize32 montSerratFont text-white">Organise your daily task
                            <div>In a <span className="fontMedium">“simple way”</span></div>
                        </div>
                    </div>
                    <div className="paddingTop32">
                        <img src={picBg} alt="" width={530} height={332} />
                    </div>
                </div>
            </div>
            <div className="formSide">
                <Route path={`${match.url}/adminLogin`} component={Login} />
                <Route path={`${match.url}/createPassword`} component={CreatePassword} />
                <Route path={`${match.url}/forgotPassword`} component={ForgotPassword} />
                <Route path={`${match.url}/changePassword`} component={ChangePassword} />
            </div>
        </div>
    </Fragment>
);

export default PreAuthLayout;
