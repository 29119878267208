export const checkValidation = (inputValues, validation, field) => {
    let errors = validation;

    // email validation
    if (field === 'email_id') {
        const emailRegex = /^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (inputValues[field]?.length > 0) {
            if (!emailRegex.test(inputValues[field])) {
                errors[field] = "Please input valid email id";
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    // mobile number validation
    if (field === 'mobile_number') {
        const mobileRegex = /^[6-9][0-9]{9}$/;
        if (inputValues[field]?.length > 0) {
            if (!mobileRegex.test(inputValues[field])) {
                errors[field] = "Please input valid mobile number";
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    // only character validation
    if (field === 'name' || field === 'past_experience') {
        const onlyCharRegex = /^[A-Za-z][A-Za-z ]*$/;
        if (inputValues[field]?.length > 0) {
            if (!onlyCharRegex.test(inputValues[field])) {
                errors[field] = `Please input valid ${field === 'name' ? 'name' : 'past experiance'}`;
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    //Alphanumeric validation
    if (field === 'emp_id') {
        const alphaNumericRegex = /^[a-zA-Z0-9][a-zA-Z0-9_]*$/;
        if (inputValues[field]?.length > 0) {
            if (!alphaNumericRegex.test(inputValues[field])) {
                errors[field] = "Please input valid employee id";
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    //Aadhaar Validation
    if (field === 'adhar_no') {
        const aadhaarRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
        if (inputValues[field]?.length > 0) {
            if (!aadhaarRegex.test(inputValues[field])) {
                errors[field] = "Please input a validadhaar number";
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    //Pan Validation
    if (field === 'pan_no') {
        const panRegex = /[A-Z]{3}[PCG]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}/;
        if (inputValues[field]?.length > 0) {
            if (!panRegex.test(inputValues[field])) {
                errors[field] = "Please input valid pan number";
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    //Pincode Validation
    if (field === 'pincode') {
        const pincodeRegex = /[1-9][0-9]{5}/;
        if (inputValues[field]?.length > 0) {
            if (!pincodeRegex.test(inputValues[field])) {
                errors[field] = "Please input valid Pincode";
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    // Decimal Validation 
    if (field === 'year_exp' || field === 'last_gross_ctc' || field === 'current_gross_ctc') {
        const decimalRegex = /^(\d{1,5}|\d{0,5}\.\d{1,2})$/;
        if (inputValues[field]?.length > 0) {
            if (!decimalRegex.test(inputValues[field])) {
                errors[field] = "Please input valid entry";
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    // Sales Validation 
    if (field === 'gross_sales') {
        const slesRegex = /[1-9][0-9,.]*$/;
        if (inputValues[field]?.length > 0) {
            if (!slesRegex.test(inputValues[field])) {
                errors[field] = "Please input valid entry";
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    //Bank Name Validation
    if (field === 'bank_name') {
        let name
        if (field === 'bank_name') {
            name = 'bank name'
        } else {
            name = 'branch'
        }
        const bankNameRegex = /^[A-Za-z][A-Za-z,.& ()]*$/;
        if (inputValues[field]?.length > 0) {
            if (!bankNameRegex.test(inputValues[field])) {
                errors[field] = `Please input valid ${name}`;
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    //Account Number Validation
    if (field === 'accountNumber') {
        const accNumberRegex = /^[0-9]{6,40}$/;
        if (inputValues[field]?.length > 0) {
            if (!accNumberRegex.test(inputValues[field])) {
                errors[field] = "Please input a valid account number";
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    //IFSC code Validation
    if (field === 'bank_ifsc') {
        const ifscCodeRegex = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;
        if (inputValues[field]?.length > 0) {
            if (!ifscCodeRegex.test(inputValues[field])) {
                errors[field] = "Please input a valid ifsc code";
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    //ESI number validation
    if (field === 'esi_no') {
        const esiNumberRegex = /^[0-9]{17}$/;
        if (inputValues[field]?.length > 0) {
            if (!esiNumberRegex.test(inputValues[field])) {
                errors[field] = "Please input a valid esi number";
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    // PF number validation
    if (field === 'pf_no') {
        const pfNumberRegex = /^[A-Z]{2}[\s\/]?[A-Z]{3}[\s\/]?[0-9]{7}[\s\/]?[0-9]{3}[\s\/]?[0-9]{7}$/;
        if (inputValues[field]?.length > 0) {
            if (!pfNumberRegex.test(inputValues[field])) {
                errors[field] = "Please input a valid pf number";
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    // Address validation
    if (field === 'address' || field === 'previous_companies' || field === 'branch_address' || field === 'education'
        || field === 'company' || field === 'remarks' || field === 'last_city' || field === 'branch_name') {
        const addressRegex = /./;
        if (inputValues[field]?.length > 0) {
            if (!addressRegex.test(inputValues[field])) {
                errors[field] = "Please provide a valid entry";
            } else {
                errors[field] = "";
            }
        } else {
            errors[field] = "";
        }
    }

    //password validation
    // const cond1 = "/^(?=.*[a-z]).{6,20}$/";
    // const cond2 = "/^(?=.*[A-Z]).{6,20}$/";
    // const cond3 = "/^(?=.*[0-9]).{6,20}$/";
    // const password = inputValues.password;
    // if (!password) {
    //     errors.password = "password is required";
    // } else if (password.length < 6) {
    //     errors.password = "Password must be longer than 6 characters";
    // } else if (password.length >= 20) {
    //     errors.password = "Password must shorter than 20 characters";
    // } else if (!password.match(cond1)) {
    //     errors.password = "Password must contain at least one lowercase";
    // } else if (!password.match(cond2)) {
    //     errors.password = "Password must contain at least one capital letter";
    // } else if (!password.match(cond3)) {
    //     errors.password = "Password must contain at least a number";
    // } else {
    //     errors.password = "";
    // }

    // //matchPassword validation
    // if (!inputValues.confirmPassword) {
    //     errors.confirmPassword = "Password confirmation is required";
    // } else if (inputValues.confirmPassword !== inputValues.Password) {
    //     errors.confirmPassword = "Password does not match confirmation password";
    // } else {
    //     errors.password = "";
    // }

    return errors
};
