import React from 'react';
import '../../global.css';

const SecondaryButton = (props) => {
    return (
        <div>
            <button className={`customSecondaryButton ${props.customClsName}`}
                onClick={props.onClick}>
                {props.title}
            </button>
        </div>
    )
}

export default SecondaryButton