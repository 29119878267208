import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    storeStatus: true,
    selectedFyYear: '',
    storeClass: '',
    storeId: '',
    storeDetailData: [],
    viewStoreSelectedTabLabel: 'Owner Details',
    region: '',
    channel: '',
    brandName: '',
    storeOpeningStatus: '',
    storeClassification: '',
    storeBusinessModel: '',
    selectedTab: 0,
    selectedPage: 1,
    cityTierValue: '',
    tableFilterValue: {},
    brandStoreRegionWiseData: [],
    stepActiveState: 0,
    stepProgress: 0,
    createStoreOwnerDetails: {},
    createStoreDetails: {},
    storeCreatedBy: '',
    displayMasterData: false,
    isProfileCompleted: false,
    isClikedProfileCompleted: false,
    backActiveStep: false,
};

const storeManagementReducerSlice = createSlice({
    name: 'storeManagement',
    initialState,
    reducers: {
        setStoreStatus: (state, action) => {
            state.storeStatus = action.payload.type
        },
        setSelectedFyYear: (state, action) => {
            state.selectedFyYear = action.payload.type
        },
        setStoreClass: (state, action) => {
            state.storeClass = action.payload.type
        },
        setStoreId: (state, action) => {
            state.storeId = action.payload.type
        },
        setStoreDetailData: (state, action) => {
            state.storeDetailData = action.payload.type
        },
        setViewStoreSelectedTabLabel: (state, action) => {
            state.viewStoreSelectedTabLabel = action.payload.type
        },
        setRegion: (state, action) => {
            state.region = action.payload.type
        },
        setChannel: (state, action) => {
            state.channel = action.payload.type
        },
        setBrandName: (state, action) => {
            state.brandName = action.payload.type
        },
        setStoreOpeningStatus: (state, action) => {
            state.storeOpeningStatus = action.payload.type
        },
        setStoreClassification: (state, action) => {
            state.storeClassification = action.payload.type
        },
        setStoreBusinessModel: (state, action) => {
            state.storeBusinessModel = action.payload.type
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload.type
        },
        setSelectedPage: (state, action) => {
            state.selectedPage = action.payload.type
        },
        setCityTierValue: (state, action) => {
            state.cityTierValue = action.payload.type
        },
        setTableFilterValue: (state, action) => {
            state.tableFilterValue = action.payload.type
        },
        setBrandStoreRegionWiseData: (state, action) => {
            state.brandStoreRegionWiseData = action.payload.type
        },
        setStepActiveState: (state, action) => {
            state.stepActiveState = action.payload.type
        },
        setStepProgress: (state, action) => {
            state.stepProgress = action.payload.type
        },
        setCreateStoreOwnerDetails: (state, action) => {
            state.createStoreOwnerDetails = action.payload.type
        },
        setCreateStoreDetails: (state, action) => {
            state.createStoreDetails = action.payload.type
        },
        setStoreCreatedBy: (state, action) => {
            state.storeCreatedBy = action.payload.type
        },
        setDisplayMasterData: (state, action) => {
            state.displayMasterData = action.payload.type
        },
        setIsProfileCompleted: (state, action) => {
            state.isProfileCompleted = action.payload.type
        },
        setIsClikedProfileCompleted: (state, action) => {
            state.isClikedProfileCompleted = action.payload.type
        },
        setBackActiveStep: (state, action) => {
            state.backActiveStep = action.payload.type
        },
    }
});

export const storeManagementActions = storeManagementReducerSlice.actions;
export default storeManagementReducerSlice;
