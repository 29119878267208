import React, { useState, useEffect, useRef } from 'react';
import CustomTabs from '../../../ReUsableComponent/CustomTabs';
import { ViewBrandTabs } from './ViewBrandTabList';
import '../../StoreManagement/ViewStoreDetails/ViewStore.css';
import Loader from '../../../ReUsableComponent/Loader';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { brandManagementActions } from '../../../store/reducers/BrandManagementReducer';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import EditIcon from '../../../assets/svg/edit-icon.svg';
import PlusIcon from '../../../assets/svg/plus_icon.svg';
import StatusTag from '../../../ReUsableComponent/StatusTag';
import BrandImage from '../../../assets/png/hrxLogo.png';
import './ViewBrandDetails.css';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import InputTextField from '../../../ReUsableComponent/InputTextField';
import CustomAutoComplete from '../../../ReUsableComponent/CustomAutocomplete';
import CustomDatePicker from '../../../ReUsableComponent/DatePickerComponent';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import MultiSelectCheckBox from '../../../ReUsableComponent/MultiSelectCheckBox';
import CustomDropzone from '../../../ReUsableComponent/CustomDropzone';
import { ToastErrorService, ToastSuccessService } from './../../../ReUsableComponent/ToastNotification/ToastService';
import ImportIcon from '../../../assets/svg/uploadIconForDoc.svg';
import ExcellIcon from '../../../assets/svg/pdf.svg';
import pdfIcon from '../../../assets/svg/pdf.svg';
import DocIcon from '../../../assets/svg/word-file-type-svgrepo-com.svg';
import PngIcon from '../../../assets/svg/png.svg';
import JpegIcon from '../../../assets/svg/jpeg.svg';

import { getCreateBrandDropdownList, getListStateCityByPincode, fileUpload, updateBrandDetailsBasedOnBrandId } from './../BrandManagement.service';
import { validateTextInputAny, validateTextInput, validateEmailInput, validateNumberInput, validatePinCodeInput, validatePANInput, validateGSTINInput, validateArray, validateAccountNumberInput, validateIFSCCode, validateTextAddressInput, validateFreeTextInputAmpresent, isFileDetailsEmpty, formatDateToTextMonthDDYYYY, allMandatoryFieldsFilledBrand } from './../../../ReUsableComponent/CustomFormValidation';
import { CustomErrorMsg } from './../../../ReUsableComponent/CustomErrorMsg';
import ProgressBarForFileUpload from './../../../ReUsableComponent/ProgressBarForFileUpload';

import SuccessPopup from './../../../ReUsableComponent/SuccessPopupMsg';

import TickIconWithGreenBg from '../../../assets/svg/noun_tick_684585.svg';
import CustomModal from '../../../ReUsableComponent/CustomModal';
import CloseIcon from '../../../assets/svg/closeIconofmodal.svg';
import FileRenderer from '../../../ReUsableComponent/FileRenderer';
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const ViewBrandDetails = () => {
    const timeoutRef = useRef(null);
    const zerothTabRef = useRef(null);
    const firstTabRef = useRef(null);
    const secondTabRef = useRef(null);
    const thirdTabRef = useRef(null);
    const forthTabRef = useRef(null);
    const fifthTabRef = useRef(null);
    const sixthTabRef = useRef(null);
    const seventhTabRef = useRef(null);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const history = useHistory();
    const dispatch = useDispatch();
    const brandDetailDataView = useSelector((state) => state.brandManagement.viewBrandDetails);
    const brandDetailData = useSelector((state) => state.brandManagement.viewBrandDetails);
    const brandSId = useSelector((state) => state.brandManagement.viewBrandDetails?._id);
    const brandBreadCrumbLabel = useSelector((state) => state.brandManagement.viewBrandSelectedTabLabel);
    const editFormVisible = useSelector((state) => state.brandManagement.isEditFormVisible);

    const STATUS = [{ label: 'Active', value: true, name: 'status' }, { label: 'Inactive', value: false, name: 'status' }];
    const YESNOSTATUS = [{ label: 'Yes', value: true, name: 'security' }, { label: 'No', value: false, name: 'security' }];
    const getStatus = STATUS.find(option => option.value === brandDetailData?.status);
    const getYesNoStatus = YESNOSTATUS.find(option => option.value === brandDetailData?.security);
    const [selectedStatus, setSelectedStatus] = useState(getStatus || null);
    const [selectedSecurity, setSelectedSecurity] = useState(getYesNoStatus || null);
    const [inputStatus, setInputStatus] = useState('');
    const [inputStatusYesNo, setInputStatusYesNo] = useState('');

    const [channelOfflineStatus, setChannelOfflineStatus] = useState(true);
    const [channelOnlineStatus, setChannelOnlineStatus] = useState(true);

    const [selectedTab, setSelectedTab] = useState(useSelector((state) => state.brandManagement.selectedTab));
    const [loading, setLoading] = useState(false);
    const [isEditFormVisible, setIsEditFormVisible] = useState(editFormVisible);

    const [dropService, setDropService] = useState('brand');
    const [dropdownKey, setDropdownKey] = useState('');

    const [productTypeMultiSlectData, setProductTypeMultiSlectData] = useState([]);
    const [bussinessModelMultiSlectData, setBussinessModelMultiSlectData] = useState([]);
    const [channelServedMultiSlectData, setChannelServedMultiSlectData] = useState([]);
    const [channelOnlineMultiSlectData, setChannelOnlineMultiSlectData] = useState([]);
    const [channelOfflineMultiSlectData, setChannelOfflineMultiSlectData] = useState([]);

    const [brandHandleFormError, setBrandHandleFormError] = useState('');
    const [brandOwnerDetailsFormError, setBrandOwnerDetailsFormError] = useState('');
    const [brandDetailsFormError, setBrandDetailsFormError] = useState('');
    const [contactDetailsFormError, setContactDetailsFormError] = useState('');
    const [channelDetailsFormError, setChannelDetailsFormError] = useState('');
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [addressFormError, setAddressFormError] = useState({
        registered_office_address: {},
        warehouse_address: {},
        return_warehouse_address: {},
        billing_address: {},
    });
    const [companyContactDetailsFormError, setCompanyContactDetailsFormError] = useState('');
    const [documentUrlsFormError, setDocumentUrlsFormError] = useState('');

    const [branchCity, setBranchCity] = useState();
    const [branchState, setBranchState] = useState();

    const [storeFacadeFile, setStoreFacadeFile] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);


    const [panDocumentFile, setPanDocumentFile] = useState(
        {
            file_id: brandDetailData?.document_details && brandDetailData?.document_details[0]?.file_id ? brandDetailData?.document_details[0]?.file_id : '',
            doc_name: brandDetailData?.document_details && brandDetailData?.document_details[0]?.doc_name ? brandDetailData?.document_details[0]?.doc_name : '',
            doc_url: brandDetailData?.document_details && brandDetailData?.document_details[0]?.doc_url ? brandDetailData?.document_details[0]?.doc_url : '',
            size: brandDetailData?.document_details && brandDetailData?.document_details[0]?.size ? brandDetailData?.document_details[0]?.size : '',
            created_at: brandDetailData?.document_details && brandDetailData?.document_details[0]?.created_at ? brandDetailData?.document_details[0]?.created_at : '',
            doc_type: brandDetailData?.document_details && brandDetailData?.document_details[0]?.doc_type ? brandDetailData?.document_details[0]?.doc_type : '',
        }
    );
    const [gstDocumentFile, setGstDocumentFile] = useState(
        {
            file_id: brandDetailData?.document_details && brandDetailData?.document_details[1]?.file_id ? brandDetailData?.document_details[1]?.file_id : '',
            doc_name: brandDetailData?.document_details && brandDetailData?.document_details[1]?.doc_name ? brandDetailData?.document_details[1]?.doc_name : '',
            doc_url: brandDetailData?.document_details && brandDetailData?.document_details[1]?.doc_url ? brandDetailData?.document_details[1]?.doc_url : '',
            size: brandDetailData?.document_details && brandDetailData?.document_details[1]?.size ? brandDetailData?.document_details[1]?.size : '',
            created_at: brandDetailData?.document_details && brandDetailData?.document_details[1]?.created_at ? brandDetailData?.document_details[1]?.created_at : '',
            doc_type: brandDetailData?.document_details && brandDetailData?.document_details[1]?.doc_type ? brandDetailData?.document_details[1]?.doc_type : '',
        }
    );
    const [agreementDocumentFile, setAgreementDocumentFile] = useState(
        {
            file_id: brandDetailData?.document_details && brandDetailData?.document_details[2]?.file_id ? brandDetailData?.document_details[2]?.file_id : '',
            doc_name: brandDetailData?.document_details && brandDetailData?.document_details[2]?.doc_name ? brandDetailData?.document_details[2]?.doc_name : '',
            doc_url: brandDetailData?.document_details && brandDetailData?.document_details[2]?.doc_url ? brandDetailData?.document_details[2]?.doc_url : '',
            size: brandDetailData?.document_details && brandDetailData?.document_details[2]?.size ? brandDetailData?.document_details[2]?.size : '',
            created_at: brandDetailData?.document_details && brandDetailData?.document_details[2]?.created_at ? brandDetailData?.document_details[2]?.created_at : '',
            doc_type: brandDetailData?.document_details && brandDetailData?.document_details[2]?.doc_type ? brandDetailData?.document_details[2]?.doc_type : '',
        }
    );
    const [uploadBrandFileLogo, setuploadBrandFileLogo] = useState(
        {
            file_id: brandDetailData?.document_details && brandDetailData?.document_details[3]?.file_id ? brandDetailData?.document_details[3]?.file_id : '',
            doc_name: brandDetailData?.document_details && brandDetailData?.document_details[3]?.doc_name ? brandDetailData?.document_details[3]?.doc_name : '',
            doc_url: brandDetailData?.document_details && brandDetailData?.document_details[3]?.doc_url ? brandDetailData?.document_details[3]?.doc_url : '',
            size: brandDetailData?.document_details && brandDetailData?.document_details[3]?.size ? brandDetailData?.document_details[3]?.size : '',
            created_at: brandDetailData?.document_details && brandDetailData?.document_details[3]?.created_at ? brandDetailData?.document_details[3]?.created_at : '',
            doc_type: brandDetailData?.document_details && brandDetailData?.document_details[3]?.doc_type ? brandDetailData?.document_details[3]?.doc_type : '',
        }
    );

    const [selectedBrandFloorSpaceFile, setSelectedBrandFloorSpaceFile] = useState(
        {
            file_id: brandDetailData?.document_details && brandDetailData?.document_details[4]?.file_id ? brandDetailData?.document_details[4]?.file_id : '',
            doc_name: brandDetailData?.document_details && brandDetailData?.document_details[4]?.doc_name ? brandDetailData?.document_details[4]?.doc_name : '',
            doc_url: brandDetailData?.document_details && brandDetailData?.document_details[4]?.doc_url ? brandDetailData?.document_details[4]?.doc_url : '',
            size: brandDetailData?.document_details && brandDetailData?.document_details[4]?.size ? brandDetailData?.document_details[4]?.size : '',
            created_at: brandDetailData?.document_details && brandDetailData?.document_details[4]?.created_at ? brandDetailData?.document_details[4]?.created_at : '',
            doc_type: brandDetailData?.document_details && brandDetailData?.document_details[4]?.doc_type ? brandDetailData?.document_details[4]?.doc_type : '',
        }
    );
    const [selectedAdjacentFile, setSelectedAdjacentFile] = useState(
        {
            file_id: brandDetailData?.document_details && brandDetailData?.document_details[5]?.file_id ? brandDetailData?.document_details[5]?.file_id : '',
            doc_name: brandDetailData?.document_details && brandDetailData?.document_details[5]?.doc_name ? brandDetailData?.document_details[5]?.doc_name : '',
            doc_url: brandDetailData?.document_details && brandDetailData?.document_details[5]?.doc_url ? brandDetailData?.document_details[5]?.doc_url : '',
            size: brandDetailData?.document_details && brandDetailData?.document_details[5]?.size ? brandDetailData?.document_details[5]?.size : '',
            created_at: brandDetailData?.document_details && brandDetailData?.document_details[5]?.created_at ? brandDetailData?.document_details[5]?.created_at : '',
            doc_type: brandDetailData?.document_details && brandDetailData?.document_details[5]?.doc_type ? brandDetailData?.document_details[5]?.doc_type : '',
        }
    );
    const [selectedOtherFile, setSelectedOtherFile] = useState(
        {
            file_id: brandDetailData?.document_details && brandDetailData?.document_details[6]?.file_id ? brandDetailData?.document_details[6]?.file_id : '',
            doc_name: brandDetailData?.document_details && brandDetailData?.document_details[6]?.doc_name ? brandDetailData?.document_details[6]?.doc_name : '',
            doc_url: brandDetailData?.document_details && brandDetailData?.document_details[6]?.doc_url ? brandDetailData?.document_details[6]?.doc_url : '',
            size: brandDetailData?.document_details && brandDetailData?.document_details[6]?.size ? brandDetailData?.document_details[6]?.size : '',
            created_at: brandDetailData?.document_details && brandDetailData?.document_details[6]?.created_at ? brandDetailData?.document_details[6]?.created_at : '',
            doc_type: brandDetailData?.document_details && brandDetailData?.document_details[6]?.doc_type ? brandDetailData?.document_details[6]?.doc_type : '',
        }
    );

    const [fileUploadprogress, setFileUploadProgress] = React.useState(0);
    const [panDocumentProgress, setPanDocumentProgress] = React.useState(0);
    const [gstDocumentProgress, setGstDocumentProgress] = React.useState(0);
    const [agreementDocumentProgress, setAgreementDocumentProgress] = React.useState(0);

    const [brandLogoProgress, setBrandLogoProgress] = React.useState(0);
    const [brandFloorSpaceProgress, setbrandFloorSpaceProgress] = React.useState(0);
    const [otherProgress, setotherProgress] = React.useState(0);

    const [panDocumentUploadSpeed, setPanDocumentUploadSpeed] = useState(null);
    const [gstDocumentUploadSpeed, setGstDocumentUploadSpeed] = useState(null);
    const [agreementDocumentUploadSpeed, setAgreementDocumentUploadSpeed] = useState(null);

    const [uploadSpeed, setUploadSpeed] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [storeFacadeUploadSpeed, setStoreFacadeUploadSpeed] = useState(null);
    const [brandFloorSpaceUploadSpeed, setBrandFloorSpaceUploadSpeed] = useState(null);
    const [adjacentProgress, setadjacentProgress] = React.useState(0);
    const [adjacentUploadSpeed, setAdjacentUploadSpeed] = useState(null);
    const [otherUploadSpeed, setOtherUploadSpeed] = useState(null);
    const [brandFloorSpaceFile, setBrandFloorSpaceFile] = useState("");
    const [adjacentFile, setAdjacentFile] = useState("");
    const [otherFile, setOtherFile] = useState("");
    const [uploadedFile, setUploadedFile] = useState([]);

    const [panDocumentFileTypeIcon, setPanDocumentFileTypeIcon] = useState(brandDetailData?.document_details && ((getFileType(brandDetailData?.document_details[0]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(brandDetailData?.document_details[0]?.doc_name) === 'png') ? PngIcon : JpegIcon)));
    const [gstDocumentFileTypeIcon, setGstDocumentFileTypeIcon] = useState(brandDetailData?.document_details && ((getFileType(brandDetailData?.document_details[1]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(brandDetailData?.document_details[1]?.doc_name) === 'png') ? PngIcon : JpegIcon)));
    const [agreementDocumentFileTypeIcon, setAgreementDocumentFileTypeIcon] = useState(brandDetailData?.document_details && ((getFileType(brandDetailData?.document_details[2]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(brandDetailData?.document_details[2]?.doc_name) === 'png') ? PngIcon : JpegIcon)));
    const [brandLogoFileTypeIcon, setBrandLogoFileTypeIcon] = useState(brandDetailData?.document_details && ((getFileType(brandDetailData?.document_details[3]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(brandDetailData?.document_details[3]?.doc_name) === 'png') ? PngIcon : JpegIcon)));
    const [brandFloorSpaceFileTypeIcon, setBrandFloorSpaceFileTypeIcon] = useState(brandDetailData?.document_details && ((getFileType(brandDetailData?.document_details[4]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(brandDetailData?.document_details[4]?.doc_name) === 'png') ? PngIcon : JpegIcon)));
    const [adjacentFileTypeIcon, setAdjacentFileTypeIcon] = useState(brandDetailData?.document_details && ((getFileType(brandDetailData?.document_details[5]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(brandDetailData?.document_details[5]?.doc_name) === 'png') ? PngIcon : JpegIcon)));
    const [otherFileTypeIcon, setOtherFileTypeIcon] = useState(brandDetailData?.document_details && ((getFileType(brandDetailData?.document_details[6]?.doc_name) === 'pdf') ? ExcellIcon : ((getFileType(brandDetailData?.document_details[6]?.doc_name) === 'png') ? PngIcon : JpegIcon)));

    const [brandLogoImage, setBrandLogoImage] = useState(brandDetailData?.brand_logo ? brandDetailData?.brand_logo : '',);

    const [brandOwnerDetails, setBrandOwnerDetails] = useState({
        person_name: brandDetailData?.owner_contact?.person_name ? brandDetailData?.owner_contact?.person_name : '',
        mobile: brandDetailData?.owner_contact?.mobile ? brandDetailData?.owner_contact?.mobile : '',
        email: brandDetailData?.owner_contact?.email ? brandDetailData?.owner_contact?.email : '',
    });
    const [brandDetails, setBrandDetails] = useState({
        brand_name: brandDetailData?.brand_name ? brandDetailData?.brand_name : '',
        status: brandDetailData?.status ? brandDetailData?.status : false,
        brand_deck: brandDetailData?.brand_deck ? brandDetailData?.brand_deck : '',
        email_id: brandDetailData?.email_id ? brandDetailData?.email_id : '',
        brand_mobile: brandDetailData?.brand_mobile ? brandDetailData?.brand_mobile : '',
        product_type: brandDetailData?.product_type ? brandDetailData?.product_type : [],
        business_model: brandDetailData?.business_model ? brandDetailData?.business_model : [],
        pan: brandDetailData?.pan ? brandDetailData?.pan : '',
        gstin: brandDetailData?.gstin ? brandDetailData?.gstin : '',
        security: brandDetailData?.security ? brandDetailData?.security : false,
        security_comment: brandDetailData?.security_comment ? brandDetailData?.security_comment : '',
        account_name: brandDetailData?.bank_detail?.account_name ? brandDetailData?.bank_detail?.account_name : '',
        bank_name: brandDetailData?.bank_detail?.bank_name ? brandDetailData?.bank_detail?.bank_name : '',
        account_number: brandDetailData?.bank_detail?.bank_no ? brandDetailData?.bank_detail?.bank_no : '',
        ifsc_code: brandDetailData?.bank_detail?.ifsc_code ? brandDetailData?.bank_detail?.ifsc_code : '',
        branch: brandDetailData?.bank_detail?.branch ? brandDetailData?.bank_detail?.branch : '',
        branch_address: brandDetailData?.bank_detail?.branch_address ? brandDetailData?.bank_detail?.branch_address : '',
        branch_pincode: brandDetailData?.bank_detail?.branch_pincode ? brandDetailData?.bank_detail?.branch_pincode : '',
        branch_city: brandDetailData?.bank_detail?.branch_city ? brandDetailData?.bank_detail?.branch_city : '',
        branch_state: brandDetailData?.bank_detail?.branch_state ? brandDetailData?.bank_detail?.branch_state : '',
    });


    // const [contactDetails, setContactDetails] = useState();
    // brandDetailData?.contact_person?.map((item, index) => {
    //     const obj = {
    //         person_dept: item?.person_dept ? item?.person_dept : '',
    //         person_name: item?.person_name ? item?.person_name : '',
    //         person_mobile: item?.person_mobile ? item?.person_mobile : '',
    //         email: item?.email ? item?.email : '',
    //         person_other: item?.person_other ? item?.person_other : '',
    //     }
    //     setContactDetails([obj])
    // })

    const [contactDetails, setContactDetails] = useState([
        // {
        //     person_dept: brandDetailData?.contact_person?.person_dept ? brandDetailData?.contact_person?.person_dept : '',
        //     person_name: brandDetailData?.contact_person?.person_name ? brandDetailData?.contact_person?.person_name : '',
        //     person_mobile: brandDetailData?.contact_person?.mobile ? brandDetailData?.contact_person?.mobile : '',
        //     email: brandDetailData?.contact_person?.email ? brandDetailData?.contact_person?.email : '',
        //     person_other: brandDetailData?.contact_person?.person_other ? brandDetailData?.contact_person?.person_other : '',
        // }
    ]);
    const [channelDetails, setChannelDetails] = useState({
        channel_served: brandDetailData?.channel_served ? brandDetailData?.channel_served : [],
        channel_offline: brandDetailData?.channel_offline ? brandDetailData?.channel_offline : [],
        channel_online: brandDetailData?.channel_online ? brandDetailData?.channel_online : [],
    });

    const [address, setAddress] = useState({
        registered_office_address: {
            address: brandDetailData?.registered_office_address?.address ? brandDetailData?.registered_office_address?.address : '',
            region: brandDetailData?.registered_office_address?.region ? brandDetailData?.registered_office_address?.region : '',
            state: brandDetailData?.registered_office_address?.state ? brandDetailData?.registered_office_address?.state : '',
            city: brandDetailData?.registered_office_address?.city ? brandDetailData?.registered_office_address?.city : '',
            area: brandDetailData?.registered_office_address?.area ? brandDetailData?.registered_office_address?.area : '',
            pincode: brandDetailData?.registered_office_address?.pincode ? brandDetailData?.registered_office_address?.pincode : '',
            email: brandDetailData?.registered_office_contact?.email ? brandDetailData?.registered_office_contact?.email : '',
        },
        warehouse_address: {
            address: brandDetailData?.warehouse_address?.address ? brandDetailData?.warehouse_address?.address : '',
            region: brandDetailData?.warehouse_address?.region ? brandDetailData?.warehouse_address?.region : '',
            state: brandDetailData?.warehouse_address?.state ? brandDetailData?.warehouse_address?.state : '',
            city: brandDetailData?.warehouse_address?.city ? brandDetailData?.warehouse_address?.city : '',
            area: brandDetailData?.warehouse_address?.area ? brandDetailData?.warehouse_address?.area : '',
            pincode: brandDetailData?.warehouse_address?.pincode ? brandDetailData?.warehouse_address?.pincode : '',
            email: brandDetailData?.warehouse_contact?.email ? brandDetailData?.warehouse_contact?.email : '',
            mobile: brandDetailData?.warehouse_contact?.mobile ? brandDetailData?.warehouse_contact?.mobile : '',
            person_mobile: brandDetailData?.warehouse_contact?.person_mobile ? brandDetailData?.warehouse_contact?.person_mobile : '',
        },
        return_warehouse_address: {
            address: brandDetailData?.return_warehouse_address?.address ? brandDetailData?.return_warehouse_address?.address : '',
            region: brandDetailData?.return_warehouse_address?.region ? brandDetailData?.return_warehouse_address?.region : '',
            state: brandDetailData?.return_warehouse_address?.state ? brandDetailData?.return_warehouse_address?.state : '',
            city: brandDetailData?.return_warehouse_address?.city ? brandDetailData?.return_warehouse_address?.city : '',
            area: brandDetailData?.return_warehouse_address?.area ? brandDetailData?.return_warehouse_address?.area : '',
            pincode: brandDetailData?.return_warehouse_address?.pincode ? brandDetailData?.return_warehouse_address?.pincode : '',
            email: brandDetailData?.return_warehouse_contact?.email ? brandDetailData?.return_warehouse_contact?.email : '',
            mobile: brandDetailData?.return_warehouse_contact?.mobile ? brandDetailData?.return_warehouse_contact?.mobile : '',
            person_mobile: brandDetailData?.return_warehouse_contact?.person_mobile ? brandDetailData?.return_warehouse_contact?.person_mobile : '',
        },
        billing_address: {
            address: brandDetailData?.billing_address?.address ? brandDetailData?.billing_address?.address : '',
            region: brandDetailData?.billing_address?.region ? brandDetailData?.billing_address?.region : '',
            state: brandDetailData?.billing_address?.state ? brandDetailData?.billing_address?.state : '',
            city: brandDetailData?.billing_address?.city ? brandDetailData?.billing_address?.city : '',
            area: brandDetailData?.billing_address?.area ? brandDetailData?.billing_address?.area : '',
            pincode: brandDetailData?.billing_address?.pincode ? brandDetailData?.billing_address?.pincode : '',
            email: brandDetailData?.billing_contact?.email ? brandDetailData?.billing_contact?.email : '',
            mobile: brandDetailData?.billing_contact?.mobile ? brandDetailData?.billing_contact?.mobile : '',
            person_mobile: brandDetailData?.billing_contact?.person_mobile ? brandDetailData?.billing_contact?.person_mobile : '',
        }
    });
    const [companyContactDetails, setCompanyContactDetails] = useState({
        contacts_name: brandDetailData?.company_contact_detail?.person_name ? brandDetailData?.company_contact_detail?.person_name : '',
        contacts_mobile: brandDetailData?.company_contact_detail?.mobile ? brandDetailData?.company_contact_detail?.mobile : '',
        contacts_email_id: brandDetailData?.company_contact_detail?.email ? brandDetailData?.company_contact_detail?.email : '',
        contacts_person_dept: brandDetailData?.company_contact_detail?.person_dept ? brandDetailData?.company_contact_detail?.person_dept : '',
        account_name: brandDetailData?.company_bank_detail?.account_name ? brandDetailData?.company_bank_detail?.account_name : '',
        bank_name: brandDetailData?.company_bank_detail?.bank_name ? brandDetailData?.company_bank_detail?.bank_name : '',
        account_number: brandDetailData?.company_bank_detail?.bank_no ? brandDetailData?.company_bank_detail?.bank_no : '',
        ifsc_code: brandDetailData?.company_bank_detail?.ifsc_code ? brandDetailData?.company_bank_detail?.ifsc_code : '',
        branch: brandDetailData?.company_bank_detail?.branch ? brandDetailData?.company_bank_detail?.branch : '',
        branch_address: brandDetailData?.company_bank_detail?.branch_address ? brandDetailData?.company_bank_detail?.branch_address : '',
        branch_pincode: brandDetailData?.company_bank_detail?.branch_pincode ? brandDetailData?.company_bank_detail?.branch_pincode : '',
        branch_city: brandDetailData?.company_bank_detail?.branch_city ? brandDetailData?.company_bank_detail?.branch_city : '',
        branch_state: brandDetailData?.company_bank_detail?.branch_state ? brandDetailData?.company_bank_detail?.branch_state : '',
    });
    // const [documentUrls, setDocumentUrls] = useState(
    //     brandDetailData?.document_details ? brandDetailData?.document_details : []
    // );

    const [openFileViewerModal, setOpenFileViewerModal] = useState(false);
    const [viewFile, setViewFile] = useState([]);

    const [docErrors, setDocErrors] = useState({});

    const handleInputOutputChange = (event, newInputValue) => {
        if (newInputValue?.name === "status") {
            setInputStatus(newInputValue);
        } else {
            setInputStatusYesNo(newInputValue);
        }
    }

    // const isFileDetailsEmpty = (obj) => {
    //     return (
    //         obj?.file_id === '' &&
    //         obj?.doc_name === '' &&
    //         obj?.doc_url === '' &&
    //         obj?.size === '' &&
    //         obj?.doc_type === ''
    //     );
    // }

    const addNewContactDetailsForm = () => {
        const newContact = {
            person_dept: brandDetailData?.contact_person?.person_dept ? brandDetailData?.contact_person?.person_dept : '',
            person_name: brandDetailData?.contact_person?.person_name ? brandDetailData?.contact_person?.person_name : '',
            person_mobile: brandDetailData?.contact_person?.person_mobile ? brandDetailData?.contact_person?.person_mobile : '',
            email: brandDetailData?.contact_person?.email ? brandDetailData?.contact_person?.email : '',
            person_other: brandDetailData?.contact_person?.person_other ? brandDetailData?.contact_person?.person_other : '',
        };

        if (contactDetails?.length <= 4) {
            setContactDetails([...contactDetails, newContact]);
        }
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        ViewBrandTabs.forEach((tab, id) => {
            if (id === newValue) {
                dispatch(brandManagementActions.setViewBrandSelectedTabLabel({ type: tab.label }));
            }
        })
        dispatch(brandManagementActions.setSelectedTab({ type: newValue }));
        renderTabData(newValue, brandDetailData);
        if (newValue === 6) {
            handleUpdateBrandDetailsBasedOnBrandId('', 6);
        }
    };

    const convertDate = (openingDate) => {
        let date = new Date(openingDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return dateString
    }

    const onClickViewMoreBrands = () => {
        console.log('View More Clicked')
    }

    const onClickEditDetails = () => {
        dispatch(brandManagementActions.setSelectedTab({ type: selectedTab }));
        dispatch(brandManagementActions.setIsEditFormVisible({ type: false }));
        // dispatch(brandManagementActions.setViewBrandDetails({ type: brandDetailDataView }));
        // getBrandDetailById();
    }

    

    const onClickCancel = () => {
        dispatch(brandManagementActions.setIsEditFormVisible({ type: true }));
    }

    // const getBrandDetailById = () => {
    //     setLoading(true);
    //     let param = `?brand_id=${brandSId}`
    //     GetBrandList(param,USER_TOKEN).then(response => {
    //         if (response?.data?.statusCode === 200) {
    //             setLoading(false);
    //             console.log("setViewBrandDetails====>", response?.data?.data)
    //             dispatch(brandManagementActions.setViewBrandDetails({ type: response?.data?.data[0] }));
    //         }
    //     }).catch(error => {
    //         console.log(error);
    //         setLoading(false);
    //     }).finally(() => {
    //         setLoading(false);
    //     })
    // }

    const onChangeBrandOpeningDate = (event) => {
        let { date } = event
    }

    const onChangeCOF = (event, newValue) => {
        handleDropDownChange(newValue, setBrandDetails, validateTextInputAny, setBrandHandleFormError, CustomErrorMsg.name);
        if (newValue?.name === "status") {
            setSelectedStatus(newValue);
        } else {
            setSelectedSecurity(newValue);
        }
    }

    const handleDropDownChange = (newValue, setState, validationFunc, setErrorState, errorMsg) => {
        const { name, value } = newValue;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (validationFunc(value)) {
            setErrorState(prevErrors => ({ ...prevErrors, [name]: null }));
        } else {
            setErrorState(prevErrors => ({ ...prevErrors, [name]: errorMsg }));
        }
    }

    useEffect(() => {
        dispatch(userAuthActions.setActivePage({ type: '/brandManagement' }));
        dispatch(userAuthActions.setHeaderName({ type: 'Brand Management' }));
        const scrollToTop = () => {
            if (selectedTab === 0 && zerothTabRef.current) {
                zerothTabRef.current.scrollTop = 0;
            } else if (selectedTab === 1 && firstTabRef.current) {
                firstTabRef.current.scrollTop = 0;
            } else if (selectedTab === 2 && secondTabRef.current) {
                secondTabRef.current.scrollTop = 0;
            } else if (selectedTab === 3 && thirdTabRef.current) {
                thirdTabRef.current.scrollTop = 0;
            } else if (selectedTab === 4 && forthTabRef.current) {
                forthTabRef.current.scrollTop = 0;
            } else if (selectedTab === 5 && fifthTabRef.current) {
                fifthTabRef.current.scrollTop = 0;
            } else if (selectedTab === 6 && sixthTabRef.current) {
                sixthTabRef.current.scrollTop = 0;
            } else if (selectedTab === 7 && seventhTabRef.current) {
                seventhTabRef.current.scrollTop = 0;
            }
        };
        setTimeout(scrollToTop, 100);
    }, [selectedTab]);

    useEffect(() => {
        handleGetAllDropdownList();
        brandDetailData?.channel_served?.forEach(status => {
            if (status === 'Offline') {
                setChannelOfflineStatus(false);
            } else if (status === 'Online') {
                setChannelOnlineStatus(false);
            }
        });
    }, []);

    useEffect(() => {
        if (brandDetailData?.contact_person) {
            const processedContacts = brandDetailData?.contact_person?.map(person => ({
                person_dept: person?.person_dept || '',
                person_name: person?.person_name || '',
                person_mobile: person?.person_mobile || '',
                email: person?.email || '',
                person_other: person?.person_other || '',
            }));

            const nonEmptyContacts = processedContacts?.filter(contact =>
                Object.values(contact).some(value => value !== '')
            );

            if (nonEmptyContacts?.length === 0) {
                setContactDetails([{
                    person_dept: '',
                    person_name: '',
                    person_mobile: '',
                    email: '',
                    person_other: ''
                }]);
            } else {
                setContactDetails(nonEmptyContacts);
            }
        } else {
            setContactDetails([{
                person_dept: '',
                person_name: '',
                person_mobile: '',
                email: '',
                person_other: ''
            }]);
        }

    }, [brandDetailData]);

    // useEffect(() => {
    //     if (brandDetailData?.contact_person) {
    //         const newContactDetails = brandDetailData?.contact_person?.map(person => ({
    //             person_dept: person?.person_dept || '-',
    //             person_name: person?.person_name || '-',
    //             person_mobile: person?.person_mobile || '-',
    //             email: person?.email || '',
    //             person_other: person?.person_other || '-',
    //         }));
    //         setContactDetails(newContactDetails);
    //     }
    // }, [brandDetailData]);

    const handleChannelChange = (name, event) => {
        const { target: { value } } = event;
        if (name === "product_type" || name === "business_model") {
            setBrandDetails(prevState => ({
                ...prevState,
                [name]: value
            }))
        } else if (name === "channel_served" || name === "channel_offline" || name === "channel_online") {
            if (name === "channel_served") {
                setChannelOfflineStatus(true);
                setChannelOnlineStatus(true);
                value?.forEach(status => {
                    if (status === 'Offline') {
                        setChannelOfflineStatus(false);
                    } else if (status === 'Online') {
                        setChannelOnlineStatus(false);
                    }
                });
            }
            setChannelDetails(prevState => ({
                ...prevState,
                [name]: value
            }))
        }
    };

    const formatCategories = (categories) => {
        return categories.map(category => ({
            label: category,
            value: category
        }));
    };
    const handleGetAllDropdownList = () => {
        const param = `?service=${dropService}&dropdown_key=${dropdownKey}`
        getCreateBrandDropdownList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                const findDropdownByKey = (dropdownKey) => {
                    return response?.data?.data?.find(entry => entry.dropdown_key === dropdownKey);
                };
                response?.data?.data?.map((item) => {
                    if (item?.dropdown_key === "ProductType") {
                        setProductTypeMultiSlectData(formatCategories(item?.dropdown_values));
                    } else if (item?.dropdown_key === "BussinessModel") {
                        setBussinessModelMultiSlectData(formatCategories(item?.dropdown_values));
                    } else if (item?.dropdown_key === "ChannelServed") {
                        setChannelServedMultiSlectData(formatCategories(item?.dropdown_values));
                    } else if (item?.dropdown_key === "ChannelOnline") {
                        setChannelOnlineMultiSlectData(formatCategories(item?.dropdown_values));
                    } else if (item?.dropdown_key === "ChannelOffline") {
                        setChannelOfflineMultiSlectData(formatCategories(item?.dropdown_values));
                    }
                });
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            // setLoading(false);
        })
    }

    const handlePinCode = (name, value, companyContactDetailsBranchPincode) => {
        const param = `?pincode=${value}`
        getListStateCityByPincode(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {

                let stateData = response?.data?.data?.map((item) => ({
                    label: item?.state,
                    value: item?.state,
                    name: 'state'
                }));
                const uniqueStateData = Array.from(new Set(stateData.map(item => item.value))).map(value => {
                    return stateData.find(item => item.value === value);
                });

                let cityData = response?.data?.data?.map((item) => ({
                    label: item?.city,
                    value: item?.city,
                    name: 'city'
                }));
                const uniqueCityData = Array.from(new Set(cityData.map(item => item.value))).map(value => {
                    return cityData.find(item => item.value === value);
                });

                let regionData = response?.data?.data?.map((item) => ({
                    label: item?.region,
                    value: item?.region,
                    name: 'region'
                }));
                const uniqueRegionData = Array.from(new Set(regionData.map(item => item.value))).map(value => {
                    return regionData.find(item => item.value === value);
                });

                let areaData = response?.data?.data?.map((item) => ({
                    label: item?.area,
                    value: item?.area,
                    name: 'area'
                }));
                const uniqueAreaData = Array.from(new Set(areaData.map(item => item.value))).map(value => {
                    return areaData.find(item => item.value === value);
                });

                if (name === 'registered_office_address.pincode') {
                    setAddress(prevState => ({
                        ...prevState,
                        registered_office_address: {
                            ...prevState.registered_office_address,
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : "",
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : "",
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : "",
                        }
                    }));
                } else if (name === 'warehouse_address.pincode') {
                    setAddress(prevState => ({
                        ...prevState,
                        warehouse_address: {
                            ...prevState.warehouse_address,
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : "",
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : "",
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : "",
                        }
                    }));
                } else if (name === 'return_warehouse_address.pincode') {
                    setAddress(prevState => ({
                        ...prevState,
                        return_warehouse_address: {
                            ...prevState.return_warehouse_address,
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : "",
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : "",
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : "",
                        }
                    }));
                } else if (name === 'billing_address.pincode') {
                    setAddress(prevState => ({
                        ...prevState,
                        billing_address: {
                            ...prevState.billing_address,
                            region: uniqueRegionData[0]?.value ? uniqueRegionData[0]?.value : "",
                            city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : "",
                            state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : "",
                        }
                    }));
                } else if ((name === 'branch_pincode') && (companyContactDetailsBranchPincode === "companyContactDetailsBranchPincode")) {
                    setCompanyContactDetails(prevState => ({
                        ...prevState,
                        branch_city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : "",
                        branch_state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : "",
                    }));
                } else if (name === 'branch_pincode') {
                    setBrandDetails(prevState => ({
                        ...prevState,
                        branch_city: uniqueCityData[0]?.value ? uniqueCityData[0]?.value : "",
                        branch_state: uniqueStateData[0]?.value ? uniqueStateData[0]?.value : "",
                    }));
                }
                setBranchCity(cityData);
                setBranchState(stateData);
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            // setLoading(false);
        })
    }

    const handleInputArrayChange = (event, index, setState, validationFunc, setErrorState, errorMsg, companyContactDetailsBranchPincode) => {
        const { name, value } = event.target;
        const updatedContactDetails = [...contactDetails];
        updatedContactDetails[index][name] = value;
        setState(updatedContactDetails);

        if (validationFunc(value)) {
            setErrorState(prevErrors => ({ ...prevErrors, [name]: null }));
        } else {
            setErrorState(prevErrors => ({ ...prevErrors, [name]: errorMsg }));
        }
    }

    const handleInputChange = (event, setState, validationFunc, setErrorState, errorMsg, companyContactDetailsBranchPincode) => {
        const { name, value } = event.target;
        const keys = name.split('.');
        setState(prevState => {
            let updatedState = { ...prevState };
            if (keys.length === 1) {
                updatedState[keys[0]] = value;
            } else {
                let nestedObj = updatedState;
                for (let i = 0; i < keys.length - 1; i++) {
                    nestedObj = nestedObj[keys[i]];
                }
                nestedObj[keys[keys.length - 1]] = value;
            }
            return updatedState;
        });
        if (validationFunc(value)) {
            setErrorState(prevState => {
                let updatedState = { ...prevState };
                if (keys.length === 1) {
                    updatedState[keys[0]] = null;
                } else {
                    let nestedObj = updatedState;
                    for (let i = 0; i < keys.length - 1; i++) {
                        nestedObj = nestedObj[keys[i]];
                    }
                    nestedObj[keys[keys.length - 1]] = null;
                }
                return updatedState;
            });

            let conditionPinCode = (value) => {
                if (value.length === 6 && /^\d+$/.test(value)) {
                    return true;
                }
                return false;
            }

            if (conditionPinCode) {
                if (name === "registered_office_address.pincode" || name === 'warehouse_address.pincode' || name === 'return_warehouse_address.pincode' || name === 'billing_address.pincode' || name === 'branch_pincode') {
                    handlePinCode(name, value, companyContactDetailsBranchPincode);
                }
            }
        } else {
            setErrorState(prevState => {
                let updatedState = { ...prevState };

                if (keys.length === 1) {
                    updatedState[keys[0]] = errorMsg;
                } else {
                    let nestedObj = updatedState;
                    for (let i = 0; i < keys.length - 1; i++) {
                        nestedObj = nestedObj[keys[i]];
                    }
                    nestedObj[keys[keys.length - 1]] = errorMsg;
                }

                return updatedState;
            });
        }
    };

    const handleBrandOwnerDetailsChange = (event) => {
        const { name, value } = event.target;
        if (name === 'person_name') {
            handleInputChange(event, setBrandOwnerDetails, validateTextInput, setBrandOwnerDetailsFormError, CustomErrorMsg.name);
        } else if (name === 'mobile') {
            handleInputChange(event, setBrandOwnerDetails, validateNumberInput, setBrandOwnerDetailsFormError, CustomErrorMsg.mobile);
        } else if (name === 'email') {
            handleInputChange(event, setBrandOwnerDetails, validateEmailInput, setBrandOwnerDetailsFormError, CustomErrorMsg.email);
        }
    };

    const handleBrandDetailsChange = (event, newValue) => {
        const { name, value } = event.target;
        if (name === 'brand_name') {
            handleInputChange(event, setBrandDetails, validateTextInput, setBrandDetailsFormError, CustomErrorMsg.name, '');
        }
        // else if( name === 'status') {
        //     handleInputChange(event, setBrandDetails, validateTextInputAny, setBrandDetailsFormError, CustomErrorMsg.name, '');
        // }
        else if (name === 'brand_deck') {
            handleInputChange(event, setBrandDetails, validateTextInputAny, setBrandDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'email_id') {
            handleInputChange(event, setBrandDetails, validateEmailInput, setBrandDetailsFormError, CustomErrorMsg.email, '');
        } else if (name === 'brand_mobile') {
            handleInputChange(event, setBrandDetails, validateNumberInput, setBrandDetailsFormError, CustomErrorMsg.mobile, '');
        } else if (name === 'product_type') {
            handleInputChange(event, setBrandDetails, validateArray, setBrandDetailsFormError, CustomErrorMsg.product, '');
        } else if (name === 'pan') {
            handleInputChange(event, setBrandDetails, validatePANInput, setBrandDetailsFormError, CustomErrorMsg.pan, '');
        } else if (name === 'gstin') {
            handleInputChange(event, setBrandDetails, validateGSTINInput, setBrandDetailsFormError, CustomErrorMsg.gstin, '');
        } else if (name === 'security_comment') {
            handleInputChange(event, setBrandDetails, validateTextInputAny, setBrandDetailsFormError, CustomErrorMsg.securityComment, '');
        } else if (name === 'account_name') {
            handleInputChange(event, setBrandDetails, validateTextInputAny, setBrandDetailsFormError, CustomErrorMsg.accountName, '');
        } else if (name === 'bank_name') {
            handleInputChange(event, setBrandDetails, validateTextInputAny, setBrandDetailsFormError, CustomErrorMsg.bankName, '');
        } else if (name === 'account_number') {
            handleInputChange(event, setBrandDetails, validateAccountNumberInput, setBrandDetailsFormError, CustomErrorMsg.accountNumber, '');
        } else if (name === 'ifsc_code') {
            handleInputChange(event, setBrandDetails, validateIFSCCode, setBrandDetailsFormError, CustomErrorMsg.ifscCode, '');
        } else if (name === 'branch') {
            handleInputChange(event, setBrandDetails, validateTextInput, setBrandDetailsFormError, CustomErrorMsg.branch, '');
        } else if (name === 'branch_address') {
            handleInputChange(event, setBrandDetails, validateTextInput, setBrandDetailsFormError, CustomErrorMsg.address, '');
        } else if (name === 'branch_pincode') {
            handleInputChange(event, setBrandDetails, validatePinCodeInput, setBrandDetailsFormError, CustomErrorMsg.pincode, '');
        } else if (name === 'branch_city') {
            handleInputChange(event, setBrandDetails, validateTextInputAny, setBrandDetailsFormError, CustomErrorMsg.city, '');
        } else if (name === 'branch_state') {
            handleInputChange(event, setBrandDetails, validateTextInputAny, setBrandDetailsFormError, CustomErrorMsg.state);
        }
    };

    const handleBrandContactsDetailsChange = (event, index) => {
        const { name, value } = event.target;

        if (name === 'person_name') {
            handleInputArrayChange(event, index, setContactDetails, validateTextInput, setContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'person_dept') {
            handleInputArrayChange(event, index, setContactDetails, validateFreeTextInputAmpresent, setContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'person_mobile') {
            handleInputArrayChange(event, index, setContactDetails, validateNumberInput, setContactDetailsFormError, CustomErrorMsg.mobile, '');
        } else if (name === 'email') {
            handleInputArrayChange(event, index, setContactDetails, validateEmailInput, setContactDetailsFormError, CustomErrorMsg.email, '');
        } else if (name === 'person_other') {
            handleInputArrayChange(event, index, setContactDetails, validateTextInput, setContactDetailsFormError, CustomErrorMsg.name, '');
        }
    }

    const handleChannelDetailsChange = (event) => {
        const { name, value } = event.target;
        if (name === 'person_name') {
            handleInputChange(event, setChannelDetails, validateTextInput, setChannelDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'person_dept') {
            handleInputChange(event, setChannelDetails, validateTextInput, setChannelDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'person_mobile') {
            handleInputChange(event, setChannelDetails, validateNumberInput, setChannelDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'email') {
            handleInputChange(event, setChannelDetails, validateEmailInput, setChannelDetailsFormError, CustomErrorMsg.name, '');
        }
    }

    const handleBrandAddressChange = (event) => {
        const { name, value } = event.target;
        if (name === 'registered_office_address.address') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'registered_office_address.email') {
            handleInputChange(event, setAddress, validateEmailInput, setAddressFormError, CustomErrorMsg.email, '');
        } else if (name === 'registered_office_address.pincode') {
            handleInputChange(event, setAddress, validatePinCodeInput, setAddressFormError, CustomErrorMsg.pincode, '');
        } else if (name === 'registered_office_address.area') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'registered_office_address.city') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.city, '');
        } else if (name === 'registered_office_address.region') {
            handleInputChange(event, setAddress, validateArray, setAddressFormError, CustomErrorMsg.name, '');
        } else if (name === 'registered_office_address.state') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.name, '');
        }

        else if (name === 'warehouse_address.address') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'warehouse_address.email') {
            handleInputChange(event, setAddress, validateEmailInput, setAddressFormError, CustomErrorMsg.email, '');
        } else if (name === 'warehouse_address.pincode') {
            handleInputChange(event, setAddress, validatePinCodeInput, setAddressFormError, CustomErrorMsg.pincode, '');
        } else if (name === 'warehouse_address.area') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'warehouse_address.city') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.city, '');
        } else if (name === 'warehouse_address.state') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.name, '');
        } else if (name === 'warehouse_address.region') {
            handleInputChange(event, setAddress, validateArray, setAddressFormError, CustomErrorMsg.name, '');
        } else if (name === 'warehouse_address.mobile') {
            handleInputChange(event, setAddress, validateNumberInput, setAddressFormError, CustomErrorMsg.mobile, '');
        } else if (name === 'warehouse_address.person_mobile') {
            handleInputChange(event, setAddress, validateNumberInput, setAddressFormError, CustomErrorMsg.mobile, '');
        }

        else if (name === 'return_warehouse_address.address') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'return_warehouse_address.email') {
            handleInputChange(event, setAddress, validateEmailInput, setAddressFormError, CustomErrorMsg.email, '');
        } else if (name === 'return_warehouse_address.pincode') {
            handleInputChange(event, setAddress, validatePinCodeInput, setAddressFormError, CustomErrorMsg.pincode, '');
        } else if (name === 'return_warehouse_address.area') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'return_warehouse_address.city') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.city, '');
        } else if (name === 'return_warehouse_address.state') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.name, '');
        } else if (name === 'return_warehouse_address.region') {
            handleInputChange(event, setAddress, validateArray, setAddressFormError, CustomErrorMsg.name, '');
        } else if (name === 'return_warehouse_address.mobile') {
            handleInputChange(event, setAddress, validateNumberInput, setAddressFormError, CustomErrorMsg.mobile, '');
        } else if (name === 'return_warehouse_address.person_mobile') {
            handleInputChange(event, setAddress, validateNumberInput, setAddressFormError, CustomErrorMsg.mobile, '');
        }

        else if (name === 'billing_address.address') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'billing_address.email') {
            handleInputChange(event, setAddress, validateEmailInput, setAddressFormError, CustomErrorMsg.email, '');
        } else if (name === 'billing_address.pincode') {
            handleInputChange(event, setAddress, validatePinCodeInput, setAddressFormError, CustomErrorMsg.pincode, '');
        } else if (name === 'billing_address.area') {
            handleInputChange(event, setAddress, validateTextAddressInput, setAddressFormError, CustomErrorMsg.address, '');
        } else if (name === 'billing_address.city') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.city, '');
        } else if (name === 'billing_address.state') {
            handleInputChange(event, setAddress, validateTextInputAny, setAddressFormError, CustomErrorMsg.name, '');
        } else if (name === 'billing_address.region') {
            handleInputChange(event, setAddress, validateArray, setAddressFormError, CustomErrorMsg.name, '');
        } else if (name === 'billing_address.mobile') {
            handleInputChange(event, setAddress, validateNumberInput, setAddressFormError, CustomErrorMsg.mobile, '');
        } else if (name === 'billing_address.person_mobile') {
            handleInputChange(event, setAddress, validateNumberInput, setAddressFormError, CustomErrorMsg.mobile, '');
        }
    }

    const handleCompanyContactDetailsChange = (event) => {
        const { name, value } = event.target;
        if (name === 'contacts_name') {
            handleInputChange(event, setCompanyContactDetails, validateTextInput, setCompanyContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'contacts_mobile') {
            handleInputChange(event, setCompanyContactDetails, validateNumberInput, setCompanyContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'contacts_email_id') {
            handleInputChange(event, setCompanyContactDetails, validateEmailInput, setCompanyContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'contacts_person_dept') {
            handleInputChange(event, setCompanyContactDetails, validateFreeTextInputAmpresent, setCompanyContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'account_name') {
            handleInputChange(event, setCompanyContactDetails, validateTextInput, setCompanyContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'bank_name') {
            handleInputChange(event, setCompanyContactDetails, validateTextInput, setCompanyContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'account_number') {
            handleInputChange(event, setCompanyContactDetails, validateAccountNumberInput, setCompanyContactDetailsFormError, CustomErrorMsg.name, '');
        }

        else if (name === 'ifsc_code') {
            handleInputChange(event, setCompanyContactDetails, validateIFSCCode, setCompanyContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'branch') {
            handleInputChange(event, setCompanyContactDetails, validateTextInput, setCompanyContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'branch_address') {
            handleInputChange(event, setCompanyContactDetails, validateTextAddressInput, setCompanyContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'branch_pincode') {
            handleInputChange(event, setCompanyContactDetails, validatePinCodeInput, setCompanyContactDetailsFormError, CustomErrorMsg.name, "companyContactDetailsBranchPincode");
        } else if (name === 'branch_city') {
            handleInputChange(event, setCompanyContactDetails, validateTextInputAny, setCompanyContactDetailsFormError, CustomErrorMsg.name, '');
        } else if (name === 'branch_state') {
            handleInputChange(event, setCompanyContactDetails, validateTextInputAny, setCompanyContactDetailsFormError, CustomErrorMsg.name, '');
        }
    }

    function getFileType(fileName) {
        if (!fileName) {
            return '';
        }
        const parts = fileName.split('.');
        return parts.length > 1 ? parts[parts.length - 1] : '';
    }

    const renderFileIcons = (file) => {
        if (getFileType(file.doc_name) === 'docx') {
            return DocIcon
        } else if (getFileType(file.doc_name) === "pdf") {
            return pdfIcon
        } else if (getFileType(file.doc_name) === "png") {
            return PngIcon
        } else if (getFileType(file.doc_name) === "jpg" || getFileType(file.doc_name) === "jpeg") {
            return JpegIcon
        }
    }

    const handleFileDrop = (name, files) => {
        const file = files[0];
        const size = files[0]?.size;
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        const allowedTypesForLogo = ['image/png', 'image/jpeg', 'image/jpg'];
        if (name === 'brandLogo') {
            if (allowedTypesForLogo.includes(file.type)) {
                setSelectedFile(file);
                setFileUploadProgress(0);
                simulateUpload(name);

                if (file.type === 'image/png') {
                    handleFileUpload(name, file, 'Brand Logo', PngIcon, size);
                } else if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
                    handleFileUpload(name, file, 'Brand Logo', JpegIcon, size);
                }

                if (name === 'brandLogo') {
                    setBrandLogoProgress(0);
                } else if (name === 'BrandFloorSpace') {
                    setbrandFloorSpaceProgress(0);
                } else if (name === 'Adjacent') {
                    setotherProgress(0)
                } else if (name === 'Other') {
                    setotherProgress(0)
                }
            } else {
                alert('Please select a JPEG, JPG or PNG file Only.');
            }
        } else {
            if (allowedTypes.includes(file.type)) {
                setSelectedFile(file);
                setFileUploadProgress(0);
                simulateUpload(name);

                if (file.type === 'application/pdf') {
                    handleFileUpload(name, file, 'Brand Documents', ExcellIcon, size);
                } else if (file.type === 'image/png') {
                    handleFileUpload(name, file, 'Brand Logo', PngIcon, size);
                } else if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
                    handleFileUpload(name, file, 'Brand Logo', JpegIcon, size);
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    handleFileUpload(name, file, 'Brand Documents', DocIcon, size);
                }

                if (name === 'PANDocument') {
                    setPanDocumentProgress(0);
                } else if (name === 'GSTDocument') {
                    setGstDocumentProgress(0);
                } else if (name === 'AgreementDocument') {
                    setAgreementDocumentProgress(0);
                } else if (name === 'brandLogo') {
                    setBrandLogoProgress(0);
                } else if (name === 'BrandFloorSpace') {
                    setbrandFloorSpaceProgress(0);
                } else if (name === 'Adjacent') {
                    setotherProgress(0)
                } else if (name === 'Other') {
                    setotherProgress(0)
                }
            } else {
                alert('Please select a PDF, JPEG, JPG or PNG file Only.');
            }
        }
    };

    const simulateUpload = (name) => {
        const interval = setInterval(() => {
            setFileUploadProgress((prevProgress) => {
                const nextProgress = prevProgress + 10;
                if (nextProgress >= 100) {
                    clearInterval(interval);
                    setUploadSpeed(null);
                    return 100;
                }
                if (!startTime) {
                    setStartTime(Date.now());
                }
                const elapsedTime = (Date.now() - startTime) / 1000;
                const speed = (nextProgress / elapsedTime).toFixed(2);
                setUploadSpeed(speed);
                return nextProgress;
            });
        }, 500);
        if (name === 'PANDocument') {
            const interval = setInterval(() => {
                setPanDocumentProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setPanDocumentUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setPanDocumentUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'GSTDocument') {
            const interval = setInterval(() => {
                setGstDocumentProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setGstDocumentUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setGstDocumentUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'AgreementDocument') {
            const interval = setInterval(() => {
                setAgreementDocumentProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setAgreementDocumentUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setAgreementDocumentUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'brandLogo') {
            const interval = setInterval(() => {
                setBrandLogoProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setStoreFacadeUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setStoreFacadeUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'BrandFloorSpace') {
            const interval = setInterval(() => {
                setbrandFloorSpaceProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setBrandFloorSpaceUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setBrandFloorSpaceUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500, '');
        } else if (name === 'Adjacent') {
            const interval = setInterval(() => {
                setadjacentProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setAdjacentUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setAdjacentUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500);
        } else if (name === 'Other') {
            const interval = setInterval(() => {
                setotherProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setOtherUploadSpeed(null);
                        return 100;
                    }
                    if (!startTime) {
                        setStartTime(Date.now());
                    }
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const speed = (nextProgress / elapsedTime).toFixed(2);
                    setOtherUploadSpeed(speed);
                    return nextProgress;
                });
            }, 500);
        }

    };

    const handleFileUpload = async (name, file, fileServiceType, fileTypeIcon, size) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('service', 'Brand_Documents');
        formData.append('file_name', 'Data');
        formData.append('service_type', fileServiceType);
        formData.append('size', size);
        formData.append('brand_name', brandDetails?.brand_name ? brandDetails?.brand_name : '');

        fileUpload(formData, USER_TOKEN).then(response => {
            if (response.data.statusCode === 400) {
                ToastErrorService(response.data.message)
            } else {
                if (name === 'PANDocument') {
                    setPanDocumentFile(
                        {
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "PANDocument",
                        }
                    );
                    setPanDocumentFileTypeIcon(fileTypeIcon);
                } else if (name === 'GSTDocument') {
                    setGstDocumentFile(
                        {
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "GSTDocument",
                        }
                    );
                    setGstDocumentFileTypeIcon(fileTypeIcon);
                } else if (name === 'AgreementDocument') {
                    setAgreementDocumentFile(
                        {
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "AgreementDocument",
                        }
                    );
                    setAgreementDocumentFileTypeIcon(fileTypeIcon);
                } else if (name === 'brandLogo') {
                    setuploadBrandFileLogo(
                        {
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "brandLogo",
                        }
                    );
                    setBrandLogoImage(response?.data?.data?.file_url);
                    setBrandLogoFileTypeIcon(fileTypeIcon);
                } else if (name === 'BrandFloorSpace') {
                    setSelectedBrandFloorSpaceFile(
                        {
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "BrandFloorSpace",
                        }
                    );
                    setBrandFloorSpaceFileTypeIcon(fileTypeIcon);
                } else if (name === 'Adjacent') {
                    setSelectedAdjacentFile(
                        {
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "Adjacent",
                        }
                    );
                    setAdjacentFileTypeIcon(fileTypeIcon);
                } else if (name === 'Other') {
                    setSelectedOtherFile(
                        {
                            file_id: response?.data?.data?._id,
                            doc_name: response?.data?.data?.fileName,
                            doc_url: response?.data?.data?.file_url,
                            size: size,
                            created_at: response?.data?.data?.created_at,
                            doc_type: "Other",
                        }
                    );
                    setOtherFileTypeIcon(fileTypeIcon);
                }
                setUploadedFile(response?.data?.data);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            // setLoading(false);
        })
    };

    const removeFile = (name) => {
        setUploadedFile([]);
        if (name === 'PANDocument') {
            setPanDocumentFile(
                {
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            );
            setPanDocumentFileTypeIcon('');
        } else if (name === 'GSTDocument') {
            setGstDocumentFile(
                {
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            );
            setGstDocumentFileTypeIcon('');
        } else if (name === 'AgreementDocument') {
            setAgreementDocumentFile(
                {
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            );
            setAgreementDocumentFileTypeIcon('');
        } else if (name === 'brandLogo') {
            setuploadBrandFileLogo(
                {
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            );
            setBrandLogoImage('');
            setBrandLogoFileTypeIcon('');
        } else if (name === 'BrandFloorSpace') {
            setSelectedBrandFloorSpaceFile(
                {
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            );
            setBrandFloorSpaceFileTypeIcon('');
        } else if (name === 'Adjacent') {
            setSelectedAdjacentFile(
                {
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            );
            setAdjacentFileTypeIcon('');
        } else if (name === 'Other') {
            setSelectedOtherFile(
                {
                    file_id: '',
                    doc_name: '',
                    doc_url: '',
                    size: '',
                    created_at: '',
                    doc_type: '',
                }
            );
            setOtherFileTypeIcon('');
        }
    }

    // useEffect(() => {
    //     setDocumentUrls([uploadBrandFileLogo, selectedBrandFloorSpaceFile, selectedAdjacentFile, selectedOtherFile])
    // }, [uploadBrandFileLogo, selectedBrandFloorSpaceFile, selectedAdjacentFile, selectedOtherFile]);

    const handleUpdateBrandDetailsBasedOnBrandId = (uploadButton, tabNum) => {
        let param = `?brand_id=${brandSId}`;

        const tempChannelDetails = {
            ...channelDetails,
            channel_offline: channelOfflineStatus ? [] : channelDetails?.channel_offline,
            channel_online: channelOnlineStatus ? [] : channelDetails?.channel_online
        }

        let reqBody = {
            company: {
                company_id: "65f2c26359a84f7e7dc548ac",
                company_name: "Tekexcelarator",
            },
            owner_contact: brandOwnerDetails,
            brand_details: brandDetails,
            contact_details: contactDetails,
            channel_details: tempChannelDetails,
            address: address,
            company_contact_details: companyContactDetails,
            document_details: [panDocumentFile, gstDocumentFile, agreementDocumentFile, uploadBrandFileLogo, selectedBrandFloorSpaceFile, selectedAdjacentFile, selectedOtherFile],
            brand_logo: brandLogoImage,
            is_profile_completed: allMandatoryFieldsFilledBrand(brandOwnerDetails, brandDetails, contactDetails, channelDetails, address, companyContactDetails, panDocumentFile, gstDocumentFile) ? true : false,
        };
        updateBrandDetailsBasedOnBrandId(reqBody, param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                dispatch(brandManagementActions.setViewBrandDetails({ type: response?.data?.data }));
                dispatch(brandManagementActions.setIsEditFormVisible({ type: true }));
                if (tabNum !== 6) {
                    ToastSuccessService(response.data.message);
                }
                // setTimeout(() => {
                if (uploadButton === 'uploadButton') {
                    setOpenSuccessPopup(true);
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    // Set a new timeout to automatically close the popup
                    timeoutRef.current = setTimeout(() => {
                        dispatch(brandManagementActions.setStepActiveState({ type: 0 }));
                        dispatch(brandManagementActions.setStepProgress({ type: 0 }));
                        history.push('/app/brandManagement');
                        setOpenSuccessPopup(false);
                        setPanDocumentFile(
                            {
                                file_id: '',
                                doc_name: '',
                                doc_url: '',
                                size: '',
                                created_at: '',
                                doc_type: '',
                            }
                        );
                        setGstDocumentFile(
                            {
                                file_id: '',
                                doc_name: '',
                                doc_url: '',
                                size: '',
                                created_at: '',
                                doc_type: '',
                            }
                        );
                        setAgreementDocumentFile(
                            {
                                file_id: '',
                                doc_name: '',
                                doc_url: '',
                                size: '',
                                created_at: '',
                                doc_type: '',
                            }
                        );
                        setuploadBrandFileLogo({
                            file_id: '',
                            doc_name: '',
                            doc_url: '',
                            size: '',
                            created_at: '',
                            doc_type: '',
                        });
                        setBrandLogoImage('');
                        setSelectedBrandFloorSpaceFile({
                            file_id: '',
                            doc_name: '',
                            doc_url: '',
                            size: '',
                            created_at: '',
                            doc_type: '',
                        });
                        setSelectedAdjacentFile({
                            file_id: '',
                            doc_name: '',
                            doc_url: '',
                            size: '',
                            created_at: '',
                            doc_type: '',
                        });
                        setSelectedOtherFile({
                            file_id: '',
                            doc_name: '',
                            doc_url: '',
                            size: '',
                            created_at: '',
                            doc_type: '',
                        });
                        setUploadedFile([]);
                    }, 5000)
                }
                // }, 10);
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        setBrandOwnerDetailsFormError({
            person_name: brandDetailData?.owner_contact?.person_name && null,
            mobile: brandDetailData?.owner_contact?.mobile && null,
            email: brandDetailData?.owner_contact?.email && null,
        });
        setBrandDetailsFormError({
            brand_name: brandDetailData?.brand_name && null,
            status: brandDetailData?.status && null,
            brand_deck: brandDetailData?.brand_deck && null,
            email_id: brandDetailData?.email_id && null,
            brand_mobile: brandDetailData?.brand_mobile && null,
            product_type: brandDetailData?.product_type && null,
            business_model: brandDetailData?.business_model && null,
            pan: brandDetailData?.pan && null,
            gstin: brandDetailData?.gstin && null,
            security: brandDetailData?.security && null,
            security_comment: brandDetailData?.security_comment && null,
            account_name: brandDetailData?.bank_detail?.account_name && null,
            bank_name: brandDetailData?.bank_detail?.bank_name && null,
            account_number: brandDetailData?.bank_detail?.bank_no && null,
            ifsc_code: brandDetailData?.bank_detail?.ifsc_code && null,
            branch: brandDetailData?.bank_detail?.branch && null,
            branch_address: brandDetailData?.bank_detail?.branch_address && null,
            branch_pincode: brandDetailData?.bank_detail?.branch_pincode && null,
            branch_city: brandDetailData?.bank_detail?.branch_city && null,
            branch_state: brandDetailData?.bank_detail?.branch_state && null,
        });
        setContactDetailsFormError({
            person_dept: brandDetailData?.contact_person && brandDetailData?.contact_person[0]?.person_dept && null,
            person_name: brandDetailData?.contact_person && brandDetailData?.contact_person[0]?.person_name && null,
            person_mobile: brandDetailData?.contact_person && brandDetailData?.contact_person[0]?.person_mobile && null,
            email: brandDetailData?.contact_person && brandDetailData?.contact_person[0]?.email && null,
        });
        setAddressFormError({
            registered_office_address: {
                address: brandDetailData?.registered_office_address?.address && null,
                region: brandDetailData?.registered_office_address?.region && null,
                state: brandDetailData?.registered_office_address?.state && null,
                city: brandDetailData?.registered_office_address?.city && null,
                area: brandDetailData?.registered_office_address?.area && null,
                pincode: brandDetailData?.registered_office_address?.pincode && null,
                email: brandDetailData?.registered_office_contact?.email && null,
            },
            warehouse_address: {
                address: brandDetailData?.warehouse_address?.address && null,
                region: brandDetailData?.warehouse_address?.region && null,
                state: brandDetailData?.warehouse_address?.state && null,
                city: brandDetailData?.warehouse_address?.city && null,
                area: brandDetailData?.warehouse_address?.area && null,
                pincode: brandDetailData?.warehouse_address?.pincode && null,
                email: brandDetailData?.warehouse_contact?.email && null,
                mobile: brandDetailData?.warehouse_contact?.mobile && null,
                person_mobile: brandDetailData?.warehouse_contact?.person_mobile && null,
            },
            return_warehouse_address: {
                address: brandDetailData?.return_warehouse_address?.address && null,
                region: brandDetailData?.return_warehouse_address?.region && null,
                state: brandDetailData?.return_warehouse_address?.state && null,
                city: brandDetailData?.return_warehouse_address?.city && null,
                area: brandDetailData?.return_warehouse_address?.area && null,
                pincode: brandDetailData?.return_warehouse_address?.pincode && null,
                email: brandDetailData?.return_warehouse_contact?.email && null,
                mobile: brandDetailData?.return_warehouse_contact?.mobile && null,
                person_mobile: brandDetailData?.return_warehouse_contact?.person_mobile && null,
            },
            billing_address: {
                address: brandDetailData?.billing_address?.address && null,
                region: brandDetailData?.billing_address?.region && null,
                state: brandDetailData?.billing_address?.state && null,
                city: brandDetailData?.billing_address?.city && null,
                area: brandDetailData?.billing_address?.area && null,
                pincode: brandDetailData?.billing_address?.pincode && null,
                email: brandDetailData?.billing_contact?.email && null,
                mobile: brandDetailData?.billing_contact?.mobile && null,
                person_mobile: brandDetailData?.billing_contact?.person_mobile && null,
            }
        });
        setCompanyContactDetailsFormError({
            contacts_name: brandDetailData?.company_contact_detail?.person_name && null,
            contacts_mobile: brandDetailData?.company_contact_detail?.mobile && null,
            contacts_email_id: brandDetailData?.company_contact_detail?.email && null,
            contacts_person_dept: brandDetailData?.company_contact_detail?.person_dept && null,
            account_name: brandDetailData?.company_bank_detail?.account_name && null,
            bank_name: brandDetailData?.company_bank_detail?.bank_name && null,
            account_number: brandDetailData?.company_bank_detail?.bank_no && null,
            ifsc_code: brandDetailData?.company_bank_detail?.ifsc_code && null,
            branch: brandDetailData?.company_bank_detail?.branch && null,
            branch_address: brandDetailData?.company_bank_detail?.branch_address && null,
            branch_pincode: brandDetailData?.company_bank_detail?.branch_pincode && null,
            branch_city: brandDetailData?.company_bank_detail?.branch_city && null,
            branch_state: brandDetailData?.company_bank_detail?.branch_state && null,
        })
    }, []);

    const openFile = (item) => {
        // console.log("item====>",item);
        setViewFile(item);
        setOpenFileViewerModal(true);
    }

    const handleEmptyDocument = (index) => {
        if (index === 0) {
            return <div className='no-doc-uploaded'>PAN Card Document Not Uploaded.</div>
        } else if (index === 1) {
            return <div className='no-doc-uploaded'>GST document Document Not Uploaded.</div>
        }
    }


    const [isSameRegisteredOfficeAddressAddress, setIsSameRegisteredOfficeAddressAddress] = useState(false);
    const [isSameWarehouseAddress, setIsSameWarehouseAddress] = useState(false);
    const [isSameAsBilling, setIsSameAsBilling] = useState(false);
    const [previousAddresses, setPreviousAddresses] = useState({});
    const [billingChecked, setBillingChecked] = useState({ registeredOffice: false, warehouse: false, });

    const initialState = {
        address: '',
        region: '',
        state: '',
        city: '',
        area: '',
        pincode: '',
        email: '',
        mobile: '',
        person_mobile: '',
    };

    const initialStateNull = {
        address: null,
        region: null,
        state: null,
        city: null,
        area: null,
        pincode: null,
        email: null,
        mobile: null,
        person_mobile: null,
    };

    const handleCopyAddress = (e, initalKey, sourceKey, targetKey, setIsSame, formErrorKey) => {

        const isChecked = e.target.checked;
        setIsSame(isChecked);

        let initalKeyAddress = `${initalKey}address`;
        let initalKeyContact = `${initalKey}contact`;
        const fetchedInitialState = {
            address: brandDetailData[initalKeyAddress]?.address ? brandDetailData[initalKeyAddress]?.address : '',
            region: brandDetailData[initalKeyAddress]?.region ? brandDetailData[initalKeyAddress]?.region : '',
            state: brandDetailData[initalKeyAddress]?.state ? brandDetailData[initalKeyAddress]?.state : '',
            city: brandDetailData[initalKeyAddress]?.city ? brandDetailData[initalKeyAddress]?.city : '',
            area: brandDetailData[initalKeyAddress]?.area ? brandDetailData[initalKeyAddress]?.area : '',
            pincode: brandDetailData[initalKeyAddress]?.pincode ? brandDetailData[initalKeyAddress]?.pincode : '',
            email: brandDetailData[initalKeyContact]?.email ? brandDetailData[initalKeyContact]?.email : '',
            mobile: brandDetailData[initalKeyContact]?.email ? brandDetailData[initalKeyContact]?.mobile : '',
            person_mobile: brandDetailData[initalKeyContact]?.email ? brandDetailData[initalKeyContact]?.person_mobile : '',
        }

        const sourceAddress = address?.[sourceKey];

        const keys = ['address', 'region', 'state', 'city', 'area', 'pincode', 'email', 'mobile', 'person_mobile'];

        const updatedSourceAddress = keys.reduce((acc, key) => {
            acc[key] = sourceAddress[key] || '';
            return acc;
        }, {});

        const updatedFormErrors = keys.reduce((acc, key) => {
            if (!sourceAddress[key]) {
                acc[key] = '';
            }
            return acc;
        }, {});

        const updatedAddressFormErrors = keys.reduce((acc, key) => {
            if (!addressFormError[key]) {
                acc[key] = null;
            }
            return acc;
        }, {});

        if (isChecked) {

            setAddress((prevDetails) => ({
                ...prevDetails,
                [targetKey]: { ...updatedSourceAddress },
            }));

            // setAddressFormError((prevErrors) => ({
            //     ...prevErrors,
            //     [formErrorKey]: { ...prevErrors[formErrorKey], ...updatedFormErrors },
            // }));

            const newFormError = {};
            for (let key in initialState) {
                if (!sourceAddress?.[key]) {
                    newFormError[key] = ''; // Set error as empty string
                } else {
                    newFormError[key] = null; // Clear the error
                }
            }

            setAddressFormError((prevErrors) => ({
                ...prevErrors,
                [formErrorKey]: { ...newFormError }
            }));
        } else {
            setAddress((prevDetails) => ({
                ...prevDetails,
                [targetKey]: fetchedInitialState,
            }));
            setAddressFormError((prevErrors) => ({
                ...prevErrors,
                [formErrorKey]: { ...prevErrors[formErrorKey], ...updatedAddressFormErrors },
            }));
        }
    };

    // Example usage for each checkbox:
    const handleCopyRegisteredOfficeAddress = (e) => {
        handleCopyAddress(e, 'warehouse_', 'registered_office_address', 'warehouse_address', setIsSameRegisteredOfficeAddressAddress, 'warehouse_address');
    }

    const handleCopyWarehouseAddress = (e) => {
        handleCopyAddress(e, 'return_warehouse_', 'warehouse_address', 'return_warehouse_address', setIsSameWarehouseAddress, 'return_warehouse_address');
    }

    const handleCopyRegisteredOfficeAddressForBillingPlace = (e) => {
        setBillingChecked({
            registeredOffice: !billingChecked.registeredOffice,
            warehouse: false, // Uncheck the other checkbox
        });
        handleCopyAddress(e, 'billing_', 'registered_office_address', 'billing_address', setIsSameAsBilling, 'billing_address');
    }

    const handleCopyWarehouseAddressForBillingPlace = (e) => {
        setBillingChecked({
            registeredOffice: false, // Uncheck the other checkbox
            warehouse: !billingChecked.warehouse,
        });
        handleCopyAddress(e, 'billing_', 'warehouse_address', 'billing_address', setIsSameAsBilling, 'billing_address');
    }


    const refs = {
        brandOwnerDetails: { person_name: useRef(null), mobile: useRef(null), email: useRef(null) },
        brandDetails: { brand_name: useRef(null), email_id: useRef(null), brand_mobile: useRef(null), product_type: useRef(null), pan: useRef(null), gstin: useRef(null), account_name: useRef(null), bank_name: useRef(null), account_number: useRef(null), ifsc_code: useRef(null), branch: useRef(null), branch_address: useRef(null) },
        contactDetails: { person_name: useRef(null), person_dept: useRef(null), person_mobile: useRef(null), email: useRef(null) },
        registered_office_address: { address: useRef(null), pincode: useRef(null), area: useRef(null), email: useRef(null) },
        warehouse_address: { address: useRef(null), pincode: useRef(null), area: useRef(null), email: useRef(null), mobile: useRef(null) },
        return_warehouse_address: { address: useRef(null), pincode: useRef(null), area: useRef(null), email: useRef(null), mobile: useRef(null) },
        billing_address: { address: useRef(null), pincode: useRef(null), area: useRef(null), email: useRef(null), mobile: useRef(null) },
        companyContactDetails: { contacts_name: useRef(null), contacts_mobile: useRef(null), contacts_email_id: useRef(null), contacts_person_dept: useRef(null), account_name: useRef(null), bank_name: useRef(null), account_number: useRef(null), ifsc_code: useRef(null), branch: useRef(null), branch_address: useRef(null) },
    };
    
    const validateEmailInput = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    
    const validateSection = (section, rules, setErrorState) => {
        let errors = {};
        let firstErrorRef = null;
    
        // Iterate through each key in rules
        Object.keys(rules).forEach((key) => {
            const rule = rules[key];
    
            // Check if this key is for an address structure or a flat field
            if (rule.fields && rule.rules) {
                // Address structure: iterate through each field within this address type
                let addressErrors = {};
                rule.fields.forEach((field) => {
                    const fieldValue = rule.value[field];
                    const validateRule = rule.rules[field];
                    let isValid;
    
                    if (typeof validateRule === "function") {
                        isValid = validateRule(fieldValue);
                    } else if (validateRule === Boolean) {
                        isValid = Boolean(fieldValue);
                    }
    
                    if (!isValid) {
                        addressErrors[field] = `${field} is invalid`; // Customize message
                        if (!firstErrorRef && rule.value[field]?.ref) {
                            firstErrorRef = rule.value[field].ref;
                        }
                    }
                });
    
                if (Object.keys(addressErrors).length > 0) {
                    errors[key] = addressErrors;
                }
            } else {
                // Flat field structure: validate directly
                const { value, validation, errorMsg, ref } = rule;
                if (!validation(value)) {
                    errors[key] = errorMsg;
                    firstErrorRef ||= ref;
                }
            }
        });
    
        // Handle errors and focus management
        if (Object.keys(errors).length > 0) {
            setErrorState(errors);
            if (firstErrorRef) {
                firstErrorRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                firstErrorRef?.current?.focus();
            }
            return false;
        }
    
        setErrorState({});
        return true;
    };
    
    
    const validateBrandOwnerDetails = () =>
        validateSection("brandOwnerDetails", {
            person_name: { value: brandOwnerDetails.person_name, validation: (v) => v?.length >= 2, errorMsg: CustomErrorMsg.name, ref: refs.brandOwnerDetails.person_name },
            mobile: { value: brandOwnerDetails.mobile, validation: (v) => /^\d{10}$/.test(v), errorMsg: CustomErrorMsg.mobile, ref: refs.brandOwnerDetails.mobile },
            email: { value: brandOwnerDetails.email, validation: validateEmailInput, errorMsg: CustomErrorMsg.email, ref: refs.brandOwnerDetails.email },
        }, setBrandOwnerDetailsFormError);
    
    const validateBrandDetails = () =>
        validateSection("brandDetails", {
            brand_name: { value: brandDetails.brand_name, validation: (v) => v?.length >= 2, errorMsg: CustomErrorMsg.name, ref: refs.brandDetails.brand_name },
            email_id: { value: brandDetails.email_id, validation: validateEmailInput, errorMsg: CustomErrorMsg.email, ref: refs.brandDetails.email_id },
            brand_mobile: { value: brandDetails.brand_mobile, validation: (v) => /^\d{10}$/.test(v), errorMsg: CustomErrorMsg.mobile, ref: refs.brandDetails.brand_mobile },
            product_type: { value: brandDetails.product_type, validation: (v) => v?.length > 0, errorMsg: CustomErrorMsg.product, ref: refs.brandDetails.product_type },
            pan: { value: brandDetails.pan, validation: (v) => v?.length === 10, errorMsg: CustomErrorMsg.pan, ref: refs.brandDetails.pan },
            gstin: { value: brandDetails.gstin, validation: (v) => v?.length === 15, errorMsg: CustomErrorMsg.gstin, ref: refs.brandDetails.gstin },
            account_name: { value: brandDetails.account_name, validation: Boolean, errorMsg: CustomErrorMsg.accountName, ref: refs.brandDetails.account_name },
            bank_name: { value: brandDetails.bank_name, validation: Boolean, errorMsg: CustomErrorMsg.bankName, ref: refs.brandDetails.bank_name },
            account_number: { value: brandDetails.account_number, validation: Boolean, errorMsg: CustomErrorMsg.accountNumber, ref: refs.brandDetails.account_number },
            ifsc_code: { value: brandDetails.ifsc_code, validation: Boolean, errorMsg: CustomErrorMsg.ifscCode, ref: refs.brandDetails.ifsc_code },
            branch: { value: brandDetails.branch, validation: Boolean, errorMsg: CustomErrorMsg.branch, ref: refs.brandDetails.branch },
            branch_address: { value: brandDetails.branch_address, validation: Boolean, errorMsg: CustomErrorMsg.address, ref: refs.brandDetails.branch_address },
        }, setBrandDetailsFormError);
    
    const validateContactDetails = () =>
        validateSection("contactDetails", {
            person_name: { value: contactDetails[0].person_name, validation: (v) => v?.length >= 2, errorMsg: CustomErrorMsg.name, ref: refs.contactDetails.person_name },
            person_dept: { value: contactDetails[0].person_dept, validation: (v) => v?.length >= 2, errorMsg: CustomErrorMsg.dept, ref: refs.contactDetails.person_dept },
            person_mobile: { value: contactDetails[0].person_mobile, validation: (v) => /^\d{10}$/.test(v), errorMsg: CustomErrorMsg.mobile, ref: refs.contactDetails.person_mobile },
            email: { value: contactDetails[0].email, validation: validateEmailInput, errorMsg: CustomErrorMsg.email, ref: refs.contactDetails.email },
        }, setContactDetailsFormError);
    
    const validateAddressesDetails = () =>
        validateSection("addresses", {
            registered_office_address: { value: address.registered_office_address, fields: ["address", "pincode", "area", "email"], rules: { address: Boolean, pincode: (v) => v?.length === 6, area: Boolean, email: validateEmailInput } },
            warehouse_address: { value: address.warehouse_address, fields: ["address", "pincode", "area", "email", "mobile"], rules: { address: Boolean, pincode: (v) => v?.length === 6, area: Boolean, email: validateEmailInput, mobile: (v) => /^\d{10}$/.test(v) } },
            return_warehouse_address: { value: address.return_warehouse_address, fields: ["address", "pincode", "area", "email", "mobile"], rules: { address: Boolean, pincode: (v) => v?.length === 6, area: Boolean, email: validateEmailInput, mobile: (v) => /^\d{10}$/.test(v) } },
            billing_address: { value: address.billing_address, fields: ["address", "pincode", "area", "email", "mobile"], rules: { address: Boolean, pincode: (v) => v?.length === 6, area: Boolean, email: validateEmailInput, mobile: (v) => /^\d{10}$/.test(v) } },
        }, setAddressFormError);
    
    const validatCompanyContactDetails = () =>
        validateSection("companyContactDetails", {
            contacts_name: { value: companyContactDetails.contacts_name, validation: Boolean, errorMsg: CustomErrorMsg.name, ref: refs.companyContactDetails.contacts_name },
            contacts_mobile: { value: companyContactDetails.contacts_mobile, validation: (v) => /^\d{10}$/.test(v), errorMsg: CustomErrorMsg.mobile, ref: refs.companyContactDetails.contacts_mobile },
            contacts_email_id: { value: companyContactDetails.contacts_email_id, validation: validateEmailInput, errorMsg: CustomErrorMsg.email, ref: refs.companyContactDetails.contacts_email_id },
            contacts_person_dept: { value: companyContactDetails.contacts_person_dept, validation: Boolean, errorMsg: CustomErrorMsg.dept, ref: refs.companyContactDetails.contacts_person_dept },
            account_name: { value: companyContactDetails.account_name, validation: Boolean, errorMsg: CustomErrorMsg.accountName, ref: refs.companyContactDetails.account_name },
            bank_name: { value: companyContactDetails.bank_name, validation: Boolean, errorMsg: CustomErrorMsg.bankName, ref: refs.companyContactDetails.bank_name },
            account_number: { value: companyContactDetails.account_number, validation: Boolean, errorMsg: CustomErrorMsg.accountNumber, ref: refs.companyContactDetails.account_number },
            ifsc_code: { value: companyContactDetails.ifsc_code, validation: Boolean, errorMsg: CustomErrorMsg.ifscCode, ref: refs.companyContactDetails.ifsc_code },
            branch: { value: companyContactDetails.branch, validation: Boolean, errorMsg: CustomErrorMsg.branch, ref: refs.companyContactDetails.branch },
            branch_address: { value: companyContactDetails.branch_address, validation: Boolean, errorMsg: CustomErrorMsg.address, ref: refs.companyContactDetails.branch_address },
        }, setCompanyContactDetailsFormError);
    
    const validateDocuments = () => {
        const newErrors = {};
        if (isFileDetailsEmpty(panDocumentFile)) newErrors.panDocument = 'PAN Card is required.';
        if (isFileDetailsEmpty(gstDocumentFile)) newErrors.gstDocument = 'GST Document is required.';
        if (isFileDetailsEmpty(agreementDocumentFile)) newErrors.agreementDocument = 'Agreement Document is required.';
        if (isFileDetailsEmpty(uploadBrandFileLogo)) newErrors.brandLogo = 'Brand Logo is required.';
        setDocErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const onClickSaveEditDetails = async () => {
        setIsEditFormVisible(!isEditFormVisible);
        if (selectedTab === 0) {
            const isValid = validateBrandOwnerDetails();
            if (isValid) {
                await handleUpdateBrandDetailsBasedOnBrandId('', '');
            }
        } else if (selectedTab === 1) {
            const isValid = validateBrandDetails();
            if (isValid) {
                await handleUpdateBrandDetailsBasedOnBrandId('', '');
            }
        } else if (selectedTab === 2) {
            const isValid = validateContactDetails();
            if (isValid) {
                await handleUpdateBrandDetailsBasedOnBrandId('', '');
            }
        } else if (selectedTab === 3) {
            await handleUpdateBrandDetailsBasedOnBrandId('', '');
        } else if (selectedTab === 4) {
            const isValid = validateAddressesDetails();
            if (isValid) {
                await handleUpdateBrandDetailsBasedOnBrandId('', '');
            }
        } else if (selectedTab === 5) {
            const isValid = validatCompanyContactDetails();
            if (isValid) {
                await handleUpdateBrandDetailsBasedOnBrandId('', '');
            }
        } else if (selectedTab === 6) {
            if (validateDocuments()){
                handleUpdateBrandDetailsBasedOnBrandId('uploadButton', 6);
            }else {
                alert('Please upload all required documents.');
            }
        } 
    };



    const renderTabData = (currentTab, brandDetailData) => {
        if (currentTab === 0) {
            return (
                <>
                    {editFormVisible ?
                        <div className='bg-white storeDetailsContainer scrollChange'>
                            <div className='montSerratFont fontSize18 tabheader'>Brand Owner Details</div>
                            <div className='position-relative'>
                                <div className='editStoreBtn'>
                                    <SecondaryButton onClick={onClickEditDetails} title={<div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <img src={EditIcon} alt="" />
                                            <div className='textColorBlue fontMedium'>Edit Details</div>
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                            <div className='paddingTop24 notoSansFont'>
                                {brandDetailDataView && <div className='storeOwnerDetails'>
                                    <div>
                                        <div className='storeLabel'>Owner Name</div>
                                        <div className='storeValue'>{brandDetailDataView?.owner_contact?.person_name}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Mobile Number</div>
                                        <div className='storeValue'>+91 {brandDetailDataView?.owner_contact?.mobile}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Owner E- Mail Id</div>
                                        <div className='storeValue'>{brandDetailDataView?.owner_contact?.email}</div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        :
                        <>
                            <div className='saveChangeCont scrollChange'>
                                <div className='bg-white storeDetailsContainer'>
                                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                                        Brand Owner Details
                                    </div>
                                    <div ref={zerothTabRef} className='pt-3 formContainer brandDetails_cont customScroll'>
                                        <div className='inputBottomSpace' ref={refs.brandOwnerDetails.person_name}>
                                            <InputTextField type='text' disabled={false} name='person_name' value={brandOwnerDetails?.person_name} onChange={handleBrandOwnerDetailsChange} required max={'2'} min={'100'} label='Owner Name' />
                                            {/* {ownerNameError && <div className="error">{ownerNameError}</div>} */}
                                            {brandOwnerDetailsFormError.person_name && <div className="error">{brandOwnerDetailsFormError.person_name}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={refs.brandOwnerDetails.mobile}>
                                            <InputTextField type='text' disabled={false} name='mobile' value={brandOwnerDetails?.mobile} onChange={handleBrandOwnerDetailsChange} required minLength={10} maxLength={10} label='Mobile Number' />
                                            {/* {mobileNumberError && <div className="error">{mobileNumberError}</div>} */}
                                            {brandOwnerDetailsFormError.mobile && <div className="error">{brandOwnerDetailsFormError.mobile}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={refs.brandOwnerDetails.email}>
                                            <InputTextField type='email' disabled={false} name='email' value={brandOwnerDetails?.email} onChange={handleBrandOwnerDetailsChange} required minLength={''} maxLength={''} label='E-Mail Id' />
                                            {/* {emailError && <div className="error">{emailError}</div>} */}
                                            {brandOwnerDetailsFormError.email && <div className="error">{brandOwnerDetailsFormError.email}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                                    <div className='pe-3'>
                                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                                    </div>
                                    <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                        <div>Save Changes</div>
                                    </div>} onClick={onClickSaveEditDetails} 
                                    // disabled={(brandOwnerDetailsFormError === "" ? true : false) || (brandOwnerDetailsFormError && (brandOwnerDetailsFormError?.person_name !== null || brandOwnerDetailsFormError?.mobile !== null || brandOwnerDetailsFormError?.email !== null))} 
                                    />
                                </div>
                            </div>
                        </>
                    }
                </>
            )
        } else if (currentTab === 1) {
            return (
                <>
                    {editFormVisible ?
                        <div className='bg-white storeDetailsContainer scrollChange'>
                            <div className='montSerratFont fontSize18 tabheader'>Brand Details</div>
                            <div className='position-relative'>
                                <div className='editStoreBtn'>
                                    <SecondaryButton onClick={onClickEditDetails} title={<div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <img src={EditIcon} alt="" />
                                            <div className='textColorBlue fontMedium'>Edit Details</div>
                                        </div>
                                    </div>} />
                                </div>
                                {/* {brandLogoImage !== '' &&  */}
                                <div className='paddingTop24'>
                                    <div className='brandImage brandLogoImage'>
                                        <img src={brandLogoImage} alt="Image Not found" />
                                    </div>
                                    {brandLogoImage === '' &&
                                        <div className='' style={{ color: "#ff0000", fontSize: '12px' }}>
                                            Please Upload The Logo In Document Tabs
                                        </div>
                                    }
                                </div>
                                {/* } */}
                            </div>
                            <div className='paddingTop24 notoSansFont'>
                                {brandDetailDataView && <div className='storeOwnerDetails'>
                                    <div>
                                        <div className='storeLabel'>Brand Name</div>
                                        <div className='storeValue'>{brandDetailDataView?.brand_name}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Brand Status</div>
                                        <div className='storeValue text-capitalize'>
                                            <StatusTag status={brandDetailDataView?.status ? 'Active' : 'Inactive'} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Brand Deck</div>
                                        <div className='storeValue'>
                                            <a className='brand_deck_a' href={brandDetailDataView?.brand_deck}>{brandDetailDataView?.brand_deck}</a>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Email Id</div>
                                        <div className='storeValue'>{brandDetailDataView?.email_id}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Brand Phone Number</div>
                                        <div className='storeValue'>+91 {brandDetailDataView?.brand_mobile}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Product Type</div>
                                        <div className='storeValue text-capitalize'>{brandDetailDataView?.product_type?.length !== 0 ? brandDetailDataView?.product_type?.join(' , ') : brandDetailDataView?.product_type}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Business Model</div>
                                        <div className='storeValue'>{brandDetailDataView?.business_model?.length !== 0 ? brandDetailDataView?.business_model?.join(' , ') : brandDetailDataView?.business_model}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>PAN Number</div>
                                        <div className='storeValue'>{brandDetailDataView?.pan}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>GSTIN</div>
                                        <div className='storeValue'>{brandDetailDataView?.gstin}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Security Status</div>
                                        <div className='storeValue text-capitalize'>{brandDetailDataView?.security ? 'Yes' : 'No'}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Security Comments</div>
                                        <div className='storeValue'>{brandDetailDataView?.security_comment}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Account Name</div>
                                        <div className='storeValue'>{brandDetailDataView?.bank_detail?.account_name}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Bank Name</div>
                                        <div className='storeValue'>{brandDetailDataView?.bank_detail?.bank_name}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Account Number</div>
                                        <div className='storeValue text-capitalize'>{brandDetailDataView?.bank_detail?.bank_no}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>IFSC Code</div>
                                        <div className='storeValue'>{brandDetailDataView?.bank_detail?.ifsc_code}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Branch</div>
                                        <div className='storeValue'>{brandDetailDataView?.bank_detail?.branch}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Branch Address</div>
                                        <div className='storeValue'>{brandDetailDataView?.bank_detail?.branch_address}</div>
                                    </div>
                                    {/* <div>
                                    <div className='storeLabel'>Branch Address Line - 2</div>
                                    <div className='storeValue text-capitalize'>
                                        <StatusTag status={brandDetails?.store_status ? 'Active' : 'Inactive'} />
                                    </div>
                                    </div> */}
                                    {/* <div>
                                    <div className='storeLabel'>Branch Pincode</div>
                                    <div className='storeValue'>{brandDetailDataView?.bank_detail?.branch_pincode}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Branch City</div>
                                    <div className='storeValue'>{brandDetailDataView?.bank_detail?.branch_city}</div>
                                </div>
                                <div>
                                    <div className='storeLabel'>Branch State</div>
                                    <div className='storeValue'>{brandDetailDataView?.bank_detail?.branch_state}</div>
                                </div> */}
                                </div>}
                            </div>
                        </div>
                        :
                        <>
                            {brandDetails &&
                                <div className='saveChangeCont scrollChange'>
                                    <div className='bg-white storeDetailsContainer'>
                                        <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                                            Brand Details
                                        </div>
                                        {/* {brandLogoImage !== '' && 
                                        <div className='paddingTop24'>
                                            <div className='brandImage brandLogoImage'>
                                                <img src={brandLogoImage} alt="" />
                                            </div>
                                        </div>
                                        } */}

                                        <div ref={firstTabRef} className='pt-3 formContainer brandDetails_cont customScroll'>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.brand_name}>
                                                <InputTextField type='text' disabled={false} name={'brand_name'} value={brandDetails?.brand_name} onChange={handleBrandDetailsChange} required minLength={''} maxLength={''} label='Brand Name' />
                                                {brandDetailsFormError.brand_name && <div className="error">{brandDetailsFormError.brand_name}</div>}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.status}>
                                                <CustomAutoComplete onChange={onChangeCOF} inputValue={inputStatus} onInputChange={handleInputOutputChange} value={selectedStatus} name={'status'} options={STATUS} required minLength={''} maxLength={''} label='Brand Status*' />
                                                {brandDetailsFormError.status && <div className="error">{brandDetailsFormError.status}</div>}
                                            </div>
                                            <div className='inputBottomSpace'>
                                                <InputTextField type='text' disabled={false} name={'brand_deck'} value={brandDetails?.brand_deck} onChange={handleBrandDetailsChange} minLength={''} maxLength={''} label='Brand Deck' />
                                                {/* {brandDetailsFormError.brand_deck && <div className="error">{brandDetailsFormError.brand_deck}</div>} */}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.email_id}>
                                                <InputTextField type='email' disabled={false} name={'email_id'} value={brandDetails?.email_id} onChange={handleBrandDetailsChange} required minLength={''} maxLength={''} label='Email Id' />
                                                {brandDetailsFormError.email_id && <div className="error">{brandDetailsFormError.email_id}</div>}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.brand_mobile}>
                                                <InputTextField type='text' disabled={false} name={'brand_mobile'} value={brandDetails?.brand_mobile} onChange={handleBrandDetailsChange} required minLength={10} maxLength={10} label='Brand Phone Number' />
                                                {brandDetailsFormError.brand_mobile && <div className="error">{brandDetailsFormError.brand_mobile}</div>}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.product_type}>
                                                {/* <CustomAutoComplete onChange={onChangeCOF} value={brandDetails?.status !== null ? (brandDetails.status ? 'Active' : 'Inactive') : ''} required options={STATUS} label='Product Type*' /> */}
                                                <MultiSelectCheckBox selectedValue={brandDetails?.product_type} name={'product_type'} handleChange={(event) => handleChannelChange('product_type', event)} options={productTypeMultiSlectData} MenuProps={MenuProps} label='Product Type*' />
                                                {brandDetailsFormError.product_type && <div className="error">{brandDetailsFormError.product_type}</div>}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.business_model}>
                                                {/* <CustomAutoComplete onChange={onChangeCOF} value={brandDetails?.status !== null ? (brandDetails.status ? 'Active' : 'Inactive') : ''} required options={STATUS} label='Business Model*' /> */}
                                                <MultiSelectCheckBox selectedValue={brandDetails?.business_model} name={'business_model'} handleChange={(event) => handleChannelChange('business_model', event)} options={bussinessModelMultiSlectData} MenuProps={MenuProps} label='Business Model' />
                                                {brandDetailsFormError.business_model && <div className="error">{brandDetailsFormError.business_model}</div>}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.pan}>
                                                <InputTextField type='text' disabled={false} name={'pan'} value={brandDetails?.pan} onChange={handleBrandDetailsChange} required minLength={''} maxLength={''} label='Pan Number' />
                                                {brandDetailsFormError.pan && <div className="error">{brandDetailsFormError.pan}</div>}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.gstin}>
                                                <InputTextField type='text' disabled={false} name={'gstin'} value={brandDetails?.gstin} onChange={handleBrandDetailsChange} required minLength={''} maxLength={''} label='GSTIN' />
                                                {brandDetailsFormError.gstin && <div className="error">{brandDetailsFormError.gstin}</div>}
                                            </div>
                                            <div className='inputBottomSpace' >
                                                <CustomAutoComplete onChange={onChangeCOF} inputValue={inputStatusYesNo} onInputChange={handleInputOutputChange} value={selectedSecurity} name={'security'} required options={YESNOSTATUS} label='Security Status' />
                                                {brandDetailsFormError.security && <div className="error">{brandDetailsFormError.security}</div>}
                                            </div>
                                            <div className='inputBottomSpace'>
                                                <InputTextField type='text' disabled={false} name={'security_comment'} value={brandDetails?.security_comment} onChange={handleBrandDetailsChange} minLength={''} maxLength={''} label='Security Comments' />
                                                {/* {brandDetailsFormError.security_comment && <div className="error">{brandDetailsFormError.security_comment}</div>} */}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.account_name}>
                                                <InputTextField type='text' disabled={false} name={'account_name'} value={brandDetails?.account_name} onChange={handleBrandDetailsChange} required minLength={''} maxLength={''} label='Account Name' />
                                                {brandDetailsFormError.account_name && <div className="error">{brandDetailsFormError.account_name}</div>}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.bank_name}>
                                                <InputTextField type='text' disabled={false} name={'bank_name'} value={brandDetails?.bank_name} onChange={handleBrandDetailsChange} required minLength={''} maxLength={''} label='Bank Name' />
                                                {brandDetailsFormError.bank_name && <div className="error">{brandDetailsFormError.bank_name}</div>}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.account_number}>
                                                <InputTextField type='text' disabled={false} name={'account_number'} value={brandDetails?.account_number} onChange={handleBrandDetailsChange} label='Account Number*' />
                                                {brandDetailsFormError.account_number && <div className="error">{brandDetailsFormError.account_number}</div>}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.ifsc_code}>
                                                <InputTextField type='text' disabled={false} name={'ifsc_code'} value={brandDetails?.ifsc_code} onChange={handleBrandDetailsChange} required minLength={''} maxLength={''} label='IFSC Code' />
                                                {brandDetailsFormError.ifsc_code && <div className="error">{brandDetailsFormError.ifsc_code}</div>}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.branch}>
                                                <InputTextField type='text' disabled={false} name={'branch'} value={brandDetails?.branch} onChange={handleBrandDetailsChange} required minLength={''} maxLength={''} label='Branch' />
                                                {brandDetailsFormError.branch && <div className="error">{brandDetailsFormError.branch}</div>}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.branch_address}>
                                                <InputTextField type='text' disabled={false} name={'branch_address'} value={brandDetails?.branch_address} onChange={handleBrandDetailsChange} required minLength={''} maxLength={''} label='Branch Address' />
                                                {brandDetailsFormError.branch_address && <div className="error">{brandDetailsFormError.branch_address}</div>}
                                            </div>
                                            {/* <div className='inputBottomSpace' ref={refs.brandOwnerDetails.person_name}>
                                                <InputTextField type='text' disabled={false} name={'branch_pincode'} value={brandDetails?.branch_pincode} onChange={handleBrandDetailsChange} required minLength={''} maxLength={''}  label='Branch Pin Code' />
                                                {brandDetailsFormError.branch_pincode && <div className="error">{brandDetailsFormError.branch_pincode}</div>}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.person_name}>
                                                <InputTextField type='text' disabled={true} name={'branch_city'} value={brandDetails?.branch_city} required minLength={''} maxLength={''}  label='Branch City' />
                                                {brandDetailsFormError.branch_city && <div className="error">{brandDetailsFormError.branch_city}</div>}
                                            </div>
                                            <div className='inputBottomSpace' ref={refs.brandOwnerDetails.person_name}>
                                                <InputTextField type='text' disabled={true} name={'branch_state'} value={brandDetails?.branch_state} required minLength={''} maxLength={''}  label='Branch State' />
                                                {brandDetailsFormError.branch_state && <div className="error">{brandDetailsFormError.branch_state}</div>}
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                                        <div className='pe-3'>
                                            <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                                        </div>
                                        <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                            <div>Save Changes</div>
                                        </div>} onClick={onClickSaveEditDetails} 
                                        // disabled={
                                        //     (brandDetailsFormError === "" ? true : false) ||
                                        //     (brandDetailsFormError &&
                                        //         (brandDetailsFormError?.brand_name !== null ||
                                        //             brandDetailsFormError?.email_id !== null ||
                                        //             brandDetailsFormError?.brand_mobile !== null ||
                                        //             brandDetailsFormError?.pan !== null ||
                                        //             brandDetailsFormError?.gstin !== null ||
                                        //             brandDetailsFormError?.account_name !== null ||
                                        //             brandDetailsFormError?.bank_name !== null ||
                                        //             brandDetailsFormError?.account_number !== null ||
                                        //             brandDetailsFormError?.ifsc_code !== null ||
                                        //             brandDetailsFormError?.branch !== null ||
                                        //             brandDetailsFormError?.branch_address !== null
                                        //             // brandDetailsFormError?.branch_pincode !== null ||
                                        //             // brandDetails?.branch_city === '' ||
                                        //             // brandDetails?.branch_state === '' 
                                        //         )) ||
                                        //     brandDetails?.product_type?.length === 0 ||
                                        //     brandDetails?.status === 'false'
                                        // } 
                                        />
                                    </div>
                                </div>
                            }
                        </>
                    }
                </>
            )
        } else if (currentTab === 2) {
            return (
                <>
                    {editFormVisible ?
                        <div className='bg-white storeDetailsContainer scrollChange'>
                            <div className='montSerratFont fontSize18 tabheader'>Brand Contacts</div>
                            <div className='position-relative'>
                                <div className='editStoreBtn'>
                                    <SecondaryButton onClick={onClickEditDetails} title={<div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <img src={EditIcon} alt="" />
                                            <div className='textColorBlue fontMedium'>Edit Details</div>
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                            <div className='paddingTop24 notoSansFont'>
                                {brandDetailDataView && <div className=''>
                                    {
                                        brandDetailDataView?.contact_person !== undefined ?
                                            <>{
                                                brandDetailDataView?.contact_person?.map((item, index) => (
                                                    <>
                                                        <div className='pt-3 pb-3'>
                                                            <strong>Contacts {index + 1}</strong>
                                                        </div>

                                                        <div className='storeOwnerDetails' key={index}>
                                                            <div>
                                                                <div className='storeLabel'>Contact Name</div>
                                                                <div className='storeValue'>{item?.person_name ? item?.person_name : '-'}</div>
                                                            </div>
                                                            <div>
                                                                <div className='storeLabel'>Contact’s Person Department</div>
                                                                <div className='storeValue'>{item?.person_dept ? item?.person_dept : '-'}</div>
                                                            </div>
                                                            <div>
                                                                <div className='storeLabel'>Contact’s Number</div>
                                                                <div className='storeValue'>+91 {item?.person_mobile ? item?.person_mobile : '-'}</div>
                                                            </div>
                                                            <div>
                                                                <div className='storeLabel'>Contact’s E- Mail Id</div>
                                                                <div className='storeValue'>{item?.email ? item?.email : '-'}</div>
                                                            </div>
                                                            <div>
                                                                <div className='storeLabel'>Contact Person Other Details</div>
                                                                <div className='storeValue'>{item?.person_other ? item?.person_other : '-'}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                            }
                                            </>
                                            :
                                            <>
                                                <div className='pt-3 pb-3'>
                                                    <strong>Contacts 1</strong>
                                                </div>

                                                <div className='storeOwnerDetails'>
                                                    <div>
                                                        <div className='storeLabel'>Contact Name</div>
                                                        <div className='storeValue'>{'-'}</div>
                                                    </div>
                                                    <div>
                                                        <div className='storeLabel'>Contact’s Person Department</div>
                                                        <div className='storeValue'>{'-'}</div>
                                                    </div>
                                                    <div>
                                                        <div className='storeLabel'>Contact’s Number</div>
                                                        <div className='storeValue'>+91 {'-'}</div>
                                                    </div>
                                                    <div>
                                                        <div className='storeLabel'>Contact’s E- Mail Id</div>
                                                        <div className='storeValue'>{'-'}</div>
                                                    </div>
                                                    <div>
                                                        <div className='storeLabel'>Contact Person Other Details</div>
                                                        <div className='storeValue'>{'-'}</div>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>}
                            </div>
                        </div>
                        :
                        <>
                            <div className='saveChangeCont scrollChange'>
                                <div className='bg-white storeDetailsContainer'>
                                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                                        Brand Contacts
                                    </div>
                                    <div className='brand_contact_tab customScroll'>
                                        <div ref={secondTabRef} className='brandContactsDetals' style={{ position: 'relative' }}>
                                            {
                                                contactDetails && contactDetails?.map((item, index) => (
                                                    <>
                                                        <div className='pt-3'>
                                                            <strong>Contacts {index + 1}</strong>
                                                        </div>

                                                        <div className='pt-3 formContainer' key={index}>
                                                            <div className='inputBottomSpace' ref={index === 0 ? refs.contactDetails.person_name : null}>
                                                                <InputTextField type='text' disabled={false} name={'person_name'} value={item?.person_name} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={''} maxLength={''} label='Contact Name' />
                                                                {index === 0 && contactDetailsFormError.person_name && <div className="error">{contactDetailsFormError.person_name}</div>}
                                                            </div>
                                                            <div className='inputBottomSpace' ref={index === 0 ? refs.contactDetails.person_dept : null}>
                                                                <InputTextField type='text' disabled={false} name={'person_dept'} value={item?.person_dept} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={''} maxLength={''} label='Contact’s Person Department' />
                                                                {index === 0 && contactDetailsFormError?.person_dept && <div className="error">{contactDetailsFormError?.person_dept}</div>}
                                                            </div>
                                                            <div className='inputBottomSpace' ref={index === 0 ? refs.contactDetails.person_mobile : null}>
                                                                <InputTextField type='text' disabled={false} name={'person_mobile'} value={item?.person_mobile} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={10} maxLength={10} label='Contact’s Number' />
                                                                {index === 0 && contactDetailsFormError.person_mobile && <div className="error">{contactDetailsFormError.person_mobile}</div>}
                                                            </div>
                                                            <div className='inputBottomSpace' ref={index === 0 ? refs.contactDetails.email : null}>
                                                                <InputTextField type='text' disabled={false} name={'email'} value={item?.email} onChange={(event) => handleBrandContactsDetailsChange(event, index)} required minLength={''} maxLength={''} label='Email Id' />
                                                                {index === 0 && contactDetailsFormError.email && <div className="error">{contactDetailsFormError.email}</div>}
                                                            </div>
                                                            <div className='inputBottomSpace'>
                                                                <InputTextField type='text' disabled={false} name={'person_other'} value={item?.person_other} onChange={(event) => handleBrandContactsDetailsChange(event, index)} minLength={''} maxLength={''} label='Contact Person Other Details' />
                                                                {/* {contactDetailsFormError.person_other && <div className="error">{contactDetailsFormError.person_other}</div>} */}
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                            }

                                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', position: 'absolute', right: '15px', bottom: '20px' }} >
                                                <div onClick={addNewContactDetailsForm} className='' style={{ width: '30px', height: '30px', display: 'flex', border: '1px solid grey', borderRadius: '50%', cursor: 'pointer' }}>
                                                    <img src={PlusIcon} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                                    <div className='pe-3'>
                                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                                    </div>
                                    <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                        <div>Save Changes</div>
                                    </div>} onClick={onClickSaveEditDetails}
                                        // disabled={(contactDetailsFormError === "" ? true : false) || (contactDetailsFormError && (contactDetailsFormError.person_dept !== null || contactDetailsFormError.person_name !== null || contactDetailsFormError.person_mobile !== null || contactDetailsFormError.email !== null))}
                                    // disabled={isDisabled()}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </>
            )
        } else if (currentTab === 3) {
            return (
                <>
                    {editFormVisible ?
                        <div className='bg-white storeDetailsContainer scrollChange'>
                            <div className='montSerratFont fontSize18 tabheader'>Channel Details</div>
                            <div className='position-relative'>
                                <div className='editStoreBtn'>
                                    <SecondaryButton onClick={onClickEditDetails} title={<div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <img src={EditIcon} alt="" />
                                            <div className='textColorBlue fontMedium'>Edit Details</div>
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                            <div className='paddingTop24 notoSansFont'>
                                {brandDetailDataView && <div className='storeOwnerDetails'>
                                    <div>
                                        <div className='storeLabel'>Channel Served</div>
                                        <div className='storeValue'>{brandDetailDataView?.channel_served ? brandDetailDataView?.channel_served?.join(", ") : '-'}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Offline Channel</div>
                                        <div className='storeValue'>{brandDetailDataView?.channel_offline ? brandDetailDataView?.channel_offline?.join(", ") : '-'}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Online Channel</div>
                                        <div className='storeValue'>{brandDetailDataView?.channel_online ? brandDetailDataView?.channel_online?.join(", ") : '-'}</div>
                                    </div>

                                </div>}
                            </div>
                        </div>
                        :
                        <>
                            <div className='saveChangeCont scrollChange'>
                                <div className='bg-white storeDetailsContainer'>
                                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                                        Channel Details
                                    </div>
                                    <div ref={thirdTabRef} className='pt-3 formContainer brandDetails_cont  customScroll'>
                                        <div className='inputBottomSpace' >
                                            <MultiSelectCheckBox selectedValue={channelDetails?.channel_served} name={'channel_served'} handleChange={(event) => handleChannelChange('channel_served', event)} options={channelServedMultiSlectData} MenuProps={MenuProps} label='Channel Served' />
                                            {channelDetailsFormError.channel_served && <div className="error">{channelDetailsFormError.channel_served}</div>}
                                        </div>
                                        <div className='inputBottomSpace' >
                                            <MultiSelectCheckBox selectedValue={channelOfflineStatus ? [] : channelDetails?.channel_offline} name={'channel_offline'} handleChange={(event) => handleChannelChange('channel_offline', event)} options={channelOfflineMultiSlectData} MenuProps={MenuProps} disabled={channelOfflineStatus} label='Offline Channel' />
                                            {channelDetailsFormError.channel_offline && <div className="error">{channelDetailsFormError.channel_offline}</div>}
                                        </div>
                                        <div className='inputBottomSpace' >
                                            <MultiSelectCheckBox selectedValue={channelOnlineStatus ? [] : channelDetails?.channel_online} name={'channel_online'} handleChange={(event) => handleChannelChange('channel_online', event)} options={channelOnlineMultiSlectData} MenuProps={MenuProps} disabled={channelOnlineStatus} label='Online Channel' />
                                            {channelDetailsFormError.channel_online && <div className="error">{channelDetailsFormError.channel_online}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                                    <div className='pe-3'>
                                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                                    </div>
                                    <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                        <div>Save Changes</div>
                                    </div>} onClick={onClickSaveEditDetails} />
                                </div>
                            </div>
                        </>
                    }
                </>
            )
        } else if (currentTab === 4) {
            return (
                <>
                    {editFormVisible ?
                        <div className='bg-white storeDetailsContainer scrollChange'>
                            <div className='montSerratFont fontSize18 tabheader'>Brand Address Details</div>
                            <div className='position-relative'>
                                <div className='editStoreBtn'>
                                    <SecondaryButton onClick={onClickEditDetails} title={<div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <img src={EditIcon} alt="" />
                                            <div className='textColorBlue fontMedium'>Edit Details</div>
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                            <div className='notoSansFont'>
                                <div className='text-decoration-underline paddingTop24 fontSize14'>Registered Office Address</div>
                                <div className='paddingTop24 notoSansFont'>
                                    {brandDetailDataView && <div className='storeOwnerDetails'>
                                        <div>
                                            <div className='storeLabel'>Address</div>
                                            <div className='storeValue'>{brandDetailDataView?.registered_office_address?.address}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Pincode</div>
                                            <div className='storeValue'>{brandDetailDataView?.registered_office_address?.pincode}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Area</div>
                                            <div className='storeValue'>{brandDetailDataView?.registered_office_address?.area}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>City</div>
                                            <div className='storeValue'>{brandDetailDataView?.registered_office_address?.city}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Region</div>
                                            <div className='storeValue text-capitalize'>{brandDetailDataView?.registered_office_address?.region}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>State</div>
                                            <div className='storeValue'>{brandDetailDataView?.registered_office_address?.state}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>E-Mail Id</div>
                                            <div className='storeValue'>{brandDetailDataView?.registered_office_contact?.email}</div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div className='notoSansFont pt-3'>
                                <div className='text-decoration-underline paddingTop24 fontSize14'>Warehouse Address</div>
                                <div className='paddingTop24 notoSansFont'>
                                    {brandDetailDataView && <div className='storeOwnerDetails'>
                                        <div>
                                            <div className='storeLabel'>Address</div>
                                            <div className='storeValue'>{brandDetailDataView?.warehouse_address?.address}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Pincode</div>
                                            <div className='storeValue'>{brandDetailDataView?.warehouse_address?.pincode}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Area</div>
                                            <div className='storeValue'>{brandDetailDataView?.warehouse_address?.area}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>City</div>
                                            <div className='storeValue'>{brandDetailDataView?.warehouse_address?.city}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Region</div>
                                            <div className='storeValue text-capitalize'>{brandDetailDataView?.warehouse_address?.region}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>State</div>
                                            <div className='storeValue'>{brandDetailDataView?.warehouse_address?.state}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>E- Mail Id</div>
                                            <div className='storeValue'>{brandDetailDataView?.warehouse_contact?.email}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Mobile Number</div>
                                            <div className='storeValue'>+91 {brandDetailDataView?.warehouse_contact?.mobile}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Person Mobile Number</div>
                                            <div className='storeValue text-capitalize'>+91 {brandDetailDataView?.warehouse_contact?.person_mobile}</div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div className='notoSansFont pt-3'>
                                <div className='text-decoration-underline paddingTop24 fontSize14'>Return Warehouse Address</div>
                                <div className='paddingTop24 notoSansFont'>
                                    {brandDetailDataView && <div className='storeOwnerDetails'>
                                        <div>
                                            <div className='storeLabel'>Address</div>
                                            <div className='storeValue'>{brandDetailDataView?.return_warehouse_address?.address}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Pincode</div>
                                            <div className='storeValue'>{brandDetailDataView?.return_warehouse_address?.pincode}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Area</div>
                                            <div className='storeValue'>{brandDetailDataView?.return_warehouse_address?.area}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>City</div>
                                            <div className='storeValue'>{brandDetailDataView?.return_warehouse_address?.city}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Region</div>
                                            <div className='storeValue text-capitalize'>{brandDetailDataView?.return_warehouse_address?.region ? brandDetailDataView?.return_warehouse_address?.region : '-'}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>State</div>
                                            <div className='storeValue'>{brandDetailDataView?.return_warehouse_address?.state}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>E- Mail Id</div>
                                            <div className='storeValue'>{brandDetailDataView?.return_warehouse_contact?.email ? brandDetailDataView?.return_warehouse_contact?.email : '-'}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Place Mobile Number</div>
                                            <div className='storeValue'>+91 {brandDetailDataView?.return_warehouse_contact?.mobile}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Person Mobile Number</div>
                                            <div className='storeValue text-capitalize'>+91 {brandDetailDataView?.return_warehouse_contact?.person_mobile}</div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div className='notoSansFont pt-3'>
                                <div className='text-decoration-underline paddingTop24 fontSize14'>Billing Place Address</div>
                                <div className='paddingTop24 notoSansFont'>
                                    {brandDetailDataView && <div className='storeOwnerDetails'>
                                        <div>
                                            <div className='storeLabel'>Address</div>
                                            <div className='storeValue'>{brandDetailDataView?.billing_address?.address}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Pincode</div>
                                            <div className='storeValue'>{brandDetailDataView?.billing_address?.pincode}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Area</div>
                                            <div className='storeValue'>{brandDetailDataView?.billing_address?.area}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>City</div>
                                            <div className='storeValue'>{brandDetailDataView?.billing_address?.city}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Region</div>
                                            <div className='storeValue text-capitalize'>{brandDetailDataView?.billing_address?.region}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>State</div>
                                            <div className='storeValue'>{brandDetailDataView?.billing_address?.state}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>E- Mail Id</div>
                                            <div className='storeValue'>{brandDetailDataView?.billing_contact?.email}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Place Mobile Number</div>
                                            <div className='storeValue'>+91 {brandDetailDataView?.billing_contact?.mobile}</div>
                                        </div>
                                        <div>
                                            <div className='storeLabel'>Person Mobile Number</div>
                                            <div className='storeValue text-capitalize'>+91 {brandDetailDataView?.billing_contact?.person_mobile}</div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className='saveChangeCont scrollChange'>
                                <div className='bg-white storeDetailsContainer'>
                                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                                        Brand Address Details
                                    </div>
                                    <div ref={forthTabRef} className='pt-3 customScroll addressDetailsMainCont'>
                                        <div className='addressDetailsCont'>
                                            <div>
                                                <div className='text-decoration-underline paddingTop24 fontSize14 pb-3'>Registered Office Address</div>
                                                <div className='formContainer addressesDetailsCont'>
                                                    <div className='inputBottomSpace' ref={refs.registered_office_address.address}>
                                                        <InputTextField type='text' disabled={false} name={'registered_office_address.address'} value={address?.registered_office_address?.address} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Address' />
                                                        {addressFormError?.registered_office_address?.address && <div className="error">{addressFormError?.registered_office_address?.address}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.registered_office_address.pincode}>
                                                        <InputTextField type='text' disabled={false} name={'registered_office_address.pincode'} value={address?.registered_office_address?.pincode} onChange={handleBrandAddressChange} required minLength={6} maxLength={6} label='Pincode' />
                                                        {/* <InputTextField type='text' disabled={false} name={'registered_office_address.pincode'} value={address?.registered_office_address?.pincode} onChange={handleBrandAddressChange} required minLength={''} maxLength={''}  label='Pincode' /> */}
                                                        {addressFormError?.registered_office_address?.pincode && <div className="error">{addressFormError?.registered_office_address?.pincode}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.registered_office_address.area}>
                                                        <InputTextField type='text' disabled={false} name={'registered_office_address.area'} value={address?.registered_office_address?.area} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Area' />
                                                        {addressFormError?.registered_office_address?.area && <div className="error">{addressFormError?.registered_office_address?.area}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' >
                                                        <InputTextField type='text' disabled={true} name={'registered_office_address.city'} value={address?.registered_office_address?.city} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='City' />
                                                        {addressFormError?.registered_office_address?.city && <div className="error">{addressFormError?.registered_office_address?.city}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' >
                                                        <InputTextField type='text' disabled={true} name={'registered_office_address.region'} value={address?.registered_office_address?.region} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Region' />
                                                        {addressFormError?.registered_office_address?.region && <div className="error">{addressFormError?.registered_office_address?.region}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' >
                                                        <InputTextField type='text' disabled={true} name={'registered_office_address.state'} value={address?.registered_office_address?.state} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='State' />
                                                        {addressFormError?.registered_office_address?.state && <div className="error">{addressFormError?.registered_office_address?.state}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.registered_office_address.email}>
                                                        <InputTextField type='text' disabled={false} name={'registered_office_address.email'} value={address?.registered_office_address?.email} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='E-Mail Id' />
                                                        {addressFormError?.registered_office_address?.email && <div className="error">{addressFormError?.registered_office_address?.email}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='text-decoration-underline paddingTop24 fontSize14 pb-3'>
                                                    Warehouse Address
                                                    <span className='copySameAsAbove'>
                                                        <input type='checkbox' className='form-check-input' id='sameAsAbove' onChange={handleCopyRegisteredOfficeAddress} />
                                                        <label className='form-check-label' htmlFor='sameAsAbove'>
                                                            Same as Registered Office Address
                                                        </label>
                                                    </span>
                                                </div>
                                                <div className='formContainer addressesDetailsCont'>
                                                    <div className='inputBottomSpace' ref={refs.warehouse_address.address}>
                                                        <InputTextField type='text' disabled={false} name={'warehouse_address.address'} value={address?.warehouse_address?.address} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Address' />
                                                        {addressFormError?.warehouse_address?.address && <div className="error">{addressFormError?.warehouse_address?.address}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.warehouse_address.pincode}>
                                                        <InputTextField type='text' disabled={false} name={'warehouse_address.pincode'} value={address?.warehouse_address?.pincode} onChange={handleBrandAddressChange} required minLength={6} maxLength={6} label='Pincode' />
                                                        {addressFormError?.warehouse_address?.pincode && <div className="error">{addressFormError?.warehouse_address?.pincode}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.warehouse_address.area}>
                                                        <InputTextField type='text' disabled={false} name={'warehouse_address.area'} value={address?.warehouse_address?.area} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Area' />
                                                        {addressFormError?.warehouse_address?.area && <div className="error">{addressFormError?.warehouse_address?.area}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} name={'warehouse_address.city'} value={address?.warehouse_address?.city} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='City' />
                                                        {addressFormError?.warehouse_address?.city && <div className="error">{addressFormError?.warehouse_address?.city}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' >
                                                        <InputTextField type='text' disabled={true} name={'warehouse_address.region'} value={address?.warehouse_address?.region} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Region' />
                                                        {addressFormError?.warehouse_address?.region && <div className="error">{addressFormError?.warehouse_address?.region}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' >
                                                        <InputTextField type='text' disabled={true} name={'warehouse_address.state'} value={address?.warehouse_address?.state} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='State' />
                                                        {addressFormError?.warehouse_address?.state && <div className="error">{addressFormError?.warehouse_address?.state}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.warehouse_address.email}>
                                                        <InputTextField type='text' disabled={false} name={'warehouse_address.email'} value={address?.warehouse_address?.email} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='E-Mail Id' />
                                                        {addressFormError?.warehouse_address?.email && <div className="error">{addressFormError?.warehouse_address?.email}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.warehouse_address.mobile}>
                                                        <InputTextField type='text' disabled={false} name={'warehouse_address.mobile'} value={address?.warehouse_address?.mobile} onChange={handleBrandAddressChange} required minLength={10} maxLength={10} label='Mobile Number' />
                                                        {addressFormError?.warehouse_address?.mobile && <div className="error">{addressFormError?.warehouse_address?.mobile}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.warehouse_address.person_mobile}>
                                                        <InputTextField type='text' disabled={false} name={'warehouse_address.person_mobile'} value={address?.warehouse_address?.person_mobile} onChange={handleBrandAddressChange} required minLength={10} maxLength={10} label='Person Mobile Number' />
                                                        {addressFormError?.warehouse_address?.person_mobile && <div className="error">{addressFormError?.warehouse_address?.person_mobile}</div>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='text-decoration-underline paddingTop24 fontSize14 pb-3'>
                                                    Return Warehouse Address
                                                    <span className='copySameAsAbove'>
                                                        <input type='checkbox' className='form-check-input' id='sameAsBilling' onChange={handleCopyWarehouseAddress} />
                                                        <label className='form-check-label' htmlFor='sameAsBilling'>
                                                            Same as Warehouse Address
                                                        </label>
                                                    </span>
                                                </div>
                                                <div className='formContainer addressesDetailsCont'>
                                                    <div className='inputBottomSpace' ref={refs.return_warehouse_address.address}>
                                                        <InputTextField type='text' disabled={false} name={'return_warehouse_address.address'} value={address?.return_warehouse_address?.address} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Address' />
                                                        {addressFormError?.return_warehouse_address?.address && <div className="error">{addressFormError?.return_warehouse_address?.address}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.return_warehouse_address.pincode}>
                                                        <InputTextField type='text' disabled={false} name={'return_warehouse_address.pincode'} value={address?.return_warehouse_address?.pincode} onChange={handleBrandAddressChange} required minLength={6} maxLength={6} label='Pincode' />
                                                        {addressFormError?.return_warehouse_address?.pincode && <div className="error">{addressFormError?.return_warehouse_address?.pincode}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.return_warehouse_address.area}>
                                                        <InputTextField type='text' disabled={false} name={'return_warehouse_address.area'} value={address?.return_warehouse_address?.area} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Area' />
                                                        {addressFormError?.return_warehouse_address?.area && <div className="error">{addressFormError?.return_warehouse_address?.area}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} name={'return_warehouse_address.city'} value={address?.return_warehouse_address?.city} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='City' />
                                                        {addressFormError?.return_warehouse_address?.city && <div className="error">{addressFormError?.return_warehouse_address?.city}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' >
                                                        <InputTextField type='text' disabled={true} name={'return_warehouse_address.region'} value={address?.return_warehouse_address?.region} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Region' />
                                                        {addressFormError?.return_warehouse_address?.region && <div className="error">{addressFormError?.return_warehouse_address?.region}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' >
                                                        <InputTextField type='text' disabled={true} name={'return_warehouse_address.state'} value={address?.return_warehouse_address?.state} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='State' />
                                                        {addressFormError?.return_warehouse_address?.state && <div className="error">{addressFormError?.return_warehouse_address?.state}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.return_warehouse_address.email}>
                                                        <InputTextField type='text' disabled={false} name={'return_warehouse_address.email'} value={address?.return_warehouse_address?.email} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='E-Mail Id' />
                                                        {addressFormError?.return_warehouse_address?.email && <div className="error">{addressFormError?.return_warehouse_address?.email}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.return_warehouse_address.mobile}>
                                                        <InputTextField type='text' disabled={false} name={'return_warehouse_address.mobile'} value={address?.return_warehouse_address?.mobile} onChange={handleBrandAddressChange} required minLength={10} maxLength={10} label='Place Mobile Number' />
                                                        {addressFormError?.return_warehouse_address?.mobile && <div className="error">{addressFormError?.return_warehouse_address?.mobile}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.return_warehouse_address.person_mobile}>
                                                        <InputTextField type='text' disabled={false} name={'return_warehouse_address.person_mobile'} value={address?.return_warehouse_address?.person_mobile} onChange={handleBrandAddressChange} required minLength={10} maxLength={10} label='Person Mobile Number' />
                                                        {addressFormError?.return_warehouse_address?.person_mobile && <div className="error">{addressFormError?.return_warehouse_address?.person_mobile}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='text-decoration-underline paddingTop24 fontSize14 pb-3'>
                                                    Billing Place Address
                                                    <span className='copySameAsAbove'>
                                                        <input type='checkbox' className='form-check-input' id='sameAsBilling' checked={billingChecked.registeredOffice} onChange={handleCopyRegisteredOfficeAddressForBillingPlace} />
                                                        <label className='form-check-label' htmlFor='sameAsBilling'>
                                                            Same as Registered Office Address
                                                        </label>
                                                    </span>
                                                    <span className='copySameAsAbove'>
                                                        <input type='checkbox' className='form-check-input' id='sameAsBilling' checked={billingChecked.warehouse} onChange={handleCopyWarehouseAddressForBillingPlace} />
                                                        <label className='form-check-label' htmlFor='sameAsBilling'>
                                                            Same as Warehouse Address
                                                        </label>
                                                    </span>
                                                </div>
                                                <div className='formContainer addressesDetailsCont'>
                                                    <div className='inputBottomSpace' ref={refs.billing_address.address}>
                                                        <InputTextField type='text' disabled={false} name={'billing_address.address'} value={address?.billing_address?.address} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Address' />
                                                        {addressFormError?.billing_address?.address && <div className="error">{addressFormError?.billing_address?.address}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.billing_address.pincode}>
                                                        <InputTextField type='text' disabled={false} name={'billing_address.pincode'} value={address?.billing_address?.pincode} onChange={handleBrandAddressChange} required minLength={6} maxLength={6} label='Pincode' />
                                                        {addressFormError?.billing_address?.pincode && <div className="error">{addressFormError?.billing_address?.pincode}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.billing_address.area}>
                                                        <InputTextField type='text' disabled={false} name={'billing_address.area'} value={address?.billing_address?.area} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Area' />
                                                        {addressFormError?.billing_address?.area && <div className="error">{addressFormError?.billing_address?.area}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace'>
                                                        <InputTextField type='text' disabled={true} name={'billing_address.city'} value={address?.billing_address?.city} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='City' />
                                                        {addressFormError?.billing_address?.city && <div className="error">{addressFormError?.billing_address?.city}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' >
                                                        <InputTextField type='text' disabled={true} name={'billing_address.region'} value={address?.billing_address?.region} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='Region' />
                                                        {addressFormError?.billing_address?.region && <div className="error">{addressFormError?.billing_address?.region}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' >
                                                        <InputTextField type='text' disabled={true} name={'billing_address.state'} value={address?.billing_address?.state} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='State' />
                                                        {addressFormError?.billing_address?.state && <div className="error">{addressFormError?.billing_address?.state}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.billing_address.email}>
                                                        <InputTextField type='text' disabled={false} name={'billing_address.email'} value={address?.billing_address?.email} onChange={handleBrandAddressChange} required minLength={''} maxLength={''} label='E-Mail Id' />
                                                        {addressFormError?.billing_address?.email && <div className="error">{addressFormError?.billing_address?.email}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.billing_address.mobile}>
                                                        <InputTextField type='text' disabled={false} name={'billing_address.mobile'} value={address?.billing_address?.mobile} onChange={handleBrandAddressChange} required minLength={10} maxLength={10} label='Place Mobile Number' />
                                                        {addressFormError?.billing_address?.mobile && <div className="error">{addressFormError?.billing_address?.mobile}</div>}
                                                    </div>
                                                    <div className='inputBottomSpace' ref={refs.billing_address.person_mobile}>
                                                        <InputTextField type='text' disabled={false} name={'billing_address.person_mobile'} value={address?.billing_address?.person_mobile} onChange={handleBrandAddressChange} required minLength={10} maxLength={10} label='Person Mobile Number' />
                                                        {addressFormError?.billing_address?.person_mobile && <div className="error">{addressFormError?.billing_address?.person_mobile}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                                    <div className='pe-3'>
                                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                                    </div>
                                    <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                        <div>Save Changes</div>
                                    </div>} onClick={onClickSaveEditDetails} 
                                    // disabled={
                                    //     (addressFormError === "" ? true : false) ||
                                    //     (addressFormError && (
                                    //         addressFormError?.registered_office_address?.address !== null ||
                                    //         addressFormError?.registered_office_address?.pincode !== null ||
                                    //         addressFormError?.registered_office_address?.area !== null ||
                                    //         addressFormError?.registered_office_address?.email !== null ||

                                    //         addressFormError?.warehouse_address?.address !== null ||
                                    //         addressFormError?.warehouse_address?.pincode !== null ||
                                    //         addressFormError?.warehouse_address?.area !== null ||
                                    //         addressFormError?.warehouse_address?.email !== null ||
                                    //         addressFormError?.warehouse_address?.mobile !== null ||
                                    //         addressFormError?.warehouse_address?.person_mobile !== null ||

                                    //         addressFormError?.return_warehouse_address?.address !== null ||
                                    //         addressFormError?.return_warehouse_address?.pincode !== null ||
                                    //         addressFormError?.return_warehouse_address?.area !== null ||

                                    //         addressFormError?.return_warehouse_address?.email !== null ||
                                    //         addressFormError?.return_warehouse_address?.mobile !== null ||
                                    //         addressFormError?.return_warehouse_address?.person_mobile !== null ||

                                    //         addressFormError?.billing_address?.address !== null ||
                                    //         addressFormError?.billing_address?.pincode !== null ||
                                    //         addressFormError?.billing_address?.area !== null ||
                                    //         addressFormError?.billing_address?.email !== null ||
                                    //         addressFormError?.billing_address?.mobile !== null ||
                                    //         addressFormError?.billing_address?.person_mobile !== null ||

                                    //         address?.registered_office_address?.state === '' ||
                                    //         address?.registered_office_address?.city === '' ||

                                    //         address?.warehouse_address?.state === '' ||
                                    //         address?.warehouse_address?.city === '' ||
                                    //         address?.warehouse_address?.region === '' ||

                                    //         address?.return_warehouse_address?.state === '' ||
                                    //         address?.return_warehouse_address?.city === '' ||

                                    //         address?.billing_address?.region === '' ||
                                    //         address?.billing_address?.state === ''
                                    //     ))} 
                                    />
                                </div>
                            </div>
                        </>
                    }
                </>
            )
        } else if (currentTab === 5) {
            return (
                <>
                    {editFormVisible ?
                        <div className='bg-white storeDetailsContainer scrollChange'>
                            <div className='montSerratFont fontSize18 tabheader'>Tekx Contact Details</div>
                            <div className='position-relative'>
                                <div className='editStoreBtn'>
                                    <SecondaryButton onClick={onClickEditDetails} title={<div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <img src={EditIcon} alt="" />
                                            <div className='textColorBlue fontMedium'>Edit Details</div>
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                            <div className='paddingTop24 notoSansFont'>
                                {brandDetailDataView && <div className='storeOwnerDetails'>
                                    <div>
                                        <div className='storeLabel'>Contact Name</div>
                                        <div className='storeValue'>{brandDetailDataView?.company_contact_detail && brandDetailDataView?.company_contact_detail?.person_name}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Mobile Number</div>
                                        <div className='storeValue'>+91 {brandDetailDataView?.company_contact_detail && brandDetailDataView?.company_contact_detail?.mobile}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Email Id</div>
                                        <div className='storeValue text-capitalize'>{brandDetailDataView?.company_contact_detail && brandDetailDataView?.company_contact_detail?.email}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Contact Person Department</div>
                                        <div className='storeValue'>{brandDetailDataView?.company_contact_detail && brandDetailDataView?.company_contact_detail?.person_dept}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Account Name</div>
                                        <div className='storeValue'>{brandDetailDataView?.company_bank_detail?.account_name}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Bank Name</div>
                                        <div className='storeValue text-capitalize'>{brandDetailDataView?.company_bank_detail?.bank_name}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Account Number</div>
                                        <div className='storeValue'>{brandDetailDataView?.company_bank_detail?.bank_no}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>IFSC Code</div>
                                        <div className='storeValue'>{brandDetailDataView?.company_bank_detail?.ifsc_code}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Branch</div>
                                        <div className='storeValue text-capitalize'>{brandDetailDataView?.company_bank_detail?.branch}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Branch Address</div>
                                        <div className='storeValue'>{brandDetailDataView?.company_bank_detail?.branch_address}</div>
                                    </div>
                                    {/* <div>
                                    <div className='storeLabel'>Branch Address Line - 2</div>
                                    <div className='storeValue'>{brandDetailDataView?.company_bank_detail?.branch_address2}</div>
                                    </div> */}
                                    {/* <div>
                                        <div className='storeLabel'>Branch Pin Code</div>
                                        <div className='storeValue'>{brandDetailDataView?.company_bank_detail?.branch_pincode ? brandDetailDataView?.company_bank_detail?.branch_pincode : '-'}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Branch City</div>
                                        <div className='storeValue'>{brandDetailDataView?.company_bank_detail?.branch_city ? brandDetailDataView?.company_bank_detail?.branch_city : '-'}</div>
                                    </div>
                                    <div>
                                        <div className='storeLabel'>Branch State</div>
                                        <div className='storeValue'>{brandDetailDataView?.company_bank_detail?.branch_state ? brandDetailDataView?.company_bank_detail?.branch_state : '-'}</div>
                                    </div> */}
                                </div>}
                            </div>
                        </div>
                        :
                        <>
                            <div className='saveChangeCont scrollChange'>
                                <div className='bg-white storeDetailsContainer'>
                                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                                        Tekx Contact Details
                                    </div>
                                    <div ref={fifthTabRef} className='pt-3 formContainer customScroll tekxContactDetails_cont'>
                                        <div className='inputBottomSpace' ref={refs.companyContactDetails.contacts_name}>
                                            <InputTextField type='text' disabled={false} name={'contacts_name'} value={companyContactDetails?.contacts_name} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Contact Name' />
                                            {companyContactDetailsFormError.contacts_name && <div className="error">{companyContactDetailsFormError.contacts_name}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={refs.companyContactDetails.contacts_mobile}>
                                            <InputTextField type='text' disabled={false} name={'contacts_mobile'} value={companyContactDetails?.contacts_mobile} onChange={handleCompanyContactDetailsChange} required minLength={10} maxLength={10} label='Mobile Number' />
                                            {companyContactDetailsFormError.contacts_mobile && <div className="error">{companyContactDetailsFormError.contacts_mobile}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={refs.companyContactDetails.contacts_email_id}>
                                            <InputTextField type='text' disabled={false} name={'contacts_email_id'} value={companyContactDetails?.contacts_email_id} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Email Id' />
                                            {companyContactDetailsFormError.contacts_email_id && <div className="error">{companyContactDetailsFormError.contacts_email_id}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={refs.companyContactDetails.contacts_person_dept}>
                                            <InputTextField type='text' disabled={false} name={'contacts_person_dept'} value={companyContactDetails?.contacts_person_dept} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Contact’s Person Department' />
                                            {/* <CustomAutoComplete onChange={onChangeCOF} value={brandDetails?.status !== null ? (brandDetails.status ? 'Active' : 'Inactive') : ''} required options={STATUS} label='Contact’s Person Department*' /> */}
                                            {companyContactDetailsFormError.contacts_person_dept && <div className="error">{companyContactDetailsFormError.contacts_person_dept}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={refs.companyContactDetails.account_name}>
                                            <InputTextField type='text' disabled={false} name={'account_name'} value={companyContactDetails?.account_name} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Account Name' />
                                            {companyContactDetailsFormError.account_name && <div className="error">{companyContactDetailsFormError.account_name}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={refs.companyContactDetails.bank_name}>
                                            <InputTextField type='text' disabled={false} name={'bank_name'} value={companyContactDetails?.bank_name} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Bank Name' />
                                            {companyContactDetailsFormError.bank_name && <div className="error">{companyContactDetailsFormError.bank_name}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={refs.companyContactDetails.account_number}>
                                            <InputTextField type='text' disabled={false} name={'account_number'} value={companyContactDetails?.account_number} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Account Number' />
                                            {companyContactDetailsFormError.account_number && <div className="error">{companyContactDetailsFormError.account_number}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={refs.companyContactDetails.ifsc_code}>
                                            <InputTextField type='text' disabled={false} name={'ifsc_code'} value={companyContactDetails?.ifsc_code} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='IFSC Code' />
                                            {companyContactDetailsFormError.ifsc_code && <div className="error">{companyContactDetailsFormError.ifsc_code}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={refs.companyContactDetails.branch}>
                                            <InputTextField type='text' disabled={false} name={'branch'} value={companyContactDetails?.branch} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Branch' />
                                            {companyContactDetailsFormError.branch && <div className="error">{companyContactDetailsFormError.branch}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={refs.companyContactDetails.branch_address}>
                                            <InputTextField type='text' disabled={false} name={'branch_address'} value={companyContactDetails?.branch_address} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''} label='Branch Address' />
                                            {companyContactDetailsFormError.branch_address && <div className="error">{companyContactDetailsFormError.branch_address}</div>}
                                        </div>

                                        {/* <div className='inputBottomSpace' ref={refs.companyContactDetails.person_name}>
                                            <InputTextField type='text' disabled={false} name={'branch_pincode'} value={companyContactDetails?.branch_pincode} onChange={handleCompanyContactDetailsChange} required minLength={''} maxLength={''}  label='Branch PinCode' />
                                            {companyContactDetailsFormError?.branch_pincode && <div className="error">{companyContactDetailsFormError?.branch_pincode}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={refs.companyContactDetails.person_name}>
                                            <InputTextField type='text' disabled={true} name={'branch_city'} value={companyContactDetails?.branch_city} required minLength={''} maxLength={''}  label='Branch City' />
                                            {companyContactDetailsFormError?.branch_city && <div className="error">{companyContactDetailsFormError?.branch_city}</div>}
                                        </div>
                                        <div className='inputBottomSpace' ref={refs.companyContactDetails.person_name}>
                                            <InputTextField type='text' disabled={true} name={'branch_state'} value={companyContactDetails?.branch_state} required minLength={''} maxLength={''}  label='Branch State' />
                                            {companyContactDetailsFormError?.branch_state && <div className="error">{companyContactDetailsFormError?.branch_state}</div>}
                                        </div> */}
                                    </div>
                                </div>
                                <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                                    <div className='pe-3'>
                                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                                    </div>
                                    <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                        <div>Save Changes</div>
                                    </div>} onClick={onClickSaveEditDetails} 
                                    // disabled={
                                    //     (companyContactDetailsFormError === "" ? true : false) ||
                                    //     (companyContactDetailsFormError && (
                                    //         companyContactDetailsFormError?.contacts_name !== null ||
                                    //         companyContactDetailsFormError?.contacts_mobile !== null ||
                                    //         companyContactDetailsFormError?.contacts_email_id !== null ||
                                    //         companyContactDetailsFormError?.contacts_person_dept !== null ||
                                    //         companyContactDetailsFormError?.account_name !== null ||
                                    //         companyContactDetailsFormError?.bank_name !== null ||
                                    //         companyContactDetailsFormError?.account_number !== null ||
                                    //         companyContactDetailsFormError?.ifsc_code !== null ||
                                    //         companyContactDetailsFormError?.branch !== null ||
                                    //         companyContactDetailsFormError?.branch_address !== null
                                    //         // companyContactDetailsFormError?.branch_pincode !== null ||
                                    //         // companyContactDetails?.branch_city === '' ||
                                    //         // companyContactDetails?.branch_state === '' 
                                    //     ))} 
                                    />
                                </div>
                            </div>
                        </>
                    }
                </>
            )
        } else if (currentTab === 6) {
            return (
                <>
                    {editFormVisible ?
                        <div className='bg-white storeDetailsContainer scrollChange'>
                            <div className=''>
                                <div className='montSerratFont fontSize18 tabheader'>Uploaded Documents</div>
                                <div className='position-relative'>
                                    <div className='editStoreBtn'>
                                        <SecondaryButton onClick={onClickEditDetails} title={<div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <img src={EditIcon} alt="" />
                                                <div className='textColorBlue fontMedium'>Edit Details</div>
                                            </div>
                                        </div>} />
                                    </div>
                                </div>
                                <div className='pt-3 formContainerNew viewDocumentUpload customScroll'>
                                    <div className='documentUpload emptyDocumentCont notoSansFont'>
                                        {brandDetailDataView?.document_details?.map((item, index) => (
                                            !isFileDetailsEmpty(item) ?
                                                <div className='viewUploadCard' key={index} onClick={() => openFile(item)}>
                                                    <div>
                                                        <img src={renderFileIcons(item)} alt="" />
                                                    </div>
                                                    <div className='viewUploadCardNameDetails'>
                                                        <p>{item?.doc_name}</p>
                                                        <p>Last updated on {formatDateToTextMonthDDYYYY(item?.created_at)}</p>
                                                    </div>
                                                    <div>
                                                        <img src={TickIconWithGreenBg} alt="" />
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    {handleEmptyDocument(index)}
                                                </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className='saveChangeCont scrollChange'>
                                <div className='bg-white storeDetailsContainer'>
                                    <div className='montSerratFont fontSize18'>Uploaded Documents</div>
                                    <div ref={sixthTabRef} className='notoSansFont fontSize18 paddingTop24 customScroll uploadedDocuments_cont'>
                                        <div className='documentUpload'>
                                            <div className='uploadCard'>
                                                {isFileDetailsEmpty(panDocumentFile) ?
                                                    <CustomDropzone name={'PANDocument'} onFileDrop={(file) => handleFileDrop('PANDocument', file)} displayContent={<div>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                                <label className="cursorPointer">
                                                                    <img src={ImportIcon} alt="" />
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <div className='fontMedium textColorBlack fontSize16'>Upload PAN Card</div>
                                                                <div className='fontSize14 textColorGray'>
                                                                    <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                                    <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>} />
                                                    :
                                                    <div style={{ width: '100%' }}>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                                <label className="cursorPointer">
                                                                    <img src={panDocumentFileTypeIcon} alt="" />
                                                                </label>
                                                            </div>
                                                            <div style={{ width: '100%' }}>
                                                                <div className='fontMedium textColorBlack fontSize16'>Upload PAN Card</div>
                                                                <div className='fontSize14 textColorGray'>
                                                                    <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('PANDocument')} progress={panDocumentProgress === 0 ? 100 : panDocumentProgress} File={panDocumentFile} uploadSpeed={panDocumentUploadSpeed} notShowBrand={true} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className='uploadCard'>
                                                {isFileDetailsEmpty(gstDocumentFile) ?
                                                    <CustomDropzone name={'GSTDocument'} onFileDrop={(file) => handleFileDrop('GSTDocument', file)} displayContent={<div>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                                <label className="cursorPointer">
                                                                    <img src={ImportIcon} alt="" />
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <div className='fontMedium textColorBlack fontSize16'>Upload GST Document</div>
                                                                <div className='fontSize14 textColorGray'>
                                                                    <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                                    <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>} />
                                                    :
                                                    <div style={{ width: '100%' }}>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                                <label className="cursorPointer">
                                                                    <img src={renderFileIcons(gstDocumentFile)} alt="" />
                                                                </label>
                                                            </div>
                                                            <div style={{ width: '100%' }}>
                                                                <div className='fontMedium textColorBlack fontSize16'>Upload GST Document</div>
                                                                <div className='fontSize14 textColorGray'>
                                                                    <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('GSTDocument')} progress={gstDocumentProgress === 0 ? 100 : gstDocumentProgress} File={gstDocumentFile} uploadSpeed={gstDocumentUploadSpeed} notShowBrand={true} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className='uploadCard'>
                                                {isFileDetailsEmpty(agreementDocumentFile) ?
                                                    <CustomDropzone name={'AgreementDocument'} onFileDrop={(file) => handleFileDrop('AgreementDocument', file)} displayContent={<div>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                                <label className="cursorPointer">
                                                                    <img src={ImportIcon} alt="" />
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <div className='fontMedium textColorBlack fontSize16'>Upload Agreement Document</div>
                                                                <div className='fontSize14 textColorGray'>
                                                                    <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                                    <div className='pt-2'>PDF, JPEG, JPG or PNG</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>} />
                                                    :
                                                    <div style={{ width: '100%' }}>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                                <label className="cursorPointer">
                                                                    <img src={renderFileIcons(agreementDocumentFile)} alt="" />
                                                                </label>
                                                            </div>
                                                            <div style={{ width: '100%' }}>
                                                                <div className='fontMedium textColorBlack fontSize16'>Upload Agreement Document</div>
                                                                <div className='fontSize14 textColorGray'>
                                                                    <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('AgreementDocument')} progress={agreementDocumentProgress === 0 ? 100 : agreementDocumentProgress} File={agreementDocumentFile} uploadSpeed={agreementDocumentUploadSpeed} notShowBrand={true} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className='uploadCard'>
                                                {isFileDetailsEmpty(uploadBrandFileLogo) ?
                                                    <CustomDropzone name={'brandLogo'} onFileDrop={(file) => handleFileDrop('brandLogo', file)} displayContent={<div>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                                <label className="cursorPointer">
                                                                    <img src={ImportIcon} alt="" />
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <div className='fontMedium textColorBlack fontSize16'>Upload Brand Logo</div>
                                                                <div className='fontSize14 textColorGray'>
                                                                    <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                                    <div className='pt-2'>JPEG, JPG or PNG</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>} />
                                                    :
                                                    <div style={{ width: '100%' }}>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                                <label className="cursorPointer">
                                                                    <img src={brandLogoFileTypeIcon} alt="" />
                                                                </label>
                                                            </div>
                                                            <div style={{ width: '100%' }}>
                                                                <div className='fontMedium textColorBlack fontSize16'>Upload Brand Logo</div>
                                                                <div className='fontSize14 textColorGray'>
                                                                    <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('brandLogo')} progress={brandLogoProgress === 0 ? 100 : brandLogoProgress} File={uploadBrandFileLogo} uploadSpeed={storeFacadeUploadSpeed} notShowBrand={true} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {/* <div className='uploadCard'>
                                            {isFileDetailsEmpty(selectedBrandFloorSpaceFile) ?
                                            <CustomDropzone name={'BrandFloorSpace'} onFileDrop={(file) => handleFileDrop('BrandFloorSpace', file)} displayContent={<div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={ImportIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <div className='fontMedium textColorBlack fontSize16'>Brand Floor Space</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                            <div className='pt-2'>PDF, PNG or JPG</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>} />
                                            :
                                            <div style={{width : '100%'}}>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={brandFloorSpaceFileTypeIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div style={{width : '100%'}}>
                                                        <div className='fontMedium textColorBlack fontSize16'>Brand Floor Space</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('BrandFloorSpace')} progress={brandFloorSpaceProgress === 0 ? 100 : brandFloorSpaceProgress} File={selectedBrandFloorSpaceFile} uploadSpeed={brandFloorSpaceUploadSpeed} notShowBrand={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        <div className='uploadCard'>
                                            {isFileDetailsEmpty(selectedAdjacentFile) ?
                                            <CustomDropzone name={'Adjacent'} onFileDrop={(file) => handleFileDrop('Adjacent', file)} displayContent={<div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={ImportIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <div className='fontMedium textColorBlack fontSize16'>Adjacent</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                            <div className='pt-2'>PDF, PNG or JPG</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>} />
                                            :
                                            <div style={{width : '100%'}}>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={adjacentFileTypeIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div style={{width : '100%'}}>
                                                        <div className='fontMedium textColorBlack fontSize16'>Adjacent</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('Adjacent')} progress={adjacentProgress === 0 ? 100 : adjacentProgress} File={selectedAdjacentFile} uploadSpeed={adjacentUploadSpeed} notShowBrand={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        <div className='uploadCard'>
                                            {isFileDetailsEmpty(selectedOtherFile) ?
                                            <CustomDropzone name={'Other'} onFileDrop={(file) => handleFileDrop('Other', file)} displayContent={<div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={ImportIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <div className='fontMedium textColorBlack fontSize16'>Other</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <div><span className='text-decoration-underline textColorBlack'>Click to Upload</span> Or drag and drop</div>
                                                            <div className='pt-2'>PDF, PNG or JPG</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>} />
                                            :
                                            <div style={{width : '100%'}}>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                        <label className="cursorPointer">
                                                            <img src={otherFileTypeIcon} alt="" />
                                                        </label>
                                                    </div>
                                                    <div style={{width : '100%'}}>
                                                        <div className='fontMedium textColorBlack fontSize16'>Other</div>
                                                        <div className='fontSize14 textColorGray'>
                                                            <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('Other')} progress={otherProgress === 0 ? 100 : otherProgress} File={selectedOtherFile} uploadSpeed={otherUploadSpeed} notShowBrand={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white storeDetailsBtnContainer d-flex mt-4'>
                                    <div className='pe-3'>
                                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                                    </div>
                                    <CustomBlueButton customCreatePropsBtnClass="save_change" title={<div className='d-flex align-items-center'>
                                        <div>Upload Document</div>
                                    </div>} onClick={onClickSaveEditDetails} 
                                    // disabled={(isFileDetailsEmpty(panDocumentFile) || isFileDetailsEmpty(gstDocumentFile))} 
                                    />
                                </div>
                            </div>
                        </>
                    }
                </>
            )
        }
    }

    return (
        <div>
            <div className='d-flex justify-content-between align-items-baseline'>
                <div className='breadCrumbs'>
                    <span className='fontLight'>
                        <Link className="breadCrumbs" to={'/app/viewCardBrandManagement'}>Home</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>
                        <Link className="breadCrumbs" to={'/app/brandManagement'}>Brand List View</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>View Brand Details</span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>{brandBreadCrumbLabel}</span>
                </div>
            </div>
            <div className='paddingTop12'>
                <div>
                    <CustomTabs tabList={ViewBrandTabs} value={selectedTab} handleChange={editFormVisible ? handleTabChange : ""} children={<div className=''>{renderTabData(selectedTab, brandDetailData)}</div>} />
                </div>
            </div>

            <SuccessPopup open={openSuccessPopup} closable={false} onClose={() => { if (timeoutRef.current) { clearTimeout(timeoutRef.current); } setOpenSuccessPopup(false); history.push('/app/brandManagement'); }} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>Updated Successfully!</div>
                    <div className='fontSize16 textColor333'>You have successfully updated your brand</div>
                </div>
            </div>} />

            <CustomModal classes="customeProgressModal" open={openFileViewerModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>{viewFile?.doc_name}</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileViewerModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24' style={{ height: '80vh' }}>
                        {getFileType(viewFile?.doc_name) === 'pdf' ?
                            // <PdfViewer pdfUrl={`${proxyUrl}${viewFile?.doc_url}`} />
                            <object data={viewFile?.doc_url} type="application/pdf" width="100%" height="100%">
                                <p>Alternative text - include a link <a href={viewFile?.doc_url}>to the PDF!</a></p>
                            </object>
                            :
                            <FileRenderer file={viewFile?.doc_url} fileType={getFileType(viewFile?.doc_name)} />
                        }
                    </div>
                </div>} />
            {loading && <Loader />}
        </div>
    )
}

export default ViewBrandDetails