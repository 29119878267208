import axios from "axios";
import API_CONFIG from "../../Configurations/ApiDeclaration";

export function GetBrandNameList(param, token) {
    const URL = API_CONFIG.GET_BRAND_NAME + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetEmployeeStatusList(param, token) {
    const URL = API_CONFIG.GET_ALL_EMPLOYEE_STATUS + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetEmployeeSalesList(param, token) {
    const URL = API_CONFIG.GET_ALL_SALES_DETAILS + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetEmployeeAttendanceList(param, token) {
    const URL = API_CONFIG.GET_ALL_ATTENDANCE_DETAILS + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}


