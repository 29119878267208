import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    viewIncentiveDetails: [],
    selectedPage: 1,
    defaultBrand: {},
    defaultBrandName: '',
    incetiveActiveTabBtn: 1,
    selectedMonth: '',
};

const incentiveSlice = createSlice({
    name: 'incentive',
    initialState,
    reducers: {
        setViewFeedbackDetails: (state, action) => {
            state.viewIncentiveDetails = action.payload.type
        },
        setSelectedPage: (state, action) => {
            state.selectedPage = action.payload.type
        },
        setDefaultBrand: (state, action) => {
            state.defaultBrand = action.payload.type
        },
        setIncetiveActiveTabBtn: (state, action) => {
            state.incetiveActiveTabBtn = action.payload.type
        },
        setDefaultBrandName: (state, action) => {
            state.defaultBrandName = action.payload.type
        },
        setSelectedMonth: (state, action) => {
            state.selectedMonth = action.payload.type
        },
    }
});

export const incentiveActions = incentiveSlice.actions;
export default incentiveSlice;
