import * as React from 'react';
import Lottie from 'lottie-react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '../../assets/svg/crossIcon.svg';
import SuccessLottie from '../../assets/lottie/successLottie.json';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 405,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '24px',
    padding: '40px 68px'
};


const SuccessPopup = (props) => {

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                className='notoSansFont'
                closable={props.closable}
            >
                <Box className='' sx={props.style ? props.style : style}>
                    <button className='successPopupCloseIconPlacement cursorPointer' onClick={props.onClose}>
                        <img src={CloseIcon} alt="" />
                    </button>
                    <div className='d-flex flex-column align-items-center'>
                        <Lottie className='welldoneLottie ' animationData={SuccessLottie} />
                    </div>
                    {props.content}
                </Box>
            </Modal>
        </div>
    );
}

export default SuccessPopup