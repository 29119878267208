import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: 0,
    userId: '',
    userRole: '',
    activePageHeaderName: '',
    activePage: '/brandManagement',
    userName: '',
    userEmail: '',
    isBackBtnActive: false,
    isSearchBtnActive: '',
    userToken: '',
};

const userAuthReducerSlice = createSlice({
    name: 'userAuthentication',
    initialState,
    reducers: {
        setUserEmail: (state, action) => {
            state.userEmail = action.payload.type
        },
        setUserId: (state, action) => {
            state.userId = action.payload.type
        },
        setUserStatus: (state, action) => {
            state.userStatus = action.payload.type
        },
        setUserRole: (state, action) => {
            state.userRole = action.payload.type
        },
        setHeaderName: (state, action) => {
            state.activePageHeaderName = action.payload.type
        },
        setActivePage: (state, action) => {
            state.activePage = action.payload.type
        },
        setIsBackBtnActive: (state, action) => {
            state.isBackBtnActive = action.payload.type
        },
        setUserName: (state, action) => {
            state.userName = action.payload.type
        },
        setIsSearchBtnActive: (state, action) => {
            state.isSearchBtnActive = action.payload.type
        },
        setUserToken: (state, action) => {
            state.userToken = action.payload.type
        },
    }
});

export const userAuthActions = userAuthReducerSlice.actions;
export default userAuthReducerSlice;
