import React, { useEffect, useRef, useState } from 'react';
import SuccessModal from '../../ReUsableComponent/SuccesModal';
import Lottie from 'lottie-react';
import WellDoneIcon from "../../assets/lottie/welldone.json";
import Loader from '../../ReUsableComponent/Loader';
import CustomBlueButton from '../../ReUsableComponent/CustomBlueButton';
import CustomTableWithFilter from '../../ReUsableComponent/CustomTableWithFilter';
import CustomPagination from '../../ReUsableComponent/Pagination';
import { notificationActions } from '../../store/reducers/NotificationsReducer';
import { notificationTableHeader } from '../TableHeaders';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userAuthActions } from '../../store/reducers/UserAuthReducers';
import { GetNotificationsList } from './promotions.service';
import moment from 'moment';

const SuccessUploadModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 390,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const Notifications = () => {
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [tableData, setTableData] = React.useState(false);
    const gridRef = useRef(null);
    const dispatch = useDispatch(null);
    const history = useHistory(null);
    const filterModel = null;
    const selectedPage = useSelector(state => state.notification.selectedPage);
    const USER_ID = useSelector(state => state.userAuthentication.userId);

    useEffect(() => {
        dispatch(userAuthActions.setIsBackBtnActive({ type: false }));
        dispatch(userAuthActions.setActivePage({ type: '/notifications' }));
        dispatch(userAuthActions.setHeaderName({ type: 'Notifications' }));
        getListNotifications();
        dispatch(notificationActions.setEditNotiFormData({ type: false }));
    }, [])

    const onClickOk = () => {
        setOpenSuccessModal(false);
    }

    const onFilterChanged = (event) => {
        dispatch(notificationActions.setTableFilters({ type: event.api.getFilterModel() }));
    };

    const onGridReady = (params) => {
        params.api.setFilterModel(filterModel);
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };

    const onClickCreateNotification = () => {
        history.push('/app/createNotifications');
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
        let notidata = {
            notification: {
                title: '',
                body: ''
            },
            data: {
                emp_ids: []
            }
        }
        dispatch(notificationActions.setNotiFormData({ type: notidata }));
    }

    const handlePageChange = (page) => {
        dispatch(notificationActions.setSelectedPage({ type: page }));
    }

    const convertDate = (date_string) => {
        // let fullDateTimeString = '2024-03-26T13:30:00';
        let date = new Date(date_string);
        // Extract the date part
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // Month is zero-based, so we add 1
        let day = date.getDate();

        // Create a new date string in the format YYYY-MM-DD
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

        return moment(dateString).format("DD MMM YYYY");
    }

    const getListNotifications = () => {
        setLoading(true);
        let param = `?page=${selectedPage}&limit=20&user_id=${USER_ID}`
        GetNotificationsList(param).then(response => {
            let tableROwData = []
            response.data.data.forEach(item => {
                let rowData = {
                    notiSub: item?.notification_subject,
                    notiBody: item?.notification_body,
                    date: convertDate(item?.created_at),
                    id: item?._id
                }
                tableROwData.push(rowData)
            })
            setTableData(tableROwData)
        }).catch(error => {

        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickEdit = (data) => {
        setLoading(true);
        let param = `?id=${data.id}`
        GetNotificationsList(param).then(response => {
            let notidata = {
                notification: {
                    title: response.data.data[0].notification_subject,
                    body: response.data.data[0].notification_body
                },
                data: {
                    emp_ids: []
                }
            }
            dispatch(notificationActions.setNotiFormData({ type: notidata }));
            dispatch(notificationActions.setEditNotiFormData({ type: true }));
        }).catch(error => {

        }).finally(() => {
            setLoading(false);
        })
        history.push('/app/createNotifications')
    }

    return (
        <div>
            <div className='mainContainer notoSansFont'>
                <div className='d-flex justify-content-end align-items-center '>
                    <div>
                        <CustomBlueButton className={''} title={<div className='d-flex align-items-center'>
                            <span className='fontSize18 fontBold pr-13px'>+</span>
                            <div>Create Notification</div>
                        </div>} onClick={onClickCreateNotification} />
                    </div>
                </div>
                <div className='paddingTop24'>
                    <div className='tableContainer'>
                        <CustomTableWithFilter refGrid={gridRef} onFilterChanged={onFilterChanged} filterModel={filterModel} onGridReady={onGridReady} column={notificationTableHeader} tableRowData={tableData} filter={null} handleActiveToggle={null} onClickEdit={onClickEdit} onClickView={null} customeHeightClass={'tableCustomeHeightClass'} />

                    </div>
                    <div className='paddingTop16 d-flex justify-content-end customPagination'>
                        <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={0} />
                    </div>
                </div>
            </div>

            {/* File Uploaded Successfully Modal */}
            <SuccessModal style={SuccessUploadModalStyle} open={openSuccessModal} handleClose={null} content={<div className='text-center'>
                <div className='d-flex justify-content-center align-items-center'>
                    <Lottie className="welldoneLottie" animationData={WellDoneIcon} loop={false} />
                </div>
                <div className='fontBold textBlack fontSize18 paddingTop24'>File Uploaded Successfully!!!</div>
                <div className='fontSize12 pt-2'>We will be processing the data in background, you can continue with your work.</div>
                <div className='pt-4 d-flex justify-content-center align-items-center'>
                    <button className='modalButton'
                        onClick={onClickOk}>OK</button>
                </div>
            </div>} />

            {loading && <Loader />}
        </div>
    )
}

export default Notifications