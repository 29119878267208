import * as React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import './CustomMonthYearPicker.css';

const CustomMonthYearPicker = (props) => {
    const { selectedDate, onDateChange, label, name, minDateRange, maxDateRange, disablePastDates, disableFutureDates, defaultValue, disabled, customClass } = props;
    const defaultDate = defaultValue ? dayjs(defaultValue) : null;
    const OpenPickerIcon = () => {
        return (
            <div>
                <svg id="calendar_2_fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path id="Path_49700" d="M24,0V24H0V0ZM12.594,23.258l-.012,0-.071.035-.02,0-.014,0-.071-.036a.022.022,0,0,0-.024.006l0,.01-.017.428.005.02.01.013.1.074.015,0,.012,0,.1-.074.012-.016,0-.017-.017-.427a.022.022,0,0,0-.016-.018m.264-.113-.014,0-.184.093-.01.01,0,.011.018.43.005.012.008.008.2.092a.025.025,0,0,0,.029-.008l0-.014-.034-.614a.027.027,0,0,0-.02-.022m-.715,0a.023.023,0,0,0-.027.006l-.006.014-.034.614a.025.025,0,0,0,.017.024l.015,0,.2-.093.01-.008,0-.011.018-.43,0-.012-.01-.01Z" fill="none" fillRule="evenodd" />
                    <path id="Path_49701" d="M16,3a1,1,0,0,1,1,1V5h2a2,2,0,0,1,2,2V19a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V7A2,2,0,0,1,5,5H7V4A1,1,0,0,1,9,4V5h6V4a1,1,0,0,1,1-1M8.01,16H8a1,1,0,0,0-.117,1.993L8.01,18a1,1,0,0,0,0-2m4,0H12a1,1,0,0,0-.117,1.993L12.01,18a1,1,0,0,0,0-2m4,0H16a1,1,0,0,0-.117,1.993L16.01,18a1,1,0,0,0,0-2m-8-4H8a1,1,0,0,0-.117,1.993L8.01,14a1,1,0,0,0,0-2m4,0H12a1,1,0,0,0-.117,1.993L12.01,14a1,1,0,0,0,0-2m4,0H16a1,1,0,0,0-.117,1.993L16.01,14a1,1,0,0,0,0-2M19,7H5V9H19Z" fill="#4169e1" fillRule="evenodd" />
                </svg>
            </div>
        );
    };

    return (
        <div className={`customDatePicker ${customClass ? customClass : ''}`}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label={label}
                    minDate={minDateRange}
                    maxDate={maxDateRange}
                    value={selectedDate}
                    defaultValue={defaultDate}
                    onChange={onDateChange}
                    disablePast={disablePastDates}
                    disableFuture={disableFutureDates}
                    disabled={disabled}
                    views={['year', 'month']} // Restrict views to year and month
                    format="MMM YYYY" // Format the date as Month and Year
                    slotProps={{ textField: { variant: 'filled', name: name, disabled: true } }}
                    slots={{
                        openPickerIcon: OpenPickerIcon,
                    }}
                />
            </LocalizationProvider>
        </div>
    );
};

export default CustomMonthYearPicker;
