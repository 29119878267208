import * as React from 'react';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const CustomFilterDropDown = (props) => {

    return (
        <div>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                // sx={{ maxHeight: '350px' }}
                title={props.selectedValue}
                className='customDropDownFilter'
                anchorEl={props.anchorEl}
                open={props.open}
                onClose={props.handleClose}
                TransitionComponent={Fade}
            >
                <div className='notoSansFont fontMedium fontSize12 textColorBlack pb-1 d-flex align-items-center'>
                    <span className='pe-2'>Select {props.category}</span>
                    <InfoOutlinedIcon fontSize='small' />
                </div>
                {props.data.map((item, index) => {
                    return <RadioGroup onChange={props.onChange} key={index}
                        value={props.selectedValue}>
                        <FormControlLabel value={item.value} label={item?.label} control={<Radio />} />
                    </RadioGroup>
                })}
            </Menu>
        </div>
    );
}

export default CustomFilterDropDown
