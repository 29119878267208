import axios from "axios";
import API_CONFIG from "../../Configurations/ApiDeclaration";

export function GetBrandNameList(param, token) {
    const URL = API_CONFIG.GET_BRAND_NAME + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function GetStoreCustomerNameList(param, token) {
    const URL = API_CONFIG.GET_STORE_CUSTOMER_NAME + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetIncentiveHistory(param, token) {
    const URL = API_CONFIG.INCENTIVE_HISTORY + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetUserIncentive(param, token) {
    const URL = API_CONFIG.GET_USER_INCENTIVE + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function DownloadUserIncentive(param, token) {
    const URL = API_CONFIG.DOWNLOAD_USER_INCENTIVE + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetBarcodes(param, token) {
    const URL = API_CONFIG.GET_BARCODES + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}

export function CreateNewIncentive(requestBody, token) {
    const URL = API_CONFIG.CREATE_INCENTIVE;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}
