import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    defaultYear: {},
    defaultBrand: {},
    startDateDisplay: null,
    endDateDisplay: null,
    startDate: null,
    endDate: null
};

const stockSlice = createSlice({
    name: 'stock',
    initialState,
    reducers: {
        setDefaultYear: (state, action) => {
            state.defaultYear = action.payload.type
        },
        setDefaultBrand: (state, action) => {
            state.defaultBrand = action.payload.type
        },
        setStartDateDisplay: (state, action) => {
            state.startDateDisplay = action.payload.type
        },
        setEndDateDisplay: (state, action) => {
            state.endDateDisplay = action.payload.type
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload.type
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload.type
        },
    }
});

export const stockActions = stockSlice.actions;
export default stockSlice;