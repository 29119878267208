import React, { useEffect } from 'react';
import '../../../global.css';
import dropDownIcon from '../../../assets/svg/dropDownIcon.svg';
import '../storeManagement.css';
import Lottie from 'lottie-react';
import { useHistory } from 'react-router-dom';
import personWave from '../../../assets/lottie/personWave.json';
import StoreCardBg from '../../../assets/svg/purpleCard.svg';
import ThreeFingerIcon from '../../../assets/svg/three-fingers_4645729.svg';
import StoreCountGraph from '../../../assets/svg/graphForDashboard.svg';
import CustomFilterDropDown from '../../../ReUsableComponent/CustomFilterDropdown';
import { GetFinanicalYearList, GetStoreDashboardData } from '../StoreManagent.service';
import StoreClassIcon from '../../../assets/svg/storeClassIcon.svg';
import ChannelIcon from '../../../assets/svg/channelsCardIcon.svg';
import OpeningStatusIcon from '../../../assets/svg/store_726569.svg';
import LocationIcon from '../../../assets/svg/location_4315546.svg';
import BrandIcon from '../../../assets/svg/favorite_13859676.svg';
import CityCardIcon from '../../../assets/svg/cityCardicon.svg';
import StoreClassificationIcon from '../../../assets/svg/structure_12865486.svg';
import TotalSquareCardIcon from '../../../assets/svg/up-left-arrow_5728816.svg';
import BusinessModelIcon from '../../../assets/svg/handshake_3585639.svg';
import CustomBarChart from '../../../ReUsableComponent/CustomBarChart';
import CustomDoughnutChart from '../../../ReUsableComponent/CustomDoughnutChart';
import Loader from '../../../ReUsableComponent/Loader';
import GrowingGraphIcon from '../../../assets/svg/increaseGraph.svg';
import DownGraphIcon from '../../../assets/svg/decreaseGraph.svg';
import CustomModal from '../../../ReUsableComponent/CustomModal';
import CloseIcon from '../../../assets/svg/crossIcon.svg';
import { useDispatch, useSelector } from "react-redux";
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';
import { storeManagementActions } from '../../../store/reducers/StoreManagementReducer';
import StoreInfo from './StoreInfo';
import StoreIcon from '../../../assets/svg/store_10945791.svg';
import CloseIconForInfo from '../../../assets/svg/closeIconForInfo.svg';
import { ToastErrorService } from '../../../ReUsableComponent/ToastNotification/ToastService';


const storeStatuses = [{ label: 'Active', value: true }, { label: 'Inactive', value: false }]

const channelStoreCountModalStye = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 624,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const StoreDashboard = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [isFullView, setIsFullView] = React.useState(false);
    const [anchorElForStatus, setAnchorElForStatus] = React.useState(null);
    const [anchorElForYear, setAnchorElForYear] = React.useState(null);
    const [storeDashboardData, setStoreDashboardData] = React.useState([]);
    const [storeCountData, setStoreCountData] = React.useState([]);
    const [storeClassData, setStoreClassData] = React.useState([]);
    const [storeDashboardChannelsData, setStoreDashboardChannelsData] = React.useState([]);
    const [storeDashboardRegionData, setStoreDashboardRegionData] = React.useState([]);
    const [storeDashboardBrandData, setStoreDashboardBrandData] = React.useState([]);
    const [storeDashboardCityTierData, setStoreDashboardCityTierData] = React.useState([]);
    const [storeClassificationData, setStoreClassificationData] = React.useState([]);
    const [storeOpeningData, setStoreOpeningData] = React.useState([]);
    const [storeTotalSqFtData, setStoreTotalSqFtData] = React.useState([]);
    const [storeBusinessModelData, setStoreBusinessModelData] = React.useState([]);
    const [barGraphLabels, setBarGraphLabels] = React.useState([]);
    const [barGraphValues, setBarGraphValues] = React.useState([]);
    const [doughnutChartLabels, setDoughnutChartLabels] = React.useState([]);
    const [doughnutChartValues, setDoughnutChartValues] = React.useState([]);
    const openStatusFilter = Boolean(anchorElForStatus);
    const openYearFilter = Boolean(anchorElForYear);
    const [channelStoreCountModalOpen, setChannelStoreCountModalOpen] = React.useState(false);
    const handleChannelStoreCountModalOpen = () => setChannelStoreCountModalOpen(true);
    const handleChannelStoreCountModalClose = () => setChannelStoreCountModalOpen(false);
    const [selectedStatus, setSelectedStatus] = React.useState({ label: useSelector((state) => state.storeManagement.storeStatus) ? 'Active' : 'Inactive', value: useSelector((state) => state.storeManagement.storeStatus) });
    const [selectedYear, setSelectedYear] = React.useState({ label: '', value: useSelector((state) => state.storeManagement.selectedFyYear) });
    const [financialYearList, setFinancialYearList] = React.useState([]);
    const [brandStoreRegionWise, setBrandStoreRegionWise] = React.useState([]);


    const userName = useSelector((state) => state.userAuthentication.userName);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);

    const getDashboardData = (status, year) => {
        setLoading(true);
        let queryParams = `?fy_year=${year}`;
        let reqBody = {
            'store_status': status
        }
        GetStoreDashboardData(reqBody, queryParams, USER_TOKEN).then(response => {
            if (response?.data?.statusCode === 200) {
                dispatch(userAuthActions.setIsBackBtnActive({ type: false }));
                setStoreDashboardData(response?.data?.data);
                setStoreCountData(response?.data?.data?.total_store);
                setStoreClassData(response?.data?.data?.class_store);
                setStoreDashboardChannelsData(response?.data?.data?.channel_store);
                setStoreDashboardRegionData(response?.data?.data?.region_store);
                setStoreDashboardBrandData(response?.data?.data?.brand_store);
                setStoreDashboardCityTierData(response?.data?.data?.city_tier);
                setStoreClassificationData(response?.data?.data?.store_classification);
                setStoreOpeningData(response?.data?.data?.store_opening_status);
                // setStoreTotalSqFtData(response?.data?.data?.total_sqft_overview);
                setStoreBusinessModelData(response?.data?.data?.business_model);
                setBrandStoreRegionWise(response?.data?.data?.brand_store_region_wise);

                // Bar Chart Data
                let graphLabel = [];
                let graphValue = [];
                response?.data?.data?.city_tier.map(item => {
                    graphValue.push(item?.count);
                    graphLabel.push(item?.tier)
                })
                setBarGraphLabels(graphLabel);
                setBarGraphValues(graphValue);

                // Doughnut Chart Data
                let doughnutLabel = [];
                let doughnutValue = [];
                response?.data?.data?.store_classification.map(item => {
                    doughnutLabel.push(item?.store_classification)
                    doughnutValue.push(item?.count);
                })

                setDoughnutChartLabels(doughnutLabel);
                setDoughnutChartValues(doughnutValue);

                // Total sqft
                let midpoint = Math.ceil(response?.data?.data?.total_sqft_overview.length / 2);
                let newArray = [];
                let subArr1 = []
                let subArr2 = []
                // Split the array into two subarrays
                if (response?.data?.data?.total_sqft_overview.length > 2) {
                    subArr1 = response?.data?.data?.total_sqft_overview.slice(0, midpoint);
                    subArr2 = response?.data?.data?.total_sqft_overview.slice(midpoint);
                    newArray.push(subArr1, subArr2);
                } else {
                    subArr1 = response?.data?.data?.total_sqft_overview;
                    newArray.push(subArr1);
                }

                // Create a new array and push the two subarrays into it
                setStoreTotalSqFtData(newArray);
                resetPrevSelectedValues();
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        getListOfFinancialYears();
        dispatch(storeManagementActions.setTableFilterValue({ type: {} }));
        dispatch(storeManagementActions.setSelectedPage({ type: 1 }));
        dispatch(storeManagementActions.setIsClikedProfileCompleted({ type: false }));
    }, [])

    const randomBackgroundColor = () => {
        const colors = ['bg1', 'bg2', 'bg3', 'bg4', 'bg5', 'bg6', 'bg7', 'bg8', 'bg9'];
        const randomColor = colors[Math.floor(Math.random() * colors.length)];
        return randomColor;
    }

    const getListOfFinancialYears = () => {
        setLoading(true);
        GetFinanicalYearList(USER_TOKEN).then(response => {
            if (response?.data?.statusCode === 200) {
                let yearValue = ''
                if (selectedYear?.value === '') {
                    yearValue = response?.data?.data[0].current_fy_year
                    setSelectedYear({ label: response?.data?.data[0].fy_year, value: response?.data?.data[0].current_fy_year });
                    dispatch(storeManagementActions.setSelectedFyYear({ type: response?.data?.data[0].current_fy_year }));
                } else {
                    yearValue = selectedYear?.value;
                    response?.data?.data.map(year => {
                        if (selectedYear.value === year?.current_fy_year) {
                            setSelectedYear({ label: year?.fy_year, value: selectedYear?.value })
                        }
                    })
                }
                getDashboardData(selectedStatus?.value, yearValue);
                let yearList = [];
                response?.data?.data.map(item => {
                    let yearObject = {
                        label: item?.fy_year,
                        value: item?.current_fy_year
                    }
                    yearList.push(yearObject);
                });
                setFinancialYearList(yearList);
            }
        }).catch(error => {
            console.log(error);
            setLoading(false);
        }).finally(() => {

        })
    }

    const handleStatusClick = (event) => {
        setAnchorElForStatus(event.currentTarget);
    };

    const handleYearClick = (event) => {
        setAnchorElForYear(event.currentTarget);
        localStorage.setItem('fy_year', '2023-04-01')
    };

    const handleStatusClose = () => {
        setAnchorElForStatus(null);
    };

    const handleYearClose = () => {
        setAnchorElForYear(null);
    };

    const onClickGotoMaster = () => {
        dispatch(storeManagementActions.setSelectedPage({ type: 1 }));
        history.push('/app/storeManagement');
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
        dispatch(storeManagementActions.setDisplayMasterData({ type: true }));
        dispatch(storeManagementActions.setStoreClass({ type: '' }));
    }

    const onSelectStatus = (event) => {
        setSelectedStatus({ label: event.target.value === 'true' ? 'Active' : 'Inactive', value: event.target.value === 'true' ? true : false });
        dispatch(storeManagementActions.setStoreStatus({ type: event.target.value === 'true' ? true : false }));
        setAnchorElForStatus(null);
        let Status = event.target.value === 'true' ? true : false;
        getDashboardData(Status, selectedYear?.value);
    }

    const onSelectYear = (event) => {
        financialYearList.map(year => {
            if (event.target.value === year?.value) {
                setSelectedYear({ label: year?.label, value: event.target.value })
                setAnchorElForYear(null);
                let Status = selectedStatus?.value;
                getDashboardData(Status, event.target.value);
            }
        })
        dispatch(storeManagementActions.setSelectedFyYear({ type: event.target.value }));
    }

    const toggleView = () => {
        setIsFullView((prev => !prev))
    }

    const renderOpeningStatusDot = () => {
        return findMax(storeOpeningData[0].count,
            storeOpeningData[1].count,
            storeOpeningData[2].count,
            storeOpeningData[3].count
        )
    }

    const renderTotalSqftDot = () => {
        if (storeTotalSqFtData?.length === 2) {
            if (storeTotalSqFtData[1]?.length === 2) {
                return findMax(storeTotalSqFtData[0][0].total_sq_ft,
                    storeTotalSqFtData[0][1].total_sq_ft,
                    storeTotalSqFtData[1][0].total_sq_ft,
                    storeTotalSqFtData[1][1].total_sq_ft
                )
            } {
                return findMax(storeTotalSqFtData[0][0].total_sq_ft,
                    storeTotalSqFtData[0][1].total_sq_ft,
                    storeTotalSqFtData[1][0].total_sq_ft
                )
            }
        } else if (storeTotalSqFtData?.length === 1) {
            if (storeTotalSqFtData[0]?.length === 2) {
                return findMax(storeTotalSqFtData[0][0].total_sq_ft,
                    storeTotalSqFtData[0][1].total_sq_ft,
                )
            } else {
                return findMax(storeTotalSqFtData[0][0].total_sq_ft,
                )
            }
        }
    }

    const renderStoreClasstDot = () => {
        return findMax(storeClassData[0].count,
            storeClassData[1].count,
            storeClassData[2].count,
            storeClassData[3].count
        )
    }

    function findMax(a, b, c, d) {
        let max = a;
        if (b > max) {
            max = b;
        }
        if (c > max) {
            max = c;
        }
        if (d > max) {
            max = d;
        }
        return max;
    }

    const barChartData = {
        labels: barGraphLabels,
        datasets: [
            {
                label: "Store Count",
                backgroundColor: "#7D96FF",
                borderColor: "#7D96FF",
                borderWidth: 1,
                hoverBackgroundColor: "#7D96FF",
                hoverBorderColor: "#7D96FF",
                borderCapStyle: "round",
                data: barGraphValues,
                borderRadius: 8
            },
        ],
    };

    const doughnutChartData = {
        labels: doughnutChartLabels,
        datasets: [
            {
                data: doughnutChartValues,
                backgroundColor: ["#4169E1", "#6887E6", "#8DA4EA", "#B3C2F1", "#D9E0F6"],
                hoverBackgroundColor: ["#4169E1", "#6887E6", "#8DA4EA", "#B3C2F1", "#D9E0F6"],

            },
        ],
    };

    const renderStoreClassificationDot = (id) => {
        if (id === 0) {
            return 'bgStoreClassfication1'
        } else if (id === 1) {
            return 'bgStoreClassfication2'
        } else if (id === 2) {
            return 'bgStoreClassfication3'
        } else if (id === 3) {
            return 'bgStoreClassfication4'
        } else {
            return 'bgStoreClassfication5'
        }
    }

    const onClickStoreClass = (value) => {
        history.push('/app/storeManagement');
        let filterValue = {
            filterType: "text",
            type: "contains",
            filter: value
        }
        dispatch(storeManagementActions.setTableFilterValue({ type: { storeClass: filterValue } }));
        dispatch(storeManagementActions.setStoreClass({ type: value }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
    }
    const onClickStoreRegion = (value) => {
        history.push('/app/storeManagement');
        let filterValue = {
            filterType: "text",
            type: "contains",
            filter: value
        }
        dispatch(storeManagementActions.setTableFilterValue({ type: { region: filterValue } }));
        dispatch(storeManagementActions.setRegion({ type: value }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
    }
    const onClickChannel = (value) => {
        history.push('/app/storeManagement');
        let filterValue = {
            filterType: "text",
            type: "contains",
            filter: value
        }
        dispatch(storeManagementActions.setTableFilterValue({ type: { channel: filterValue } }));
        dispatch(storeManagementActions.setChannel({ type: value }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
    }
    const onClickBrandName = (value) => {
        history.push('/app/storeManagement');
        let filterValue = {
            filterType: "text",
            type: "contains",
            filter: value
        }
        dispatch(storeManagementActions.setTableFilterValue({ type: { nameBrand: filterValue } }));
        dispatch(storeManagementActions.setBrandName({ type: value }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
    }
    const onClickStoreOpeningStatus = (value) => {
        history.push('/app/storeManagement');
        let filterValue = {
            filterType: "text",
            type: "contains",
            filter: value
        }
        dispatch(storeManagementActions.setTableFilterValue({ type: { openingStatus: filterValue } }));
        dispatch(storeManagementActions.setStoreOpeningStatus({ type: value }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
    }
    const onClickStoreClassification = (value) => {
        history.push('/app/storeManagement');
        let filterValue = {
            filterType: "text",
            type: "contains",
            filter: value
        }
        dispatch(storeManagementActions.setTableFilterValue({ type: { classification: filterValue } }));
        dispatch(storeManagementActions.setStoreClassification({ type: value }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
    }
    const onClickBusinessModel = (value) => {
        history.push('/app/storeManagement');
        let filterValue = {
            filterType: "text",
            type: "contains",
            filter: value
        }
        dispatch(storeManagementActions.setTableFilterValue({ type: { businessModel: filterValue } }));
        dispatch(storeManagementActions.setStoreBusinessModel({ type: value }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
    }
    const onClickCityTier = (value) => {
        history.push('/app/storeManagement');
        let filterValue = {
            filterType: "text",
            type: "contains",
            filter: value
        }
        dispatch(storeManagementActions.setTableFilterValue({ type: { cityTier: filterValue } }));
        dispatch(storeManagementActions.setCityTierValue({ type: value }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
    }

    const resetPrevSelectedValues = () => {
        dispatch(storeManagementActions.setStoreOpeningStatus({ type: '' }));
        dispatch(storeManagementActions.setBrandName({ type: '' }));
        dispatch(storeManagementActions.setRegion({ type: '' }));
        dispatch(storeManagementActions.setChannel({ type: '' }));
        dispatch(storeManagementActions.setStoreClass({ type: '' }));
        dispatch(storeManagementActions.setStoreClassification({ type: '' }));
        dispatch(storeManagementActions.setStoreBusinessModel({ type: '' }));
        dispatch(storeManagementActions.setCityTierValue({ type: '' }));
        dispatch(storeManagementActions.setDisplayMasterData({ type: false }));
        dispatch(storeManagementActions.setTableFilterValue({ type: {} }));
    }

    const onClickBrandViewAll = () => {
        history.push('/app/brandStoreView');
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
        dispatch(storeManagementActions.setBrandStoreRegionWiseData({ type: brandStoreRegionWise }));
    }

    const onClickStoreTotalPendingProfileTillDate = () => {
        history.push('/app/storeManagement');
        dispatch(storeManagementActions.setIsProfileCompleted({ type: false }));
        dispatch(storeManagementActions.setIsClikedProfileCompleted({ type: true }));
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
    }

    return (
        <div className='notoSansFont pb-4'>
            <div className='storeDashboardContainer'>
                <div className='storeDashboardFilter'>
                    <div className='FilterSubCont d-flex align-items-end'>
                        <div className='goToMasterBtn me-3' onClick={onClickGotoMaster}>Go to Master</div>
                        <div className='position-relative'>
                            <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                onClick={handleStatusClick}>{selectedStatus?.label}
                                <img className={`${openStatusFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                            </div>
                            <CustomFilterDropDown data={storeStatuses} handleClose={handleStatusClose} open={openStatusFilter} anchorEl={anchorElForStatus}
                                onChange={onSelectStatus} selectedValue={selectedStatus?.value} category={'Status'} />
                        </div>
                        {/* <div className='position-relative'>
                            <div className='d-flex yearFilter cursorPointer align-items-center justify-content-between'
                                onClick={handleYearClick}>{selectedYear?.label}
                                <img className={`${openYearFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                            </div>
                            <CustomFilterDropDown data={financialYearList} handleClose={handleYearClose} open={openYearFilter} anchorEl={anchorElForYear}
                                onChange={onSelectYear} selectedValue={selectedYear?.value} category={'Year'} />
                        </div> */}
                    </div>
                    {/* <div className='d-flex justify-content-end align-items-center'>
                        <div className='lottieCss'>
                            <Lottie animationData={personWave} />
                        </div>
                        <div className='montSerratFont fontSize30 welcomeUser'>Hello <span className='text-capitalize'>{userName}</span>, Have a great day!</div>
                    </div> */}
                </div>
                <div className='paddingTop24 minWidth' style={{ minWidth: '1100px' }}>
                    <div className='FirstRow'>
                        <div className='p-4 storeCountCard boxShadow maxWidthPer20 minWidth'>
                            <div className='d-flex cursorPointer' onClick={onClickGotoMaster}>
                                <div className='bg-white threeFinger'>
                                    <img src={ThreeFingerIcon} alt='' />
                                </div>
                                <div className='d-flex flex-column justify-content-center text-white'>
                                    <div className='montSerratFont fontSize18 text-white d-flex align-items-center'>Stores Count</div>
                                    {storeCountData && <div className='storeCountNumber notoSansFont '>
                                        <span className='fontSemiBold font32'>{storeCountData?.totalStoreCount}</span>
                                        <span className='ps-3 fontSize12'>{`${Math.round(storeCountData?.percentage_increased)}`}%</span>
                                        <span className='ps-1'>
                                            <img src={(storeCountData?.trend === 'Increased' ? GrowingGraphIcon : (storeCountData?.trend === 'Decreased' ? DownGraphIcon : ''))} alt="" />
                                        </span>
                                    </div>}
                                    <div className='fontSize12 fontWeight200'>Total Store count present</div>
                                </div>
                            </div>
                            <div>
                                <img src={StoreCountGraph} alt="" />
                            </div>
                        </div>
                        <div className='maxWidthPer30 minWidth'>
                            <div className='p-4 storeClassCard '>
                                <div className='d-flex align-items-center'>
                                    <div className='storeClassIcon'>
                                        <img src={StoreClassIcon} alt='' />
                                    </div>
                                    <div className='montSerratFont fontSize18 textColorBlack'>Store Class</div>
                                </div>
                                <div className='d-flex paddingTop16 justify-content-between'>
                                    {storeClassData.map(item => (
                                        <div className='storeClassCard cursorPointer' onClick={() => onClickStoreClass(item?.store_class)} key={item?.store_class}>
                                            <div className={`${renderStoreClasstDot() === item.count ? 'cardBgGreen' : 'cardBgGray'} storeClassPlus zoomInEffect`}>
                                                <div className='d-flex align-items-center justify-content-start '>
                                                    <div className={`classDot ${renderStoreClasstDot() === item.count ? 'greenDot' : 'grayDot'}`}></div>
                                                    <div>{item?.store_class}</div>
                                                </div>
                                                <div className='fontSemiBold fontSize18'>{item?.count}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='maxWidthPer30 minWidth'>
                            <div className='p-4 channelsCard '>
                                <div className='d-flex align-items-center'>
                                    <div className='storeClassIcon'>
                                        <img src={ChannelIcon} alt='' />
                                    </div>
                                    <div className='montSerratFont fontSize18 textColorBlack'>Channels - Store Count</div>
                                </div>
                                <div className='paddingTop16 storeChannel'>
                                    {storeDashboardChannelsData.slice(0, 4).map(item => (
                                        <div className='dottedBorder' key={item?.channel} title={item?.channel}>
                                            <div className='zoomInEffect cursorPointer' onClick={() => onClickChannel(item?.channel)} >
                                                <div className='fontSize14 text-truncate'>{item?.channel}</div>
                                                <div className='fontSize18 textColorBlack fontSemiBold paddingTop12'>{item?.count}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='text-decoration-underline d-flex justify-content-end align-items-center textColorBlue fontSize14 fontMedium cursorPointer'
                                    onClick={handleChannelStoreCountModalOpen}>
                                    <div className='widthFit'>View All</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='secondRow'>
                        <div className='p-4  regionStoreCard maxWidthPer50 minWidth'>
                            <div className='d-flex align-items-center'>
                                <div className='storeClassIcon'>
                                    <img src={LocationIcon} alt='' />
                                </div>
                                <div className='montSerratFont fontSize18 textColorBlack'>Regions - Store Count</div>
                            </div>
                            <div className='paddingTop16 regionDataCard'>
                                {storeDashboardRegionData.map(item => (
                                    <div key={item?.region} className={`${(item?.trend === 'Increased' ? 'cardBgGreen' : (item?.trend === 'Decreased' ? 'cardBgRed' : 'cardBgGray'))} regionData zoomInEffect cursorPointer`}
                                        onClick={() => onClickStoreRegion(item?.region)}>
                                        <div className='fontSize14 text-truncate textCapitalize'>{item?.region}</div>
                                        <div className='d-flex align-items-baseline justify-content-center'>
                                            <div className='fontSize18 textColorBlack fontSemiBold paddingTop12'>{item?.count}</div>
                                            <div className='fontSize12 ps-1 d-flex'>
                                                <div className={`${(item?.trend === 'Increased' ? 'textColorGreen' : (item?.trend === 'Decreased' ? 'textColorRed' : 'textColorGray'))}`}>{Math.round(item?.percentageChange)}%</div>
                                                <div className='ps-1'>
                                                    <img src={(item?.trend === 'Increased' ? GrowingGraphIcon : (item?.trend === 'Decreased' ? DownGraphIcon : ''))} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='p-4  brandStoreCard maxWidthPer50 minWidth'>
                            <div className='d-flex align-items-center'>
                                <div className='storeClassIcon'>
                                    <img src={BrandIcon} alt='' />
                                </div>
                                <div className='montSerratFont fontSize18 textColorBlack'>Brands - Store Count</div>
                            </div>
                            <div className='paddingTop16 storeBrand'>
                                {storeDashboardBrandData.slice(0, 6).map(item => (
                                    <div className='dottedBorder' key={item?.brand_name} title={item?.brand_name}>
                                        <div className='zoomInEffect cursorPointer' onClick={() => onClickBrandName(item?.brand_name)}>
                                            <div className='fontSize14 text-truncate'>{item?.brand_name}</div>
                                            <div className='fontSize18 textColorBlack fontSemiBold paddingTop12'>{item?.count}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='pt-2 text-decoration-underline d-flex justify-content-end align-items-center textColorBlue fontSize14 fontMedium cursorPointer'>
                                <div className='widthFit' onClick={onClickBrandViewAll}>View All</div>
                            </div>
                        </div>
                    </div>
                    <div className='thirdRow'>
                        <div className='p-4  cityTierCard maxWidthPer60 minWidth'>
                            <div className='d-flex align-items-center'>
                                <div className='storeClassIcon'>
                                    <img src={CityCardIcon} alt='' />
                                </div>
                                <div className='montSerratFont fontSize18 textColorBlack'>City Tier - Store Count</div>
                            </div>
                            <div className='d-flex barChartHeightFit pt-3'>
                                <div className=''>
                                    <CustomBarChart width={370} height={249} chartData={barChartData} />
                                </div>
                                <div className='ps-3'>
                                    <div className='barChartDetails w-100'>
                                        {storeDashboardCityTierData.map(item => (
                                            <div className='barChartDataDetails' onClick={() => onClickCityTier(item?.tier)} key={item?.tier}>
                                                <div className='d-flex align-items-center justify-content-between zoomInEffect'>
                                                    <div className='fontSize14 text-capitalize'>{item?.tier}</div>
                                                    <div className='d-flex align-items-baseline justify-content-end'>
                                                        <div className='fontSize18 fontSemiBold'>{item?.count}</div>
                                                        <div style={{ minWidth: '32px' }} className={`fontSize12 ps-2 ${(item?.trend === 'Increased' ? 'textColorGreen' : (item?.trend === 'Decreased' ? 'textColorRed' : ''))}`}>{Math.round(item?.percentage)}%</div>
                                                        <div className='ps-1' style={{ width: '12px' }}>
                                                            <img width={12} src={(item?.trend === 'Increased' ? GrowingGraphIcon : (item?.trend === 'Decreased' ? DownGraphIcon : ''))} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-4  classificationCard maxWidthPer40 minWidth'>
                            <div>
                                <div className='d-flex align-items-center'>
                                    <div className='storeClassIcon'>
                                        <img src={StoreClassificationIcon} alt='' />
                                    </div>
                                    <div className='montSerratFont fontSize18 textColorBlack'>Store Classification</div>
                                </div>
                                <div className='d-flex doughnutChartDetailsComp'>
                                    <div className='pe-3 d-flex align-items-center doughnutChart'>
                                        <CustomDoughnutChart width={180} height={180} doughnutChartData={doughnutChartData} />
                                    </div>
                                    <div className='w-100 d-flex justify-content-end doughnutChartDetails'>
                                        <div className='w-75'>
                                            {storeClassificationData.map((item, id) => (
                                                <div className='pieChartDataDetails' key={item?.store_classification} onClick={() => onClickStoreClassification(item?.store_classification)}>
                                                    <div className='d-flex align-items-center justify-content-between zoomInEffect'>
                                                        <div className='fontSize14 d-flex align-items-center'>
                                                            <div className={`pirChartDot me-2 ${renderStoreClassificationDot(id)}`}></div>
                                                            <div>{item?.store_classification}</div>
                                                        </div>
                                                        <div className='fontSize18 fontSemiBold text-end'>{item?.count}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='fourthRow'>
                        <div className='p-4  openingStatus maxWidthPer40 minWidth'>
                            <div className='d-flex align-items-center'>
                                <div className='storeClassIcon'>
                                    <img src={OpeningStatusIcon} alt='' />
                                </div>
                                <div className='montSerratFont fontSize18 textColorBlack'>Store Opening Status</div>
                            </div>
                            <div className='pt-3'>
                                <div className='openingStatusCard'>
                                    <div className='storeChannel'>
                                        {storeOpeningData.map(item => (
                                            <div className={`dottedBorder openingStatusDot`} key={item?.store_opening_status} title={item?.store_opening_status}>
                                                <div className='zoomInEffect cursorPointer' onClick={() => onClickStoreOpeningStatus(item?.store_opening_status)}>
                                                    <div className='fontSize14 d-flex align-items-center'>
                                                        <div className={`classDot ${renderOpeningStatusDot() === item?.count ? 'greenDot' : 'grayDot'}`}></div>
                                                        <div className='text-truncate text_truncate'>{item?.store_opening_status}</div>
                                                    </div>
                                                    <div className='fontSize18 textColorBlack fontSemiBold paddingTop12'>{item?.count}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-4  totalArea maxWidthPer30 minWidth'>
                            <div className='d-flex align-items-center'>
                                <div className='storeClassIcon'>
                                    <img src={TotalSquareCardIcon} alt='' />
                                </div>
                                <div className='montSerratFont fontSize18 textColorBlack'>Total Sqft Overview</div>
                            </div>
                            <div className=''>
                                {storeTotalSqFtData.map((item, id) => (<div className='totalSqFt mt-3' key={id}>
                                    {item.map((sqftData, index) => (
                                        <div className='ps-3 dottedBorderRight' onClick={() => onClickStoreRegion(sqftData?.region)} key={index}>
                                            <div className='zoomInEffect'>
                                                <div className='d-flex align-items-center'>
                                                    <div className={`pirChartDot me-2 ${renderTotalSqftDot() === sqftData?.total_sq_ft ? 'greenDot' : 'grayDot'}`}></div>
                                                    <div className='text-capitalize'>{sqftData?.region}</div>
                                                </div>
                                                <div className='d-flex align-items-baseline'>
                                                    <div className='fontSize18 fontSemiBold'>{sqftData?.total_sq_ft}</div>
                                                    <div className='fontSize12 ps-2'>sqft</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>))}
                            </div>
                        </div>
                        <div className='p-4  businessModelCard maxWidthPer20 minWidth'>
                            <div className='d-flex align-items-center'>
                                <div className='storeClassIcon'>
                                    <img src={BusinessModelIcon} alt='' />
                                </div>
                                <div className='montSerratFont fontSize18 textColorBlack'>Business Model</div>
                            </div>
                            <div className='w-100'>
                                {storeBusinessModelData.sort((a, b) => a.business_model.toLowerCase().localeCompare(b.business_model.toLowerCase())).map(item => (
                                    <div className='businessModelDetails pe-3 ' key={item?.business_model} onClick={() => onClickBusinessModel(item?.business_model)}>
                                        <div className='d-flex align-items-center justify-content-between zoomInEffect'>
                                            <div className='fontSize14 d-flex align-items-center'>
                                                <div className='pirChartDot bgGray me-2'></div>
                                                <div>{item?.business_model}</div>
                                            </div>
                                            <div className='fontSize18 fontSemiBold text-end'>{item?.count}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Incomplete Store Profile Info */}
                    <StoreInfo content={<div className={`info-display ${isFullView ? 'full-view' : ''}`} onClick={toggleView}>
                        {isFullView && <button className="close-button" >
                            <img src={CloseIconForInfo} alt="" />
                        </button>}
                        {isFullView ? <div className='d-flex notoSansFont align-items-center' onClick={onClickStoreTotalPendingProfileTillDate}>
                            <div>
                                <img src={StoreIcon} alt="" />
                            </div>
                            <div className='ps-3'>
                                <div className='textColorLightGray'>Total pending profile till date</div>
                                <div className='fontSize18 lh-1'>
                                    {storeCountData?.incompleteProfileCount}/{storeCountData?.current_year_count}
                                </div>
                            </div>
                        </div> : ''}
                    </div>} />
                </div>
            </div>

            {/* Channel Store Count Modal */}
            <CustomModal open={channelStoreCountModalOpen} style={channelStoreCountModalStye} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='storeClassIcon'>
                            <img src={ChannelIcon} alt='' />
                        </div>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Channels - Store Count</div>
                            <div className='modalCloseIconPlacement cursorPointer' onClick={() => setChannelStoreCountModalOpen(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop12'>
                        <div className='allChannelContainer'>
                            <div className='channelDisplayData'>
                                {storeDashboardChannelsData.map(item => (<div key={item?.channel} className={`dataCardChannelStoreCount zoomInEffect cursorPointer content ${randomBackgroundColor()}`} onClick={() => onClickChannel(item?.channel)} title={item.channel}>
                                    <div className='fontSize14 text-truncate'>{item?.channel}</div>
                                    <div className='fontSize18 textColorBlack fontBold paddingTop12'>{item?.count}</div>
                                </div>))}
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* Loader */}
            {loading && <Loader />}
        </div >


    )
}

export default StoreDashboard
