import React from 'react';
import './Table.css';

const CustomTable = ({ data , backgroundColor }) => {
    const calculatePercentage = (firstValue, secondValue) => {
        if (firstValue === 0) {
            return '0%';
        }
        if (secondValue === 0) {
            return '0%';
        }
        let percentage = (firstValue / secondValue) * 100;
        return Math.round(percentage) + '%';
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        
        return `${day}/${month}/${year}`;
    }


    return (
        <>
            {  data?.date ? 
                <div className={`table-container ${backgroundColor}`}>
                    <div className='d-flex align-items-center'>
                        <span className='fontSize12'>Date -</span>
                        <div className="header">
                            <span>{formatDate(data?.date)}</span>
                        </div>
                    </div>
                    <table className={`custom-table mt-2 `}>
                        <thead>
                            <tr>
                                <th id='first-th'></th>
                                <th>Value</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td id='value-header'>Target</td>
                                <td>{data?.value}</td>
                                <td>{data?.quantity}</td>
                            </tr>
                            <tr>
                                <td id='value-header'>Achievement</td>
                                <td>{data?.achievement_value ? data?.achievement_value : 0}</td>
                                <td>{data?.achievement_quantity ? data?.achievement_quantity : 0}</td>
                            </tr>
                            <tr>
                                <td id='value-header'>Achievement %</td>
                                <td>{calculatePercentage(data?.achievement_value ? data?.achievement_value : 0, data?.value)}</td>
                                <td>{calculatePercentage(data?.achievement_quantity ? data?.achievement_quantity : 0, data?.quantity)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                :
                <div className={`table-container ${backgroundColor}`}>
                    <div className='d-flex align-items-center'>
                        <span className='fontSize12'>Week - {data?.week}</span>
                        <div className="header">
                            <span>{data?.weekStartDate} - {data?.weekEndDate}</span>
                        </div>
                    </div>
                    <table className={`custom-table mt-2 `}>
                        <thead>
                            <tr>
                                <th id='first-th'></th>
                                <th>Value</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td id='value-header'>Target</td>
                                <td>{data?.target_value}</td>
                                <td>{data?.target_quantity}</td>
                            </tr>
                            <tr>
                                <td id='value-header'>Achievement</td>
                                <td>{data?.achievement_value ? data?.achievement_value : 0}</td>
                                <td>{data?.achievement_quantity ? data?.achievement_quantity : 0}</td>
                            </tr>
                            <tr>
                                <td id='value-header'>Achievement %</td>
                                <td>{calculatePercentage(data?.achievement_value ? data?.achievement_value : 0, data?.target_value)}</td>
                                <td>{calculatePercentage(data?.achievement_quantity ? data?.achievement_quantity : 0, data?.target_quantity)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
};

export default CustomTable;
