import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import './BrandStoreView.css';
import { storeBrandData } from '../StoreData';
import CustomPagination from '../../../ReUsableComponent/Pagination';
import RedirectIcon from './../../../assets/svg/redirectIcon.svg';
import { storeManagementActions } from '../../../store/reducers/StoreManagementReducer';
import { Link, useHistory } from 'react-router-dom';

const BrandStoreView = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [selectedPage, setSelectedPage] = useState(1);
    const [dataSliceValueFrom, setDataSliceValueFrom] = useState(0);
    const [displayData, setDisplayData] = useState([]);
    const [pageValue, setPageValue] = useState(20);

    const brandStoreRegionWiseDatas = useSelector((state) => state.storeManagement.brandStoreRegionWiseData);

    // Calculate the index range for the current page
    const startIndex = (selectedPage - 1) * pageValue;
    const endIndex = startIndex + pageValue;

    // Get the items for the current page
    const currentItems = brandStoreRegionWiseDatas.slice(startIndex, endIndex);

    useEffect(() => {
        setDisplayData(brandStoreRegionWiseDatas.slice(0, pageValue));
    }, [])


    const handlePageChange = (page) => {
        setSelectedPage(page);
        if (page === 1) {
            setDisplayData(brandStoreRegionWiseDatas.slice(0, 20))
        } else {
            setDisplayData(brandStoreRegionWiseDatas.slice((20 * (page - 1)), (page * 20)))
        }
    }
    const onClickRedirect = (brandName) => {
        history.push('/app/storeManagement');
        dispatch(storeManagementActions.setBrandName({ type: brandName }));
    }

    return (
        <div className='pb-4'>
            <div className='d-flex justify-content-between align-items-baseline'>
                <div className='breadCrumbs'>
                    <span className='fontLight'> <Link style={{ color: "#161138" }} to="/app/storeDashboard">Home</Link> </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'> Brand Store Count</span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>Active Brand</span>
                </div>
            </div>
            <div className='paddingTop24'>
                <div className='brandDataContainer'>
                    {brandStoreRegionWiseDatas && currentItems?.map((item, id) => (
                        <div className='brandCard' key={id}>
                            <div className='redirectIcon'>
                                <button onClick={() => onClickRedirect(item?.brand_name)}>
                                    <img src={RedirectIcon} alt="" />
                                </button>
                            </div>
                            <div className='brandDetailsDiv'>
                                <div className='imgDiv'>
                                    <img height={42} src={item?.brand_logo} alt="" />
                                </div>
                                <div className='montSerratFont fontSize18 mt-1'>{item?.brand_name ? item?.brand_name : '-'}</div>
                                <div className='fontSize24 fontSemiBold notoSansFont'>{item?.total_count}</div>
                                <div className='montSerratFont fontSize12'>Total store count</div>
                            </div>
                            <div className='notoSansFont pt-2'>
                                <div className='textColorBlack fontSize12'>Region Overview</div>
                                <div className='pt-2 displayRegions'>
                                    {item?.region_details.map(region => (<div>
                                        <p className='fontSize12 fontLight text-capitalize textColorGray'>{region?.region}</p>
                                        <p className='fontSize14 fontSemiBold'>{region?.count}</p>
                                    </div>))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='paddingTop16 d-flex justify-content-end customPagination'>
                <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={brandStoreRegionWiseDatas?.length} />
            </div>
        </div>
    )
}

export default BrandStoreView