import React, { useState, useEffect } from 'react';
import '../BrandManagement.css';
import '../../../global.css';
import Loader from '../../../ReUsableComponent/Loader';
import RedirectIcon from './../../../assets/svg/redirectIcon.svg';
import hrxLogo from './../../../assets/png/hrxLogo.png';
import CustomPagination from '../../../ReUsableComponent/Pagination';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';
import { brandManagementActions } from '../../../store/reducers/BrandManagementReducer';
import { getAllBrands } from '../BrandManagement.service';
import { ToastErrorService } from '../../../ReUsableComponent/ToastNotification/ToastService';


const ViewCardBrandManagement = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const USER_ID = useSelector((state) => state.userAuthentication.userId);
    const [loading, setLoading] = React.useState(false);
    const [selectedPage, setSelectedPage] = useState(1);
    const [allBrands, setAllBrands] = useState([]);
    // const userName = useSelector((state) => state.userAuthentication.userName);

    useEffect(() => {
        dispatch(userAuthActions.setIsBackBtnActive({ type: false }));
        dispatch(brandManagementActions.setTableFilters({ type: {} }));
        dispatch(userAuthActions.setActivePage({ type: '/brandManagement' }));
        dispatch(userAuthActions.setHeaderName({ type: 'Brand Management' }));
        getAllBrand();
    }, [USER_ID])


    const handlePageChange = (pageValue) => {
        setSelectedPage(pageValue)
    }

    const onClickGotoMaster = () => {
        dispatch(brandManagementActions.setSelectedPage({ type: 1 }));
        history.push('/app/brandManagement');
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
        // dispatch(storeManagementActions.setStoreStatus({ type: selectedStatus?.value }));
        // dispatch(storeManagementActions.setStoreClass({ type: '' }));
        // alert('coming soon!!!');
    }

    const onClickBrand = (brandName, brandLogo, brandId) => {
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
        let brandObj = {
            label: brandName,
            value: brandId
        }
        dispatch(brandManagementActions.setSelectedBrand({ type: brandObj }));
        dispatch(brandManagementActions.setDefaultBrand({ type: brandObj }));
        dispatch(brandManagementActions.setSelectedBrandLogo({ type: brandLogo }));
        history.push('/app/brandDashboard');
    }

    const getAllBrand = () => {
        setLoading(true);
        let param = `?user_id=${USER_ID}`
        getAllBrands(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                setAllBrands(response.data.data);
                let brands = []
                response.data.data.forEach(element => {
                    let brandObject = {
                        label: element.brand_name,
                        value: element.brand_id
                    }
                    brands.push(brandObject)
                });
                dispatch(brandManagementActions.setBrandDropdownList({ type: brands }));
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='mainContainer notoSansFont'>
            <div className='storeDashboardFilter'>
                <div className='FilterSubCont d-flex align-items-end'>
                    <div className='goToMasterBtn me-3' onClick={onClickGotoMaster}>Go to Master</div>
                </div>
            </div>
            <div className='paddingTop24 '>
                <div className='cardBrandManagementContainer display-height customScroll'>
                    {allBrands.map(item => (<div className='eachBrandCard customShadow' onClick={() => onClickBrand(item.brand_name, item.brand_logo, item.brand_id)}>
                        <div className='redirectIconCont'>
                            <button>
                                <img src={RedirectIcon} alt={"RedirectIcon"} />
                            </button>
                        </div>
                        <div className='brandLogo'>
                            <div className='d-flex align-items-center justify-content-center' style={{ width: '50px', height: '50px' }}>
                                <img style={{ width: '100%' }} src={item.brand_logo} alt="" />
                            </div>
                        </div>
                        <div className='brandDicriptionDetails'>
                            <p className='brandName montserratFont'>{item.brand_name}</p>
                            <p className='brandTotalStoreCount'>{item.storeCount}</p>
                            <p className='brandTotalStore montserratFont'>Total store count</p>
                        </div>
                    </div>))}
                </div>
                {/* <div className='d-flex justify-content-end customPagination'>
                    <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={allBrands.length} />
                </div> */}
            </div>
            {loading && <Loader />}
        </div>
    )
}

export default ViewCardBrandManagement