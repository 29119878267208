import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userId: '',
    viewUserDetails: [],
    selectedTab: 0,
    viewUserDetailsTabLabel: 'User Personal Information',
    tableFilters: [],
    viewUserDetailData: {},
    searchUser: false,
    selectedPage: 1,
    storeList: [],
    brandList: [],
    reportingMngr: {},
    designations: {},
    userRoles: {},
    channels: {},
    selectedStores: [],
    selectedStoreNames: [],
    selectedBrands: [],
    pan: {},
    aadhaar: {},
    isEditFormVisible: false,
    statesList: [],
    selectedStates: [],
    createUserRole: '',
};

const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
        setUserId: (state, action) => {
            state.userId = action.payload.type
        },
        setViewBrandDetails: (state, action) => {
            state.viewUserDetails = action.payload.type
        },
        setViewUserDetailsTabLabel: (state, action) => {
            state.viewUserDetailsTabLabel = action.payload.type
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload.type
        },
        setTableFilters: (state, action) => {
            state.tableFilters = action.payload.type
        },
        setViewUserDetailData: (state, action) => {
            state.viewUserDetailData = action.payload.type
        },
        setSearchUser: (state, action) => {
            state.searchUser = action.payload.type
        },
        setSelectedPage: (state, action) => {
            state.selectedPage = action.payload.type
        },
        setStoreList: (state, action) => {
            state.storeList = action.payload.type
        },
        setBrandList: (state, action) => {
            state.brandList = action.payload.type
        },
        setUserRoles: (state, action) => {
            state.userRoles = action.payload.type
        },
        setDesignations: (state, action) => {
            state.designations = action.payload.type
        },
        setReportingMngr: (state, action) => {
            state.reportingMngr = action.payload.type
        },
        setChannels: (state, action) => {
            state.channels = action.payload.type
        },
        setSelectedStores: (state, action) => {
            state.selectedStores = action.payload.type
        },
        setSelectedStoreNames: (state, action) => {
            state.selectedStoreNames = action.payload.type
        },
        setSelectedBrands: (state, action) => {
            state.selectedBrands = action.payload.type
        },
        setPan: (state, action) => {
            state.pan = action.payload.type
        },
        setAadhaar: (state, action) => {
            state.aadhaar = action.payload.type
        },
        setIsEditFormVisible: (state, action) => {
            state.isEditFormVisible = action.payload.type
        },
        setStatesList: (state, action) => {
            state.statesList = action.payload.type
        },
        setSelectedStates: (state, action) => {
            state.selectedStates = action.payload.type
        },
        setCreateUserRole: (state, action) => {
            state.createUserRole = action.payload.type
        },
    }
});

export const userManagementActions = userManagementSlice.actions;
export default userManagementSlice;
