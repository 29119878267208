import axios from "axios";
import API_CONFIG from "../../Configurations/ApiDeclaration";

export function GetList(queryParam, token) {
    const URL = API_CONFIG.FETCH_TARGET_VS_ACHIEVEMENT_LIST + queryParam;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function DeleteList(queryParam, token) {
    const URL = API_CONFIG.FETCH_TARGET_VS_ACHIEVEMENT_LIST + queryParam;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.delete(URL, headers);
}
export function DownloadList(queryParam, token) {
    const URL = API_CONFIG.DOWNLOAD_TARGET_VS_ACHIEVEMENT_LIST + queryParam;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function DownloadTempleteList(queryParam, token) {
    const URL = API_CONFIG.DOWNLOAD_TEMPLETE_TARGET_VS_ACHIEVEMENT_LIST + queryParam;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetDashboardData(queryParam, token) {
    const URL = API_CONFIG.TARGET_VS_ACHIEVEMENT_DASHBOARD_DATA + queryParam;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function BulkInsert(formdata, param, token) {
    const URL = API_CONFIG.BULK_INSERT + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, formdata, headers);
}