import React, { useState } from 'react';
import CustomUserCircle from './../../../ReUsableComponent/CustomUserCircle';
import CustomBackIcon from './../../../ReUsableComponent/CustomBackIcon';
import './HierarchyManagerCircle.css';

const HierarchyManagerCircle = ({ handleTableData, handleBackClick, setReporteeLevels, reporteeLevels, setCurrentLevel, currentLevel, setPreviousId }) => {
    const [lastClickedIds, setLastClickedIds] = useState([]);
    const [savedLevels, setSavedLevels] = useState([]);

    const handleCircleClick = (currentLevelIndex, reportee, id) => {
        setLastClickedIds(prevIds => {
            const newIds = [...prevIds];
            newIds[currentLevelIndex] = id;
            return newIds;
        });

        if (currentLevelIndex < 3) {
            setReporteeLevels(prevLevels => {
                const newLevels = [...prevLevels];
                newLevels[currentLevelIndex + 1] = reportee.reportees || [];
                return newLevels;
            });

            setSavedLevels(prevLevels => {
                const newLevels = [...prevLevels];
                newLevels[currentLevelIndex] = reporteeLevels[currentLevelIndex];
                return newLevels;
            });

            setPreviousId(prevId => {
                const newIds = [...prevId];
                newIds[currentLevelIndex + 1] = id;
                return newIds;
            });

            setCurrentLevel(currentLevelIndex + 1);
        }
    };

    const handleBackArrowClick = (levelIndex) => {
        setCurrentLevel(levelIndex);

        setLastClickedIds(prevIds => {
            const newIds = [...prevIds];
            newIds.length = levelIndex + 1; // Remove the clicked ID for the next levels
            return newIds;
        });

        setReporteeLevels(savedLevels.slice(0, levelIndex + 1)); // Restore the saved levels

        setPreviousId(prevId => {
            const newIds = [...prevId];
            newIds.length = levelIndex + 1; // Clear the ID for the next levels
            return newIds;
        });

        if (handleBackClick) {
            handleBackClick(levelIndex);
        }
    };

    return (
        <div className="circle-container">
            {reporteeLevels?.map((levelReportees, index) => (
                index <= currentLevel && (
                    <div key={index} className="level-container">
                        {index > 0 && (
                            <div className="back-arrow-container">
                                <CustomBackIcon
                                    onClick={() => handleBackArrowClick(index - 1)}
                                    isBackIcon={true}
                                />
                            </div>
                        )}
                        <div className="reportee-container">
                            {levelReportees?.map((reportee) => (
                                (lastClickedIds[index] === undefined || lastClickedIds[index] === reportee.Id) && (
                                    <CustomUserCircle
                                        key={reportee.Id}
                                        number={reportee.reportee_count}
                                        initials={reportee.name}
                                        onCircleClick={() => {
                                            handleCircleClick(index, reportee, reportee?.Id);
                                            handleTableData(index, reportee?.Id);
                                        }}
                                    />
                                )
                            ))}
                        </div>
                    </div>
                )
            ))}
        </div>
    );
};

export default HierarchyManagerCircle;
