import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import '../Notifications.css';
import { Link, useHistory } from 'react-router-dom';
import { GetBrandNameList, GetStoreCustomerNameList } from '../../Promotion/promotion.service';
import MultiSelectCheckBox from '../../../ReUsableComponent/MultiSelectCheckBox';
import { GetReportingManagerList } from '../../TeamManagement/TeamManagement.service';
import InputTextField from '../../../ReUsableComponent/InputTextField';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import Loader from '../../../ReUsableComponent/Loader';
import { SubmitNotificationFormDetails } from '../promotions.service';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    getContentAnchorel: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center"
    },
    variant: "menu"
};
const CreateNotifications = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [brandList, setBrandList] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [selectedStores, setSelectedStores] = useState([]);
    const [selectedBrandId, setSelectedBrandId] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newErrors, setNewErrors] = useState({});
    const notiDatas = useSelector(state => state.notification.notiFormData);
    const [formData, setFormData] = useState({});
    const TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const EditNoti = useSelector((state) => state.notification.editNotiForm);

    useEffect(() => {
        dispatch(userAuthActions.setActivePage({ type: '/notifications' }));
        dispatch(userAuthActions.setHeaderName({ type: 'Notifications' }));
        getBrandList();
        getStoreList();
    }, [])

    useEffect(() => {
        setFormData(notiDatas)
    }, [EditNoti])


    useEffect(() => {
        getStoreList();
    }, [selectedBrandId])

    useEffect(() => {
        getUserList();
    }, [selectedBrandId, selectedStores])


    const getBrandList = () => {
        let param = '';
        GetBrandNameList(param, TOKEN).then(response => {
            let brandData = []
            response.data.data.forEach(brand => {
                if (brand.brand_name) {
                    let brandOption = {
                        label: brand?.brand_name,
                        value: brand?.brand_name,
                        brandId: brand?._id
                    }
                    brandData.push(brandOption);
                }
            })
            setBrandList(brandData)
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
        }).finally(() => {

        })
    }

    const getStoreList = () => {
        let param = `?brand_id=${selectedBrandId?.map(brand => brand.brandId).join(',')}`;
        GetStoreCustomerNameList(param, TOKEN).then(response => {
            let storeData = []
            response.data.data.forEach(store => {
                let storeOption = {
                    label: store?.customer_name,
                    value: store?.customer_name,
                    storeId: store?._id,
                    storeCode: store?.store_code
                }
                storeData.push(storeOption);
            })
            setStoreList(storeData)
        }).catch(error => {
            console.log(error)
            // if (error.response.status === 401) {
            //     history.push('/')
            // }
        }).finally(() => {

        })
    }

    const handleInputChange = (field, value) => {
        if (field === 'store') {
            if (value[value.length - 1] === "all") {
                if (selectedStores?.length === storeList?.length) {
                    setSelectedStores([]);
                    return;
                } else {
                    let storeDetails = []
                    storeList.map(store => {
                        storeDetails.push({ storeId: store.storeId, storeName: store.value });
                    })
                    setSelectedStores(storeDetails);
                    return;
                }
            } else {
                let storeDetails = [];
                setSelectedStores(storeDetails = value.map(storeName => {
                    const selectedStore = storeList?.find(store => store.value === storeName);
                    return selectedStore ? {
                        storeId: selectedStore.storeId, storeName: selectedStore.value
                    } : null
                }))
            }

        } else if (field === 'brand') {
            if (value[value.length - 1] === "all") {
                if (selectedBrandId?.length === brandList?.length) {
                    setSelectedBrandId([]);
                    return;
                } else {
                    let brandsDetails = [];
                    brandList.map(brand => {
                        brandsDetails.push({ brandId: brand.brandId, brandName: brand.value });
                    })
                    setSelectedBrandId(brandsDetails);
                    return;
                }
            } else {
                let brandsDetails = [];
                setSelectedBrandId(brandsDetails = value.map(brnadName => {
                    const selectedBrand = brandList?.find(brand => brand.value === brnadName);
                    return selectedBrand ? { brandId: selectedBrand.brandId, brandName: selectedBrand.value } : null
                }))
            }
        } else if (field === 'user') {
            setNewErrors(prev => ({
                ...prev, emp_ids: '',
            }))
            if (value[value.length - 1] === "all") {
                if (selectedUsers?.length === userList?.length) {
                    setSelectedUsers([]);
                    setFormData(prev => ({
                        ...prev, 'data': { emp_ids: [] }
                    }))
                    return;
                } else {
                    let userDetails = [];
                    userList.map(user => {
                        userDetails.push({ userName: user.value, userId: user.userEmail });
                    });
                    setSelectedUsers(userDetails)
                    setFormData(prev => ({
                        ...prev, data: { emp_ids: [...userDetails.map(user => user.userName)] }
                    }))
                    return;
                }
            } else {
                let userDetails = [];
                setSelectedUsers(userDetails = value.map(userName => {
                    const selectedUser = userList?.find(user => user.value === userName);
                    return selectedUser ? { userName: selectedUser.value, userId: selectedUser.emp_id } : null
                }))
                setFormData(prev => ({
                    ...prev, data: { emp_ids: [...userDetails.map(user => user.userId)] }
                }))
            }
        } else if (field === 'title') {
            setFormData(prev => ({
                ...prev, notification: {
                    ...prev.notification,
                    title: value
                }
            }))
            setNewErrors(prev => ({
                ...prev, title: '',
            }))
        } else if (field === 'body') {
            setFormData(prev => ({
                ...prev, notification: { ...prev.notification, body: value }
            }))
            setNewErrors(prev => ({
                ...prev, body: ''
            }))
        }
    }

    const validateForm = () => {
        const newError = {}
        if (formData.data.emp_ids.length === 0) newError.emp_ids = 'At least 1 User is required';
        if (!formData.notification.title.trim()) newError.title = 'Notification subject is required'
        if (!formData.notification.body.trim()) newError.body = 'Notification body is required'
        setNewErrors(newError);
        return Object.keys(newError).length === 0;
    }

    const getUserList = () => {
        let param = `?brand_id=${selectedBrandId?.map(brand => brand.brandId)?.join(',')}&store_id=${selectedStores.map(store => store.storeId)?.join(',')}`
        GetReportingManagerList(param, TOKEN).then(response => {
            let userData = []
            response.data.data.forEach(user => {
                let userOption = {
                    label: user?.name,
                    value: user?.name,
                    userEmail: user?.email,
                    emp_id: user?.user_id
                }
                userData.push(userOption);
            })
            setUserList(userData)
        }).catch(error => {
            console.log(error)
        }).finally(() => {

        })
    }

    const handleCreateNotification = () => {
        if (validateForm()) {
            setLoading(true);
            SubmitNotificationFormDetails(formData, TOKEN).then(response => {
                ToastSuccessService('Notification sent successfully.');
                history.push('/app/notifications')
            }).catch(error => {
                ToastErrorService(error?.response?.data?.message);
            }).finally(() => {
                setLoading(false);
            })
        } else {
            console.log(newErrors)
        }
    }

    const onClickCancel = () => {
        history.push('/app/notifications')
    }

    return (
        <>
            <div className='mainContainer notoSansFont'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='breadCrumbs'>
                        <span className='fontLight'>
                            <Link className="breadCrumbs" to={'/app/notifications'}>Home</Link>
                        </span>
                        <span>
                            {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                        </span>
                        <span className='fontSemiBold'>{EditNoti ? 'Edit' : 'Create'} Notification</span>
                    </div>
                </div>
                <div className=' notification-cont'>
                    <div className='montSerratFont fontMedium fontSize18 textColorBlack'>
                        Notification Details
                    </div>
                    <div className='noti-form-cont'>
                        <div className='notification-form mt-3'>
                            <div className='inputBottomSpace'>
                                <MultiSelectCheckBox showSelectAll={true}
                                    handleChange={(e) => handleInputChange('brand', e?.target?.value)}
                                    selectedValue={selectedBrandId?.map(brand => brand.brandName)}
                                    options={brandList}
                                    MenuProps={MenuProps}
                                    label='Brands'
                                />
                            </div>
                            <div className='inputBottomSpace'>
                                <MultiSelectCheckBox showSelectAll={true}
                                    handleChange={(e) => handleInputChange('store', e?.target?.value)}
                                    selectedValue={selectedStores?.map(store => store.storeName)}
                                    options={storeList}
                                    MenuProps={MenuProps}
                                    label='Stores'
                                />
                            </div>
                        </div>
                        <div className='notification-form'>
                            <div className='inputBottomSpace'>
                                <MultiSelectCheckBox showSelectAll={true}
                                    handleChange={(e) => handleInputChange('user', e?.target?.value)}
                                    selectedValue={selectedUsers.map(user => user.userName)}
                                    options={userList}
                                    MenuProps={MenuProps}
                                    label='Users*'
                                    error={newErrors.emp_ids === '' ? false : true}
                                />
                                {newErrors.emp_ids !== '' && <div className='fontSize12 ps-1' style={{ color: 'red' }}>{newErrors.emp_ids}</div>}
                            </div>
                            <div className='inputBottomSpace input-p-r'>
                                <InputTextField
                                    onChange={(event, newVal) => handleInputChange('title', event?.target?.value)}
                                    value={formData?.notification?.title}
                                    type='text'
                                    required
                                    disabled={EditNoti}
                                    label='Notification Subject'
                                // InputLabelProps={{ shrink: formData.notification.title ? true : false }}
                                />
                                {newErrors.title !== '' && <div className='fontSize12 ps-1' style={{ color: 'red' }}>{newErrors.title}</div>}
                            </div>
                        </div>
                        <div className='notification-form'>
                            <div className='inputBottomSpace'>
                                <div className='notification-body'>
                                    <textarea placeholder='Notification Body' rows={6} value={formData?.notification?.body}
                                        onChange={(e) => handleInputChange('body', e.target.value)} disabled={EditNoti} />
                                </div>
                                {newErrors.body !== '' && <div className='fontSize12 ps-1' style={{ color: 'red' }}>{newErrors.body}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pt-3'>
                    <div className='bg-white continueBtn d-flex'>
                        <div className='pe-3'>
                            <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                        </div>
                        <div>
                            <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleCreateNotification} title={'Submit'} />
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </>
    )
}

export default CreateNotifications